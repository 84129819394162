export default function VideoEmbed({ entry }) {
  // replace view links with embed links
  const url = entry.fields.url
    .replace(
      "https://share.descript.com/view/",
      "https://share.descript.com/embed/",
    )
    .replace(
      "https://www.youtube.com/watch/",
      "https://www.youtube.com/embed/",
    );

  return (
    <div>
      <iframe
        src={url}
        style={{ maxWidth: "100%", width: "100%", height: 390, border: 0 }}
        title={entry.fields.title}
        allowFullScreen={true}
      />
    </div>
  );
}
