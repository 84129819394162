import AllRoleplaySection from "@/components/pages_logged_in/roleplays/AllRoleplaySection";
import RoleplaysHeader from "@/components/pages_logged_in/roleplays/RoleplaysHeader";

export function RoleplaysListContainer({ pageLoading = false }) {
  return (
    <>
      <RoleplaysHeader pageLoading={pageLoading} />
      <AllRoleplaySection pageLoading={pageLoading} />
    </>
  );
}
