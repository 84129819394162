import { useEffect, useState } from "react";

import { useLazyQuery, useMutation } from "@apollo/client";
import truncate from "lodash/truncate";

import {
  CreateOrderDocument,
  PendingOrderDocument,
  PendingOrderQuery,
  UpdateOrderDocument,
} from "@/graphql";

// TODO: since this component is used for fetching not just pending
// orders, a later PR should update the names used for better clarity.
export default function usePendingOrder({
  id = null,
  key = null,
  defaultProductQuantities = null,
  customerId = null,
  allowCreate = true,
  withCoachInfo = false,
  withRecommendations = false,
}): [
  PendingOrderQuery["pendingOrder"] | null,
  {
    syncing: boolean;
    setItems: (productQuantities: Record<string, number>) => void;
    setOrder: (order: PendingOrderQuery["pendingOrder"] | null) => void;
  },
] {
  const [initializing, setInitializing] = useState(true);
  const [order, setOrder] = useState<PendingOrderQuery["pendingOrder"] | null>(
    null,
  );

  const setItems = (productQuantities) => {
    updateOrder({
      variables: {
        id: order.id,
        customerId,
        productQuantities,
        withCoachInfo,
        withRecommendations,
      },
    });
  };
  const doCreate = () => {
    createOrder({
      variables: {
        productQuantities: defaultProductQuantities,
        key: truncate(key, {
          length: 100,
          omission: "",
        }),
        customerId,
        withCoachInfo,
        withRecommendations,
      },
    });
  };

  const [fetch, { loading: fetching }] = useLazyQuery<PendingOrderQuery>(
    PendingOrderDocument,
    {
      onCompleted: (data) => {
        // If we get an order back, serve it up.
        // Otherwise, create the order instead.
        if (data && data.pendingOrder) {
          setOrder(data.pendingOrder);
          setInitializing(false);
        } else if (allowCreate) {
          doCreate();
        } else {
          setInitializing(false);
        }
      },
      onError: (error) => {
        // If there is a permissions error on the fetch attempt,
        // create the order instead
        if (error && error.message && error.message.includes("Forbidden")) {
          doCreate();
        }
      },
    },
  );
  const [createOrder, { loading: creating }] = useMutation(
    CreateOrderDocument,
    {
      onCompleted: (r) => {
        if (r && r.createOrder && r.createOrder.order) {
          setOrder(r.createOrder.order);
        }
        setInitializing(false);
      },
    },
  );
  const [updateOrder, { loading: updating }] = useMutation(
    UpdateOrderDocument,
    {
      onCompleted: (r) => {
        if (r && r.updateOrder && r.updateOrder.order) {
          setOrder(r.updateOrder.order);
        }
      },
    },
  );

  // Upon page load, try to fetch an existing order
  useEffect(() => {
    fetch({
      variables: {
        id,
        key,
        withRecommendations,
        withCoachInfo,
        customerId,
        pendingOnly: allowCreate,
      },
    });
  }, []);

  return [
    order,
    {
      syncing: initializing || fetching || creating || updating,
      setItems,
      setOrder,
    },
  ];
}
