import { ReactNode } from "react";

import { useMutation } from "@apollo/client";
import { Menu, MenuDivider, MenuItem } from "@blueprintjs/core";
import idx from "idx";
import { useNavigate } from "react-router-dom";

import { isPendingBookingRequest } from "@/components/helpers/meeting/is-pending-booking-request";
import { Popover } from "@/components/helpers/ui/blueprint-overrides/Popover";
import { isAttendees } from "@/components/helpers/workspace/permissions";
import { getMeetingStatus } from "@/components/pieces/coaching/meetings/utils";
import { StyledButton } from "@/components/pieces/link/LinkButton";
import {
  FullMeetingQuery,
  SendGoogleInviteCoachMeetingDocument,
} from "@/graphql";

interface MeetingHeaderButtonProps {
  label: ReactNode;
  isCoach: boolean;
  meeting: FullMeetingQuery["meeting"];
  setSentSuccessVisible?: (visible: boolean) => void;
  onCancel: (shouldCancel: boolean) => void;
  view?: string;
}

export default function MeetingHeaderButton({
  label,
  isCoach,
  meeting,
  setSentSuccessVisible,
  onCancel,
  view = "coach",
}: MeetingHeaderButtonProps) {
  const meetingStatus = getMeetingStatus(meeting);
  const showMenuButton = meetingStatus === "UPCOMING";

  const allowReschedule = isCoach ? true : !meeting.isBookingRequest;
  const customerId = meeting.customerId;
  const navigate = useNavigate();
  const [sendGoogleInviteCoachMeeting] = useMutation(
    SendGoogleInviteCoachMeetingDocument,
    {
      variables: {
        meetingId: meeting?.id,
      },
      onCompleted: (response) => {
        if (idx(response, (_) => _.sendGoogleInviteCoachMeeting.ok)) {
          setSentSuccessVisible?.(true);
          setTimeout(() => {
            setSentSuccessVisible?.(false);
          }, 3000);
        }
      },
    },
  );

  if (!showMenuButton || isPendingBookingRequest(meeting)) {
    return null;
  }

  const rescheduleUrl =
    view === "assist"
      ? `/assist/${customerId}/sessions/${meeting.id}/reschedule`
      : `/sessions/${meeting.id}/reschedule`;

  if (isAttendees(meeting)) {
    return null;
  }

  const menuContent = (
    <Menu>
      {allowReschedule && (
        <>
          <MenuItem
            onClick={() => navigate(rescheduleUrl)}
            text="Reschedule"
            icon="calendar"
          />
          <MenuDivider />
        </>
      )}
      {isCoach && !meeting.googleEventId && setSentSuccessVisible && (
        <>
          <MenuItem
            onClick={() => sendGoogleInviteCoachMeeting()}
            text="Send Google Invite"
            icon="calendar"
          />
          <MenuDivider />
        </>
      )}
      <MenuItem
        onClick={() => {
          onCancel(true);
        }}
        text="Cancel"
        icon="trash"
      />
    </Menu>
  );

  return (
    <Popover
      content={menuContent}
      placement="bottom-end"
      minimal={true}
      canEscapeKeyClose={true}
    >
      <StyledButton>{label}</StyledButton>
    </Popover>
  );
}
