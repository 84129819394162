/**
 * Avoid lagging when switching icon via state
 */
export default function ({ activeIcon, inactiveIcon, isActive }) {
  return (
    <span>
      <span style={{ display: isActive ? "inline-block" : "none" }}>
        {activeIcon}
      </span>
      <span style={{ display: !isActive ? "inline-block" : "none" }}>
        {inactiveIcon}
      </span>
    </span>
  );
}
