import {
  getJwtToken,
  getRefreshToken,
} from "@/components/session/JwtTokenManager";

const endpoint = "/files";

export function uploadFile(file: File) {
  const formData = new FormData();
  formData.append("file", file);
  formData.append("name", file.name);
  return fetch(`${API_HOST}${endpoint}`, {
    method: "POST",
    body: formData,
    headers: {
      Authorization: `JWT ${getJwtToken()}`,
      Session: getRefreshToken(),
      IsExec: "true",
    },
  })
    .then((response) => {
      if (!response.ok) {
        return response.text().then(Promise.reject.bind(Promise));
      }
      return response.json();
    })
    .then((data) => {
      return {
        id: data.user_file.id,
        name: data.user_file.name,
        size: data.user_file.size,
        type: data.user_file.file_type,
        url: `${API_HOST}${data.url}`,
      };
    });
}
