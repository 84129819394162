import { CSSProperties, ReactNode } from "react";

import { Colors } from "@blueprintjs/core";
import { useMediaQuery } from "react-responsive";

import { Box } from "@/components/layout/flexbox";
import { isXsQuery } from "@/components/layout/Media";

export default function PageTitle({
  title,
  children = null,
  subtitle = null,
  style = {},
}: {
  title?: ReactNode;
  children?: ReactNode;
  subtitle?: ReactNode;
  style?: CSSProperties;
}) {
  const isXs = useMediaQuery(isXsQuery);
  const fontSize = style?.fontSize || 36;
  return (
    <Box mb={[3, 36]} sx={style}>
      <h1
        style={{
          fontSize: isXs ? 24 : fontSize,
          fontWeight: 600,
          lineHeight: 1.3,
        }}
      >
        {title}
        {children}
      </h1>
      {subtitle ? (
        <Box
          css={{
            color: Colors.GRAY2,
            fontSize: isXs ? 18 : 24,
          }}
          mt={[0, 3]}
        >
          {subtitle}
        </Box>
      ) : null}
    </Box>
  );
}
