import React from "react";

import { Button, ButtonProps, Classes } from "@blueprintjs/core";
import styled from "@emotion/styled";

import { breakpoints } from "@/components/layout/Media";
import { BRAND_PURPLE } from "@/css/constants";

export const StyledButton = styled(Button)`
  &.${Classes.BUTTON}.${Classes.INTENT_PRIMARY} {
    transition: all 50ms ease;
    border-radius: 6px;
    font-weight: 600;
    padding: 10px 14px;
    border: 1px solid ${BRAND_PURPLE};
    box-shadow: none;
    background-image: none;
    &:hover {
      box-shadow: none;
      border: 1px solid #5354bb;
    }
  }
  :focus {
    outline: none;
    box-shadow: none;
    outline-offset: 0;
  }

  @media screen and (max-width: ${breakpoints.sm}px) {
    &.${Classes.BUTTON}.${Classes.INTENT_PRIMARY} {
      padding: 6px 10px;
      font-size: 15px;
    }
  }
`;

export default function PrimaryButton({
  children,
  ...rest
}: ButtonProps & React.HTMLAttributes<HTMLElement>) {
  return (
    <StyledButton intent="primary" {...rest}>
      {children}
    </StyledButton>
  );
}
