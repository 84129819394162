import { useContext, useEffect, useRef } from "react";

import { useHotkeys } from "react-hotkeys-hook";
import { useMediaQuery } from "react-responsive";

import hideVirtualKeyboard from "@/components/helpers/ui/hide-virtual-keyboard";
import { Box } from "@/components/layout/flexbox";
import { isXsQuery } from "@/components/layout/Media";
import { PageSizeContext } from "@/components/layout/PageSize";
import BackDrop from "@/components/pieces/BackDrop";
import { PORTAL_Z_INDEX, STANDARD_NAV_HEIGHT } from "@/css/constants";

export default function Overlay(props) {
  const { visible, minimizedOffset } = props;

  const isXs = useMediaQuery(isXsQuery);
  const { windowHeight } = useContext(PageSizeContext);
  useEffect(() => {
    if (!visible || minimizedOffset) {
      hideVirtualKeyboard();
    }
  }, [visible, minimizedOffset]);

  return (
    <OverlayWithHotkeys {...props} isXs={isXs} windowHeight={windowHeight} />
  );
}

function OverlayWithHotkeys({
  isXs,
  windowHeight,
  visible,
  minimizedOffset,
  top,
  backgroundColor,
  children,
  animationSpeed = "0.4s",
  onFullyOpened,
  onFullyClosed,
  onClose,
  onScroll,
  zIndexOffset = 0,
  cardWidth = 450,
  style = {},
}) {
  useHotkeys(
    "escape",
    (e) => {
      if (visible && onClose) {
        onClose();
        e.stopPropagation();
      }
    },
    {},
    [visible, onClose],
  );
  const ref = useRef(null);

  useEffect(() => {
    if (visible && ref) {
      ref.current.focus();
    }
  }, [visible, ref]);
  let translateY = "translateY(0%)";

  if (!visible) {
    translateY = "translateY(120%)";
  } else if (minimizedOffset) {
    if (!windowHeight) {
      translateY = "translateY(120%)";
    } else {
      translateY = `translateY(${windowHeight - top - minimizedOffset}px)`;
    }
  }

  return (
    <>
      <BackDrop visible={visible} zIndex={PORTAL_Z_INDEX} opacity={0.6} />
      <Box
        ref={ref}
        style={{
          position: "fixed",
          left: 0,
          bottom: 0,
          right: 0,
          margin: "0 auto",
          backgroundColor,
          top: top || (isXs ? 0 : STANDARD_NAV_HEIGHT),
          visibility: visible ? "visible" : "hidden",
          transform: translateY,
          transition: visible
            ? `transform ${animationSpeed}`
            : `transform ${animationSpeed}, visibility 0s ${animationSpeed}`,
          zIndex: PORTAL_Z_INDEX + zIndexOffset,
          borderTopLeftRadius: isXs ? 0 : 6,
          borderTopRightRadius: isXs ? 0 : 6,
          ...style,
        }}
        width={[1, cardWidth]}
        onTransitionEnd={() => {
          if (visible && onFullyOpened) {
            onFullyOpened();
          }

          if (!visible && onFullyClosed) {
            onFullyClosed();
          }
        }}
        onScroll={() => {
          if (onScroll) {
            onScroll(ref.current);
          }
        }}
      >
        {children}
      </Box>
    </>
  );
}
