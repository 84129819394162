import { Classes, Icon } from "@blueprintjs/core";
import { Link } from "react-router-dom";

import { Box, Flex } from "@/components/layout/flexbox";

function SetPassword() {
  return (
    <Flex
      className="bg-dark"
      justifyContent="space-around"
      alignItems="center"
      px={2}
      py={[2, 1]}
      style={{ minHeight: 50 }}
    >
      <Flex alignItems="center" flexWrap="wrap">
        <Flex alignItems="top" className={Classes.DARK} mb={[1, 0]}>
          <Icon icon="error" color="#CFDDE9" style={{ marginTop: 2 }} />
          <Box ml={2}>Please set a password so you can login in the future</Box>
        </Flex>
        <Box ml={2} pl={[3, 1]} my={[1, 0]}>
          <Link className={Classes.BUTTON} to="/set-password">
            Set Password
          </Link>
        </Box>
      </Flex>
    </Flex>
  );
}

export default SetPassword;
