import { Radio, RadioGroup } from "@blueprintjs/core";
import { PaymentRequestButtonElement } from "@stripe/react-stripe-js";

import { Box } from "@/components/layout/flexbox";

import { CARD_BRANDS } from "./constants";
import NewPaymentMethodForm from "./NewPaymentMethodForm";

export default function CardSelector({
  paymentMethods,
  paymentRequest,
  selectedMethodId,
  setSelectedMethodId,
  billingName,
  setBillingName,
  handleSubmit,
}) {
  return (
    <Box
      className="bas round-corners"
      backgroundColor="#FFF"
      pt={24}
      pb={3}
      px={[3, 24]}
    >
      {paymentRequest ? (
        <Box mb={24} maxWidth={300}>
          <PaymentRequestButtonElement options={{ paymentRequest }} />
        </Box>
      ) : null}
      <RadioGroup
        onChange={(e) => setSelectedMethodId(e.currentTarget.value)}
        selectedValue={selectedMethodId}
      >
        {paymentMethods &&
          paymentMethods.map((method) => (
            <Radio
              labelElement={
                <>
                  <b>{CARD_BRANDS[method.brand]}</b> ending in {method.last4}
                </>
              }
              large={true}
              value={method.stripeId}
              key={method.stripeId}
            />
          ))}
        <Radio
          large={true}
          key={`add-new-method`}
          labelElement={<b>Add new card</b>}
          value="NEW"
        />
      </RadioGroup>
      {selectedMethodId === "NEW" ? (
        <NewPaymentMethodForm
          billingName={billingName}
          setBillingName={setBillingName}
          handleSubmit={handleSubmit}
        />
      ) : null}
    </Box>
  );
}
