import { useSSRMediaQuery } from "@/components/helpers/custom-hooks/use-ssr-media-query";

/**
 * Hook that returns the number of cards to display per row based on screen size
 * @param defaultCards - Default number of cards (optional, defaults to 4)
 * @returns Number of cards to display per row
 */
export function useResponsiveGridSize(defaultCards = 4): number {
  const { isXs, isSm, isMd, isLg, isXl } = useSSRMediaQuery();

  if (isXs) return 2;
  if (isSm) return 2;
  if (isMd) return 2;
  if (isLg) return 4;
  if (isXl) return 4;

  return defaultCards;
}
