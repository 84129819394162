import { useState } from "react";

import { Button, Callout, Intent } from "@blueprintjs/core";

import { useWorkspace } from "@/components/helpers/custom-hooks/use-workspace";
import { Box } from "@/components/layout/flexbox";
import { useUpdateCollectionVisibilityScope } from "@/components/pages_logged_in/roleplays/api";
import useUpdateScenarioVisibilityScope from "@/components/pages_logged_in/roleplays/details/scenario/ScenarioVisibility/useUpdateScenariosVisibilityScope";
import StyledDialog from "@/components/pieces/StyledDialog";
import {
  PublicWorkspaceGraphqlType,
  RoleplayDocument,
  ScenarioDocument,
  ScenarioType,
  VisibilityScope,
} from "@/graphql";

const SEGMENT_TITLES = {
  [VisibilityScope.Global]: "Core ",
  [VisibilityScope.Workspace]: "Workspace ",
  [VisibilityScope.User]: "Personal ",
};

interface UpdateVisibilityScopeDialogProps {
  open: boolean;
  afterUpdatedScope: (senario?: ScenarioType) => void;
  onClose: () => void;
  scopeToUpdate?: VisibilityScope;
  name: string;
  id: string;
  type: "roleplay" | "scenario";
  visibilityScope: VisibilityScope;
  slug: string;
  workspace?: PublicWorkspaceGraphqlType;
}

function UpdateVisibilityScopeDialog({
  open,
  afterUpdatedScope,
  onClose,
  scopeToUpdate,
  slug,
  name,
  id,
  type,
  visibilityScope,
  workspace,
}: UpdateVisibilityScopeDialogProps) {
  const {
    programsEnabled,
    id: currentWorkspaceId,
    name: currentWorkspaceName,
  } = useWorkspace() || {};
  const [errorMessage, setErrorMessage] = useState(null);

  const isDifferentWorkspace = workspace?.id !== currentWorkspaceId;

  const { updateScenarioVisibilityScope, loading: isUpdatingScenarioScope } =
    useUpdateScenarioVisibilityScope({
      refetchQueries: [
        {
          query: ScenarioDocument,
          variables: {
            scenarioSlug: slug,
          },
        },
      ],
      onCompleted: (updatedScenario) => {
        afterUpdatedScope(updatedScenario);
        onClose();
      },
      onError: (error) => {
        setErrorMessage(error?.message);
      },
    });

  const {
    updateCollectionVisibilityScope,
    loading: isUpdatingCollectionScope,
  } = useUpdateCollectionVisibilityScope({
    refetchQueries: [
      {
        query: RoleplayDocument,
        variables: {
          slug: slug,
        },
      },
    ],
    onCompleted: (updatedCollection) => {
      afterUpdatedScope(updatedCollection);
      onClose();
    },
    onError: (error) => {
      setErrorMessage(error?.message);
    },
  });

  const basedMsg = (
    <>
      Are you sure to update {type} <strong>{name}'s</strong> visibility from{" "}
      <strong>{SEGMENT_TITLES[visibilityScope]}</strong> to{" "}
      {scopeToUpdate === VisibilityScope.Workspace ? (
        <>
          <strong>{workspace.name}</strong> workspace
        </>
      ) : (
        <strong>{SEGMENT_TITLES[scopeToUpdate]}</strong>
      )}
      ?
    </>
  );

  const msg =
    VisibilityScope.User == scopeToUpdate && programsEnabled ? (
      <Box>
        <Box>{basedMsg} This will:</Box>
        <ul>
          <li>Update the {type} scope</li>
          <li>Update related setting on program components</li>
        </ul>
      </Box>
    ) : (
      <Box>{basedMsg}</Box>
    );

  const handleUpdateVisibilityScope = () => {
    if (type === "roleplay") {
      updateCollectionVisibilityScope(id, scopeToUpdate);
    } else {
      updateScenarioVisibilityScope(id, scopeToUpdate);
    }
  };

  return (
    <StyledDialog
      isOpen={open}
      lazy={true}
      title={<Box fontSize={18}>Update Confirmation</Box>}
      width="480px"
      onClose={() => {
        onClose();
      }}
      onOpening={() => {
        setErrorMessage(null);
      }}
    >
      <Box py={3} px={24} css={{ lineHeight: "24px" }}>
        {errorMessage && (
          <Box mb={2}>
            <Callout icon={null} intent={Intent.DANGER}>
              {errorMessage}
            </Callout>
          </Box>
        )}
        {msg}
        {isDifferentWorkspace && (
          <Box mb={2} mt={2}>
            <Callout icon={null} intent={Intent.WARNING}>
              You are in <b>{currentWorkspaceName}</b> workspace. This will only
              be visible to users in the <b>{workspace.name}</b> workspace.
            </Callout>
          </Box>
        )}
        <Box mt={3} mb={"5px"}>
          <Button
            intent={Intent.PRIMARY}
            large
            loading={isUpdatingScenarioScope || isUpdatingCollectionScope}
            disabled={
              isUpdatingScenarioScope ||
              isUpdatingCollectionScope ||
              !(id && scopeToUpdate)
            }
            onClick={handleUpdateVisibilityScope}
            style={{ borderRadius: "4px" }}
          >
            Yes, Update Scope
          </Button>
          <Button
            intent={Intent.NONE}
            onClick={onClose}
            large
            style={{
              marginLeft: "10px",
              backgroundColor: "#0719560A",
              borderRadius: "4px",
            }}
            disabled={isUpdatingScenarioScope || isUpdatingCollectionScope}
          >
            Nevermind
          </Button>
        </Box>
      </Box>
    </StyledDialog>
  );
}

export default UpdateVisibilityScopeDialog;
