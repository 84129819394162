import { useState } from "react";

import {
  Classes,
  Colors,
  Icon,
  Intent,
  Portal,
  Toast2,
} from "@blueprintjs/core";
import moment from "moment-timezone";
import { Link } from "react-router-dom";

import { Box, Flex } from "@/components/layout/flexbox";
import SlideContainer, { Directions } from "@/components/layout/SlideContainer";
import CancelMeetingConfirmDialog from "@/components/pieces/coaching/meetings/CancelMeetingConfirmDialog";
import AttendingDropdownButton from "@/components/pieces/coaching/meetings/MeetingAttendees/components/AttendingButton";
import MeetingHeaderButton from "@/components/pieces/coaching/meetings/MeetingHeaderButton";
import { getUser } from "@/components/session/JwtTokenManager";
import { STANDARD_APP_MAX_WIDTH, SUBTEXT_COLOR } from "@/css/constants";
import { FullMeetingQuery } from "@/graphql";

function MeetingHeaderView({
  productName,
  loading,
  meetingDate,
  attendingButton,
  menuButton,
  canceled,
  isCoach,
  customerId,
  view = "coach",
}) {
  const roleView = view === "coach" ? "client" : "assist";
  return (
    <Box className="bbs" py={[2, 24]} bg={Colors.WHITE}>
      <Box maxWidth={STANDARD_APP_MAX_WIDTH} m="0 auto" px={[3, 24]}>
        <Box mb={3}>
          {isCoach || view === "assist" ? (
            <Link to={`/${roleView}/${customerId}/sessions`}>
              <Icon icon="chevron-left" size={18} /> Back To Client
            </Link>
          ) : (
            <Link to="/sessions">
              <Icon icon="chevron-left" size={18} /> View All Sessions
            </Link>
          )}
        </Box>
        <Flex justifyContent="space-between" alignItems="center">
          <Box>
            <Box
              fontSize={28}
              fontWeight={600}
              className={loading ? Classes.SKELETON : null}
            >
              {productName}
            </Box>
            <Flex
              fontSize={18}
              color={SUBTEXT_COLOR}
              mt={1}
              alignItems="center"
              className={loading ? Classes.SKELETON : null}
              style={{
                textDecoration: canceled ? "line-through" : null,
              }}
            >
              <Icon icon="calendar" /> <Box ml={1}>{meetingDate}</Box>
            </Flex>
          </Box>

          {
            /** Note:
             * Currently attendingButton just for attendee
             * while menuButton is not for attendee aswell
             * */
            attendingButton ? attendingButton : menuButton
          }
        </Flex>
      </Box>
    </Box>
  );
}

interface MeetingHeaderProps {
  meeting?: FullMeetingQuery["meeting"];
  loading?: boolean;
  view?: string;
  customerId?: number;
}

export default function MeetingHeader({
  meeting,
  loading,
  view = "coach",
  customerId = null,
}: MeetingHeaderProps) {
  const [showCancelConfirm, setShowCancelConfirm] = useState<boolean>(false);
  const [sentSuccessVisible, setSentSuccessVisible] = useState(false);

  if (loading) {
    return (
      <MeetingHeaderView
        loading={true}
        productName="Loading Data"
        canceled={false}
        meetingDate="Loading the meeting date"
        attendingButton={null}
        menuButton={null}
        isCoach={false}
        customerId={null}
        view={view}
      />
    );
  }

  const browserTimezone = moment.tz.guess();

  const isCoach = getUser().id === meeting.coach.id;

  let meetingDate = meeting.eventAt
    ? moment(meeting.eventAt)
        .tz(browserTimezone)
        .format("dddd, MMM Do [at] h:mm a z")
    : "Awaiting Scheduling";

  if (meeting.canceledAt) {
    meetingDate = "Canceled";
  }
  const currentCustomerId = customerId || meeting.customerId;
  const redirectUrl =
    view === "assist" ? `/assist/${currentCustomerId}/sessions` : "/sessions";

  return (
    <>
      <MeetingHeaderView
        loading={false}
        productName={meeting.product.name}
        attendingButton={
          meeting?.allowInvitation && meeting?.attendee ? (
            <AttendingDropdownButton attendee={meeting?.attendee} />
          ) : null
        }
        menuButton={
          <MeetingHeaderButton
            meeting={meeting}
            isCoach={isCoach}
            setSentSuccessVisible={setSentSuccessVisible}
            onCancel={setShowCancelConfirm}
            label={
              <Box minWidth={24}>
                <Icon icon="more" color={SUBTEXT_COLOR} size={24} />
              </Box>
            }
            view={view}
          />
        }
        meetingDate={meetingDate}
        canceled={meeting.canceledAt}
        isCoach={isCoach}
        customerId={currentCustomerId}
        view={view}
      />
      <CancelMeetingConfirmDialog
        meeting={meeting}
        isCoach={isCoach}
        isOpen={showCancelConfirm}
        onCancel={() => setShowCancelConfirm(false)}
        onConfirm={() => (window.location.href = redirectUrl)}
        customerId={currentCustomerId}
      />
      <Portal>
        <SlideContainer
          visible={sentSuccessVisible}
          slideFrom={Directions.DOWN}
          style={{
            position: "fixed",
            top: undefined,
            bottom: 15,
            left: undefined,
            width: 385,
          }}
        >
          <Toast2
            message="Invite Sent"
            css={{ width: "200px" }}
            intent={Intent.SUCCESS}
            onDismiss={() => setSentSuccessVisible(false)}
          />
        </SlideContainer>
      </Portal>
    </>
  );
}
