import { CSSProperties, useMemo, useState } from "react";

import { Button } from "@blueprintjs/core";
import throttle from "lodash/throttle";
import { useMediaQuery } from "react-responsive";

import { Flex } from "@/components/layout/flexbox";
import { isXsQuery } from "@/components/layout/Media";
import Filter from "@/components/pages_logged_in/workspace/pieces/Filter";
import FilterButton from "@/components/pages_logged_in/workspace/pieces/FilterButton";
import SearchBox from "@/components/pages_logged_in/workspace-admin/SearchBox";
import SettingSvg from "@/components/pieces/icons/svg/setting";
import { VisibilityScope } from "@/graphql";

import CreateCollectionDialog from "./CreateCollectionDialog";

interface RoleplayControllerProps {
  selectedScopes: Array<number | null>;
  scopes: Array<{
    id: VisibilityScope;
    name: string;
  }>;
  onChangeScope: (scope: VisibilityScope) => void;
  onSearch?: (query: string | null) => void;
  style?: CSSProperties;
  loading?: boolean;
  onCreateCollectionFinished: () => void;
  allowAdd?: boolean;
}

export default function RoleplayController({
  selectedScopes,
  scopes,
  onChangeScope,
  onSearch,
  style = {},
  loading = false,
  onCreateCollectionFinished,
  allowAdd = false,
}: RoleplayControllerProps) {
  const [isOpenCreateDialog, setIsOpenDialog] = useState(false);
  const scopeById: { [key: number]: string } = useMemo(() => {
    return scopes.reduce((res, scope) => {
      const { id, name } = scope;
      res[id] = name;
      return res;
    }, {});
  }, [scopes]);

  const scopeMenuLabel = useMemo(() => {
    const selectedScopeIdsNoViewAll = selectedScopes;
    if (selectedScopeIdsNoViewAll.length === 1) {
      return scopeById[selectedScopes[0]];
    } else if (selectedScopeIdsNoViewAll.length === 0) {
      return "None Selected";
    }

    return `${selectedScopeIdsNoViewAll.length} Scopes`;
  }, [selectedScopes, scopeById]);

  const isXs = useMediaQuery(isXsQuery);

  const borderStyle = {
    border: "0.5px solid #E2E2E2",
    marginRight: 10,
    padding: "10px 24px",
    minWidth: isXs ? 80 : 150,
  };

  return (
    <Flex
      fontSize={24}
      fontWeight={600}
      mt={4}
      mb={3}
      justifyContent="space-between"
    >
      <CreateCollectionDialog
        isOpen={isOpenCreateDialog}
        onClose={() => setIsOpenDialog(false)}
        onFinished={onCreateCollectionFinished}
      />
      <Flex>
        <Filter
          button={
            <FilterButton
              style={{ ...style, ...borderStyle }}
              rightIcon={<SettingSvg />}
              text={scopeMenuLabel}
              disabled={loading}
            />
          }
          items={scopes.map(({ id, name }) => ({
            id,
            text: name,
          }))}
          isSelectedFn={(x) => selectedScopes.includes(x)}
          multiple={false}
          onSelect={(id) =>
            throttle(() => onChangeScope(id as VisibilityScope), 200)()
          }
          menuPosition="bottom-left"
          viewMode="radio"
          fullHeight={true}
        />
        <SearchBox
          style={{
            borderRadius: 6,
            border: "0.5px solid #E2E2E2",
            overflow: "hidden",
            padding: "22px 0px 22px 36px",
            fontSize: 14,
          }}
          className="icon-center"
          disabled={loading}
          refine={onSearch}
          throttledDuration={400}
          searchPlaceholder="Search Collections..."
        />
      </Flex>

      {allowAdd && (
        <Button
          intent="primary"
          rightIcon="plus"
          style={{ fontSize: 14, marginLeft: 10 }}
          onClick={() => setIsOpenDialog(true)}
        >
          {isXs ? "Add" : "Create Collection"}
        </Button>
      )}
    </Flex>
  );
}
