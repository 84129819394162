import { Colors } from "@blueprintjs/core";
import { Link } from "react-router-dom";

import { useCurrentSession } from "@/components/helpers/custom-hooks/use-current-session";
import { Box, Flex } from "@/components/layout/flexbox";
import { Media } from "@/components/layout/Media";
import {
  Col,
  Container,
  PrimaryAnchorBtn,
  PrimaryLinkBtn,
  Row,
} from "@/components/pieces/bootstrap";
import { NAV_Z_INDEX } from "@/css/constants";

import MobileNav from "./MobileNav";
import NavLinks from "./NavLinks";
import NavLogo from "./NavLogo";

interface PublicNavProps {
  ctaLink?: string;
  fullWidth?: boolean;
}

export default function PublicNav({
  ctaLink = "/match",
  fullWidth = false,
}: PublicNavProps) {
  const { currentSessionLink } = useCurrentSession();

  return (
    <div className="public-header" style={{ zIndex: NAV_Z_INDEX }}>
      <Container maxWidth={fullWidth ? "100%" : 1170} px={24}>
        <Row>
          <Col width={1}>
            <Flex alignItems="center" justifyContent="space-between">
              <Flex alignItems="center">
                <NavLogo path="/" />
                <Media greaterThanOrEqual="lg">
                  <NavLinks pill={true} />
                </Media>
              </Flex>
              <Flex>
                {currentSessionLink ? (
                  <Flex alignItems="center" className="account">
                    <PrimaryLinkBtn
                      style={{ color: Colors.WHITE, marginLeft: "2rem" }}
                      to={currentSessionLink}
                    >
                      Go to app
                    </PrimaryLinkBtn>
                  </Flex>
                ) : (
                  <Flex alignItems="center" className="account">
                    <Media greaterThanOrEqual="lg">
                      <Link className="mr1 nav-login-link" to="/login">
                        Login
                      </Link>
                    </Media>
                    {ctaLink.startsWith("http") ? (
                      <PrimaryAnchorBtn
                        style={{ color: Colors.WHITE, marginLeft: "2rem" }}
                        href={ctaLink}
                      >
                        Get Started
                      </PrimaryAnchorBtn>
                    ) : (
                      <PrimaryLinkBtn
                        style={{ color: Colors.WHITE, marginLeft: "2rem" }}
                        to={ctaLink}
                      >
                        Get Started
                      </PrimaryLinkBtn>
                    )}
                  </Flex>
                )}
                <Media lessThan="lg">
                  <Box ml={3}>
                    <MobileNav />
                  </Box>
                </Media>
              </Flex>
            </Flex>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
