import { Classes } from "@blueprintjs/core";

import { Box } from "@/components/layout/flexbox";
import ProgramHeader from "@/components/pages_logged_in/program/components/ProgramHeader";
import { Container } from "@/components/pieces/bootstrap";

export default function ProgramListLoader() {
  return (
    <>
      <ProgramHeader />
      <Box>
        <Container mb={2}>
          <Box mt={36} mb={5} className={Classes.SKELETON} height={400} />
        </Container>
      </Box>
    </>
  );
}
