import { ReactNode } from "react";

import { useQuery } from "@apollo/client";
import { useSearchParams } from "react-router-dom";

import { getStartPageURL } from "@/components/helpers/url-utils";
import { Box, Flex } from "@/components/layout/flexbox";
import { SizeBreakpoint } from "@/components/layout/Media";
import LoggedInNav from "@/components/nav/LoggedInNav";
import PublicFooter from "@/components/nav/PublicFooter";
import PublicNav from "@/components/nav/PublicNav";
import ShapesCTA from "@/components/nav/ShapesCTA";
import Authenticate from "@/components/pages_logged_in/Authenticate";
import WorkspaceNav from "@/components/pages_logged_in/workspace/nav";
import { StyledLinkBtn } from "@/components/pieces/bootstrap";
import { SecondaryLinkBtn } from "@/components/pieces/button/SecondaryLinkButton";
import UserExperienceV2 from "@/components/pieces/marketing/UserExperienceV2";
import {
  PADDING_BREAKPOINT_MAP,
  STANDARD_SIZE_NAV_BREAKPOINT,
} from "@/css/constants";
import { IsLoggedInQuery } from "@/graphql/cachedVarsQueries";

interface PublicFrameProps {
  hideBottomSignup?: boolean;
  children: ReactNode;
  bareLayout?: boolean;
  contentType?: string;
}

function PublicFrame({
  hideBottomSignup,
  children,
  bareLayout,
  contentType,
}: PublicFrameProps) {
  if (contentType === "coach") {
    return (
      <>
        {!bareLayout && <PublicNav />}
        <Box mt={bareLayout ? 0 : 58}>{children}</Box>
        {!hideBottomSignup && (
          <div style={{ background: "#f5f8ff" }}>
            <ShapesCTA text="Grow your career with a coach" />
          </div>
        )}
        {!bareLayout && <PublicFooter />}
      </>
    );
  }
  const buttons = (
    <Flex
      justifyContent="center"
      flexDirection={["column", "row"]}
      alignItems="center"
      style={{ gap: 16 }}
    >
      <StyledLinkBtn
        to={getStartPageURL("?nextPath=/roleplays")}
        style={{ width: 288, maxWidth: "100%", zIndex: 998 }}
      >
        <Flex fontSize={20} padding={1} justifyContent="center">
          Try It Now
        </Flex>
      </StyledLinkBtn>
      <SecondaryLinkBtn to="/demo" style={{}}>
        <Flex fontSize={20} padding={1} justifyContent="center">
          Book a Demo
        </Flex>
      </SecondaryLinkBtn>
    </Flex>
  );

  return (
    <>
      {!bareLayout && <PublicNav />}
      <Box mt={bareLayout ? 0 : 58}>{children}</Box>
      {!hideBottomSignup && (
        <Box mt={30}>
          <UserExperienceV2
            title={
              <Box maxWidth={780} mx="auto" fontSize={[36, 48, 60]}>
                Launch training programs that actually stick
              </Box>
            }
            subtitle={
              <Box maxWidth={780} mx="auto" fontSize={[20, 24, 30]}>
                AI Roleplays. Vetted Coaches. Comprehensive Program Management.
                All in a single platform.
              </Box>
            }
            borderWidth={0}
            footer={<Box>{buttons}</Box>}
          />
        </Box>
      )}
      {!bareLayout && <PublicFooter />}
    </>
  );
}

interface LoggedInFrameProps {
  workspace?: unknown;
  children: ReactNode;
  bareLayout?: boolean;
  sizeNavBreakpoint?: SizeBreakpoint;
  contentType?: string;
}

function LoggedInFrame({
  workspace,
  children,
  bareLayout,
  sizeNavBreakpoint = STANDARD_SIZE_NAV_BREAKPOINT,
}: LoggedInFrameProps) {
  return (
    <>
      {!bareLayout &&
        (workspace ? (
          <WorkspaceNav
            pageLoading={false}
            sizeNavBreakpoint={sizeNavBreakpoint}
          />
        ) : (
          <LoggedInNav
            pageLoading={false}
            sizeNavBreakpoint={sizeNavBreakpoint}
          />
        ))}
      <Box ml={!bareLayout ? PADDING_BREAKPOINT_MAP[sizeNavBreakpoint] : 0}>
        {children}
      </Box>
      <Box style={{ height: 150 }} />
    </>
  );
}

interface PageContainerProps {
  workspace?: unknown;
  maxWidth?: string;
  children: ReactNode;
  sizeNavBreakpoint?: SizeBreakpoint;
  hideBottomSignup?: boolean;
  layout?: string;
  contentType?: string;
}

export default function PageContainer({
  workspace,
  maxWidth,
  children,
  sizeNavBreakpoint,
  hideBottomSignup = false,
  layout = "dashboard",
  contentType = "coach",
}: PageContainerProps) {
  const { data } = useQuery(IsLoggedInQuery);
  const [searchParams] = useSearchParams();
  const bareLayout = searchParams.get("layout") === "bare";

  if (data?.isLoggedIn) {
    return (
      <Authenticate
        layout={bareLayout ? "full" : layout}
        maxWidth={workspace ? "100%" : maxWidth}
      >
        <LoggedInFrame
          workspace={workspace}
          bareLayout={bareLayout}
          sizeNavBreakpoint={sizeNavBreakpoint}
          contentType={contentType}
        >
          {children}
        </LoggedInFrame>
      </Authenticate>
    );
  }

  return (
    <PublicFrame
      hideBottomSignup={hideBottomSignup || bareLayout}
      bareLayout={bareLayout}
    >
      {children}
    </PublicFrame>
  );
}
