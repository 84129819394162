/**
 * HeaderButton - A reusable component for header navigation buttons
 *
 * This component provides consistent styling for navigation buttons in the header.
 * It supports different content types:
 * - Icon buttons (MainMenuButton, SearchButton)
 * - User avatar buttons (HeaderProfile)
 * - Custom content via children prop
 *
 * Note: Some specialized navigation elements like MessageNav may need custom styling
 * for specific functionality (like notification badges).
 */

import React, { ReactNode } from "react";

import { Colors, Icon, IconName } from "@blueprintjs/core";
import styled from "@emotion/styled";

import { Flex } from "@/components/layout/flexbox";
import Avatar from "@/components/pieces/users/Avatar";
import { CTA_COLOR, INNER_BORDER_COLOR, MIDTEXT_COLOR } from "@/css/constants";

export interface HeaderButtonProps {
  onClick: () => void;
  icon?: IconName;
  label?: string;
  color?: string;
  style?: React.CSSProperties;
  disabled?: boolean;
  children?: ReactNode;
  user?: any;
  pageLoading?: boolean;
}

export default function HeaderButton({
  onClick,
  icon,
  label,
  color = Colors.GRAY3,
  style = {},
  disabled = false,
  children,
  user,
}: HeaderButtonProps) {
  const opacity = disabled ? 0.3 : 1;

  return (
    <ButtonContainer
      onClick={onClick}
      disabled={disabled}
      style={{ ...style, opacity }}
      aria-label={label || (icon ? String(icon) : "Button")}
      type="button"
    >
      <Flex justifyContent="center" alignItems="center">
        {children ? (
          children
        ) : icon ? (
          <>
            <Icon icon={icon as any} color={color} size={20} />
            {label && <LabelText>{label}</LabelText>}
          </>
        ) : user ? (
          <>
            <Avatar size={24} user={user} />
            {label && <LabelText>{label}</LabelText>}
          </>
        ) : null}
      </Flex>
    </ButtonContainer>
  );
}

const ButtonContainer = styled.button`
  &:hover {
    box-shadow:
      0 1px 1px rgba(24, 32, 38, 0.1),
      0 2px 6px rgba(24, 32, 38, 0.05);
    color: ${MIDTEXT_COLOR};
    background-color: #f8fafe;
  }

  &:active {
    border: 1px solid ${CTA_COLOR};
  }

  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  min-width: 42px;
  height: 42px;
  box-sizing: border-box;
  border-radius: 6px;
  border: 1px solid ${INNER_BORDER_COLOR};
  cursor: pointer;
  background: #fff;
  appearance: none;

  &:disabled {
    cursor: not-allowed;
  }
`;

const LabelText = styled.span`
  margin-left: 8px;
  font-size: 14px;
`;
