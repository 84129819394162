import { useState } from "react";

import { Colors, Portal } from "@blueprintjs/core";
import { Helmet } from "react-helmet-async";
import { useMediaQuery } from "react-responsive";

import { Box } from "@/components/layout/flexbox";
import { isXsQuery, Media, SizeBreakpoint } from "@/components/layout/Media";
import LoggedInMenu from "@/components/nav/LoggedInMenu";
import HeaderButton from "@/components/pieces/button/HeaderButton";
import Clickable from "@/components/pieces/interaction/Clickable";
import { NAV_Z_INDEX } from "@/css/constants";
import { LoggedInNavDataQueryQuery, WorkspaceNavQueryQuery } from "@/graphql";

import WorkspaceMenu from "./WorkspaceMenu";

interface HeaderProfileProps {
  data: LoggedInNavDataQueryQuery | WorkspaceNavQueryQuery;
  pageLoading: boolean;
  sizeNavBreakpoint: SizeBreakpoint;
  prerenderNavSection: string;
}

export default function HeaderProfile({
  data,
  pageLoading,
  sizeNavBreakpoint,
  prerenderNavSection,
}: HeaderProfileProps) {
  const [isOpen, setIsOpen] = useState(!!prerenderNavSection);
  const isXs = useMediaQuery(isXsQuery);

  // Always slide from right regardless of screen size
  const menuTransform = isOpen ? "translateX(0%)" : "translateX(100%)";

  const zIndex = NAV_Z_INDEX + 1;
  return (
    <>
      <Helmet bodyAttributes={{ overflow: isOpen ? "hidden" : "visible" }} />
      <Box p={3} pl={2} mr={-24}>
        <HeaderButton
          onClick={data ? () => setIsOpen(true) : null}
          user={data?.currentUser}
          label={!isXs && data?.currentUser ? "Me" : undefined}
          disabled={!data}
        />
      </Box>

      {data ? (
        <Portal>
          <Media greaterThan="xs">
            <Clickable onClick={() => setIsOpen(false)}>
              <Box
                style={{
                  position: "fixed",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  zIndex,
                  backgroundColor: Colors.BLACK,
                  opacity: 0.5,
                  display: isOpen ? "inherit" : "none",
                }}
              />
            </Clickable>
          </Media>
          <Box
            style={{
              visibility: isOpen ? "visible" : "hidden",
              position: "fixed",
              top: 0,
              bottom: 0,
              right: 0,
              width: isXs ? "100%" : "385px",
              transform: menuTransform,
              transition: isOpen
                ? "transform 0.4s"
                : "transform 0.4s, visibility 0s 0.4s",
              zIndex,
            }}
          >
            <Box width="100%">
              {"currentUserWorkspaceMember" in data ? (
                <WorkspaceMenu
                  data={data}
                  onClose={() => setIsOpen(false)}
                  prerenderNavSection={undefined}
                  isOpen={isOpen}
                  pageLoading={pageLoading}
                  sizeNavBreakpoint={sizeNavBreakpoint}
                />
              ) : (
                <LoggedInMenu
                  user={data.currentUser}
                  onClose={() => setIsOpen(false)}
                  onRefetch={() => {}}
                  prerenderNavSection={undefined}
                  isOpen={isOpen}
                  sizeNavBreakpoint={sizeNavBreakpoint}
                />
              )}
            </Box>
          </Box>
        </Portal>
      ) : null}
    </>
  );
}
