import { ReactNode } from "react";

import styled from "@emotion/styled";

import { Box, Flex } from "@/components/layout/flexbox";
import Subtext from "@/components/pieces/form/Subtext";
import { CTA_COLOR } from "@/css/constants";

interface ToggleFieldProps {
  // Required props
  checked: boolean;
  onChange: (checked: boolean) => void;

  // Optional props
  label?: ReactNode;
  size?: number;
  padding?: number;
  subtext?: ReactNode;
  disabled?: boolean;
  checkedColor?: string;
}

export default function ToggleField({
  checked,
  onChange,
  size = 20,
  padding = 3,
  label,
  subtext = null,
  disabled = false,
  checkedColor = CTA_COLOR,
}: ToggleFieldProps) {
  return (
    <Container
      size={size}
      checkedColor={checkedColor}
      padding={padding}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        if (disabled) return;
        onChange(!checked);
      }}
      className={disabled ? "disabled" : ""}
    >
      <Flex alignItems="center">
        <Box className="_toggle">
          <input type="checkbox" checked={checked} readOnly={true} />
          <div className="_slider" />
        </Box>
        {label ? <Box ml={2}>{label}</Box> : null}
      </Flex>
      {subtext ? <Subtext mt={1}>{subtext}</Subtext> : null}
    </Container>
  );
}

const Container = styled("label")<{
  size: number;
  padding: number;
  checkedColor: string;
}>`
  outline: none;
  margin: 0;

  &.disabled ._toggle ._slider {
    cursor: not-allowed;
    opacity: 0.5;
  }

  ._toggle {
    position: relative;
    display: inline-block;
    width: ${({ size, padding }) => size * 2 + padding * 2}px;
    height: ${({ size, padding }) => size + padding * 2}px;

    ._slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #ccc;
      -webkit-transition: 0.3s;
      transition: 0.3s;
      border-radius: ${({ size, padding }) => size + padding * 2}px;
    }
    ._slider:before {
      position: absolute;
      content: "";
      height: ${({ size }) => size}px;
      width: ${({ size }) => size}px;
      left: ${({ padding }) => padding}px;
      bottom: ${({ padding }) => padding}px;
      background-color: white;
      -webkit-transition: 0.3s;
      transition: 0.3s;
      border-radius: 50%;
    }

    input {
      position: absolute;
      top: -99999px;
      left: -99999px;
    }

    input:checked + ._slider {
      background-color: ${({ checkedColor }) => checkedColor};
    }
    input:focus + ._slider {
      box-shadow: 0 0 1px #1a94ef;
    }
    input:checked + ._slider:before {
      -webkit-transform: translateX(${({ size }) => size}px);
      -ms-transform: translateX(${({ size }) => size}px);
      transform: translateX(${({ size }) => size}px);
    }
  }
`;
