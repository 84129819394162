import styled from "@emotion/styled";
import { DragDropContext, Draggable, Droppable } from "@hello-pangea/dnd";

import { Box, Flex } from "@/components/layout/flexbox";
import { getItemStyle } from "@/components/pages_logged_in/program/components/set-components/Customize";
import BehaviorCard from "@/components/pages_logged_in/roleplays/details/evaluation-criteria/BehaviorCard";

import { BehaviorCriterionType, BehaviorType } from "./types";

export default function BehaviorList({
  onDragEnd,
  criterion,
  onUpdateBehavior,
  onRemoveBehavior,
}: {
  onDragEnd: (result: any) => void;
  criterion: BehaviorCriterionType;
  onUpdateBehavior: (behavior: BehaviorType) => void;
  onRemoveBehavior: (behavior: BehaviorType) => void;
}) {
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">
        {(provided) => (
          <Box
            width={1}
            className="round-corners"
            flexWrap="wrap"
            {...provided.droppableProps}
            ref={provided.innerRef}
          >
            {criterion?.items?.map((behavior, index) => (
              <Draggable
                key={behavior.id}
                draggableId={behavior.id}
                index={index}
              >
                {(provided, snapshot) => (
                  <AnimatedFlex
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    style={getItemStyle(
                      snapshot.isDragging,
                      provided.draggableProps.style,
                    )}
                    className={
                      behavior.isNew && index !== 0 ? "new-behavior" : ""
                    }
                  >
                    <BehaviorCard
                      behavior={behavior}
                      dragHandleProps={{ ...provided.dragHandleProps }}
                      onRemoveBehavior={onRemoveBehavior}
                      onUpdateBehavior={onUpdateBehavior}
                    />
                  </AnimatedFlex>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </Box>
        )}
      </Droppable>
    </DragDropContext>
  );
}

const AnimatedFlex = styled(Flex)`
  &.new-behavior {
    animation: fadeInScale 0.3s ease-in-out;
  }

  @keyframes fadeInScale {
    0% {
      transform: scale(0);
      opacity: 0;
    }
    100% {
      transform: scale(1);
      opacity: 1;
    }
  }
`;
