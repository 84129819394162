import { Icon, Spinner } from "@blueprintjs/core";
import styled from "@emotion/styled";

import { Tooltip } from "@/components/helpers/ui/blueprint-overrides/Tooltip";
import { Flex } from "@/components/layout/flexbox";
import Clickable from "@/components/pieces/interaction/Clickable";
import { HOVER_BACKGROUND, SUBTEXT_COLOR } from "@/css/constants";

export default function RoundedIconButton({
  onClick,
  icon,
  color = SUBTEXT_COLOR,
  tooltipContent = null,
  iconSize = 24,
  size = 44,
  tabIndex = 0,
  loading = false,
  backgroundColor,
}) {
  const content = (
    <Clickable onClick={onClick} tabIndex={tabIndex} onFocus={() => null}>
      <RoundedIcon
        alignItems="center"
        justifyContent="center"
        height={size}
        width={size}
        style={{
          backgroundColor,
        }}
      >
        {loading ? (
          <Spinner size={iconSize} />
        ) : (
          <Icon icon={icon} color={color} size={iconSize} />
        )}
      </RoundedIcon>
    </Clickable>
  );
  if (tooltipContent) {
    return <Tooltip content={tooltipContent}>{content}</Tooltip>;
  }
  return content;
}
const RoundedIcon = styled(Flex)`
  border-radius: 8px;

  :hover {
    background-color: ${HOVER_BACKGROUND};
  }

  :focus {
    background-color: #f4f6fb;
  }
`;
