import { useEffect, useState } from "react";

import { Button, Icon } from "@blueprintjs/core";

import { useWorkspaceSlug } from "@/components/helpers/custom-hooks/use-workspace-slug";
import { Box, Flex } from "@/components/layout/flexbox";
import LoginForm from "@/components/pages_public/login/LoginForm";
import WorkspaceLogo from "@/components/pages_public/WorkspaceLogo";
import SignUpForm from "@/components/pieces/signup/SignUpForm";
import StyledDialog from "@/components/pieces/StyledDialog";

export default function AuthenticateDialog({
  nextPath,
  isOpen,
  onClose,
  signUpToText,
  initialForm = "signup",
  onCompleted = undefined,
}) {
  const [form, setForm] = useState(initialForm);
  const workspaceSlug = useWorkspaceSlug();

  useEffect(() => {
    if (isOpen) {
      const insideIframe =
        !import.meta.env.SSR && window.location !== window.parent.location;
      if (insideIframe) {
        window.open(`${window.location.origin}${nextPath}`, "_blank").focus();
        onClose();
      }
    }
  }, [isOpen]);

  function handleFormSwitchClick(e, newForm) {
    e.preventDefault();
    if (workspaceSlug) {
      window.location.href = "/start";
    } else {
      setForm(newForm);
    }
  }

  return (
    <StyledDialog isOpen={isOpen} onClose={onClose} lazy={true} width="400px">
      <Box p={4} style={{ position: "relative" }}>
        <Box
          style={{
            position: "absolute",
            top: 8,
            right: 8,
          }}
        >
          <Button
            minimal={true}
            icon={<Icon icon="cross" size={24} />}
            onClick={onClose}
          />
        </Box>
        <Flex justifyContent="center" mb={3}>
          <Box>
            <Flex justifyContent="center">
              <WorkspaceLogo />
            </Flex>
            <h4>
              {form === "signup" ? "Sign up" : "Log in"} {signUpToText}
            </h4>
          </Box>
        </Flex>

        {form === "signup" ? (
          <SignUpForm
            fields={["name", "email", "password"]}
            nextPath={nextPath}
            onCompleted={onCompleted}
          />
        ) : (
          <LoginForm
            nextPath={nextPath}
            onComplete={onCompleted}
            inlineForm={true}
          />
        )}

        <Flex justifyContent="center" mt={4}>
          {form === "signup" ? (
            <Box>
              Already a member?{" "}
              <a
                href="/login"
                onClick={(e) => handleFormSwitchClick(e, "login")}
              >
                Log in
              </a>
            </Box>
          ) : (
            <Box>
              Don’t have an account yet?{" "}
              <a
                href="/signup"
                onClick={(e) => handleFormSwitchClick(e, "signup")}
              >
                Sign up
              </a>
            </Box>
          )}
        </Flex>
      </Box>
    </StyledDialog>
  );
}
