import CreditSVG from "@/../../hypetrain/static/img/credit.svg";
import { Classes } from "@blueprintjs/core";

import { Popover } from "@/components/helpers/ui/blueprint-overrides/Popover";
import EmbedSvg from "@/components/helpers/ui/EmbedSvg";
import { Box, Flex } from "@/components/layout/flexbox";
import { MIDTEXT_COLOR, SUBTEXT_COLOR } from "@/css/constants";

import formatCredits from "./helpers/formatCredits";

function AccountList({ ledgerAccounts }) {
  return (
    <Box width={250} style={{ maxWidth: "100%" }}>
      {ledgerAccounts.map((account, i) => (
        <Box key={`acc-${account.id}`} mt={i > 0 ? 3 : 0}>
          <Box fontWeight={500}>{account.label}</Box>
          <Flex alignItems="center" fontSize={14} color={MIDTEXT_COLOR}>
            <EmbedSvg src={CreditSVG} height={12} width={12} />
            <Box ml={2}>{formatCredits(account.balance)}</Box>
          </Flex>
        </Box>
      ))}
    </Box>
  );
}

export default function CreditsWithAccountsPopover({
  ledgerAccounts,
  totalBalance = undefined,
}) {
  const total =
    totalBalance ||
    ledgerAccounts.reduce((acc, account) => acc + account.balance, 0);
  return (
    <Popover
      content={<AccountList ledgerAccounts={ledgerAccounts} />}
      popoverClassName={Classes.POPOVER_CONTENT_SIZING}
      interactionKind="click"
      placement="bottom-start"
      modifiers={{
        arrow: { enabled: true },
      }}
    >
      <Flex
        alignItems="center"
        css={{
          width: "fit-content",
          borderBottom: `1px dashed ${SUBTEXT_COLOR}`,
          cursor: "help",
          wordBreak: "break-word",
        }}
        py={1}
      >
        <EmbedSvg src={CreditSVG} height={14} width={14} />
        <Box ml={2}>{formatCredits(total)}</Box>
      </Flex>
    </Popover>
  );
}
