import { CSSProperties } from "react";

import { Colors, Icon } from "@blueprintjs/core";
import styled from "@emotion/styled";
import { Link } from "react-router-dom";

import Card from "@/components/layout/Card";
import { Box, Flex } from "@/components/layout/flexbox";
import { LIGHT_BACKGROUND, OFFER_COLOR, SUBTEXT_COLOR } from "@/css/constants";
import { Article, ArticleReadStatusReadStatus } from "@/graphql";

import { CARD_IMG_HEIGHT, CARD_IMG_HEIGHT_XS } from "./constants";
import { getRandomDefaultImg } from "./image-util";

function ArticleStatusLine({ readStatus, timeToRead = 0 }) {
  if (readStatus === "READING_IN_PROGRESS") {
    return (
      <Box fontSize={14} color={SUBTEXT_COLOR}>
        Started
      </Box>
    );
  }

  if (readStatus === "READING_COMPLETED") {
    return (
      <Flex
        fontSize={14}
        color={OFFER_COLOR}
        fontWeight={500}
        alignItems="center"
      >
        <Icon icon="small-tick" />
        <Box ml={1}>Read</Box>
      </Flex>
    );
  }

  return (
    <Box fontSize={14} color={SUBTEXT_COLOR}>
      {timeToRead} min read
    </Box>
  );
}

export type ArticleWithReadStatus = Article & {
  currentUserReadStatus: ArticleReadStatusReadStatus;
};

interface ArticleCardProps {
  item: ArticleWithReadStatus;
  isXs?: boolean;
  style?: CSSProperties;
  imgCss?: CSSProperties | null;
}

export default function ArticleCard({
  item,
  isXs = false,
  style = {},
  imgCss = null,
}: ArticleCardProps) {
  const { title, urlSlug, heroImage, currentUserReadStatus, timeToRead } = item;

  const imgUrl = heroImage
    ? `${heroImage}?h=${
        isXs ? CARD_IMG_HEIGHT_XS * 2 : CARD_IMG_HEIGHT * 2
      }&fm=webp`
    : getRandomDefaultImg();
  const url = `/learn/${urlSlug}`;
  const isReadCompleted = "READING_COMPLETED" === currentUserReadStatus;
  return (
    <Link
      to={url}
      style={{
        color: Colors.BLACK,
        ...style,
      }}
    >
      <Box width="100%" height="100%">
        <Card
          elevation={1}
          interactive={true}
          style={{
            overflow: "hidden",
            borderRadius: 6,
            width: "100%",
            padding: "0",
            height: "100%",
            backgroundColor: isReadCompleted ? LIGHT_BACKGROUND : Colors.WHITE,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box
            height={[CARD_IMG_HEIGHT_XS, CARD_IMG_HEIGHT]}
            width={1}
            style={{
              opacity: isReadCompleted ? 0.4 : 1,
              backgroundImage: `url(${imgUrl})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              ...imgCss,
            }}
          />
          <Flex
            justifyContent="space-between"
            flexDirection="column"
            style={{ flex: 1 }}
            p={3}
            bg={isReadCompleted ? LIGHT_BACKGROUND : Colors.WHITE}
          >
            <StyledArticleTitle
              style={{
                color: isReadCompleted ? SUBTEXT_COLOR : undefined,
              }}
            >
              {title}
            </StyledArticleTitle>
            <Box py={2}>
              <ArticleStatusLine
                readStatus={currentUserReadStatus}
                timeToRead={timeToRead}
              />
            </Box>
          </Flex>
        </Card>
      </Box>
    </Link>
  );
}

const StyledArticleTitle = styled.div`
  font-size: 16px;
  font-weight: 600;
  line-height: 27.72px;
`;
