import { Classes } from "@blueprintjs/core";

export const BRAND_PURPLE = IS_EXEC ? "#1478E3" : "#6466EB";
export const LIGHT_BRAND_PURPLE = IS_EXEC ? "#AED2F4" : "#AEB0F4";
export const PURPLE_TEXT_COLOR = "#2D2750";
export const BLUE_TEXT_COLOR = "#3788EE";
export const BG_PURPLE = IS_EXEC ? "#123568" : PURPLE_TEXT_COLOR;
export const PURPLE = "#5642A6";
export const LIGHT_PURPLE = "#DDD9EE";
export const DARK_PURPLE = "#2B2165";
export const YELLOW_TEXT_COLOR = "#FAE89D";

export const RED = "#c61d1d";
export const YELLOW = "#eaaf23";
export const TEAL = "#26c6ca";
export const ORANGE = "#F26516";
export const GREEN = "#27AE60";
export const BLUE = "#1a94ef";
export const BRONZE = "#BF7326";

export const CTA_COLOR = BRAND_PURPLE;
export const LIGHTEST_BACKGROUND = "#FBFCFF";
export const LIGHT_BACKGROUND = "#F5F5FB";
// This is lighter than the light background, but it is called dark background in figma.
export const DARK_BACKGROUND = "#F6F6FB";
export const LIGHT_GRAY = "#F5F6F8";
export const LIGHT_STEEL_GRAY = "#F4F6FC";
export const LIGHT_BLUE = "#E5F2FF";
export const LIGHT_YELLOW = "#fff4ea";
export const MAINTEXT_COLOR = "#242424";
export const MIDTEXT_COLOR = "#394B59";
export const SUBTEXT_COLOR = "#778397";
export const OFFER_COLOR = "#27AE60";
export const BOTTOM_SHADOW = "rgb(225, 222, 238) 0px 2px 4px -2px";
export const HOVER_BACKGROUND = "#f8fafe";
export const MENU_BACKGROUND = LIGHTEST_BACKGROUND;
export const TAB_BORDER_COLOR = IS_EXEC ? BLUE_TEXT_COLOR : PURPLE;
export const BUDGET_BG_COLOR = IS_EXEC ? "#10153B" : "#2d084e";
export const BUDGET_BORDER_COLOR = IS_EXEC ? "#e2e2e2" : "#53376C";

export const INNER_BORDER_COLOR = "#E2E2E2";

export const PRACTICE_FONT_COLOR = "#333333";

export const STANDARD_NAV_HEIGHT = 60;
export const STANDARD_APP_MAX_WIDTH = 1188; // 1140 + 24px paddding on each side
export const TALL_NAV_HEIGHT = 100;
export const STANDARD_SIZE_NAV_BREAKPOINT = "md";

export const NAV_Z_INDEX = 1300;
export const PORTAL_Z_INDEX = 9999;
export const PAGE_DISABLED_Z_INDEX = 99999;

export const PRO_COLOR = "#F5D114";
export const PLUS_COLOR = BRAND_PURPLE;

export const PUBLIC_CONTENT_MAX_WIDTH = 1140;
export const SIDE_NAV_WIDTH = 210;
export const PADDING_BREAKPOINT_MAP = {
  sm: [0, SIDE_NAV_WIDTH],
  md: [0, 0, SIDE_NAV_WIDTH],
  lg: [0, 0, 0, SIDE_NAV_WIDTH],
};

export const HEADING_FONT = `${
  IS_EXEC ? "guyot-headline" : "poppins"
}, Georgia, Times New Roman, Times, serif`;

export const SANS_SERIF_FONT =
  "Inter,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Open Sans,Helvetica Neue,Icons16,sans-serif";

export const OPACITY_TRANSITION = "opacity 700ms ease";

export const SITE_NAME = IS_EXEC ? "Exec" : "Placement";

export const DOMAIN = IS_EXEC ? "exec.com" : "placement.com";

export const SUPPORT_EMAIL = IS_EXEC ? "hello@exec.com" : "hello@placement.com";

// Get the current Blueprint namespace.
export const BP_NS = Classes.getClassNamespace();
export const CARD_CLASSES = `${Classes.ELEVATION_1} bas round-corners`;

export const DARK_GREEN = "#0D8050";
export const LIGHT_GREEN = "#ECFAF3";
export const DARK_BLUE = "#215DB0";
