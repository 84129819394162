import { useCallback, useState } from "react";

import { Button, Menu, Position } from "@blueprintjs/core";

import { Popover } from "@/components/helpers/ui/blueprint-overrides/Popover";

export function MoreMenu({ children }) {
  const [isOpen, setIsOpen] = useState(false);

  const handleClose = useCallback(() => {
    setIsOpen(false);
  }, [setIsOpen]);

  const handleClick = useCallback(
    (e) => {
      e.stopPropagation();
      handleClose();
    },
    [handleClose],
  );

  return (
    <Popover
      content={
        <Menu className="bas" onClick={handleClick}>
          {children}
        </Menu>
      }
      canEscapeKeyClose={true}
      position={Position.BOTTOM_RIGHT}
      usePortal={true}
      onClose={handleClose}
      isOpen={isOpen}
      minimal={true}
      modifiers={{
        arrow: { enabled: false },
      }}
    >
      <Button
        style={{ minHeight: 36 }}
        minimal={true}
        icon="more"
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          setIsOpen(!isOpen);
        }}
      />
    </Popover>
  );
}
