import styled from "@emotion/styled";
import { Link } from "react-router-dom";

import { Box } from "@/components/layout/flexbox";
import { BRAND_PURPLE } from "@/css/constants";

export const Container = styled(Box)<{
  maxWidth?: number | string;
  px?: number;
}>`
  margin-right: auto;
  margin-left: auto;
  padding-left: ${(props) => props.px ?? 15}px;
  padding-right: ${(props) => props.px ?? 15}px;
  width: 100%;
  &:after {
    clear: both;
  }

  @media (min-width: 1200px) {
    max-width: ${(props) => props.maxWidth ?? 1170}px;
  }
`;

export const Row = styled(Box)`
  &:before,
  &:after {
    content: " ";
    display: table;
  }
  &:after {
    clear: both;
  }

  margin-left: -15px;
  margin-right: -15px;
`;

export const Col = styled(Box)`
  position: relative;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px;
  float: left;
`;

export const Clearfix = styled.div`
  &:after,
  &:before {
    content: " ";
    display: table;
  }
  &:after {
    clear: both;
  }
`;

const btnCss = `
  display: inline-block;
  margin-bottom: 0;
  font-weight: 500;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  white-space: nowrap;
  padding: 8px 16px;
  font-size: 16px;
  line-height: 1.42857143;
  border-radius: 6px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  &:focus{
    outline:thin dotted;
    outline:5px auto -webkit-focus-ring-color;
    outline-offset:-2px;
  }

&:focus,&:hover{
    color:#333;
    text-decoration:none;
}
&:active{
    outline:0;
    background-image:none;
    box-shadow:inset 0 3px 5px rgba(0,0,0,.125);
}
&[disabled], fieldset[disabled] &{
    cursor:not-allowed;
    pointer-events:none;
    opacity:.65;
    filter:alpha(opacity=65);
    box-shadow:none;
}
`;

const defaultBtnCss = `
&{
    color:#333;
    background-color:#fff;
    border-color:#e2e2e2;
    box-shadow: inset 0 -1px #f0f0f7, 0px 1px 6px #f9f9f9f2;
}
&:active,&:focus,&:hover{
    color:#333;
    background-color:#fafafa;
    border-color:#e2e2e2;
}
&:active{
    background-image:none;
}
&[disabled],
&[disabled]:active,
&[disabled]:focus,
&[disabled]:hover,
fieldset[disabled] &,
fieldset[disabled] &:active,
fieldset[disabled] &:focus,
fieldset[disabled] &:hover{
    background-color:#fff;
    border-color:#e2e2e2;
}
`;

const primaryBtnCss = `
  color: #fff;
  background-color: #6466eb;
  border-color: transparent;
  box-shadow: 0 1px transparent, 0 1px 3px rgb(0 0 0 / 9%);


&:active,
&:focus,
&:hover{
    color:#fff;
    background-color:#3739e5;
    border-color:transparent;
}
&:active {
    background-image:none;
}
&[disabled],
&[disabled]:active,
&[disabled]:focus,
&[disabled]:hover,
fieldset[disabled] &,
fieldset[disabled] &:active,fieldset[disabled] &:focus,
fieldset[disabled] &:hover{
    background-color:#6466eb;
    border-color:transparent;
}
`;

export const DefaultAnchorBtn = styled.a`
  ${btnCss}
  ${defaultBtnCss}
`;

export const DefaultLinkBtn = styled(Link)`
  ${btnCss}
  ${defaultBtnCss}
`;

export const DefaultBtn = styled("button")`
  ${btnCss}
  ${defaultBtnCss}
`;

export const PrimaryAnchorBtn = styled.a`
  ${btnCss}
  ${primaryBtnCss}
`;

export const PrimaryLinkBtn = styled(Link)`
  ${btnCss}
  ${primaryBtnCss}
`;

export const PrimaryBtn = styled("button")`
  ${btnCss}
  ${primaryBtnCss}
`;

const styledLinkCss = `
  background-color: ${BRAND_PURPLE};
  border-color: ${BRAND_PURPLE};
  box-shadow: '3px 5px 1.25rem rgba(36,65,102,.2)';
  font-size: 18px;
  font-weight: 600;
  max-width: 100%;
  width: 350px;
  padding: 12px 20px;
  border-radius: 10px;

  &:hover {
    background-color: ${BRAND_PURPLE};
    opacity: 0.8;
  }
`;

export const StyledLinkBtn = styled(PrimaryLinkBtn)`
  ${styledLinkCss}
`;

export const StyledAnchorBtn = styled(PrimaryAnchorBtn)`
  ${styledLinkCss}
`;

export const StyledBtn = styled(PrimaryBtn)`
  ${styledLinkCss}
`;
