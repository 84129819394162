import ExecNav from "@/components/nav/PublicNav/ExecNav";
import PlacementNav from "@/components/nav/PublicNav/PlacementNav";

interface PublicNavProps {
  ctaLink?: string;
  fullWidth?: boolean;
}

function PublicNav(props: PublicNavProps) {
  return IS_EXEC ? <ExecNav {...props} /> : <PlacementNav {...props} />;
}

export default PublicNav;
