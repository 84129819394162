import { Button, Callout, Icon, InputGroup, Intent } from "@blueprintjs/core";
import styled from "@emotion/styled";

import { Box, Flex } from "@/components/layout/flexbox";
import { StyledFormGroup } from "@/components/pages_logged_in/program/components/set-components/StyledFormGroup";
import BehaviorList from "@/components/pages_logged_in/roleplays/details/evaluation-criteria/BehaviorList";

import { BehaviorCriterionType } from "./types";

export default function CustomCriterionTab({
  updatedCriterion,
  onUpdateCriterion,
  onSaveCriterion,
  onDragEnd,
  onUpdateBehavior,
  onRemoveBehavior,
  errorMessage,
  buttonText,
  isSaveDisabled,
}: {
  updatedCriterion: BehaviorCriterionType | null;
  onUpdateCriterion: (criterion) => void;
  onSaveCriterion: (criterion) => void;
  onDragEnd: (result) => void;
  onUpdateBehavior: (behavior) => void;
  onRemoveBehavior: (behavior) => void;
  errorMessage: string;
  buttonText: string;
  isSaveDisabled: boolean;
}) {
  return (
    <>
      <Box
        pt={3}
        px={24}
        css={{
          lineHeight: "24px",
          overflowY: "auto",
        }}
      >
        {errorMessage && (
          <Box mb={2}>
            <Callout icon={null} intent={Intent.DANGER}>
              {errorMessage}
            </Callout>
          </Box>
        )}

        <StyledFormGroup
          label="Criteria Title"
          subLabel="Name the skill or competency being evaluated (e.g., 'Practice Active Listening')"
        >
          <InputGroup
            onChange={(e) => {
              onUpdateCriterion({
                ...updatedCriterion,
                name: e.target.value,
              });
            }}
            value={updatedCriterion?.name || ""}
            large={true}
            fill={true}
            placeholder="Practice Active Listening"
            autoFocus={true}
          />
        </StyledFormGroup>

        <StyledFormGroup
          label="Success checklist"
          subLabel="List specific, observable behaviors that demonstrate mastery. These will be used to evaluate the conversation."
        >
          <BehaviorList
            onDragEnd={onDragEnd}
            criterion={updatedCriterion}
            onUpdateBehavior={onUpdateBehavior}
            onRemoveBehavior={onRemoveBehavior}
          />
          <Button
            large={true}
            intent="none"
            icon={<Icon icon="plus" color="#3788EE" />}
            minimal={true}
            style={{
              padding: "8px 16px",
              fontSize: "16px",
              color: "#3788EE",
            }}
            onClick={() => {
              onUpdateCriterion({
                ...updatedCriterion,
                items: [
                  ...updatedCriterion.items,
                  {
                    id: `new-behavior-${updatedCriterion.items.length + 1}`,
                    name: "",
                    description: "",
                    isNew: true,
                  },
                ],
              });
            }}
          >
            Add Another Behavior
          </Button>
        </StyledFormGroup>
      </Box>
      <DialogFooterSticky>
        <Box className="bbs" />
        <Box py={3} px={24}>
          <Button
            style={{ minWidth: 118 }}
            large={true}
            intent="primary"
            onClick={() => {
              onSaveCriterion(updatedCriterion);
            }}
            disabled={isSaveDisabled}
          >
            {buttonText}
          </Button>
        </Box>
      </DialogFooterSticky>
    </>
  );
}

export const DialogFooterSticky = styled(Flex)`
  position: sticky;
  bottom: 0px;
  z-index: 99;
  width: 100%;
  background: #fff;
  border-radius: 0 0 12px 12px;
  border-top: 1px solid #e2e2e2;
`;
