import { CSSProperties, MouseEvent, ReactNode } from "react";

import { Card, Classes, Elevation } from "@blueprintjs/core";

import { Box } from "@/components/layout/flexbox";

interface ExtendedCardProps {
  header?: ReactNode;
  elevation?: Elevation;
  interactive?: boolean;
  onClick?: (e: MouseEvent<HTMLDivElement>) => void;
  p?: number | string;
  children: ReactNode;
  className?: string;
  style?: CSSProperties;
}

function ExtendedCard({
  className = "",
  elevation = 0,
  interactive = false,
  header = null,
  children,
  p = 24,
  style: propStyle,
  ...htmlProps
}: ExtendedCardProps) {
  let style: CSSProperties;

  if (header !== null) {
    style = { padding: 0 };
    const combinedStyle = propStyle ? { ...style, ...propStyle } : style;

    return (
      <Card
        className={className}
        elevation={elevation}
        interactive={interactive}
        style={combinedStyle}
        {...htmlProps}
      >
        <Box p={p} className={`${Classes.TEXT_LARGE} bbs`}>
          {header}
        </Box>
        <Box p={p}>{children}</Box>
      </Card>
    );
  }

  const space = [0, 8, 16, 24, 36];
  if (typeof p === "number" && p <= 4) {
    style = { padding: space[p] };
  } else {
    style = { padding: p };
  }

  const combinedStyle = propStyle ? { ...style, ...propStyle } : style;

  return (
    <Card
      className={className}
      elevation={elevation}
      interactive={interactive}
      style={combinedStyle}
      {...htmlProps}
    >
      {children}
    </Card>
  );
}

export default ExtendedCard;
