import { useRef } from "react";

import { Classes } from "@blueprintjs/core";
import styled from "@emotion/styled";
import { Link, useLocation } from "react-router-dom";

import SubMenuViewList from "@/components/nav/SubMenuViewList";

import { LinkType } from "./types";

function MenuItem({ path, label }) {
  const location = useLocation();
  const isInternalLink = path && path.startsWith("/");
  const isActivePage = location.pathname.includes(path);
  return (
    <li className={isActivePage ? "active-page" : null}>
      {isInternalLink ? (
        <Link className="nav-link-sub-menu" to={path}>
          <span className="nav-text">{label}</span>
        </Link>
      ) : (
        <a className="nav-link-sub-menu" href={path}>
          <span className="nav-text">{label}</span>
        </a>
      )}
    </li>
  );
}

const PLACEMENT_SUBLINKS: LinkType[] = [
  {
    title: "Career Coaching",
    path: "/career-coaching",
    description:
      "Find your direction and move farther faster on your career goals",
  },
  {
    title: "Job Search Coaching",
    path: "/job-search-coaching",
    description: "Meet your secret weapon for today’s hot job market",
  },
  {
    title: "Leadership Coaching",
    path: "/leadership-coaching",
    description: "Improve as a leader and help your team thrive",
  },
  {
    title: "Life Coaching",
    path: "/life-coaching",
    description:
      "Find your life purpose, remove roadblocks & achieve your potential",
  },
  {
    title: "Resume & LinkedIn",
    path: "/asset-reviews",
    description:
      "Improve your resume & LinkedIn with expert feedback from a professional",
  },
];

const EXEC_SUBLINKS = [
  {
    title: "Executive Development",
    path: "/exec-development",
    description: "Empower your leadership team with tailored programs",
  },
  {
    title: "Manager Growth",
    path: "/manager-growth",
    description: "Turn your managers into leaders and your leaders into stars",
  },
  {
    title: "High Potentials",
    path: "/hipo-program",
    description: "Unleash the full potential of your rising stars",
  },
  {
    title: "Offsites & Events",
    path: "/offsites",
    description: "Elevate your upcoming event with customized workshops",
  },
  {
    title: "Outplacement",
    path: "/outplacement",
    description: "Transition employees at all levels of your organization",
  },
];

export default function NavLinks({ pill = false }) {
  const resourcesLoadedRef = useRef(false);
  const NavContainer = pill ? StyledPillNavLinks : StyledNavLinks;

  return (
    <NavContainer>
      <SubMenuViewList
        label={IS_EXEC ? "Solutions" : "Specialties"}
        subMenus={IS_EXEC ? EXEC_SUBLINKS : PLACEMENT_SUBLINKS}
        onSubMenusVisible={() => {
          if (resourcesLoadedRef.current) {
            return;
          }
          resourcesLoadedRef.current = true;
        }}
      />

      {IS_EXEC ? null : (
        <MenuItem
          label="Outplacement"
          path="https://www.exec.com/outplacement"
        />
      )}
      {IS_EXEC && <MenuItem label="Roleplays" path="/ai-roleplays" />}

      {IS_EXEC ? (
        <MenuItem label="For Individuals" path="/for-individuals" />
      ) : (
        <MenuItem label="For Business" path="https://www.exec.com" />
      )}
      <MenuItem
        label="For Coaches"
        path={IS_EXEC ? "/for-coaches" : "https://www.exec.com/for-coaches"}
      />
      <MenuItem label="Guides" path="/learn" />
      {IS_EXEC ? <MenuItem label="About" path="/about-us" /> : null}
    </NavContainer>
  );
}

const StyledNavLinks = styled.ul`
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;

  & > li,
  & > li > a {
    position: relative;
    display: block;
  }

  & > li > a {
    padding: 10px 15px;
  }

  & > li > a:focus,
  & > li > a:hover {
    text-decoration: none;
    color: #6466eb;
  }

  & > li.disabled > a {
    color: #999;
  }

  & > li.disabled > a:focus,
  & > li.disabled > a:hover {
    color: #999;
    text-decoration: none;
    background-color: transparent;
    cursor: not-allowed;
  }

  & .open > a,
  & .open > a:focus,
  & .open > a:hover {
    background-color: #eee;
    border-color: #6466eb;
  }

  & > li > a > img {
    max-width: none;
  }

  & > li > div > .nav-link-sub-menu-item-mobile {
    position: relative;
    display: block;
    padding: 10px 15px;
  }

  .nav-link-sub-menu .${Classes.POPOVER_TARGET} {
    display: inline-block;
  }
`;

const StyledPillNavLinks = styled(StyledNavLinks)`
  margin-left: 3rem;

  & > li {
    float: left;
  }

  & > li > a {
    border-radius: 6px;
  }

  & > li + li {
    margin-left: 2px;
  }

  & > li.active > a,
  & > li.active > a:focus,
  & > li.active > a:hover {
    color: #fff;
    background-color: #6466eb;
  }
`;
