import { useEffect, useState } from "react";

import { Box, Flex } from "@/components/layout/flexbox";
import Clickable from "@/components/pieces/interaction/Clickable";
import Overlay from "@/components/pieces/overlay/Overlay";

function secondsToHms(d) {
  d = Number(d);
  const h = Math.floor(d / 3600);
  const m = Math.floor((d % 3600) / 60);
  const s = Math.floor((d % 3600) % 60);

  return [h, m, s];
}

class Delay {
  public *getDelay() {
    const maxDelay = 3600; // 1 hours
    let seconds = 5; // 5 seconds
    while (true) {
      yield seconds;
      const nextSeconds = seconds * 2;
      seconds = nextSeconds < maxDelay ? nextSeconds : maxDelay;
    }
  }
}

function AutoReconnectOverlay({ onReconnect }) {
  const [countdown, setCountdown] = useState(null);

  useEffect(() => {
    const delayGenerator = new Delay().getDelay();
    const intervalRef = window.setInterval(() => {
      setCountdown((x) => {
        if (x === null) {
          return delayGenerator.next().value;
        } else if (x > 0) {
          return x - 1;
        } else {
          onReconnect();
          return delayGenerator.next().value;
        }
      });
    }, 1000);
    return () => {
      window.clearInterval(intervalRef);
    };
  }, []);

  const [h, m, s] = secondsToHms(countdown || 0);
  const countdownText = `${h > 0 ? `${h}h ` : ""}${m > 0 ? `${m}m ` : ""}${s}s`;
  return (
    <Overlay
      visible={countdown !== null}
      canOutsideClickClose={false}
      canEscapeKeyClose={false}
      backdropClassName="pageDisabled"
    >
      <Flex
        alignItems="center"
        justifyContent="center"
        style={{
          width: "100%",
        }}
      >
        <Flex
          mt={3}
          py={2}
          width={[320, 450]}
          alignItems="center"
          justifyContent="center"
          flexWrap="wrap"
          style={{
            background: "#FFE4A0",
            borderRadius: "6px",
            border: "1x solid gray",
          }}
        >
          <strong>Not Connected.</strong>
          <Box mx={1}>Connecting in {countdownText}... </Box>
          <Clickable onClick={onReconnect}>
            <span style={{ color: "black", textDecoration: "underline" }}>
              Try now
            </span>
          </Clickable>
        </Flex>
      </Flex>
    </Overlay>
  );
}

export default AutoReconnectOverlay;
