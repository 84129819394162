import { forwardRef, useEffect, useRef, useState } from "react";

import { useLazyQuery, useMutation } from "@apollo/client";
import {
  Button,
  Classes,
  Intent,
  OverlayToaster,
  Position,
} from "@blueprintjs/core";
import idx from "idx";
import { createRoot } from "react-dom/client";

import { Box } from "@/components/layout/flexbox";
import SimpleDialog from "@/components/pieces/simple-dialog";
import ClientCommentBlock from "@/components/pieces/talent-agents/ClientCommentBlock";
import { CoachRatingsDocument, UpdateRatingFeaturedDocument } from "@/graphql";

const NUM_RATINGS_PER_PAGE = 5;

function ClientComments({
  props,
  profile,
  enableRatingMenu = false,
  onSuccess,
  onError,
}) {
  const [firstLoad, setFirstLoad] = useState(false);
  const [offset, setOffset] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [ratings, setRatings] = useState([]);
  const [overLimitError, setOverLimitError] = useState(false);

  const [fetch, { loading: fetchLoading }] = useLazyQuery(
    CoachRatingsDocument,
    {
      fetchPolicy: "cache-and-network",
      onCompleted: (payload) => {
        if (!payload || !payload.coachRatings || !payload.coachRatings.length) {
          setHasMore(false);
        }
        if (payload.coachRatings.length < NUM_RATINGS_PER_PAGE) {
          setHasMore(false);
        }
        if (!firstLoad) {
          setFirstLoad(true);
        }
        setOffset(offset + payload.coachRatings.length);
        setRatings((oldComment) => [...oldComment, ...payload.coachRatings]);
      },
    },
  );

  const [updateRatingFeatured] = useMutation(UpdateRatingFeaturedDocument, {
    onCompleted: (data) => {
      const ok = idx(data, (_) => _.updateRatingFeatured.ok) || false;
      if (ok) {
        onSuccess();

        setOffset(0);
        setRatings([]);
        setFirstLoad(false);
        setHasMore(true);
        loadNextPage(0);
      }
    },
    onError: (error) => {
      if (
        error &&
        error.message &&
        error.message.includes("Over Featured Rating Limit")
      ) {
        setOverLimitError(true);
      } else {
        onError();
      }
    },
  });

  function loadNextPage(specificOffset = 0) {
    fetch({
      variables: {
        coachId: profile.user.id,
        limit: NUM_RATINGS_PER_PAGE,
        offset: specificOffset,
      },
    });
  }

  function updateRatingFeaturedStatus(id, isFeatured) {
    updateRatingFeatured({
      variables: {
        id,
        isFeatured,
      },
    });
  }

  useEffect(() => {
    loadNextPage();
  }, []);

  if (!ratings.length) return null;

  return (
    <Box my={4} {...props}>
      <h4
        style={{
          fontSize: 24,
        }}
      >
        Recent Client Comments
      </h4>
      {!firstLoad && fetchLoading ? (
        <Box mt={24} height={300} className={Classes.SKELETON} />
      ) : null}
      {ratings.map((rating, i) => (
        <ClientCommentBlock
          key={`rating-${rating.id}`}
          rating={rating}
          onPressFeatureRating={updateRatingFeaturedStatus}
          enableRatingMenu={enableRatingMenu}
          mt={i > 0 ? 24 : 0}
        />
      ))}
      {hasMore && firstLoad ? (
        <Box mt={3}>
          <Button loading={fetchLoading} onClick={() => loadNextPage(offset)}>
            Load More
          </Button>
        </Box>
      ) : null}
      <SimpleDialog
        onClose={() => setOverLimitError(false)}
        isOpen={overLimitError}
        title="Unable to feature comment"
      >
        You can feature up to 5 comments on your profile. You are currently at
        the limit. Please first stop featuring one of the current featured
        comments in order to feature a new one.
      </SimpleDialog>
    </Box>
  );
}

function ClientRatingToastWrapper({ ...props }, ref) {
  const toasterRef = useRef(null);

  useEffect(() => {
    const initToaster = async () => {
      toasterRef.current = await OverlayToaster.createAsync(
        {
          position: Position.BOTTOM_RIGHT,
          usePortal: true,
        },
        {
          domRenderer: (toaster, containerElement) =>
            createRoot(containerElement).render(toaster),
        },
      );
    };

    initToaster();

    return () => {
      // Cleanup if needed
      if (toasterRef.current) {
        toasterRef.current = null;
      }
    };
  }, []);

  const showSuccessToast = () => {
    if (toasterRef.current) {
      toasterRef.current.show({
        message: "Update featured rating success",
        intent: Intent.SUCCESS,
      });
    }
  };

  const showErrorToast = () => {
    if (toasterRef.current) {
      toasterRef.current.show({
        message: "Failed to update featured rating",
        intent: Intent.DANGER,
      });
    }
  };

  return (
    <Box ref={ref}>
      <ClientComments
        props={props}
        profile={props.profile}
        {...props}
        onSuccess={showSuccessToast}
        onError={showErrorToast}
      />
    </Box>
  );
}

export default forwardRef(ClientRatingToastWrapper);
