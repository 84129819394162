import { Classes } from "@blueprintjs/core";

import { Box } from "@/components/layout/flexbox";
import {
  AgentProfileWithSlug,
  SpecialtyRenderDataType,
} from "@/components/pieces/coaching/coach-profile/coachingUtils";
import { TITLE_CSS } from "@/components/pieces/talent-agents/constants";

import Speciality from "./Speciality";

interface CoachingProductsProps {
  agentProfile?: AgentProfileWithSlug;
  specialties: SpecialtyRenderDataType[];
  isCoachInUserCoaches: boolean;
  asCoach: boolean;
  showLinks: boolean;
  isLoading?: boolean;
  noBorder?: boolean;
  showSpecialtyInfo?: boolean;
  backgroundColor?: string;
}

export default function CoachingProducts({
  agentProfile,
  specialties,
  isCoachInUserCoaches = false,
  asCoach = false,
  showLinks = true,
  isLoading = false,
  noBorder = false,
  showSpecialtyInfo = true,
  backgroundColor = null,
}: CoachingProductsProps) {
  if (isLoading) {
    return <Box mt={24} height={300} className={Classes.SKELETON} />;
  }

  return (
    <>
      {specialties && specialties.length > 0 && (
        <Box css={TITLE_CSS}>All Services</Box>
      )}
      {specialties.map((specialty) => (
        <Speciality
          key={specialty.id}
          agentProfile={agentProfile}
          isCoachInUserCoaches={isCoachInUserCoaches}
          asCoach={asCoach}
          showLinks={showLinks}
          specialty={specialty}
          noBorder={noBorder}
          showInfo={showSpecialtyInfo}
          backgroundColor={backgroundColor}
        />
      ))}
    </>
  );
}
