import { Button, Classes, Icon } from "@blueprintjs/core";
import styled from "@emotion/styled";

import { Box, Flex } from "@/components/layout/flexbox";

export default function DialogHeader({
  children,
  onClose,
  onBack = null,
  className = "",
}) {
  return (
    <HeaderContainer className={`${Classes.DIALOG_HEADER} ${className}`}>
      <Flex
        width={1}
        fontSize={24}
        fontWeight={500}
        alignItems="center"
        justifyContent="space-between"
      >
        {onBack ? (
          <Box flexShrink={0} mr={24}>
            <StyledButton
              aria-label="Black"
              icon={<Icon icon="arrow-left" size={28} />}
              minimal={true}
              onClick={onBack}
            />
          </Box>
        ) : null}
        <Box>{children}</Box>
        {onClose ? (
          <StyledButton
            aria-label="Close"
            icon={<Icon icon="cross" size={28} />}
            minimal={true}
            onClick={onClose}
          />
        ) : null}
      </Flex>
    </HeaderContainer>
  );
}
const HeaderContainer = styled.div`
  &.${Classes.DIALOG_HEADER} {
    padding: 12px 24px;
  }
`;

const StyledButton = styled(Button)`
  &.${Classes.BUTTON} {
    background-color: transparent;
    padding: 0;
  }
  &.${Classes.BUTTON}.${Classes.MINIMAL}:hover {
    background-color: transparent;
  }
`;
