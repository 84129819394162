import { useEffect, useState } from "react";

import { Colors } from "@blueprintjs/core";

import { isProgramLead } from "@/components/helpers/workspace/permissions";
import { Flex } from "@/components/layout/flexbox";
import Tabs from "@/components/pieces/Tabs";

const TRANSITION_STYLE = "all 300ms ease-in-out";

export const PROGRAM_TABS_CONFIG = [
  {
    key: "program-dashboard",
    label: "Dashboard",
    linkTo: "/programs/{id}",
  },
  {
    key: "program-details",
    label: "Program Details",
    linkTo: "/programs/{id}/details",
  },
  {
    key: "program-participants",
    label: "Participants",
    linkTo: "/programs/{id}/participants",
  },
];

export function getProgramTabs(programId, tabData = PROGRAM_TABS_CONFIG) {
  const isLead = isProgramLead(programId);

  const tabs = isLead ? tabData : [tabData[0], tabData[1]];

  return tabs.map((item) => {
    let linkTo = item.linkTo;

    linkTo = linkTo.replace("{id}", programId);

    return { ...item, linkTo: linkTo };
  });
}

function ProgramTabs({
  initialTab = PROGRAM_TABS_CONFIG[0].key,
  tabs = PROGRAM_TABS_CONFIG,
  onTabChange = null,
  backgroundColor = Colors.WHITE,
  marginTop = 16,
}) {
  const [tab, setTab] = useState(
    tabs.find((tab) => tab.key === initialTab) || tabs[0],
  );

  useEffect(() => {
    if (onTabChange) {
      onTabChange(tab.key);
    }
  }, [tab]);

  useEffect(() => {
    const newTab = tabs.find((tab) => tab.key === initialTab) || tabs[0];
    setTab(newTab);
  }, [initialTab, tabs]);

  return (
    <Flex
      css={{
        position: "relative",
        backgroundColor: backgroundColor,
        transition: TRANSITION_STYLE,
        borderRadius: 0,
        marginTop: marginTop,
        marginLeft: -16,
      }}
    >
      <Tabs
        tabs={tabs}
        tab={tab}
        onTabChange={setTab}
        style={{
          background: backgroundColor,
          borderTop: "none",
          boxShadow: null,
        }}
      />
    </Flex>
  );
}

export default ProgramTabs;
