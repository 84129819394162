import React, { ReactNode } from "react";

import { Classes, Intent } from "@blueprintjs/core";
import classNames from "classnames";

import EPRIconComponent from "./svg/epr";
import FacebookComponent from "./svg/facebook";
import GiftPath from "./svg/gift";
import GithubComponent from "./svg/github";
import LinkedInComponent from "./svg/linkedin";
import SkypeComponent from "./svg/skype";
import SlackComponent from "./svg/slack";
import TwitterComponent from "./svg/twitter";
import UserComponent from "./svg/user";

function CustomIconComponent({
  icon,
  color = null,
  htmlTitle = null,
  className = "",
  iconSize = 16,
  intent = "",
  title = icon,
  tagName = "span",
  ...htmlProps
}) {
  let iconComponent: ReactNode = null;
  if (icon === "gift") {
    iconComponent = GiftPath;
  }

  const classes = classNames(
    Classes.ICON,
    Classes.intentClass(intent as Intent),
    className,
  );

  return React.createElement(
    tagName,
    {
      ...htmlProps,
      className: classes,
      title: htmlTitle,
    },
    <svg
      fill={color}
      viewBox="0 0 22 22"
      width={iconSize}
      height={iconSize}
      {...htmlProps}
    >
      {title && <desc>{title}</desc>}
      {iconComponent}
    </svg>,
  );
}

export const Slack = SlackComponent;
export const Skype = SkypeComponent;
export const Facebook = FacebookComponent;
export const LinkedIn = LinkedInComponent;
export const Twitter = TwitterComponent;
export const Github = GithubComponent;
export const User = UserComponent;
export const EPR = EPRIconComponent;
export const CustomIcon = CustomIconComponent;
