import { Classes } from "@blueprintjs/core";

import { Box } from "@/components/layout/flexbox";
import { PUBLIC_CONTENT_MAX_WIDTH } from "@/css/constants";

export function ExploreDetailSkeleton() {
  return (
    <>
      <Box height={350} className={Classes.SKELETON} />
      <Box mt={5} width={1} maxWidth={PUBLIC_CONTENT_MAX_WIDTH} mx="auto">
        <Box mb={2} height={24} width={150} className={Classes.SKELETON} />
        <Box height={100} className={Classes.SKELETON} />

        <Box
          mt={36}
          mb={2}
          height={24}
          width={125}
          className={Classes.SKELETON}
        />
        <Box height={200} className={Classes.SKELETON} />

        <Box
          mt={36}
          mb={2}
          height={24}
          width={180}
          className={Classes.SKELETON}
        />
        <Box height={100} className={Classes.SKELETON} />
      </Box>
    </>
  );
}
