export function PauseSVG() {
  return (
    <svg
      width="14"
      height="15"
      viewBox="0 0 14 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="7"
        cy="7.5"
        r="5.83333"
        stroke="#BF7326"
        strokeWidth="2.33333"
      />
      <path
        d="M7 4.58301V10.4163C7.97222 10.4163 9.91667 9.83301 9.91667 7.49967C9.91667 5.16634 7.97222 4.58301 7 4.58301Z"
        fill="#BF7326"
      />
    </svg>
  );
}

export function LiveSVG() {
  return (
    <svg
      width="14"
      height="15"
      viewBox="0 0 14 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="7"
        cy="7.5"
        r="5.83333"
        stroke="#215DB0"
        strokeWidth="2.33333"
      />
      <path
        d="M7 4.58301V10.4163C7.97222 10.4163 9.91667 9.83301 9.91667 7.49967C9.91667 5.16634 7.97222 4.58301 7 4.58301Z"
        fill="#215DB0"
      />
    </svg>
  );
}
