import { useEffect, useState, useCallback } from "react";

import {
  getJwtToken,
  getUser,
  LoggedInUserType,
} from "@/components/session/JwtTokenManager";

export function useCurrentUser(): LoggedInUserType | null {
  const [user, setUser] = useState<LoggedInUserType | null>(null);

  // Memoize the update function to ensure consistent reference
  const updateUser = useCallback(() => {
    const jwtToken = getJwtToken();
    const currentUser = getUser();
    setUser(jwtToken ? currentUser : null);
  }, []);

  useEffect(() => {
    // Initial user load
    updateUser();

    // Handler for token loaded events
    const handleTokensLoaded = () => {
      updateUser();
    };

    // Subscribe to token loaded events
    window.addEventListener("exec.tokensLoaded", handleTokensLoaded);

    return () => {
      // Cleanup subscription
      window.removeEventListener("exec.tokensLoaded", handleTokensLoaded);
    };
  }, [updateUser]);

  return user;
}
