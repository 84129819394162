import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { Intent, OverlayToaster, Position } from "@blueprintjs/core";
import { createRoot } from "react-dom/client";

import { ScenarioRefetchQueryBuilder } from "@/components/pages_logged_in/program/api";
import {
  useCreateEvaluationCriterion,
  useDeleteEvaluationCriterion,
  useDismissScenarioCriteriaBanner,
  useGetEvaluationCriteria,
  useReorderEvaluationCriteria,
  useUpdateEvaluationCriterion,
} from "@/components/pages_logged_in/roleplays/details/api";
import { ConfirmDialog } from "@/components/pieces/dialog/ConfirmDialog";
import {
  GetEvaluationCriteriaDocument,
  TargetType,
  useRemoveAllEvaluationCriteriaFromTargetMutation,
} from "@/graphql";

import RemoveCriterionDialog from "./dialogs/RemoveCriterionDialog";
import SortCriteriaDialog from "./dialogs/SortCriteriaDialog";
import UpdateCriterionDialog from "./dialogs/UpdateCriterionDialog";
import EvaluationCriteriaCard from "./EvaluationCriteriaCard";
import { ImportFromCollectionDialog } from "./ImportFromCollectionDialog";
import { BehaviorCriterionType, EvaluationCriteriaDialogs } from "./types";

interface EvaluationCriteriaManagerProps {
  roleplayId?: string;
  scenarioId?: string;
  templatesSlug?: string;
  canEdit: boolean;
  isDefault: boolean;
  showBanner: boolean;
  templateCriterion?: boolean;
  scenarioSlug?: string;
}

export default function EvaluationCriteriaManager({
  roleplayId = null,
  scenarioId = null,
  templatesSlug = null,
  canEdit = false,
  isDefault = false,
  showBanner = false,
  scenarioSlug,
  templateCriterion,
}: EvaluationCriteriaManagerProps) {
  const [dialogOpen, setDialogOpen] =
    useState<EvaluationCriteriaDialogs | null>(null);
  const [errMsg, setErrMsg] = useState("");
  const [selectedCriterion, setSelectedCriterion] =
    useState<BehaviorCriterionType | null>(null);
  const [isShowingBanner, setIsShowingBanner] = useState(showBanner);
  const [createFromTemplate, setCreateFromTemplate] = useState(false);

  const { fetch, data: criteriaData } = useGetEvaluationCriteria({
    onCompleted: () => {},
    onError: () => {},
  });

  const criteria = criteriaData?.evaluationCriteria || [];

  const toasterRef = useRef(null);

  useEffect(() => {
    const initToaster = async () => {
      toasterRef.current = await OverlayToaster.createAsync(
        {
          position: Position.TOP,
          usePortal: true,
        },
        {
          domRenderer: (toaster, containerElement) =>
            createRoot(containerElement).render(toaster),
        },
      );
    };

    initToaster();

    return () => {
      if (toasterRef.current) {
        toasterRef.current = null;
      }
    };
  }, []);

  const onDismissBannerFail = (error: string) => {
    setIsShowingBanner(true);
    toasterRef.current.show({
      message: error,
      intent: Intent.DANGER,
    });
  };

  useEffect(() => {
    fetch(roleplayId, scenarioId, null);
  }, [roleplayId, scenarioId]);

  const onOpenDialog = (
    dialog: EvaluationCriteriaDialogs,
    criterion?: BehaviorCriterionType,
  ) => {
    setDialogOpen(dialog);
    if (criterion) {
      setSelectedCriterion(criterion);
    } else {
      setSelectedCriterion(null);
    }
  };

  const onCloseDialog = () => {
    setSelectedCriterion(null);
    setErrMsg("");
    setDialogOpen(null);
  };

  const refetchQueries = [
    {
      query: GetEvaluationCriteriaDocument,
      variables: { roleplayId, scenarioId, roleplaySlug: null },
    },
    scenarioSlug
      ? ScenarioRefetchQueryBuilder.buildScenarioQuery(scenarioSlug)
      : null,
  ];

  const { createCriterion, loading: createCriterionLoading } =
    useCreateEvaluationCriterion({
      onCompleted: () => {
        if (!createFromTemplate) {
          onCloseDialog();
        }
        setCreateFromTemplate(false);
      },
      onError: (error) => {
        setErrMsg(error);
        setCreateFromTemplate(false);
      },
      refetchQueries,
    });

  const { reorderCriteria, loading: reorderCriteriaLoading } =
    useReorderEvaluationCriteria({
      onCompleted: () => {
        onCloseDialog();
      },
      onError: (error) => {
        setErrMsg(error);
      },
      refetchQueries,
    });

  const { deleteCriterion, loading: deleteCriterionLoading } =
    useDeleteEvaluationCriterion({
      onCompleted: () => {
        onCloseDialog();
      },
      onError: () => {
        setErrMsg("Couldn't delete criterion");
      },
      refetchQueries,
    });

  const { updateCriterion } = useUpdateEvaluationCriterion({
    onCompleted: () => {
      onCloseDialog();
    },
    onError: (error) => {
      setErrMsg(error);
    },
    refetchQueries,
  });

  const { dismissScenarioCriteriaBanner } = useDismissScenarioCriteriaBanner({
    onCompleted: () => {
      setIsShowingBanner(false);
    },
    onError: (error: string) => {
      onDismissBannerFail(error);
    },
    refetchQueries,
  });

  const onRemoveCriterion = (criterion: BehaviorCriterionType) => {
    deleteCriterion(criterion.id);
  };

  const onSaveReorderedCriteria = (
    orderedCriteria: BehaviorCriterionType[],
  ) => {
    reorderCriteria(
      orderedCriteria.map((c) => ({
        id: c.id,
        order: c.order,
      })),
      scenarioId,
      roleplayId,
    );
  };

  const onUpdatedCriterion = (
    criterion: BehaviorCriterionType,
    fromTemplate: boolean,
  ) => {
    setCreateFromTemplate(fromTemplate);

    const evaluationCriterionItems = criterion?.items?.map((item) => ({
      id: item.id,
      order: item.order,
      name: item.name,
      description: item.description,
    }));

    if (!criterion?.id) {
      createCriterion({
        name: criterion.name,
        roleplayId: roleplayId,
        scenarioId: scenarioId,
        evaluationCriterionItems: evaluationCriterionItems,
      });
    } else {
      updateCriterion({
        id: criterion.id,
        roleplayId: roleplayId,
        scenarioId: scenarioId,
        name: criterion.name,
        evaluationCriterionItems: evaluationCriterionItems,
      });
    }
  };

  const [removeAllCriteria] =
    useRemoveAllEvaluationCriteriaFromTargetMutation();

  const target: { type: TargetType; id: string } = useMemo(() => {
    return {
      type: scenarioId ? TargetType.Scenario : TargetType.Collection,
      id: scenarioId || roleplayId,
    };
  }, [scenarioId, roleplayId]);

  const handleRemoveAllCriteria = useCallback(() => {
    removeAllCriteria({
      variables: { targetId: target.id, targetType: target.type },
      onCompleted: (data) => {
        if (!data.removeAllEvaluationCriteriaFromTarget.ok) {
          setErrMsg(
            data.removeAllEvaluationCriteriaFromTarget.errors
              .map((error) => error.message)
              .join(", "),
          );
        }
      },
      onError: (error) => {
        setErrMsg(error.message);
      },
      refetchQueries,
    });
    onCloseDialog();
  }, [removeAllCriteria, target, refetchQueries, setErrMsg, onCloseDialog]);

  return (
    <>
      <EvaluationCriteriaCard
        evaluationCriteria={criteria}
        onOpenDialog={onOpenDialog}
        showBanner={isShowingBanner}
        onDismissBanner={() => {
          if (scenarioId) {
            dismissScenarioCriteriaBanner(scenarioId);
            setIsShowingBanner(false);
          }
        }}
        canEdit={canEdit}
        canDelete={canEdit && criteria.length > 0}
        isDefault={isDefault}
        errorMessage={errMsg}
      />

      <RemoveCriterionDialog
        open={dialogOpen === EvaluationCriteriaDialogs.RemoveCriterion}
        onClose={onCloseDialog}
        criterion={selectedCriterion}
        loading={deleteCriterionLoading}
        onRemove={onRemoveCriterion}
        errorMessage={errMsg}
      />

      <SortCriteriaDialog
        open={dialogOpen === EvaluationCriteriaDialogs.SortCriteria}
        onClose={onCloseDialog}
        onSave={onSaveReorderedCriteria}
        criteria={criteria}
        loading={reorderCriteriaLoading}
        errorMessage={errMsg}
      />

      <UpdateCriterionDialog
        open={
          dialogOpen === EvaluationCriteriaDialogs.EditCriterion ||
          dialogOpen === EvaluationCriteriaDialogs.AddCriterion
        }
        dialog={dialogOpen}
        templateCriterion={templateCriterion}
        templatesSlug={templatesSlug}
        onClose={onCloseDialog}
        onSave={onUpdatedCriterion}
        currentCriteria={criteria}
        criterion={
          dialogOpen === EvaluationCriteriaDialogs.EditCriterion
            ? selectedCriterion
            : null
        }
        loading={createCriterionLoading}
        errorMessage={errMsg}
        isRoleplay={roleplayId ? true : false}
      />

      <ImportFromCollectionDialog
        open={dialogOpen === EvaluationCriteriaDialogs.ImportFromCollection}
        onClose={onCloseDialog}
        target={target}
      />
      {dialogOpen === EvaluationCriteriaDialogs.ConfirmRemoveAllCriteria && (
        <ConfirmDialog
          onCancel={onCloseDialog}
          onConfirm={handleRemoveAllCriteria}
          confirmLabel="Remove All"
          cancelLabel="Nevermind"
        >
          <p>
            Are you sure you want to remove all criteria from this scenario?
          </p>
        </ConfirmDialog>
      )}
    </>
  );
}
