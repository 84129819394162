import React, { useState } from "react";

import chevronDown from "@/../../hypetrain/static/img/chevron-down.svg";
import chevronUp from "@/../../hypetrain/static/img/chevron-up.svg";
import { Classes } from "@blueprintjs/core";
import styled from "@emotion/styled";

import LazyImg from "@/components/helpers/ui/LazyImg";
import { RichContentRenderer } from "@/components/helpers/ui/RichContentRenderer";
import { Box, Flex } from "@/components/layout/flexbox";
import Clickable from "@/components/pieces/interaction/Clickable";
import VideoWithPopout from "@/components/pieces/talent-agents/VideoWithPopout";
import { BRAND_PURPLE, INNER_BORDER_COLOR } from "@/css/constants";

const PAGE_SIZE = 4;

function DBFaqItem({ question, answer = null, videoUrl = null }) {
  const [isOpen, setOpen] = useState(false);

  return (
    <CollapsibleItem
      className={`${isOpen ? "item-open custom-faq-item" : "custom-faq-item"}`}
    >
      <Clickable onClick={() => setOpen(!isOpen)}>
        <Flex
          className="collapsible-item-header"
          justifyContent="space-between"
          alignItems="center"
        >
          <div className="collapsible-item-lead-wrapper">
            <div className="collapsible-item-lead">{question}</div>
          </div>
          <div>
            <div className="item-caret item-caret-open">
              <LazyImg src={chevronUp} />
            </div>
            <div className="item-caret item-caret-closed">
              <LazyImg src={chevronDown} />
            </div>
          </div>
        </Flex>
      </Clickable>
      <Box className="collapsible-item-content">
        {answer && <RichContentRenderer content={answer} />}
        {videoUrl && (
          <Box>
            <VideoWithPopout url={videoUrl} />
          </Box>
        )}
      </Box>
    </CollapsibleItem>
  );
}

const FAQ_QUESTION_SKELETON = (
  <Box className={Classes.SKELETON} width="100%" height="1em" />
);

function DBFaqItems({ faqItems = [] }) {
  const [page, setPage] = React.useState(1);
  return (
    <Box
      css={{ border: `1px solid ${INNER_BORDER_COLOR}`, overflow: "hidden" }}
      className="round-corners"
    >
      {faqItems.slice(0, page * PAGE_SIZE).map((item) => (
        <DBFaqItem
          question={item?.faqQuestion?.content || FAQ_QUESTION_SKELETON}
          answer={item?.content}
          videoUrl={item?.videoUrl}
          key={item.id}
        />
      ))}
      {page * PAGE_SIZE < faqItems.length && (
        <Clickable onClick={() => setPage(page + 1)}>
          <MoreButton>More questions</MoreButton>
        </Clickable>
      )}
    </Box>
  );
}

const CollapsibleItem = styled("div")`
  background-color: #fff;
  text-align: left;
  overflow: hidden;

  &:not(:first-of-type) {
    border-top: 1px solid ${INNER_BORDER_COLOR};
  }

  .collapsible-item-header {
    cursor: pointer;
    padding: 16px;
    font-weight: normal;
    .collapsible-item-lead-wrapper {
      flex-grow: 1;
    }
    .item-caret {
      padding-left: 10px;
    }
  }
  .collapsible-item-content {
    display: none;
    padding: 16px;
    padding-right: 36px;
    padding-top: 0;
  }
  .item-caret-open {
    display: none;
  }

  &.item-open {
    .collapsible-item-header {
      cursor: default;
      color: #000;
      font-weight: 600;
    }
    .collapsible-item-content {
      display: block;
    }
    .item-caret-open {
      display: block;
    }
    .item-caret-closed {
      display: none;
    }
  }

  &:hover .collapsible-item-header {
    color: #000;
  }
  &:last-child {
    margin-bottom: 0;
  }
`;

const MoreButton = styled("h4")`
  color: ${BRAND_PURPLE};
  font-size: 16px;
  font-weight: 600;
  margin: 0;
  padding: 16px;
  border-top: 1px solid ${INNER_BORDER_COLOR};
`;

export default DBFaqItems;
