import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import { Classes } from "@blueprintjs/core";
import Progress from "react-progress";

import { useCurrentSession } from "@/components/helpers/custom-hooks/use-current-session";
import { useWorkspaceSlug } from "@/components/helpers/custom-hooks/use-workspace-slug";
import { Box } from "@/components/layout/flexbox";
import { SizeBreakpoint } from "@/components/layout/Media";
import PublicNav from "@/components/nav/PublicNav";
import WorkspaceNav from "@/components/pages_logged_in/workspace/nav";
import { Clearfix, Col, Container, Row } from "@/components/pieces/bootstrap";
import { SIDE_NAV_WIDTH, STANDARD_NAV_HEIGHT } from "@/css/constants";

interface LoadingContextType {
  active: boolean;
  setActive: (active: boolean) => void;
  percent: number;
  setPercent: (percent: number) => void;
}

const IndicatorContext = createContext<LoadingContextType>({
  active: false,
  setActive: () => {},
  percent: 0,
  setPercent: () => {},
});

export function LoadingProvider({ children }: { children: ReactNode }) {
  const [active, setActive] = useState(false);
  const [percent, setPercent] = useState(0);

  const value = useMemo(
    () => ({ active, setActive, percent, setPercent }),
    [active, setActive, percent, setPercent],
  );
  return (
    <IndicatorContext.Provider value={value}>
      {children}
    </IndicatorContext.Provider>
  );
}

let timeoutRef = null;

export function LoadingIndicator({
  type = "full",
  visibleSideNav = false,
  flexiblePage = false,
  extraProps = null,
}) {
  const { setActive, setPercent } = useContext(IndicatorContext);
  const { currentSessionLink } = useCurrentSession();

  const showSideNav =
    (!flexiblePage && visibleSideNav) ||
    (flexiblePage && visibleSideNav && currentSessionLink);

  useEffect(() => {
    setActive(true);
    return () => {
      setActive(false);
      clearTimeout(timeoutRef);
      timeoutRef = setTimeout(() => setPercent(0), 1000);
    };
  });

  return (
    <Indicator
      type={type}
      visibleSideNav={showSideNav}
      extraProps={extraProps}
    />
  );
}

function Indicator({ type, visibleSideNav, extraProps }) {
  const mounted = useRef(false);
  const { active, percent, setPercent } = useContext(IndicatorContext);
  const workspaceSlug = useWorkspaceSlug();

  useEffect(() => {
    mounted.current = true;
    setTimeout(() => {
      if (!mounted.current) return;
      const newPercent = percent < 95 ? percent + getIncrement(percent) : 95;
      setPercent(newPercent);
    }, 200);
    return () => {
      mounted.current = false;
    };
  });

  const Skeleton = Components[type];

  return active ? (
    <>
      <Progress percent={percent} />
      <Skeleton
        workspace={workspaceSlug}
        visibleSideNav={visibleSideNav}
        extraProps={extraProps}
      />
    </>
  ) : null;
}

interface LoadingContainerProps {
  visibleSideNav: boolean;
  children: ReactNode;
  sizeNavBreakpoint?: SizeBreakpoint;
}

export function LoadingContainer({
  visibleSideNav,
  children,
  sizeNavBreakpoint = "sm",
}: LoadingContainerProps) {
  return (
    <>
      {visibleSideNav ? (
        <WorkspaceNav sizeNavBreakpoint={sizeNavBreakpoint} />
      ) : (
        <PublicNav />
      )}
      <Box
        mt={STANDARD_NAV_HEIGHT}
        ml={[0, 0, visibleSideNav ? SIDE_NAV_WIDTH : 0]}
      >
        {children}
      </Box>
    </>
  );
}

function FullSkeleton() {
  return (
    <div className="content-page">
      <div className="hero">
        <div className="hero-content text-center" />
      </div>
      <Clearfix />
      <Container mt="4rem" mb="4rem">
        <Row>
          <Col width="50%">
            <div
              className={Classes.SKELETON}
              style={{ width: "100%", height: 400 }}
            />
          </Col>
          <Col width="50%">
            <div
              className={Classes.SKELETON}
              style={{ width: "100%", height: 400 }}
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
}

function MarketingPageSkeleton({ visibleSideNav }) {
  return (
    <>
      <LoadingContainer visibleSideNav={visibleSideNav}>
        <Box px={[2, 4, 4]}>
          <FullSkeleton />
        </Box>
      </LoadingContainer>
    </>
  );
}

function CompactSkeleton() {
  return (
    <div className="content-page">
      <div className="hero">
        <div className="hero-content text-center">
          <Box my="4rem">
            <div
              className={`inline ${Classes.SKELETON}`}
              style={{ width: 200, maxWidth: "100%", height: 70 }}
            />
            <Box mt="1rem">
              <div
                className={`inline ${Classes.SKELETON}`}
                style={{ width: 350, maxWidth: "100%", height: 70 }}
              />
            </Box>
            <Box mt="2rem">
              <div
                className={`inline ${Classes.SKELETON}`}
                style={{ width: 400, maxWidth: "100%", height: 200 }}
              />
            </Box>
            <Box mt="4rem">
              <div
                className={`inline ${Classes.SKELETON}`}
                style={{ width: 300, maxWidth: "100%", height: 20 }}
              />
            </Box>
            <Box mt="4rem">
              <div
                className={`inline ${Classes.SKELETON}`}
                style={{ width: 300, maxWidth: "100%", height: 200 }}
              />
            </Box>
          </Box>
        </div>
      </div>
    </div>
  );
}

function getIncrement(percent: number) {
  let ratio = 5;
  if (percent > 10) ratio = 10;
  else if (percent > 20) ratio = 50;
  else if (percent > 30) ratio = 500;
  else if (percent > 40) ratio = 5000;
  else if (percent > 50) ratio = 50000;
  else if (percent > 60) ratio = 500000;
  else if (percent > 70) ratio = 5000000;
  else if (percent > 80) ratio = 50000000;

  return (100 - percent) / ratio;
}

const Components = {
  full: FullSkeleton,
  marketing: MarketingPageSkeleton,
  compact: CompactSkeleton,
};

export type { LoadingContextType };
