import { useMutation, useQuery } from "@apollo/client";

import {
  AddCollectionScenarioDocument,
  AddCollectionScenarioMutationVariables,
  InviteUsersToSessionDocument,
  InviteUsersToSessionMutationResult,
  PersonalityAttributesDocument,
  PersonalityAttributesQuery,
  PersonalityAttributesQueryVariables,
  QueryScenariosStatus,
  RemoveCollectionDocument,
  RemoveCollectionScenarioDocument,
  RemoveCollectionScenarioMutationVariables,
  RevokeAccessFromSessionDocument,
  RoleplayVoicesDocument,
  RoleplayVoicesQuery,
  RoleplayVoicesQueryVariables,
  SaveCollectionDocument,
  SaveCollectionMutationVariables,
  ScenariosQueryQueryVariables,
  SwitchPersonalityModeDocument,
  UpdateCollectionVisibilityScopeDocument,
  UpdateScenarioCharacterDocument,
  useScenariosQueryLazyQuery,
} from "@/graphql";

export const useScenariosLazyQuery = ({ onCompleted, onError }) => {
  const [fetch, { data, loading }] = useScenariosQueryLazyQuery({
    fetchPolicy: "no-cache",
    onCompleted: (response) => {
      if (response?.scenarios) {
        onCompleted(response.scenarios);
      } else {
        onError(Error("Something went wrong. Please try again."));
      }
    },
    onError: (err) => {
      onError(err);
    },
  });

  return {
    scenariosData: data?.scenarios,
    loading,
    queryPersonalScenarios: ({
      page,
      pageSize,
      status = QueryScenariosStatus.All,
      scope,
      query = "",
      orderBy = null,
    }: ScenariosQueryQueryVariables) =>
      fetch({
        variables: {
          isOwner: true,
          page,
          pageSize,
          status,
          scope,
          query,
          orderBy,
        },
      }),
    queryAssignedScenarios: ({
      page,
      pageSize,
      status = QueryScenariosStatus.Incomplete,
      scope,
      query = "",
      orderBy = null,
    }: ScenariosQueryQueryVariables) =>
      fetch({
        variables: {
          isAssignee: true,
          page,
          pageSize,
          status,
          scope,
          query,
          orderBy,
        },
      }),
    queryAllScenarios: ({
      page,
      pageSize,
      status = QueryScenariosStatus.All,
      scope,
      query = "",
      collectionId = null,
      workspaceOnly = false,
      isOrphaned = false,
      orderBy = null,
    }) =>
      fetch({
        variables: {
          page,
          pageSize,
          status,
          scope,
          query,
          collectionId,
          workspaceOnly,
          isOrphaned,
          orderBy,
        },
      }),
  };
};

export const useRoleplayVoicesQuery = ({ onCompleted, onError }) => {
  const { data, loading } = useQuery<
    RoleplayVoicesQuery,
    RoleplayVoicesQueryVariables
  >(RoleplayVoicesDocument, {
    onCompleted: (response) => {
      if (response?.roleplayVoices) {
        onCompleted(response.roleplayVoices);
      } else {
        onError(Error("Something went wrong. Please try again."));
      }
    },
    onError: (err) => {
      onError(err);
    },
  });

  return {
    voicesData: data?.roleplayVoices,
    loading,
  };
};

export const usePersonalityAttributesQuery = ({
  onCompleted,
  onError,
}: {
  onCompleted?: (
    personalityAttributes: PersonalityAttributesQuery["personalityAttributes"],
  ) => void;
  onError?: (error: Error) => void;
}) => {
  const { data, loading } = useQuery<
    PersonalityAttributesQuery,
    PersonalityAttributesQueryVariables
  >(PersonalityAttributesDocument, {
    onCompleted: (response) => {
      if (response?.personalityAttributes) {
        onCompleted(response.personalityAttributes);
      } else {
        onError(
          Error(
            "Something went wrong. Please try again. If the issue persists, please contact support.",
          ),
        );
      }
    },
    onError: (err) => {
      onError(err);
    },
  });

  return {
    personalityAttributes: data?.personalityAttributes,
    loading,
  };
};

export const useUpdateScenarioCharacterMutation = ({
  onCompleted,
  onError,
  refetchQueries = null,
}) => {
  const [updateScenarioCharacter, { loading }] = useMutation(
    UpdateScenarioCharacterDocument,
    {
      onCompleted: (response) => {
        if (response?.updateScenarioCharacter?.ok) {
          onCompleted(response.updateScenarioCharacter);
        } else if (response?.updateScenarioCharacter?.error?.message) {
          onError(Error(response.updateScenarioCharacter.error.message));
        } else {
          onError(
            "An error occurred while updating the scenario character. Please try again later.",
          );
        }
      },
      onError: () => {
        onError(
          "An error occurred while updating the scenario character. Please try again later.",
        );
      },
      refetchQueries,
    },
  );

  return {
    updateScenarioCharacter,
    loading,
  };
};

export const useSaveCollectionMutation = ({
  onCompleted,
  onError,
  refetchQueries = null,
}) => {
  const [saveCollection, { loading }] = useMutation(SaveCollectionDocument, {
    onCompleted: (response) => {
      if (response?.saveCollection?.ok) {
        onCompleted(response?.saveCollection?.collection);
      } else {
        const errors = response?.saveCollection?.errors || [];
        const errMsg = errors.map((e) => e.message).join(", ");
        onError(errMsg);
      }
    },
    onError: () => {
      onError();
    },
    refetchQueries,
  });

  return {
    saveCollection: ({
      id,
      name,
      introduction,
      description,
      emojiUnicode,
    }: SaveCollectionMutationVariables) =>
      saveCollection({
        variables: {
          id,
          name,
          introduction,
          description,
          emojiUnicode,
        },
      }),
    loading,
  };
};

export const useRemoveCollectionMutation = ({ onCompleted, onError }) => {
  const [removeCollection, { loading }] = useMutation(
    RemoveCollectionDocument,
    {
      onCompleted: (response) => {
        if (response?.removeCollection?.ok) {
          onCompleted();
        } else {
          onError();
        }
      },
      onError: () => {
        onError();
      },
    },
  );

  return {
    removeCollection: (id) =>
      removeCollection({
        variables: {
          id,
        },
      }),
    loading,
  };
};

export const useAddAndRemoveCollectionScenarioMutation = ({
  onCompleted,
  onError,
  refetchQueries = null,
}) => {
  const [addCollectionScenario, { loading: addLoading }] = useMutation(
    AddCollectionScenarioDocument,
    {
      onCompleted: (response) => {
        if (response?.addCollectionScenario?.ok) {
          onCompleted();
        } else {
          const errors = response?.addCollectionScenario?.errors || [];
          const errMsg = errors.map((e) => e.message).join(", ");
          onError(errMsg);
        }
      },
      onError: () => {
        onError();
      },
      refetchQueries,
    },
  );

  const [removeCollectionScenario, { loading: removeLoading }] = useMutation(
    RemoveCollectionScenarioDocument,
    {
      onCompleted: (response) => {
        if (response?.removeCollectionScenario?.ok) {
          onCompleted();
        } else {
          onError();
        }
      },
      onError: () => {
        onError();
      },
      refetchQueries,
    },
  );

  return {
    addCollectionScenario: ({
      roleplaySlug,
      scenarioIds,
    }: AddCollectionScenarioMutationVariables) =>
      addCollectionScenario({
        variables: {
          roleplaySlug,
          scenarioIds,
        },
      }),
    addLoading,
    removeCollectionScenario: ({
      roleplaySlug,
      scenarioIds,
    }: RemoveCollectionScenarioMutationVariables) =>
      removeCollectionScenario({
        variables: {
          roleplaySlug,
          scenarioIds,
        },
      }),
    removeLoading,
  };
};

export const useSwitchPersonalityModeMutation = ({
  onCompleted,
  onError,
  refetchQueries = null,
}) => {
  const [switchPersonalityMode, { loading }] = useMutation(
    SwitchPersonalityModeDocument,
    {
      onCompleted: (response) => {
        if (response?.switchPersonalityMode?.ok) {
          onCompleted(response?.switchPersonalityMode);
        } else if (response?.switchPersonalityMode?.error?.message) {
          onError(Error(response.switchPersonalityMode.error.message));
        } else {
          onError(
            "An error occurred while switching the personality mode. Please try again later.",
          );
        }
      },
      onError: () => {
        onError(
          "An error occurred while switching the personality mode. Please try again later.",
        );
      },
      refetchQueries,
    },
  );

  return {
    switchPersonalityMode: (slug) =>
      switchPersonalityMode({ variables: { slug } }),
    loading,
  };
};

export const useInviteUsersToSessionMutation = ({
  onCompleted,
  onError,
  refetchQueries = null,
}: {
  onCompleted: (response: InviteUsersToSessionMutationResult) => void;
  onError: (error?: string) => void;
  refetchQueries?: any;
}) => {
  const [inviteUsersToSession, { loading }] = useMutation(
    InviteUsersToSessionDocument,
    {
      onCompleted: (response) => {
        if (response?.inviteUsersToSession?.ok) {
          onCompleted(response?.inviteUsersToSession);
        } else {
          const errors = response?.inviteUsersToSession?.errors || [];
          const errMsg = errors.map((e: any) => e.message).join(", ");
          onError(errMsg);
        }
      },
      onError: () => {
        onError();
      },
      refetchQueries,
    },
  );

  return {
    inviteUsersToSession: (
      sessionId: string,
      emails: string[],
      isNotified?: boolean,
      message?: string,
    ) =>
      inviteUsersToSession({
        variables: {
          sessionId,
          emails,
          isNotified,
          message,
        },
      }),
    loading,
  };
};

export const useRevokeAccessFromSessionMutation = ({
  onCompleted,
  onError,
  refetchQueries = null,
}: {
  onCompleted: () => void;
  onError: () => void;
  refetchQueries?: any;
}) => {
  const [revokeAccessFromSession, { loading }] = useMutation(
    RevokeAccessFromSessionDocument,
    {
      onCompleted: (response) => {
        if (response?.revokeAccessFromSession?.ok) {
          onCompleted();
        } else {
          onError();
        }
      },
      onError: () => {
        onError();
      },
      refetchQueries,
    },
  );

  return {
    revokeAccessFromSession: (sessionId: string, workspaceMemberId: string) =>
      revokeAccessFromSession({
        variables: {
          sessionId,
          workspaceMemberId,
        },
      }),
    loading,
  };
};

export const useUpdateCollectionVisibilityScope = ({
  onCompleted,
  onError,
  refetchQueries,
}: {
  onCompleted?: (data: any) => void;
  onError?: (error: any) => void;
  refetchQueries?: any;
}) => {
  const [updateCollectionVisibilityScope, { loading }] = useMutation(
    UpdateCollectionVisibilityScopeDocument,
    {
      refetchQueries: refetchQueries,
      awaitRefetchQueries: true,
      onCompleted: (data) => {
        const { ok, msg, collection } =
          data?.updateCollectionVisibilityScope || {};
        if (ok) {
          onCompleted(collection);
        } else {
          onError(Error(msg || "Something went wrong. Please try again."));
        }
      },
      onError: (err) => {
        onError(err);
      },
    },
  );

  return {
    updateCollectionVisibilityScope: (collectionId, visibilityScope) =>
      updateCollectionVisibilityScope({
        variables: {
          collectionId,
          visibilityScope,
        },
      }),
    loading,
  };
};
