import { Icon } from "@blueprintjs/core";

import { Box, Flex } from "@/components/layout/flexbox";
import { YELLOW } from "@/css/constants";

export default function CoachRating({ rating }) {
  return (
    <Flex alignItems="center" lineHeight={`${rating ? 14 : 13}px`}>
      <Icon icon="star" size={16} color={YELLOW} />
      <Box ml={1} fontSize={rating ? 14 : 13} fontWeight={600}>
        {rating ? (rating + Number.EPSILON).toFixed(1) : "NEW"}
      </Box>
    </Flex>
  );
}
