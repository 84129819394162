import { useState } from "react";

import { Colors, Portal, Icon } from "@blueprintjs/core";
import { Helmet } from "react-helmet-async";
import { useMediaQuery } from "react-responsive";

import { Flex, Box } from "@/components/layout/flexbox";
import { isXsQuery, SizeBreakpoint } from "@/components/layout/Media";
import { SideNavContent } from "@/components/pages_logged_in/workspace/nav/SideNav";
import HeaderButton from "@/components/pieces/button/HeaderButton";
import Clickable from "@/components/pieces/interaction/Clickable";
import { NAV_Z_INDEX } from "@/css/constants";

interface MainMenuButtonProps {
  pageLoading?: boolean;
  sizeNavBreakpoint?: SizeBreakpoint;
}

export default function MainMenuButton({
  pageLoading = false,
}: MainMenuButtonProps) {
  const [isOpen, setIsOpen] = useState(false);
  const isXs = useMediaQuery(isXsQuery);
  return (
    <>
      <Helmet bodyAttributes={{ overflow: isOpen ? "hidden" : "visible" }} />
      <Box mr={2}>
        <HeaderButton icon="menu" onClick={() => setIsOpen(true)} />
      </Box>

      <Portal>
        <Clickable onClick={() => setIsOpen(false)}>
          <Box
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              zIndex: NAV_Z_INDEX + 1,
              backgroundColor: Colors.BLACK,
              opacity: 0.5,
              display: isOpen ? "inherit" : "none",
            }}
          />
        </Clickable>
        <Box
          style={{
            visibility: isOpen ? "visible" : "hidden",
            position: "fixed",
            top: 0,
            left: 0,
            bottom: 0,
            width: isXs ? "100%" : "280px",
            transform: isOpen ? "translateX(0%)" : "translateX(-100%)",
            transition: isOpen
              ? "transform 0.4s"
              : "transform 0.4s, visibility 0s 0.4s",
            zIndex: NAV_Z_INDEX + 1,
            backgroundColor: Colors.WHITE,
            borderRight: `1px solid ${Colors.LIGHT_GRAY3}`,
            overflow: "auto",
          }}
        >
          <Box>
            <Flex justifyContent="space-between" alignItems="center" pl={3}>
              <Box>
                <h4>Menu</h4>
              </Box>
              <Box>
                <Clickable onClick={() => setIsOpen(false)}>
                  <Box p={3}>
                    <Icon icon="cross" size={24} color={Colors.DARK_GRAY3} />
                  </Box>
                </Clickable>
              </Box>
            </Flex>
            <Box>
              <SideNavContent pageLoading={pageLoading} />
            </Box>
          </Box>
        </Box>
      </Portal>
    </>
  );
}
