import { useState } from "react";

import { useQuery } from "@apollo/client";
import { Button, Icon } from "@blueprintjs/core";
import styled from "@emotion/styled";
import { Link, useParams } from "react-router-dom";

import { pluralize } from "@/components/helpers/string-utils";
import { Tooltip } from "@/components/helpers/ui/blueprint-overrides/Tooltip";
import { Box, Flex } from "@/components/layout/flexbox";
import CreateCollectionDialog from "@/components/pages_logged_in/roleplays/CreateCollectionDialog";
import {
  buildRoleplayUrl,
  sortScenariosByDifficulty,
} from "@/components/pages_logged_in/roleplays/utils";
import EmojiCircle from "@/components/pieces/EmojiCircle";
import CloneCollectionDialog from "@/components/pieces/roleplays/CloneCollectionDialog";
import RankIcon from "@/components/pieces/roleplays/RankIcon";
import RoleplayDeleteConfirm from "@/components/pieces/roleplays/RoleplayDeleteConfirm";
import RoleplayMenu from "@/components/pieces/roleplays/RoleplayMenu";
import { CTA_COLOR, LIGHT_GRAY, SUBTEXT_COLOR } from "@/css/constants";
import { RoleplayDocument, VisibilityScope } from "@/graphql";

import { RoleplayViewMode } from "./types";

const scopeIconMap = {
  [VisibilityScope.Global]: { icon: "globe", content: "Global" },
  [VisibilityScope.User]: { icon: "person", content: "User" },
  [VisibilityScope.Workspace]: { icon: "office", content: "Workspace" },
};

export function RoleplayScopeIcon({ scope }) {
  const { icon, content } = scopeIconMap[scope] || {};

  if (!icon || !content) {
    return null;
  }

  return (
    <Tooltip content={content}>
      <Icon icon={icon} color={SUBTEXT_COLOR} />
    </Tooltip>
  );
}

export const enum RoleplayModals {
  editCollection = "editCollection",
  deleteRoleplay = "deleteRoleplay",
  cloneCollection = "cloneCollection",
}

export default function RoleplayDetails({
  roleplay,
  onChoose = null,
  showNumScenarios = true,
  showProgress = true,
  showActionBar = false,
  headerBackground = LIGHT_GRAY,
  iconBackground = LIGHT_GRAY,
  prefixURL = null,
  viewMode = RoleplayViewMode.Column,
  refetchRoleplays = null,
}) {
  let link = buildRoleplayUrl(roleplay.slug);
  const [modal, setModal] = useState<RoleplayModals | null>(null);
  const { slug } = useParams();
  const { refetch } = useQuery(RoleplayDocument, {
    variables: {
      slug: slug,
    },
  });

  if (prefixURL) {
    link = `${prefixURL}${link}`;
  }

  const actionBar = (
    <Flex css={{ gap: 8 }} mt={24} alignItems="center">
      <Button intent="primary" onClick={() => onChoose(roleplay)}>
        Choose
      </Button>
      <Link to={link} target="_blank">
        <Button
          style={{
            backgroundColor: "#F5F6F8",
          }}
        >
          <Flex css={{ gap: 4 }} alignItems="center">
            <span>Preview</span>
            <Icon icon="share" />
          </Flex>
        </Button>
      </Link>
    </Flex>
  );

  const hasExtraIntro = showNumScenarios && showProgress;
  const isListView = viewMode === RoleplayViewMode.List;

  const content = (
    <>
      {!isListView && (
        <EmojiHeader style={{ backgroundColor: headerBackground }}>
          <HeaderEmojiCircle
            unified={roleplay.emojiUnicode.toLowerCase()}
            size={48}
            backgroundColor={iconBackground}
          />
        </EmojiHeader>
      )}
      <Flex
        px={24}
        pb={24}
        pt={isListView ? 24 : 32}
        flexDirection="column"
        flexGrow={1}
      >
        <Box flexGrow={1}>
          <Flex
            mb="6px"
            fontWeight={500}
            fontSize={18}
            alignItems={isListView ? "flex-start" : "center"}
          >
            {isListView && (
              <EmojiCircle
                style={{
                  width: "auto",
                  marginRight: 10,
                  display: "inline",
                }}
                unified={roleplay.emojiUnicode.toLowerCase()}
                size={40}
              />
            )}
            <Box flex={1}>{roleplay.name}</Box>
            {isListView && (
              <>
                <RoleplayScopeIcon scope={roleplay.visibilityScope} />
                <RoleplayMenu
                  roleplay={roleplay}
                  onDelete={() => setModal(RoleplayModals.deleteRoleplay)}
                  onEdit={() => setModal(RoleplayModals.editCollection)}
                  canEdit={!showActionBar && roleplay?.canEdit}
                  onClone={() => setModal(RoleplayModals.cloneCollection)}
                />
              </>
            )}
          </Flex>
          <Box color={SUBTEXT_COLOR} mt={1}>
            {roleplay.description}
          </Box>
          <Flex
            css={{ gap: 8 }}
            mt={hasExtraIntro ? 24 : 0}
            alignItems="center"
          >
            {showNumScenarios && (
              <Box fontSize={14} color={SUBTEXT_COLOR}>
                {pluralize(
                  roleplay.scenarios.length,
                  "scenario",
                  "scenarios",
                  true,
                )}
              </Box>
            )}
            {showNumScenarios && showProgress && (
              <Box fontSize={14} color={SUBTEXT_COLOR}>
                ·
              </Box>
            )}
            {showProgress && (
              <Flex css={{ gap: 4 }} alignItems="baseline" height={16}>
                {sortScenariosByDifficulty(roleplay.scenarios).map(
                  (scenario) => (
                    <Tooltip key={scenario.id} content={scenario.name}>
                      <RankIcon rank={scenario.rank} size={16} />
                    </Tooltip>
                  ),
                )}
              </Flex>
            )}
          </Flex>
          {showActionBar && actionBar}
        </Box>
      </Flex>
    </>
  );

  const dialog = (
    <>
      <CreateCollectionDialog
        isOpen={modal === RoleplayModals.editCollection}
        roleplay={roleplay}
        onClose={() => setModal(null)}
        onFinished={() => {
          if (slug) {
            refetch();
          }
          if (refetchRoleplays) {
            refetchRoleplays();
          }
          setModal(null);
        }}
      />
      <RoleplayDeleteConfirm
        open={modal === RoleplayModals.deleteRoleplay}
        onClose={() => setModal(null)}
        roleplay={roleplay}
        onFinished={() => {
          if (slug) {
            refetch();
          }
          if (refetchRoleplays) {
            refetchRoleplays();
          }
          setModal(null);
        }}
        shouldNavigate={false}
      />
      <CloneCollectionDialog
        open={modal === RoleplayModals.cloneCollection}
        onClose={() => setModal(null)}
        collection={roleplay}
      />
    </>
  );

  if (onChoose) {
    return <RoleplayCard className="bas round-corners">{content}</RoleplayCard>;
  }
  return (
    <>
      <RoleplayCardLink to={link} className="bas round-corners">
        {content}
      </RoleplayCardLink>
      {dialog}
    </>
  );
}
const sharedStyles = `
  display: flex;
  flex-direction: column;
  background-color: #fff;
  overflow: hidden;
  `;

const RoleplayCard = styled(Flex)`
  ${sharedStyles}
`;

const RoleplayCardLink = styled(Link)`
  ${sharedStyles}
  color: inherit;

  &:hover,
  &:focus,
  &:active {
    cursor: pointer;
    border-color: ${CTA_COLOR};
    box-shadow:
      0 1px 1px rgba(24, 32, 38, 0.1),
      0 2px 6px rgba(24, 32, 38, 0.05);
  }
`;

const HeaderEmojiCircle = styled(EmojiCircle)`
  position: absolute;
  top: 24px;
  left: 24px;
`;

const EmojiHeader = styled.div`
  background-color: ${LIGHT_GRAY};
  height: 48px;
  position: relative;
`;
