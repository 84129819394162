import React from "react";

import { Icon, IconName } from "@blueprintjs/core";

import { BoxProps, Flex } from "@/components/layout/flexbox";
import { DARK_PURPLE, LIGHT_GRAY } from "@/css/constants";

interface IconCircleProps extends BoxProps {
  icon: IconName | React.ReactElement;
  backgroundColor?: string;
  iconColor?: string;
  size?: number;
  iconSize?: number;
}

export default function IconCircle({
  icon,
  backgroundColor = LIGHT_GRAY,
  color = DARK_PURPLE,
  size = 36,
  iconSize,
  ...flexProps
}: IconCircleProps): React.ReactElement {
  const calculatedIconSize = iconSize || Math.floor(size / 2);
  const safeFlexProps: any = flexProps;

  return (
    <Flex
      alignItems="center"
      justifyContent="center"
      style={{ borderRadius: "50%" }}
      bg={backgroundColor}
      width={size}
      minWidth={size}
      height={size}
      minHeight={size}
      {...safeFlexProps}
    >
      <Icon icon={icon} color={color as string} size={calculatedIconSize} />
    </Flex>
  );
}
