import CreditSVG from "@/../../hypetrain/static/img/credit.svg";
import { Colors, Spinner } from "@blueprintjs/core";
import { useNavigate } from "react-router-dom";

import { pluralize } from "@/components/helpers/string-utils";
import EmbedSvg from "@/components/helpers/ui/EmbedSvg";
import { allowBuyCredits } from "@/components/helpers/workspace/permissions";
import { Box, Flex } from "@/components/layout/flexbox";
import { CustomIcon } from "@/components/pieces/icons/Icons";
import MenuItem from "@/components/pieces/menu/MenuItem";
import { MIDTEXT_COLOR } from "@/css/constants";
import { useAvailableCreditsQuery } from "@/graphql";

export default function AccountCredit({ onClose }) {
  const navigate = useNavigate();
  const { data, loading } = useAvailableCreditsQuery();

  if (!data || !data.currentUser) {
    return null;
  }
  const {
    currentUser: { availableCredits },
  } = data;

  return (
    <Box my={4}>
      <Box px={3} pb={1} className="small-caps" fontWeight={500}>
        Credit Balance
      </Box>
      <Box style={{ backgroundColor: Colors.WHITE }}>
        <Flex px={3} py={24} alignItems="center">
          <Box>
            <EmbedSvg src={CreditSVG} height={20} width={20} />
          </Box>
          <Box ml={2}>
            {loading ? (
              <Spinner size={24} />
            ) : (
              pluralize(
                Math.max(0, availableCredits),
                "Credit",
                "Credits",
                true,
              )
            )}
          </Box>
        </Flex>
        {allowBuyCredits() && (
          <Box className="bts" width={1}>
            <MenuItem
              icon="dollar"
              label="Buy Credits"
              onClick={() => {
                onClose();
                navigate("/buy");
              }}
            />
          </Box>
        )}
        <Box className="bts" width={1}>
          <MenuItem
            icon={
              <CustomIcon icon="gift" iconSize={16} color={MIDTEXT_COLOR} />
            }
            label="Earn Credits"
            onClick={() => {
              onClose();
              navigate("/referrals");
            }}
          />
        </Box>
      </Box>
    </Box>
  );
}
