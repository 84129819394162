import { pluralize } from "@/components/helpers/string-utils";

export default function formatCredits(availableCredits) {
  const credits = pluralize(
    Math.max(0, availableCredits),
    "Credit",
    "Credits",
    true,
  );
  return credits;
}
