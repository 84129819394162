import { Link, useSearchParams } from "react-router-dom";

import {
  buildUrl,
  getPlacementLinkFromUrl,
  isPlacementLink,
} from "@/components/helpers/url-utils";

interface NodeType {
  data: {
    uri: string;
  };
  content: Array<{
    value: string;
  }>;
}

export default function Hyperlink({ node }: { node: NodeType }) {
  const [searchParams] = useSearchParams();
  const url = node.data.uri;
  const content = node.content[0].value;
  if (isPlacementLink(url)) {
    let placementUrl = getPlacementLinkFromUrl(url);
    const isLearnUrl = placementUrl.startsWith("/learn");

    if (isLearnUrl) {
      placementUrl = buildUrl(placementUrl, {
        data: {
          layout: searchParams.get("layout"),
        },
      });
    }

    return (
      <Link
        to={placementUrl}
        target={isLearnUrl ? undefined : "_parent"}
        rel="noopener noreferer"
      >
        {content}
      </Link>
    );
  } else {
    return (
      <a href={url} target="_BLANK" rel="noopener noreferer">
        {content}
      </a>
    );
  }
}
