export default function PlacementSvg({ size = 28, color = "#7663FC" }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 58 72"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M43.1783 57.8872L35.6894 50.3084L35.8064 50.1899C45.7526 40.9532 47.6249 35.506 47.6249 30.0587C47.6249 19.2825 39.1998 10.6379 28.7856 10.6379C18.3713 10.6379 9.94623 19.4009 9.94623 30.0587C9.94623 35.506 11.8185 41.0717 21.7647 50.1899C23.7539 52.0846 26.0942 54.3346 28.7856 57.1767L36.0404 64.5187L36.1575 64.6371C33.1151 67.5976 30.6578 70.0844 28.7856 72.2159C26.2112 69.3739 22.7008 65.8213 18.0202 61.4398C2.80835 47.2295 0 38.7033 0 30.2955C0 13.7168 12.8716 0.335449 28.7856 0.335449C44.6995 0.335449 57.5711 13.7168 57.5711 30.2955C57.5711 37.9928 55.1138 45.8084 43.1783 57.8872ZM28.9026 20.5851C34.0512 20.5851 38.1467 24.8482 38.1467 30.0587C38.1467 35.2691 29.7217 42.3743 28.9026 43.2032C28.0835 42.3743 19.6584 35.506 19.6584 30.0587C19.6584 24.8482 23.8709 20.5851 28.9026 20.5851Z"
        fill={color}
      />
    </svg>
  );
}
