import { useEffect, useRef, useState } from "react";

import { useQuery } from "@apollo/client";
import {
  Button,
  Colors,
  Divider,
  FormGroup,
  Icon,
  Intent,
  OverlayToaster,
  Position,
} from "@blueprintjs/core";
import { createRoot } from "react-dom/client";

import { isWorkspaceAdmin } from "@/components/helpers/workspace/permissions";
import { Box, Flex } from "@/components/layout/flexbox";
import AddAssistantDialog from "@/components/pages_logged_in/workspace-admin/AddAssistantDialog";
import RemoveAssistantDialog from "@/components/pages_logged_in/workspace-admin/RemoveAssistantDialog";
import { StyledButton } from "@/components/pieces/link/LinkButton";
import Avatar from "@/components/pieces/users/Avatar";
import {
  WorkspaceMemberAssistantsDocument,
  WorkspaceMemberAssistantsQuery,
  WorkspaceMemberAssistantsQueryVariables,
  WorkspaceMemberType,
} from "@/graphql";

interface MemberAssistantBoxProps {
  member: WorkspaceMemberType;
  isMenu?: boolean;
  onClose?: () => void;
}

interface MemberContainerProps {
  member: WorkspaceMemberAssistantsQuery["workspaceMember"]["assistants"][0]["assistant"];
  isMenu?: boolean;
  onClickRemove?: () => void;
  allowRemove?: boolean;
}

function MemberContainer({
  member,
  isMenu,
  onClickRemove,
  allowRemove = false,
}: MemberContainerProps) {
  const user = member ? member.user : null;
  const name = user?.name || user?.email || "";

  return (
    <Flex
      width={[1, 1]}
      flex={["undefined", "undefined", 1]}
      maxWidth={
        isMenu ? "100%" : [undefined, undefined, "50%", "calc(30% - 24px)"]
      }
      minWidth={250}
      ml={0}
      mr={isMenu ? 0 : 2}
      mt={2}
      style={{
        border: "1px solid #e2e2e2",
        padding: "18px",
        borderRadius: "8px",
      }}
      justifyContent="space-between"
      alignItems="center"
    >
      <Flex alignItems="center">
        <Avatar size={20} user={user} />
        <Box flex={1} mx={2} fontSize={16} style={{ overflow: "hidden" }}>
          {name}
        </Box>
      </Flex>
      {allowRemove && (
        <Button
          minimal
          small
          icon={<Icon icon="cross" />}
          onClick={onClickRemove}
        ></Button>
      )}
    </Flex>
  );
}

function MemberAssistantBox({
  member = undefined,
  isMenu = false,
}: MemberAssistantBoxProps) {
  const user = member ? member.user : null;
  const memberName = user?.name || user?.email || "";

  const toasterRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenRemoveDialog, setIsOpenRemoveDialog] = useState(false);
  const [selectedMember, setSelectedMember] = useState(null);
  const [isAssistant, setIsAssistant] = useState(false);
  const isMemberWorkspaceAdmin = isWorkspaceAdmin();

  const { data, refetch } = useQuery<
    WorkspaceMemberAssistantsQuery,
    WorkspaceMemberAssistantsQueryVariables
  >(WorkspaceMemberAssistantsDocument, {
    fetchPolicy: "cache-and-network",
    skip: !user,
    variables: {
      customerId: user && user.id,
    },
  });

  const assistants = data?.workspaceMember?.assistants || [];
  const assistantClients = data?.workspaceMember?.assistantClients || [];

  useEffect(() => {
    const initToaster = async () => {
      toasterRef.current = await OverlayToaster.createAsync(
        {
          position: Position.TOP,
          usePortal: true,
        },
        {
          domRenderer: (toaster, containerElement) =>
            createRoot(containerElement).render(toaster),
        },
      );
    };

    initToaster();

    return () => {
      if (toasterRef.current) {
        toasterRef.current = null;
      }
    };
  }, []);

  const handleOnClickRemove = (member, isAssistant) => {
    setSelectedMember(member);
    setIsOpenRemoveDialog(true);
    setIsAssistant(isAssistant);
  };

  const handleOnClose = () => {
    setIsOpen(false);
    setSelectedMember(null);
    setIsAssistant(false);
    setIsOpenRemoveDialog(false);
  };

  const afterModifyAssistant = () => {
    refetch();
  };

  return (
    <FormGroup
      style={{ marginTop: 24, marginBottom: 0 }}
      subLabel={
        <span style={{ color: Colors.BLACK }}>
          Assistants can select coaches and book, reschedule, and cancel
          meetings on behalf of {memberName}.
        </span>
      }
      label={
        !isMenu && (
          <Box>
            <b style={{ fontSize: 20 }}>Assistants</b>
          </Box>
        )
      }
    >
      <Flex
        flexDirection={[
          "column",
          "column",
          isMenu ? "column" : "row",
          isMenu ? "column" : "row",
        ]}
        style={{ overflowX: "auto" }}
      >
        {assistants.map((assistant) => {
          return (
            <MemberContainer
              key={assistant?.id}
              member={assistant?.assistant}
              isMenu={isMenu}
              onClickRemove={() =>
                handleOnClickRemove(assistant?.assistant, true)
              }
              allowRemove={isMenu || isMemberWorkspaceAdmin}
            />
          );
        })}
      </Flex>
      {isMemberWorkspaceAdmin && (
        <Box width="200px" mt={2}>
          <StyledButton onClick={() => setIsOpen(true)}>
            Add Assistant
          </StyledButton>
        </Box>
      )}

      {assistantClients && assistantClients.length > 0 && (
        <Box mt={3}>
          <Divider />
          <Box mt={3} mb={1}>
            {memberName} is an assistant for the following users:
          </Box>
          <Flex
            flexDirection={[
              "column",
              "column",
              isMenu ? "column" : "row",
              isMenu ? "column" : "row",
            ]}
            style={{ overflowX: "auto" }}
          >
            {assistantClients.map((assistant) => {
              return (
                <MemberContainer
                  key={assistant?.id}
                  member={assistant?.client}
                  isMenu={isMenu}
                  onClickRemove={() =>
                    handleOnClickRemove(assistant?.client, false)
                  }
                  allowRemove={isMemberWorkspaceAdmin}
                />
              );
            })}
          </Flex>
        </Box>
      )}
      {isMemberWorkspaceAdmin && (
        <AddAssistantDialog
          member={member}
          isOpen={isOpen}
          onClose={() => handleOnClose()}
          afterModifyAssistant={afterModifyAssistant}
        />
      )}
      <RemoveAssistantDialog
        client={isAssistant ? member : selectedMember}
        assistant={isAssistant ? selectedMember : member}
        isOpen={isOpenRemoveDialog}
        afterModifyAssistant={afterModifyAssistant}
        onSuccess={() =>
          toasterRef.current.show({
            message: "Successfully removed the assistant from the user.",
            intent: Intent.SUCCESS,
          })
        }
        onFail={() =>
          toasterRef.current.show({
            message:
              "Unable to remove the assistant from the user. Please try again later.",
            intent: Intent.DANGER,
          })
        }
        onClose={() => handleOnClose()}
      />
    </FormGroup>
  );
}

export default MemberAssistantBox;
