import idx from "idx";
import pick from "lodash/pick";

import { AgentProfileType, CoachProductsQuery } from "@/graphql";

type CoachProduct = CoachProductsQuery["coachProducts"][number];

export interface DisplayedProduct
  extends Omit<CoachProduct["product"], "specialty" | "category"> {
  displayedCreditPrice: number; // Overridden from coachProduct.price || product.creditPrice
  acceptingNewClients: boolean; // From coachProduct
  enabled: boolean; // From coachProduct
}

export type AgentProfileWithSlug = Pick<AgentProfileType, "id" | "coachSlug">;

export interface SpecialtyRenderDataType {
  id: string;
  name: string;
  description?: string | null;
  categories: {
    category: string;
    products: DisplayedProduct[];
  }[];
  inactiveProducts: {
    id: string;
    name: string;
    description: string;
  }[];
  slug: string;
}

const toProduct = (product) => {
  return pick(product, [
    "id",
    "shortDescription",
    "displayedCreditPrice",
    "name",
    "slug",
    "duration",
    "enabled",
    "enableRfq",
    "acceptingNewClients",
    "introProduct",
    "price",
    "msrp",
  ]);
};

const toCategory = (category, product) => ({
  category,
  products: [toProduct(product)],
});

const toSpecialty = (specialtyItem, category, product) => ({
  id: specialtyItem.id,
  name: specialtyItem.name,
  description: specialtyItem.description,
  slug: specialtyItem.slug,
  categories: [toCategory(category, product)],
});

export const categoryTitle = {
  MEETING: "1:1 VIDEO SESSIONS",
  REVIEW: "OTHER SERVICES",
};

export function coachProductReducer(
  result = [],
  coachProduct,
): SpecialtyRenderDataType[] {
  const { product } = coachProduct;
  if (!product) return [];

  const { specialty, category, introProduct } = product;

  // Create a new product object instead of modifying the existing one
  const enhancedProduct = {
    ...product,
    enabled: coachProduct.enabled,
    displayedCreditPrice: coachProduct.price || product.creditPrice,
    acceptingNewClients: coachProduct.acceptingNewClients,
    introProduct,
  };

  const specialtiesIndex = result.findIndex(
    (element) => specialty.id === element.id,
  );
  if (specialtiesIndex === -1) {
    result.push(toSpecialty(specialty, category, enhancedProduct));
  } else {
    const specialtesItem = result[specialtiesIndex];
    const categoryIndex = specialtesItem.categories.findIndex(
      (element) => element.category === category,
    );
    if (categoryIndex === -1) {
      result[specialtiesIndex].categories.push(
        toCategory(category, enhancedProduct),
      );
    } else {
      result[specialtiesIndex].categories[categoryIndex].products.push(
        toProduct(enhancedProduct),
      );
    }
  }
  return result;
}

function specialtiesEnhancerReducer(
  enhancedSpecialties: SpecialtyRenderDataType[] = [],
  product,
): SpecialtyRenderDataType[] {
  product = product || {
    specialty: { id: "0", name: "", description: "" },
  };
  const matchedSpecialty = enhancedSpecialties.find(
    (spec) => spec.id === product.specialty.id,
  );
  if (!matchedSpecialty) {
    const newSpecialty: SpecialtyRenderDataType = {
      ...product.specialty,
      categories: [],
      inactiveProducts: [product],
    };
    enhancedSpecialties.push(newSpecialty);
    return enhancedSpecialties;
  }
  if (!matchedSpecialty.inactiveProducts) {
    matchedSpecialty.inactiveProducts = [];
  }
  matchedSpecialty.inactiveProducts = [
    ...matchedSpecialty.inactiveProducts,
    product,
  ];
  return enhancedSpecialties;
}

export function generateMappings(
  coachProducts: CoachProductsQuery["coachProducts"] = [],
  inactiveProducts = [],
  showIntroProduct: boolean = false,
  onlyCategories: string[] = [],
): SpecialtyRenderDataType[] {
  const specialties = coachProducts
    .filter((coachProduct) => {
      if (!coachProduct || !coachProduct.product || coachProduct.locked) {
        return false;
      }
      const { specialty, category, introProduct } = coachProduct.product;
      if (onlyCategories.length > 0 && !onlyCategories.includes(category)) {
        return false;
      }
      return (
        coachProduct &&
        specialty &&
        category &&
        (showIntroProduct || !introProduct)
      );
    })
    .reduce(coachProductReducer, []);
  return inactiveProducts.reduce(specialtiesEnhancerReducer, [...specialties]);
}

export function checkCoachInUserCoaches(profile, coaches = []) {
  const coachId = idx(profile, (_) => _.user.id);
  if (!coachId) return false;
  return coaches.find((c) => c && c.id && c.id === coachId);
}

export function generateSpecialtiesSubstring(specialties) {
  if (!specialties || !specialties.length) return "-";
  else if (specialties.length === 1) {
    return `- ${specialties[0].name} -`;
  } else if (specialties.length === 2) {
    return `- ${specialties[0].name} and ${specialties[1].name} -`;
  } else {
    return `- ${specialties[0].name}, ${specialties[1].name}, more -`;
  }
}

export function generateCoachSeoData(profile, specialties) {
  const title = idx(profile, () => profile.user.name) || "Coach Profile";
  const description = idx(profile, () => profile.summary) || "Coach profile";
  const specialtiesSubString = generateSpecialtiesSubstring(specialties);

  const coachData = {
    seoTitle: `${title} ${specialtiesSubString} ${
      IS_EXEC ? "Exec" : "Placement"
    }`,
    seoDescription: description,
  };

  return coachData;
}
