import noresponse from "@/../../hypetrain/static/img/no-response-circle.svg";
import ongoing from "@/../../hypetrain/static/img/ongoing-circle.svg";
import actionItemsIcon from "@/../../hypetrain/static/img/program/action-items.svg";
import aiRoleplayIcon from "@/../../hypetrain/static/img/program/ai-roleplay.svg";
import announcementIcon from "@/../../hypetrain/static/img/program/announcement.svg";
import articleIcon from "@/../../hypetrain/static/img/program/article-icon.svg";
import groupMeeting from "@/../../hypetrain/static/img/program/meeting.svg";
import surveyIcon from "@/../../hypetrain/static/img/program/survey.svg";
import videoIcon from "@/../../hypetrain/static/img/program/video-icon.svg";
import { Colors, Icon } from "@blueprintjs/core";
import moment from "moment";

import EmbedSvg from "@/components/helpers/ui/EmbedSvg";
import {
  isLeadComponent,
  isParticipantComponent,
  ProgramComponentType,
} from "@/components/pages_logged_in/program/components/types";
import { ComponentTypeEnum } from "@/graphql";

export enum ComponentStatus {
  Upcoming = "Upcoming",
  Ongoing = "Ongoing",
  Completed = "Completed",
  InDesign = "InDesign",
  NoResponse = "NoResponse",
}

const markAsProgramLeadComponent = (_component: ProgramComponentType) => {
  const component = { ..._component };
  delete component["participantSubmitAt"];
  return component;
};

export const getStatusOptionKey = (_component: ProgramComponentType) => {
  let component = _component;

  // if the component is a group meeting
  // then we want to show the status as program lead by removing the participantSubmitAt
  if (component?.type === ComponentTypeEnum.GroupMeeting) {
    component = markAsProgramLeadComponent(_component);
  }
  const status = getComponentStatus(component);

  if (status !== ComponentStatus.Completed) return status;

  if (isParticipantComponent(component)) {
    const hasNoParticipantSubmission = !component.participantSubmitAt;
    const isParticipantContent =
      component.survey?.isParticipantSurvey || component.isParticipantRoleplay;

    if (hasNoParticipantSubmission && isParticipantContent)
      return ComponentStatus.NoResponse;
  }

  return status;
};

export const getComponentStatus = (_component: ProgramComponentType) => {
  let component = _component;

  // if the component is a group meeting
  // then we want to show the status as program lead by removing the participantSubmitAt
  if (component?.type === ComponentTypeEnum.GroupMeeting) {
    component = markAsProgramLeadComponent(_component);
  }
  const { targetStartDate, targetEndDate, name } = component || {};
  const currentTime = moment();
  const startDate = moment(targetStartDate);
  const endDate = moment(targetEndDate);

  if (
    isParticipantComponent(component) &&
    (component.survey?.isParticipantSurvey ||
      component.isParticipantRoleplay) &&
    component.participantSubmitAt
  ) {
    return ComponentStatus.Completed;
  }

  if (currentTime.isAfter(endDate) || component?.closedAt) {
    return ComponentStatus.Completed;
  }

  const isComponentInDesign =
    !targetStartDate || !targetEndDate || !name || !component?.isSetupComplete;

  if (isComponentInDesign) {
    return ComponentStatus.InDesign;
  }

  if (currentTime.isBefore(startDate)) {
    return ComponentStatus.Upcoming;
  }

  return ComponentStatus.Ongoing;
};

export const programComponentIcons = {
  [ComponentTypeEnum.Announcement]: {
    icon: announcementIcon,
    classIcon: "volume-up",
  },
  [ComponentTypeEnum.ActionItem]: {
    icon: actionItemsIcon,
    classIcon: "form",
  },
  [ComponentTypeEnum.Survey]: {
    icon: surveyIcon,
    classIcon: "grouped-bar-chart",
  },
  [ComponentTypeEnum.AiRoleplay]: {
    icon: aiRoleplayIcon,
    classIcon: "chat",
  },
  [ComponentTypeEnum.Video]: {
    icon: videoIcon,
    classIcon: "video",
  },
  [ComponentTypeEnum.Article]: {
    icon: articleIcon,
    classIcon: "manual",
  },
  [ComponentTypeEnum.GroupMeeting]: {
    icon: groupMeeting,
    classIcon: "manual",
  },
};

export const componentStatusConfig = {
  [ComponentStatus.Upcoming]: {
    color: Colors.ORANGE3,
    backgroundColor: "#FBF2E9",
    icon: <Icon icon="circle" color="#BF7326" size={14} />,
    text: "Upcoming",
  },
  [ComponentStatus.Ongoing]: {
    color: Colors.BLUE3,
    backgroundColor: "#E9F1FB",
    icon: <EmbedSvg src={ongoing} height={14} width={14} lazy={false} />,
    text: "Ongoing",
  },
  [ComponentStatus.Completed]: {
    color: Colors.GREEN3,
    backgroundColor: "#E6F5EF",
    icon: <Icon icon="tick-circle" size={14} color="#0d8050" />,
    text: "Completed",
  },
  [ComponentStatus.InDesign]: {
    color: "#778397",
    backgroundColor: "#F2F4F8",
    icon: <Icon icon="wrench" size={14} color="#778397" />,
    text: "In Design",
  },
  [ComponentStatus.NoResponse]: {
    color: "#C61D1D",
    icon: <EmbedSvg src={noresponse} height={14} width={14} lazy={false} />,
    text: "No Response",
    backgroundColor: "#F5E6E8",
  },
};

export const componentDetailsPaths = {
  [ComponentTypeEnum.ActionItem]: "action-items",
  [ComponentTypeEnum.Survey]: "surveys",
  [ComponentTypeEnum.AiRoleplay]: "ai-roleplays",
  [ComponentTypeEnum.Video]: "videos",
  [ComponentTypeEnum.Article]: "articles",
  [ComponentTypeEnum.Announcement]: "announcement",
  [ComponentTypeEnum.GroupMeeting]: "group-meetings",
  [ComponentTypeEnum.OneOneCoachingMaster]: "one-one-coaching",
};

export function getComponentDatetime({
  component,
}: {
  component: ProgramComponentType;
}) {
  const { targetStartDate, targetDueDate, closedAt } = component || {};

  const startDate = targetStartDate;
  const dueDate = closedAt || targetDueDate;

  return { startDate, dueDate };
}

interface GetComponentDetailsPathsProps {
  programId: string;
  type: ComponentTypeEnum;
  tagID?: string | null;
  currentRoute?: string;
}

export function getComponentDetailsPaths({
  programId,
  type,
  tagID = null,
  currentRoute = "",
}: GetComponentDetailsPathsProps) {
  let path = `/programs/${programId}`;

  if (type && componentDetailsPaths[type]) {
    path = `${path}/${componentDetailsPaths[type]}`;
    // If the path is the same as the current route, return null to not redirect
    if (path === currentRoute) {
      return null;
    }
  }

  if (tagID) {
    path = `${path}${tagID}`;
  }

  return path;
}

export function ArticleSvg({ fillColor = "#FFFFFF", size = 50 }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 23 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.0455 0.257039C22.5745 0.337539 22.9655 0.774539 23 1.26904V18.634C23 18.68 22.9885 18.726 23 18.7835C22.8965 19.393 22.2985 19.7955 21.6775 19.692C17.25 18.9675 14.329 19.876 12.4775 22.521C12.4545 22.544 12.443 22.5555 12.4315 22.5785C12.4315 22.59 12.42 22.6015 12.4085 22.613L12.351 22.6705C12.3395 22.6705 12.328 22.682 12.3165 22.6935C12.2935 22.7165 12.2705 22.728 12.2475 22.751C12.236 22.751 12.236 22.7625 12.2245 22.7625C12.1555 22.8085 12.0865 22.8545 12.006 22.889C11.9945 22.889 11.983 22.9005 11.9715 22.9005C11.937 22.9235 11.891 22.935 11.8565 22.9465C11.845 22.9465 11.822 22.958 11.8105 22.958C11.7645 22.9695 11.73 22.9695 11.6955 22.981C11.684 22.9925 11.661 22.9925 11.6495 22.9925C11.6035 23.004 11.546 23.004 11.5 23.004H11.339C11.316 22.9925 11.3045 22.9925 11.293 22.9925C11.2585 22.9925 11.2125 22.981 11.178 22.9695C11.155 22.9695 11.1435 22.958 11.132 22.958C11.0975 22.9465 11.0515 22.9235 11.017 22.912C11.0055 22.912 11.0055 22.9005 10.994 22.9005C10.902 22.8545 10.8215 22.8085 10.7525 22.751C10.718 22.728 10.695 22.705 10.672 22.682L10.6605 22.6705C10.6375 22.636 10.6145 22.613 10.5915 22.5901L10.5915 22.59C10.5685 22.5555 10.557 22.5325 10.534 22.5095C8.694 19.8645 5.7615 18.9675 1.334 19.6805C0.713 19.784 0.115 19.37 0.0115 18.772C0 18.6915 0 18.611 0 18.5305V1.26904C0.0345 0.774539 0.4255 0.349039 0.966 0.257039C4.56896 -0.326981 7.51906 0.0858258 9.77244 1.47901C10.2754 1.79 10.5417 2.35687 10.5417 2.94825V18.2083C10.5417 18.2083 10.5417 19.1667 11.5 19.1667C12.4584 19.1667 12.4584 18.2083 12.4584 18.2083V2.94376C12.4584 2.35236 12.7246 1.78999 13.2276 1.47903C15.4815 0.08583 18.4335 -0.326984 22.0455 0.257039Z"
        fill={fillColor}
        fillOpacity="0.5"
      />
      <path
        d="M0 1.26904C0.0345 0.774539 0.4255 0.349039 0.966 0.257039C4.56896 -0.326981 7.51906 0.0858258 9.77244 1.47901C10.2755 1.79 10.5417 2.35687 10.5417 2.94825V18.2083C10.5417 18.2083 10.5417 19.1667 11.5 19.1667L11.5 23.004H11.339C11.316 22.9925 11.3045 22.9925 11.293 22.9925C11.2585 22.9925 11.2125 22.981 11.178 22.9695C11.155 22.9695 11.1435 22.958 11.132 22.958C11.0975 22.9465 11.0515 22.9235 11.017 22.912C11.0055 22.912 11.0055 22.9005 10.994 22.9005C10.902 22.8545 10.8215 22.8085 10.7525 22.751C10.718 22.728 10.695 22.705 10.672 22.682L10.6605 22.6705C10.6375 22.636 10.6145 22.613 10.5915 22.5901L10.5915 22.59C10.5685 22.5555 10.557 22.5325 10.534 22.5095C8.694 19.8645 5.7615 18.9675 1.334 19.6805C0.713 19.784 0.115 19.37 0.0115 18.772C0 18.6915 0 18.611 0 18.5305V1.26904Z"
        fill={fillColor}
      />
    </svg>
  );
}

export function AnnouncementSvg({ fillColor = "#FFFFFF", size = 50 }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="14 15 22 21"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29 31.6348C28.7043 33.3825 27.7974 34.7009 26.1925 35.4896C25.0084 36.0715 23.76 36.1542 22.5021 35.746C21.0967 35.2896 20.0661 34.3835 19.4563 33.0454C18.8499 31.7141 18.854 30.3611 19.4324 29L21.4127 29.5453C21.0677 30.2027 20.941 30.8567 21.072 31.5649C21.3732 33.198 23.0757 34.3103 24.6962 33.9169C25.9992 33.6009 26.8809 32.5897 27.0243 31.2598C27.0355 31.1533 27.0199 31.1036 26.9485 31.0698L29 31.6348Z"
        fill={fillColor}
      />
      <path
        d="M33.8421 15.8088C33.7943 15.9803 33.8002 16.1676 33.7793 16.3731L16.1478 21.3267C16.1112 21.2527 16.0835 21.19 16.0514 21.1303C15.7743 20.6053 15.1296 20.4036 14.6089 20.6801C14.1607 20.918 14.0016 21.3207 14.0008 21.805C13.9993 23.9172 14.0001 26.0295 14.0016 28.1403C14.0016 28.271 14.0113 28.404 14.0344 28.5332C14.1099 28.9615 14.3654 29.2463 14.7665 29.3816C15.1617 29.5153 15.5322 29.4397 15.8325 29.1398C15.9648 29.0076 16.0596 28.8376 16.1777 28.6759L33.8002 33.628C33.8002 33.7399 33.798 33.8343 33.801 33.928C33.8241 34.6736 34.5473 35.179 35.248 34.9403C35.7283 34.7764 35.9995 34.3457 35.9995 33.7414C36.0003 27.9152 36.0003 22.0883 35.9988 16.2613C35.9988 16.1616 35.9868 16.0611 35.9726 15.9622C35.8934 15.4099 35.487 15.0156 34.9245 15.0004C34.4539 14.9876 33.9975 15.252 33.8421 15.8088Z"
        fill={fillColor}
      />
    </svg>
  );
}

export function RoleplaySvg({ fillColor = "#FFFFFF", size = 50 }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="12.5 12.87 26 23.4"
      width={size}
      height={size}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.3665 12.8652C27.3238 12.8652 28.0999 13.6413 28.0999 14.5986V18.0658H23.7666C22.3307 18.0658 21.1666 19.2298 21.1666 20.6658V24.9986H16.3999L12.4999 28.4652V14.5986C12.4999 13.6413 13.2759 12.8652 14.2332 12.8652H26.3665Z"
        fill={fillColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.6335 20.668C23.6762 20.668 22.9001 21.444 22.9001 22.4013V31.068C22.9001 32.0253 23.6762 32.8013 24.6335 32.8013H35.0335L38.5001 36.268V22.4013C38.5001 21.444 37.7241 20.668 36.7668 20.668H24.6335ZM35.2071 24.2071C35.5976 23.8166 35.5976 23.1834 35.2071 22.7929C34.8166 22.4024 34.1834 22.4024 33.7929 22.7929L29 27.5858L27.2071 25.7929C26.8166 25.4024 26.1834 25.4024 25.7929 25.7929C25.4024 26.1834 25.4024 26.8166 25.7929 27.2071L28.2929 29.7071C28.6834 30.0976 29.3166 30.0976 29.7071 29.7071L35.2071 24.2071Z"
        fill={fillColor}
      />
    </svg>
  );
}

export function GroupMeetingSvg({ fillColor = "#FFFFFF", size = 50 }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 26 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.0476 4.73179C19.0476 4.44914 19.1732 4.18109 19.3903 4.00015L23.7713 0.349351C24.3916 -0.167575 25.3333 0.273528 25.3333 1.08099V12.7285C25.3333 13.536 24.3916 13.9771 23.7713 13.4602L19.3903 9.80938C19.1732 9.62843 19.0476 9.36039 19.0476 9.07774V4.73179Z"
        fill={fillColor}
      />
      <path
        d="M0 1.52381C0 0.682233 0.682233 0 1.52381 0H13.5238C16.0485 0 18.0952 2.0467 18.0952 4.57143V12.4762C18.0952 13.3178 17.413 14 16.5714 14H4.57143C2.0467 14 0 11.9533 0 9.42857V1.52381Z"
        fill={fillColor}
      />
    </svg>
  );
}

export function scrollWithOffset({
  componentTag,
  offset = 0,
}: {
  componentTag: string;
  offset?: number;
}) {
  try {
    const element = document.getElementById(componentTag);
    if (!element) {
      console.warn(`Element with id ${componentTag} not found`);
      return;
    }

    const elementPosition =
      element.getBoundingClientRect().top + window.scrollY;
    window.scrollTo({
      top: elementPosition + offset,
      behavior: "smooth",
    });
  } catch (e) {
    console.error("Error in scrollWithOffset:", e);
  }
}

export const hasParticipant = (component?: ProgramComponentType) => {
  if (isLeadComponent(component)) {
    const { isSetupComplete, totalParticipants } = component;
    return isSetupComplete && totalParticipants > 0;
  }
  return false;
};

export const hasScenario = (component?: ProgramComponentType) => {
  if (!component) return false;
  if (!("roleplayScenarios" in component)) return false;
  return component.roleplayScenarios?.length > 0;
};
