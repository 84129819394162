export interface ArticleContentFilter {
  isSearchable?: boolean;
  showPublicOnly?: boolean;
  visibleOnSite?: string;
  excludeUrlSlug?: string | null;
  articleSeriesUrlSlug?: string;
}

export const buildArticleContentFilter = ({
  isSearchable,
  showPublicOnly = true,
  visibleOnSite,
  excludeUrlSlug = null,
  articleSeriesUrlSlug,
}: ArticleContentFilter = {}): string => {
  const filters: string[] = [];
  const sanitizeValue = (value: string): string => {
    return value.replace(/[:\s]/g, (c) => `\\${c}`);
  };

  if (showPublicOnly) {
    filters.push("public:true");
  }

  if (isSearchable) {
    filters.push("is_searchable:true");
  }

  if (visibleOnSite) {
    filters.push(`visible_on:${sanitizeValue(visibleOnSite)}`);
  }

  if (excludeUrlSlug) {
    filters.push(`NOT url_slug:${sanitizeValue(excludeUrlSlug)}`);
  }

  if (articleSeriesUrlSlug) {
    filters.push(
      `article_series_url_slug:${sanitizeValue(articleSeriesUrlSlug)}`,
    );
  }

  return filters.join(" AND ");
};
