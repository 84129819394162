import { FC } from "react";

import { BaseHit, Hit as InstantSearchHit } from "instantsearch.js";

import { SearchPreferenceQuery } from "@/graphql";

export const ALL_FILTER_OPTION = {
  key: "all",
  name: "All",
};

export interface ResultComponentProps {
  hit: InstantSearchHit<BaseHit>;
  selected?: boolean;
  onClose?: () => void;
  onSelect?: (hit: InstantSearchHit<BaseHit>) => void;
}

export type IndexComponent = FC<IndexComponentProps>;
export type ResultComponent = FC<ResultComponentProps>;

export type IndexKey =
  | "CUSTOMER"
  | "COACH"
  | "ARTICLE"
  | "PROGRAM"
  | "ROLEPLAY"
  | "SCENARIO"
  | "COMPANY"
  | "JOB_TITLE";

export interface SearchIndex {
  key: IndexKey;
  name: string;
  IndexComponent: IndexComponent;
  ResultComponent: ResultComponent;
  indexName: string;
}

export interface IndexComponentProps {
  indexKey: string;
  hitsPerPage: number;
  onClose: () => void;
  onSelect: (hit: InstantSearchHit<BaseHit>) => void;
  searchPreference?: SearchPreferenceQuery;
  selectedObjectId?: string;
}
