import { Configure, Hits, Index } from "react-instantsearch";

import { useWorkspace } from "@/components/helpers/custom-hooks/use-workspace";
import { SCENARIO_INDEX } from "@/components/pieces/algolia/indexes";
import Avatar from "@/components/pieces/users/Avatar";
import { getUser } from "@/components/session/JwtTokenManager";

import { EntityResult } from "./EntityResult";
import { IndexComponentProps, ResultComponentProps } from "./search-types";

export default function Scenarios({
  indexKey,
  selectedObjectId,
  onClose,
  onSelect,
}: IndexComponentProps) {
  const user = getUser();
  const workspace = useWorkspace();

  return (
    <Index indexName={SCENARIO_INDEX} indexId={indexKey}>
      <Configure
        hitsPerPage={7}
        filters={`(_tags:owner_${user?.id} OR _tags:global OR _tags:workspace_${workspace?.id}) AND (_tags:owner_${user?.id} OR _tags:has_criteria)`}
      />
      <Hits
        hitComponent={(props) => (
          <ScenarioResult
            {...props}
            selected={props.hit.objectID === selectedObjectId}
            onClose={onClose}
            onSelect={onSelect}
          />
        )}
      />
    </Index>
  );
}

export function ScenarioResult({
  selected,
  hit,
  onClose,
  onSelect,
}: ResultComponentProps) {
  const link = `/scenarios/${hit.slug}`;

  return (
    <EntityResult
      selected={selected}
      hit={hit}
      onClose={onClose}
      onSelect={onSelect}
      avatar={
        <Avatar
          user={{
            ...hit,
            photo: hit.persona_photo_url,
          }}
          size={40}
        />
      }
      link={link}
      titleHighlightKey="name"
      descriptionHighlightKey="short_desc"
    />
  );
}
