import { Box } from "@/components/layout/flexbox";
import MenuHeader from "@/components/pieces/menu/MenuHeader";

import AccountCredit from "./AccountCredit";
import GiftCode from "./GiftCode";
import PaymentMethods from "./PaymentMethods";
import SubscriptionSetting from "./SubscriptionSetting";

export default function MenuBilling({ onBackLink, onClose, isOpen }) {
  if (!isOpen) {
    return null;
  }

  return (
    <Box width={1}>
      <MenuHeader onBackLink={onBackLink} onClose={onClose} label="Billing" />
      <SubscriptionSetting />
      <AccountCredit onClose={onClose} />
      <PaymentMethods />
      <GiftCode />
    </Box>
  );
}
