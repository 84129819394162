import { useEffect, useState } from "react";

import { Icon } from "@blueprintjs/core";

import {
  convertToMarkdown,
  isMarkdown,
} from "@/components/helpers/string-utils";
import { Box, Flex } from "@/components/layout/flexbox";
import CustomCriterionTab from "@/components/pages_logged_in/roleplays/details/evaluation-criteria/CustomCriterionTab";
import PickingTemplatesTab from "@/components/pages_logged_in/roleplays/details/evaluation-criteria/PickingTemplatesTab";
import { BehaviorCriterionType } from "@/components/pages_logged_in/roleplays/details/evaluation-criteria/types";
import {
  EvaluationCriteriaDialogs,
  formatBulletsToText,
  reorder,
} from "@/components/pages_logged_in/roleplays/details/utils";
import StyledDialog from "@/components/pieces/StyledDialog";
import Tabs from "@/components/pieces/Tabs";

function TabRenderer({ tab, active }) {
  const label = (
    <Flex
      alignItems={"center"}
      css={{
        fontWeight: "bold",
        color: active ? "#3788EE" : "#242424",
        border: "none",
      }}
    >
      <Icon icon={tab.icon} style={{ marginRight: 8 }} />
      {tab.label}
    </Flex>
  );

  return label;
}

const ADDING_TABS = [
  {
    key: "template",
    label: "From Template",
    icon: "duplicate",
    TabRenderer,
  },
  {
    key: "custom",
    label: "Create Custom",
    icon: "plus",
    TabRenderer,
  },
];

export default function UpdateCriterionDialog({
  open,
  dialog,
  templateCriterion,
  templatesSlug,
  onClose,
  onSave,
  currentCriteria,
  criterion,
  loading = false,
  errorMessage,
  isRoleplay = false,
}: {
  open: boolean;
  dialog: EvaluationCriteriaDialogs;
  templateCriterion: boolean;
  templatesSlug: string;
  onClose: () => void;
  onSave: (criterion: BehaviorCriterionType, fromTemplate: boolean) => void;
  currentCriteria: BehaviorCriterionType[];
  criterion: BehaviorCriterionType | null;
  loading: boolean;
  errorMessage?: string;
  isRoleplay?: boolean;
}) {
  const [updatedCriterion, setUpdatedCriterion] = useState(null);
  const [selectedTabId, setSelectedTabId] = useState("template");

  const isShowTab =
    dialog === EvaluationCriteriaDialogs.AddCriterion && templateCriterion;
  const isEditDialog = dialog === EvaluationCriteriaDialogs.EditCriterion;
  const showOnlyCustomTab = !templateCriterion || isEditDialog;

  const filterValidItems = (items) =>
    items.filter((item) => item.name && item.description);

  const handleSave = (criterion) => {
    const validItems = filterValidItems(criterion.items);

    onSave(
      {
        ...criterion,
        items: validItems.map((item, index) => ({
          ...item,
          id: item.isNew ? null : item.id,
          order: index + 1,
          description: isMarkdown(item.description)
            ? item.description
            : convertToMarkdown(item.description),
        })),
      },
      false,
    );
  };

  const handleSelectTemplate = (template) => {
    onSave(
      {
        ...template,
        id: null,
        items: template.items.map((item) => ({
          ...item,
          id: null,
          isNew: true,
        })),
      },
      true,
    );
  };

  useEffect(() => {
    if (open && criterion) {
      setUpdatedCriterion({
        ...criterion,
        items: criterion.items.map((item) => ({
          ...item,
          isNew: false,
        })),
      });
    } else {
      setUpdatedCriterion({
        id: null,
        name: "",
        items: [
          {
            id: `new-behavior-1`,
            name: "",
            description: "",
            isNew: true,
          },
        ],
      });
    }
  }, [open, criterion]);

  const onRemoveBehavior = (behavior) => {
    const newItems = updatedCriterion.items.filter(
      (item) => item.id !== behavior.id,
    );

    // Handle case where all behaviors are removed
    setUpdatedCriterion({
      ...updatedCriterion,
      items:
        newItems.length === 0
          ? [
              {
                id: `new-behavior-1`,
                name: "",
                description: "",
                isNew: true,
              },
            ]
          : newItems,
    });
  };

  const onUpdateBehavior = (behavior) => {
    setUpdatedCriterion((prevCriterion) => ({
      ...prevCriterion,
      items: prevCriterion.items.map((item) => {
        if (item.id !== behavior.id) {
          return item;
        }

        if (behavior.description === item.description) {
          return behavior;
        }

        if (behavior.description.includes(":")) {
          return behavior;
        }

        return {
          ...behavior,
          description: formatBulletsToText(behavior.description),
        };
      }),
    }));
  };

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const reorderedItems = reorder(
      updatedCriterion.items,
      result.source.index,
      result.destination.index,
    );

    setUpdatedCriterion({
      ...updatedCriterion,
      items: reorderedItems.map((item, index) => ({
        ...(typeof item === "object" ? item : {}),
        order: index,
      })),
    });
  };

  const hasBehaviorContent = (criterion) => {
    return criterion?.items?.some(
      (item) => item.name.trim() !== "" && item.description.trim() !== "",
    );
  };

  const buttonText = {
    [EvaluationCriteriaDialogs.EditCriterion]: "Save",
    [EvaluationCriteriaDialogs.AddCriterion]: isRoleplay
      ? "Add to Collection"
      : "Add to Scenario",
  };

  const isSaveDisabled =
    loading ||
    !hasBehaviorContent(updatedCriterion) ||
    updatedCriterion?.name?.trim() === "";

  return (
    <StyledDialog
      canOutsideClickClose={false}
      isOpen={open}
      onClose={onClose}
      lazy={true}
      title={
        <Box fontSize={18}>
          {`${isEditDialog ? "Update" : "Add"} Evaluation Criteria`}
        </Box>
      }
      width="650px"
    >
      {isShowTab && (
        <Tabs
          tabs={ADDING_TABS}
          tab={selectedTabId}
          onTabChange={(tab) => setSelectedTabId(tab.key)}
          style={{
            background: "#FBFCFF",
          }}
        />
      )}

      {selectedTabId === "custom" || showOnlyCustomTab ? (
        <CustomCriterionTab
          updatedCriterion={updatedCriterion}
          onUpdateCriterion={(criterion) => setUpdatedCriterion(criterion)}
          onSaveCriterion={handleSave}
          onDragEnd={onDragEnd}
          onUpdateBehavior={onUpdateBehavior}
          onRemoveBehavior={onRemoveBehavior}
          errorMessage={errorMessage}
          buttonText={buttonText[dialog]}
          isSaveDisabled={isSaveDisabled}
        />
      ) : (
        <PickingTemplatesTab
          currentCriteria={currentCriteria}
          templatesSlug={templatesSlug}
          pickTemplateLoading={loading}
          onChooseTemplate={handleSelectTemplate}
        />
      )}
    </StyledDialog>
  );
}
