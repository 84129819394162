import * as Sentry from "@sentry/react";
import axios from "axios";
import idx from "idx";
import Cookies from "js-cookie";
import noop from "lodash/noop";

import { setSessionLink } from "@/components/helpers/custom-hooks/use-current-session";
import {
  APP_REDIRECTION_LINK_COOKIE_NAME,
  CSRF_COOKIE_NAME,
} from "@/global/constants";
import {
  RefreshTokenDocument,
  WorkspaceMemberRole,
  WorkspaceType,
} from "@/graphql";

const DEFAULT_EXPIRATION_INTERVAL = 24 * 60 * 60; // 1 day
const BUFFER_TO_RENEW_IN_SECONDS = DEFAULT_EXPIRATION_INTERVAL / 5;

let apolloClient;

let buildVersion: string = null;
let jwtToken;
let refreshToken;
let expirationTime;
let impersonator = null;
let conflictVersion = false;

export interface LoggedInUserType {
  id?: string;
  algoliaApiKey?: string;
  chatToken?: string;
  email?: string;
  isStaff?: boolean;
  isAgent?: boolean;
  isAssist?: boolean;
  assistOnUserIds?: Array<string>;
  isSuperuser?: boolean;
  isWorkspaceAgent?: boolean;
  isWorkspaceGroupAdmin?: boolean;
  ownedProgramIds?: Array<string>;
  participantProgramIds?: Array<string>;
  timeZone?: string;
  name?: string;
  onboardingCompletedAt?: string;
  photo?: string;
  workspaceRole?: WorkspaceMemberRole;
  configuration?: any;
  hasIndividualAccount?: boolean;
  subscription?: any;
  workspaces?: WorkspaceType[];
  programLeadWorkspaces?: Array<{
    id: string;
    urlSlug: string;
  }>;
  sesameQuery?: string;
}

export function isConflictConfigVersion(oldUser, newUser) {
  if (oldUser && newUser) {
    const oldConfig = oldUser.configuration;
    const newConfig = newUser.configuration;
    if (
      (oldConfig && newConfig && oldConfig.version !== newConfig.version) ||
      ((oldConfig === null || newConfig === null) && oldConfig !== newConfig)
    ) {
      return true;
    }
  }
  return false;
}

let user: LoggedInUserType = null;

let renewTimeoutRef;

export function init({ client }) {
  apolloClient = client;
}

export function getBuildVersion() {
  return buildVersion;
}

export function getJwtToken() {
  return jwtToken;
}

export function getRefreshToken() {
  return refreshToken;
}

export function isImpersonating() {
  return !!impersonator;
}

export function getImpersonator() {
  return impersonator;
}

export function getUser(): LoggedInUserType | null {
  return user;
}

export function getConflictVersion() {
  return conflictVersion;
}

export function hasValidToken() {
  const now = Math.round(new Date().getTime() / 1000);
  const hasLocalSession =
    !import.meta.env.SSR &&
    !!localStorage &&
    !!localStorage.getItem(APP_REDIRECTION_LINK_COOKIE_NAME);
  return (
    !!jwtToken &&
    hasLocalSession &&
    expirationTime > now + BUFFER_TO_RENEW_IN_SECONDS
  );
}

export function setTokens(params: {
  token: string;
  refreshToken: string;
  expiration?: number;
  oneTimeUse?: boolean;
  success?: () => void;
  error?: () => void;
}) {
  const now = new Date();
  const secondsSinceEpoch = Math.round(now.getTime() / 1000);

  jwtToken = params.token;
  refreshToken = params.refreshToken;

  if (refreshToken) {
    expirationTime =
      params.expiration || secondsSinceEpoch + DEFAULT_EXPIRATION_INTERVAL;
    const refreshInterval =
      expirationTime - secondsSinceEpoch - BUFFER_TO_RENEW_IN_SECONDS; // 30 seconds before expiration

    renewTimeoutRef = setTimeout(() => {
      renewTokens({
        success: () => {
          renewTimeoutRef = null;
        },
        error: () => {
          renewTimeoutRef = null;
        },
        oneTimeUse: params.oneTimeUse,
      });
    }, refreshInterval * 1000);

    if (!params.oneTimeUse) {
      saveRefreshToken({
        success: params.success || noop,
        error: params.error || noop,
      });
    }
  } else {
    clearTimeout(renewTimeoutRef);
    renewTimeoutRef = null;
    expirationTime = secondsSinceEpoch;
  }
}

export function logout({ redirectPath = undefined }) {
  axios
    .post("/logout", null, {
      headers: {
        "X-CSRF-TOKEN": Cookies.get(CSRF_COOKIE_NAME),
      },
    })
    .then(() => {
      setSessionLink(null);
      window.location.href = redirectPath || "/";
    })
    .catch((err) => {
      Sentry.captureException(err);
    });
}

interface LoadTokensOptions {
  verify?: boolean;
  success?: ({
    user,
    impersonator,
  }: {
    user?: LoggedInUserType;
    impersonator?: boolean;
  }) => void;
  error?: (err: any) => void;
}

export function loadTokens(options: LoadTokensOptions = {}) {
  const { verify, success = () => null, error = () => null } = options;

  const handleError = (err) => {
    impersonator = null;
    user = null;
    setTokens({
      token: null,
      refreshToken: null,
    });
    error(err);
  };

  axios
    .get("/lt", {
      headers: {
        "X-CSRF-TOKEN": Cookies.get(CSRF_COOKIE_NAME),
      },
    })
    .then((response) => {
      const { data } = response;

      const { token } = data;
      conflictVersion = isConflictConfigVersion(getUser(), data.user);
      user = data.user;
      window.dispatchEvent(new Event("exec.tokensLoaded"));

      buildVersion = data.buildVersion;

      refreshToken = token;
      impersonator = data.impersonator;
      const successResponse = {
        user,
        impersonator,
      };

      const now = new Date();
      const secondsSinceEpoch = Math.round(now.getTime() / 1000);

      if (
        verify &&
        expirationTime &&
        expirationTime > secondsSinceEpoch + BUFFER_TO_RENEW_IN_SECONDS &&
        renewTimeoutRef
      ) {
        return success(successResponse);
      }

      renewTokens({
        success: () => {
          success(successResponse);
        },
        error: handleError,
        oneTimeUse: false,
      });
    })
    .catch((err) => {
      if (err.response && err.response.status === 401) {
        return handleError(new Error(UNAUTHENTICATED_ERROR));
      } else {
        return handleError(new Error(SERVER_ERROR));
      }
    });
}

function renewTokens(
  { success, error, oneTimeUse } = {
    success: noop,
    error: noop,
    oneTimeUse: false,
  },
) {
  apolloClient
    .mutate({
      mutation: RefreshTokenDocument,
      variables: {
        refreshToken,
      },
    })
    .then((result) => {
      const rT = idx(result, (_) => _.data.refreshToken.refreshToken);
      const jwt = idx(result, (_) => _.data.refreshToken.token);
      if (!rT) {
        throw new Error("Refresh token is missing");
      }

      if (!jwt) {
        throw new Error("JWT token is missing");
      }

      const exp = idx(result, (_) => _.data.refreshToken.payload.exp);
      setTokens({
        token: jwt,
        refreshToken: rT,
        expiration: exp,
        oneTimeUse,
        success,
      });
    })
    .catch(error);
}

/**
 * Save refresh token to the session
 */

export function saveRefreshToken({ success, error }) {
  axios
    .post("/st", null, {
      headers: {
        token: refreshToken,
        "X-CSRF-TOKEN": Cookies.get(CSRF_COOKIE_NAME),
      },
    })
    .then((response) => {
      if (response.status !== 200) {
        setSessionLink(null);
        setTokens({
          token: null,
          refreshToken: null,
        });
        return error({ message: "Unable to save tokens" });
      }

      setSessionLink("/dashboard");
      success(response.data);
    })
    .catch(() => {
      setSessionLink(null);
      setTokens({
        token: null,
        refreshToken: null,
      });
      error({ message: "Unable to save tokens" });
    });
}

export const NETWORK_ERROR = "NETWORK_ERROR";
export const SERVER_ERROR = "SERVER_ERROR";
export const UNAUTHENTICATED_ERROR = "UNAUTHENTICATED_ERROR";
