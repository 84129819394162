import { CSSProperties } from "react";

import styled from "@emotion/styled";
import {
  color,
  compose,
  flexbox,
  layout,
  space,
  typography,
} from "styled-system";

type ResponsiveValue<T> = T | Array<T | null> | { [key: string]: T };

// Define the base system props
interface SpaceProps {
  m?: ResponsiveValue<CSSProperties["margin"]>;
  margin?: ResponsiveValue<CSSProperties["margin"]>;
  mt?: ResponsiveValue<CSSProperties["marginTop"]>;
  mr?: ResponsiveValue<CSSProperties["marginRight"]>;
  mb?: ResponsiveValue<CSSProperties["marginBottom"]>;
  ml?: ResponsiveValue<CSSProperties["marginLeft"]>;
  mx?: ResponsiveValue<CSSProperties["margin"]>;
  my?: ResponsiveValue<CSSProperties["margin"]>;
  p?: ResponsiveValue<CSSProperties["padding"]>;
  padding?: ResponsiveValue<CSSProperties["padding"]>;
  pt?: ResponsiveValue<CSSProperties["paddingTop"]>;
  pr?: ResponsiveValue<CSSProperties["paddingRight"]>;
  pb?: ResponsiveValue<CSSProperties["paddingBottom"]>;
  pl?: ResponsiveValue<CSSProperties["paddingLeft"]>;
  px?: ResponsiveValue<CSSProperties["padding"]>;
  py?: ResponsiveValue<CSSProperties["padding"]>;
}

interface LayoutProps {
  width?: ResponsiveValue<CSSProperties["width"]>;
  height?: ResponsiveValue<CSSProperties["height"]>;
  minWidth?: ResponsiveValue<CSSProperties["minWidth"]>;
  maxWidth?: ResponsiveValue<CSSProperties["maxWidth"]>;
  minHeight?: ResponsiveValue<CSSProperties["minHeight"]>;
  maxHeight?: ResponsiveValue<CSSProperties["maxHeight"]>;
  display?: ResponsiveValue<CSSProperties["display"]>;
  size?: ResponsiveValue<string | number>;
  overflow?: ResponsiveValue<CSSProperties["overflow"]>;
  overflowX?: ResponsiveValue<CSSProperties["overflowX"]>;
  overflowY?: ResponsiveValue<CSSProperties["overflowY"]>;
  position?: ResponsiveValue<CSSProperties["position"]>;
  top?: ResponsiveValue<CSSProperties["top"]>;
  right?: ResponsiveValue<CSSProperties["right"]>;
  bottom?: ResponsiveValue<CSSProperties["bottom"]>;
  left?: ResponsiveValue<CSSProperties["left"]>;
  zIndex?: ResponsiveValue<CSSProperties["zIndex"]>;
}

interface ColorProps {
  color?: ResponsiveValue<CSSProperties["color"]>;
  bg?: ResponsiveValue<CSSProperties["backgroundColor"]>;
  backgroundColor?: ResponsiveValue<CSSProperties["backgroundColor"]>;
  opacity?: ResponsiveValue<CSSProperties["opacity"]>;
}

interface TypographyProps {
  fontSize?: ResponsiveValue<CSSProperties["fontSize"]>;
  fontFamily?: ResponsiveValue<CSSProperties["fontFamily"]>;
  fontWeight?: ResponsiveValue<CSSProperties["fontWeight"]>;
  fontStyle?: ResponsiveValue<CSSProperties["fontStyle"]>;
  lineHeight?: ResponsiveValue<CSSProperties["lineHeight"]>;
  letterSpacing?: ResponsiveValue<CSSProperties["letterSpacing"]>;
  textAlign?: ResponsiveValue<CSSProperties["textAlign"]>;
}

interface FlexboxProps {
  alignItems?: ResponsiveValue<CSSProperties["alignItems"]>;
  alignContent?: ResponsiveValue<CSSProperties["alignContent"]>;
  justifyItems?: ResponsiveValue<CSSProperties["justifyItems"]>;
  justifyContent?: ResponsiveValue<CSSProperties["justifyContent"]>;
  flexWrap?: ResponsiveValue<CSSProperties["flexWrap"]>;
  flexDirection?: ResponsiveValue<CSSProperties["flexDirection"]>;
  flex?: ResponsiveValue<CSSProperties["flex"]>;
  flexGrow?: ResponsiveValue<CSSProperties["flexGrow"]>;
  flexShrink?: ResponsiveValue<CSSProperties["flexShrink"]>;
  flexBasis?: ResponsiveValue<CSSProperties["flexBasis"]>;
  justifySelf?: ResponsiveValue<CSSProperties["justifySelf"]>;
  alignSelf?: ResponsiveValue<CSSProperties["alignSelf"]>;
  order?: ResponsiveValue<CSSProperties["order"]>;
}

export interface BoxProps
  extends SpaceProps,
    LayoutProps,
    TypographyProps,
    ColorProps,
    FlexboxProps {
  /**
   * The sx prop can be used for theme-aware style objects,
   * similar to the `sx` prop in Theme UI or other css-in-js setups.
   */
  sx?: Record<string, any>;

  /**
   * __css is used for base styles that are theme-aware
   * and applied before other styles.
   */
  __css?: Record<string, any>;

  /**
   * The variant prop can reference a key in your theme (e.g. theme.variants)
   * or fall back to theme keys directly.
   */
  variant?: string;

  /**
   * tx indicates the top-level key in the theme to look for variants.
   * Defaults to 'variants'.
   */
  tx?: string;

  /**
   * Optional theme.
   */
  theme?: any;

  /**
   * A css prop that can be used for additional styles, similar to Emotion's `css` prop.
   */
  css?: any;
}

// Return the __css object verbatim
const base = (props: BoxProps) => props.__css || {};

// Return the sx object verbatim
const sx = (props: BoxProps) => props.sx || {};

// Lookup the variant style in the theme, if present
const variant = (props: BoxProps) => {
  const { theme, variant, tx } = props;
  if (!theme || !variant) return {};
  const variantsKey = tx || "variants";
  // first try theme[variantsKey][variant], then fallback to theme[variant]
  return theme?.[variantsKey]?.[variant] || theme?.[variant] || {};
};

export const Box = styled("div")<BoxProps>(
  {
    boxSizing: "border-box",
    margin: 0,
    minWidth: 0,
  },
  base,
  variant,
  sx,
  (props) => props.css,
  compose(space, layout, typography, color, flexbox),
);

export const Flex = styled(Box)({
  display: "flex",
});
