export const FEATURE_CONFIGS = {
  enableReviews: "enableReviews",
  enableJobSearch: "enableJobSearch",
};

interface AppProfile {
  is_exec: boolean;
  appName: string;
  webApp: string;
  apiUrl: string;
  helloEmail: string;
  billingEmail: string;
}

const EXEC_APP_PROFILE: AppProfile = {
  is_exec: true,
  appName: "Exec",
  webApp: WEB_APP,
  apiUrl: API_HOST,
  helloEmail: "hello@exec.com",
  billingEmail: "billing@exec.com",
};

const PLACEMENT_APP_PROFILE: AppProfile = {
  is_exec: false,
  appName: "Placement",
  webApp: WEB_APP,
  apiUrl: API_HOST,
  helloEmail: "hello@placement.com",
  billingEmail: "billing@placement.com",
};

export const APP_PROFILE = IS_EXEC ? EXEC_APP_PROFILE : PLACEMENT_APP_PROFILE;
