import { useMutation } from "@apollo/client";
import { Alert, Intent } from "@blueprintjs/core";

import { RemoveAssistantDocument, WorkspaceMemberType } from "@/graphql";

interface RemoveAssistantDialogProps {
  client: WorkspaceMemberType;
  assistant: WorkspaceMemberType;
  isOpen: boolean;
  onClose: () => void;
  afterModifyAssistant?: () => void;
  onSuccess?: () => void;
  onFail?: () => void;
}

function RemoveAssistantDialog({
  client,
  assistant,
  isOpen,
  onClose,
  onSuccess,
  onFail,
  afterModifyAssistant,
}: RemoveAssistantDialogProps) {
  const clientName = client?.user?.name || client?.user?.email || "";
  const assistantName = assistant?.user?.name || assistant?.user?.email || "";

  const [removeAssistant] = useMutation(RemoveAssistantDocument, {
    onCompleted: (data) => {
      if (data && data.removeAssistant && data?.removeAssistant?.ok) {
        afterModifyAssistant && afterModifyAssistant();
        onClose();
        onSuccess && onSuccess();
      } else {
        onClose();
        onFail && onFail();
      }
    },
    onError: () => {
      onClose();
      onFail && onFail();
    },
  });

  const confirmRemoveAssistant = () => {
    removeAssistant({
      variables: {
        clientMemberId: client?.id,
        assistantMemberId: assistant?.id,
      },
    });
  };

  return (
    <Alert
      cancelButtonText="Nevermind"
      confirmButtonText="Remove"
      icon="undo"
      intent={Intent.DANGER}
      isOpen={isOpen}
      onCancel={onClose}
      onConfirm={confirmRemoveAssistant}
    >
      <p>
        Are you sure you want to remove <b>{assistantName}</b> as an assistant?
        They will no longer be able to book services on behalf of{" "}
        <b>{clientName}</b>.
      </p>
    </Alert>
  );
}

export default RemoveAssistantDialog;
