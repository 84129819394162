import { useRef } from "react";

export interface FileInfo {
  id: string;
  name: string;
  size: number;
  type: string;
  url: string;
}

export function FileUpload({
  children,
  onFileUpload,
}: {
  children: (
    onClick: (e: React.MouseEvent<HTMLElement>) => void,
  ) => React.ReactNode;
  onFileUpload: (file: File) => Promise<FileInfo> | null;
}) {
  const inputRef = useRef<HTMLInputElement>(null);

  if (!onFileUpload) {
    return children(() => {});
  }

  const onClick = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
    inputRef.current?.click();
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files[0];
    if (file) {
      onFileUpload(file);
    }
    e.target.value = null;
  };

  return (
    <>
      <input
        ref={inputRef}
        type="file"
        onChange={handleChange}
        style={{ display: "none" }}
      />
      {children(onClick)}
    </>
  );
}
