import { FC, useState } from "react";

import { ScenariosPageType } from "@/components/pages_logged_in/roleplays/scenarios/const";
import ScenariosList, {
  ScopeFilterOptions,
} from "@/components/pages_logged_in/roleplays/scenarios/ScenariosList";
import { ScenariosPageMetadata } from "@/components/pages_logged_in/roleplays/scenarios/ScenariosPageContainer";
import { Container } from "@/components/pieces/bootstrap";
import Tabs from "@/components/pieces/Tabs";

import RoleplaysList from "./RoleplaysList";

interface TabConfig {
  key: string;
  label: string;
  component: FC<any>;
  props?: Record<string, any>;
}

const ROLEPLAY_TABS: TabConfig[] = [
  {
    key: "scenarios",
    label: "Scenarios",
    component: ScenariosList,
    props: {
      type: ScenariosPageType.All,
      title: null,
      statusFilter:
        ScenariosPageMetadata?.[ScenariosPageType.All]?.defaultFilter,
      scopeFilter: ScopeFilterOptions[0].id,
      showShortDesc: true,
      showPlayButton: false,
      showScope: true,
      showAction: true,
      allowAdd: true,
    },
  },
  {
    key: "collections",
    label: "Collections",
    component: RoleplaysList,
    props: {
      includeOwnerData: true,
    },
  },
];

export default function AllRoleplaySection({ pageLoading = false }) {
  const [selectedTabId, setSelectedTabId] = useState(ROLEPLAY_TABS[0].key);

  const selectedTab = ROLEPLAY_TABS.find((tab) => tab.key === selectedTabId);
  const TabComponent = selectedTab?.component;

  return (
    <Container>
      <Container fontSize={24} fontWeight={600} mb={3}>
        All Roleplays
      </Container>
      <Tabs
        tabs={ROLEPLAY_TABS}
        tab={selectedTabId}
        onTabChange={(tab) => setSelectedTabId(tab.key)}
        style={{
          borderTop: "none",
          boxShadow: "0px 1px 0px 0px #E7EBF7",
        }}
        backgroundColor="transparent"
      />
      {TabComponent && (
        <TabComponent {...selectedTab.props} pageLoading={pageLoading} />
      )}
    </Container>
  );
}
