import { Colors, Icon } from "@blueprintjs/core";
import { useHotkeys } from "react-hotkeys-hook";
import { Configure, Highlight, Hits, Index } from "react-instantsearch";
import { Link, useNavigate } from "react-router-dom";

import { getCurrentSite } from "@/components/helpers/configuration/utils";
import { buildArticleContentFilter } from "@/components/helpers/search/articleSearchFilter";
import { Box, Flex } from "@/components/layout/flexbox";
import { ARTICLE_SERIES_ARTICLES_INDEX } from "@/components/pieces/algolia/indexes";
import ArticleSvg from "@/components/pieces/icons/svg/article";
import Truncate from "@/components/pieces/Truncate";

import { IndexComponentProps, ResultComponentProps } from "./search-types";

export default function Articles({
  indexKey,
  hitsPerPage,
  selectedObjectId,
  onClose,
  onSelect,
}: IndexComponentProps) {
  const filters = buildArticleContentFilter({
    showPublicOnly: false,
    isSearchable: true,
    visibleOnSite: getCurrentSite(),
  });

  return (
    <Index indexName={ARTICLE_SERIES_ARTICLES_INDEX} indexId={indexKey}>
      <Configure hitsPerPage={hitsPerPage} distinct filters={filters} />
      <Hits
        hitComponent={(props) => (
          <ArticleResult
            {...props}
            selected={props.hit.objectID === selectedObjectId}
            onClose={onClose}
            onSelect={onSelect}
          />
        )}
      />
    </Index>
  );
}

export function ArticleResult({
  hit,
  selected,
  onClose,
  onSelect,
}: ResultComponentProps) {
  const navigate = useNavigate();
  const link = `/learn/${hit.url_slug}`;

  useHotkeys(
    "enter",
    () => {
      if (selected) {
        navigate(link);
        onSelect(hit);
        onClose();
      }
    },
    {},
    [selected, link],
  );

  const content = (
    <Flex alignItems="center" className={selected ? "selected" : null}>
      <Box pl={[2, 3]} py={12}>
        <ArticleSvg size={40} />
      </Box>
      <Box ml={[2, 3]} py={12} pr={2} flex={1} className="resultItemDetails">
        <Truncate style={{ color: Colors.GRAY3 }}>
          <Highlight hit={hit} attribute="title" />
        </Truncate>
        <Box className="secondary" style={{ color: Colors.GRAY3 }}>
          <Truncate>
            <Highlight hit={hit} attribute="summary" />
          </Truncate>
        </Box>
      </Box>
      <Box pl={2} pr={3} className="arrow">
        <Icon icon="arrow-right" />
      </Box>
    </Flex>
  );

  return (
    <Link to={link} className="resultItem" onClick={() => onSelect(hit)}>
      {content}
    </Link>
  );
}
