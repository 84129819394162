import { Configure, Hits, Index } from "react-instantsearch";

import { useWorkspace } from "@/components/helpers/custom-hooks/use-workspace";
import { Box } from "@/components/layout/flexbox";
import { PROGRAM_INDEX } from "@/components/pieces/algolia/indexes";
import PlaybookSvg from "@/components/pieces/icons/svg/playbook";
import { getUser } from "@/components/session/JwtTokenManager";
import { BRAND_PURPLE } from "@/css/constants";

import { EntityResult } from "./EntityResult";
import { IndexComponentProps, ResultComponentProps } from "./search-types";

export default function Programs({
  indexKey,
  hitsPerPage,
  selectedObjectId,
  onClose,
  onSelect,
}: IndexComponentProps) {
  const user = getUser();
  const workspace = useWorkspace();

  return (
    <Index indexName={PROGRAM_INDEX} indexId={indexKey}>
      <Configure
        hitsPerPage={hitsPerPage}
        filters={`_tags:user_${user?.id} AND _tags:workspace_${workspace?.id}`}
      />
      <Hits
        hitComponent={(props) => (
          <ProgramResult
            {...props}
            selected={props.hit.objectID === selectedObjectId}
            onClose={onClose}
            onSelect={onSelect}
          />
        )}
      />
    </Index>
  );
}

export function ProgramResult({
  selected,
  hit,
  onClose,
  onSelect,
}: ResultComponentProps) {
  const link = `/programs/${hit.id}`;

  return (
    <EntityResult
      selected={selected}
      hit={hit}
      onClose={onClose}
      onSelect={onSelect}
      avatar={
        <Box
          style={{ width: 40, height: 40 }}
          justifyContent="center"
          alignItems="center"
          display="flex"
        >
          <PlaybookSvg size={16} color={BRAND_PURPLE} />
        </Box>
      }
      link={link}
      titleHighlightKey="name"
      descriptionHighlightKey="short_desc"
    />
  );
}
