import {
  getUser,
  LoggedInUserType,
} from "@/components/session/JwtTokenManager";
import { WorkspaceMemberRole, FullMeetingQuery } from "@/graphql";
// Constants with proper readonly tuple types
export const WORKSPACE_AGENT_ROLES: readonly WorkspaceMemberRole[] = [
  WorkspaceMemberRole.Admin,
  WorkspaceMemberRole.Staff,
] as const;

export const WORKSPACE_ADMIN_ROLES: readonly WorkspaceMemberRole[] = [
  WorkspaceMemberRole.Admin,
] as const;

// Helper function with proper optional type
function getCurrentUser(
  authUser?: LoggedInUserType,
): LoggedInUserType | undefined {
  return authUser || getUser();
}

// Workspace membership checks
export function isInWorkspace(): boolean {
  const user = getCurrentUser();
  return Boolean(user?.workspaceRole);
}

// Meeting attendee checks with non-null assertion for required props
export function isAttendees(meeting: FullMeetingQuery["meeting"]): boolean {
  const user = getCurrentUser();
  const meetingOwnerId = meeting?.customerId ?? meeting?.customer?.id;

  if (!user?.id || !meetingOwnerId) {
    return false;
  }

  const isOwner = meetingOwnerId.toString() === user.id.toString();
  const isStaff = user.isAgent || user.isStaff || user.isSuperuser;

  return !isOwner && !isStaff;
}

// Role checks with proper null handling
export function isCoach(authUser: LoggedInUserType | null = null): boolean {
  const user = getCurrentUser(authUser);
  return Boolean(user?.isAgent || user?.isStaff || user?.isSuperuser);
}

export function isProgramLeadOnWorkspace(workspaceSlug: string): boolean {
  const user = getCurrentUser();
  return (
    user?.programLeadWorkspaces?.some(
      (workspace: { id: string; urlSlug: string }) =>
        workspace.urlSlug === workspaceSlug,
    ) ?? false
  );
}

export function isProgramParticipant(programId: string): boolean {
  const user = getCurrentUser();
  return user?.participantProgramIds?.includes(programId) ?? false;
}

export function isWorkspaceAdmin(workspaceRole?: WorkspaceMemberRole): boolean {
  const user = getCurrentUser();

  if (user?.isStaff || user?.isSuperuser) {
    return true;
  }

  if (workspaceRole) {
    return WORKSPACE_ADMIN_ROLES.includes(workspaceRole);
  }

  return Boolean(
    user?.workspaceRole && WORKSPACE_ADMIN_ROLES.includes(user.workspaceRole),
  );
}

export function isProgramLead(
  programId: string,
  includeWorkspaceAdmin: boolean = true,
): boolean {
  const user = getCurrentUser();
  return Boolean(
    user?.ownedProgramIds?.includes(programId) ||
      (includeWorkspaceAdmin && isWorkspaceAdmin()),
  );
}

export function isWorkspaceAgent(
  workspaceRole?: (typeof WORKSPACE_AGENT_ROLES)[number],
): boolean {
  const user = getCurrentUser();

  if (user?.isStaff || user?.isSuperuser) {
    return true;
  }

  if (workspaceRole) {
    return WORKSPACE_AGENT_ROLES.includes(workspaceRole);
  }

  return Boolean(
    user?.workspaceRole && WORKSPACE_AGENT_ROLES.includes(user.workspaceRole),
  );
}

export function isWorkspaceGroupAdmin(): boolean {
  if (isWorkspaceAdmin()) return true;
  return Boolean(getCurrentUser()?.isWorkspaceGroupAdmin);
}

export function allowBuyCredits(): boolean {
  return (
    !isInWorkspace() ||
    (isInWorkspace() && (isWorkspaceAdmin() || isWorkspaceGroupAdmin()))
  );
}

export function isAssist(authUser?: LoggedInUserType): boolean {
  const user = getCurrentUser(authUser);
  return Boolean(user?.isAssist);
}
