import { Colors } from "@blueprintjs/core";

export default function PracticeSvg({ size = 16, color = Colors.GRAY3 }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox={`0 0 24 24`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#practice_clip0)">
        <path
          d="M10.6443 23.9999C9.92349 23.8663 9.19436 23.7703 8.48333 23.595C3.32516 22.3232 -0.270385 17.4879 0.0148655 12.2309C0.311248 6.79445 4.35206 2.39603 9.71199 1.67942C12.0803 1.36356 14.7519 1.84918 16.3493 2.9067C15.9708 3.4285 15.2125 3.86263 15.8748 4.73369C13.4703 3.54121 11.0241 3.18221 8.49446 3.96004C5.9439 4.74482 4.05429 6.35892 2.8785 8.76199C1.0849 12.4257 1.949 16.8617 4.96013 19.5876C8.00188 22.3413 12.4365 22.7866 15.9346 20.6924C19.7945 18.3812 21.097 14.474 19.7514 9.24343C20.4082 9.45215 20.4082 9.45215 21.6438 8.83573C22.2449 10.3121 22.4829 11.8371 22.3632 13.4053C21.9527 18.7791 18.2653 22.8478 12.9694 23.8051C12.5645 23.8789 12.1582 23.9359 11.7519 24.0013C11.3831 23.9999 11.0144 23.9999 10.6443 23.9999Z"
          fill={color}
        />
        <path
          d="M25.2054 5.86225C23.9558 6.62894 22.6799 7.35807 21.4679 8.18182C20.9057 8.56447 20.4633 8.63126 19.9568 8.18321C19.403 7.69341 18.9466 7.76855 18.3635 8.25C16.5699 9.73191 14.7151 11.1415 12.8839 12.5788C12.7392 12.693 12.5959 12.8084 12.447 12.917C12.0463 13.2106 11.5913 13.4054 11.2086 12.974C10.7981 12.5135 11.0569 12.0724 11.4382 11.7078C13.2916 9.94341 15.1381 8.17069 17.0124 6.42857C17.3895 6.07792 17.5579 5.76902 17.2601 5.31957C16.6325 4.37616 16.8705 3.65121 17.665 2.8859C18.5528 2.03154 19.3014 1.02829 20.1864 0C20.4758 1.27458 20.7541 2.4295 20.9837 3.59416C21.0171 3.76113 20.8543 4.0102 20.7165 4.16048C19.8914 5.05798 19.0467 5.93878 18.2327 6.79731C18.2954 6.76531 18.4261 6.72217 18.5305 6.64286C19.3376 6.02783 20.1641 5.43646 20.9308 4.77551C21.3454 4.41929 21.6947 4.41651 22.1692 4.60853C23.1669 5.01067 24.1896 5.35436 25.2012 5.7231C25.2054 5.76902 25.2054 5.81494 25.2054 5.86225Z"
          fill={color}
        />
        <path
          d="M14.7623 7.18396C14.6676 7.28554 14.5702 7.38294 14.4826 7.4873C13.8592 8.22478 13.2567 8.54064 12.1519 8.20947C9.70985 7.47756 7.14816 9.20716 6.55401 11.7549C5.9682 14.2624 7.56143 16.7837 10.1106 17.3862C12.5971 17.9734 15.195 16.4233 15.7349 13.9451C15.8921 13.2257 15.8393 12.4367 15.7516 11.6951C15.7001 11.2637 15.7502 11.023 16.112 10.7934C16.4779 10.5611 16.8049 10.2661 17.1486 9.9989C18.65 12.0471 17.804 15.9405 15.458 17.9122C12.7766 20.1649 8.80818 19.9604 6.36755 17.4418C3.9297 14.9275 3.84482 10.9256 6.17275 8.33749C8.39213 5.86486 12.3522 5.32775 14.7623 7.18396Z"
          fill={color}
        />
        <path
          d="M11.184 10.6113C10.2879 11.4351 9.31387 12.3187 10.3616 13.4736C11.4011 14.6174 12.4001 13.6934 13.3366 13.0283C13.3227 14.1665 12.3974 15.0251 11.2285 15.0376C9.99291 15.0515 9.01053 14.1276 8.97018 12.9142C8.92844 11.6285 9.80367 10.6739 11.184 10.6113Z"
          fill={color}
        />
        <path
          d="M22.9318 2.92069C22.4156 3.33256 21.8994 3.74583 21.3845 4.1577C21.3177 4.14379 21.2523 4.13127 21.1855 4.11735C21.2259 3.95594 21.2148 3.70965 21.3163 3.64564C21.8089 3.33256 22.3335 3.06819 22.8456 2.78711C22.8748 2.83303 22.904 2.87755 22.9318 2.92069Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="practice_clip0">
          <rect width={24} height={24} fill={Colors.WHITE} />
        </clipPath>
      </defs>
    </svg>
  );
}
