import { useState } from "react";

import { useMutation } from "@apollo/client";
import { Button, Intent, Menu, MenuItem, Position } from "@blueprintjs/core";

import { Popover } from "@/components/helpers/ui/blueprint-overrides/Popover";
import { Flex } from "@/components/layout/flexbox";
import RoleplaySessionAccessButton from "@/components/pieces/roleplays/RoleplaySessionAccessButton";
import RoleplaySessionDeleteConfirm from "@/components/pieces/roleplays/RoleplaySessionDeleteConfirm";
import { getUser } from "@/components/session/JwtTokenManager";
import { GradeRoleplaySessionDocument, ScenarioDocument } from "@/graphql";

interface RoleplaySessionMenuProps {
  roleplaySession: any;
  showAccessButton?: boolean;
  refetchQueries?: any;
}

const VALID_ATTEMPT_MIN_CONVERSATION_DURATION_IN_SECONDS = 90;

export function RoleplaySessionMenuContent({
  roleplaySession,
  refetchQueries = null,
  showAccessButton = false,
}: RoleplaySessionMenuProps) {
  const [deleting, setDeleting] = useState(false);
  const {
    duration = 0,
    isValidAttempt = false,
    isValidAttemptExplanation,
  } = roleplaySession || {};
  const showGradeSessionButton =
    !isValidAttempt &&
    duration >= VALID_ATTEMPT_MIN_CONVERSATION_DURATION_IN_SECONDS &&
    !isValidAttemptExplanation; // Only allow regrade if there is no explanation
  const [gradeRoleplaySession] = useMutation(GradeRoleplaySessionDocument, {
    update: (cache, { data }) => {
      const existingScenarioDocument: {
        scenario: { roleplaySessions: { id: string }[] };
      } | null = cache.readQuery({
        query: ScenarioDocument,
        variables: { scenarioSlug: roleplaySession.scenario.slug },
      });

      if (existingScenarioDocument) {
        cache.writeQuery({
          query: ScenarioDocument,
          variables: { scenarioSlug: roleplaySession.scenario.slug },
          data: {
            scenario: {
              roleplaySessions:
                existingScenarioDocument.scenario.roleplaySessions.map(
                  (session) => {
                    if (session.id === roleplaySession.id) {
                      return {
                        ...session,
                        processingStatus:
                          data.gradeRoleplaySession.roleplaySession
                            .processingStatus,
                      };
                    }

                    return session;
                  },
                ),
            },
          },
        });
      }
    },
  });

  return (
    <Flex style={{ gap: 16 }}>
      {showAccessButton && (
        <RoleplaySessionAccessButton session={roleplaySession} />
      )}
      <Popover
        canEscapeKeyClose={true}
        position={Position.BOTTOM_RIGHT}
        onClose={() => {}}
        content={
          <Menu>
            {showGradeSessionButton && (
              <MenuItem
                text="Regrade Session"
                onClick={() => {
                  gradeRoleplaySession({
                    variables: { id: roleplaySession.id },
                  });
                }}
              />
            )}
            <MenuItem
              onClick={() => {
                setDeleting(true);
              }}
              text="Delete Session"
              intent={Intent.DANGER}
            />
          </Menu>
        }
      >
        <Button
          style={{ minHeight: 36 }}
          minimal={true}
          icon="more"
          onClick={() => {}}
        />
      </Popover>
      <RoleplaySessionDeleteConfirm
        open={deleting}
        roleplaySession={roleplaySession}
        onClose={() => setDeleting(false)}
        refetchQueries={refetchQueries}
      />
    </Flex>
  );
}

export default function RoleplaySessionMenu({
  roleplaySession,
  showAccessButton = false,
}) {
  if (!roleplaySession) {
    return null;
  }

  const user = getUser();
  const isSessionOwner = roleplaySession?.user?.id === user?.id;

  if (!isSessionOwner) {
    return null;
  }

  return (
    <RoleplaySessionMenuContent
      roleplaySession={roleplaySession}
      showAccessButton={showAccessButton}
    />
  );
}
