import { useState } from "react";

import LazyImg from "@/components/helpers/ui/LazyImg";
import { Box, Flex } from "@/components/layout/flexbox";
import GrayButton from "@/components/pieces/button/GrayButton";
import Subtext from "@/components/pieces/form/Subtext";
import Clickable from "@/components/pieces/interaction/Clickable";
import Uploader from "@/components/pieces/uploader";
import { BLUE_TEXT_COLOR, LIGHT_BACKGROUND } from "@/css/constants";

export enum ImageFrameType {
  Circle = "circle",
  Square = "square",
}

export enum ButtonPosition {
  Top = "top",
  Bottom = "bottom",
}

export function UploadImageController({
  logoSrc,
  endpoint,
  fieldName,
  imagesSize = 120,
  buttonColor = LIGHT_BACKGROUND,
  buttonLabel = "Change Logo",
  buttonPosition = ButtonPosition.Bottom,
  imageFrameType = ImageFrameType.Square,
  onComplete = async () => {},
}) {
  const [isLogoUploaderVisible, setIsLogoUploaderVisible] = useState(false);
  const isButtonTop = buttonPosition === ButtonPosition.Top;

  const handleUploadComplete = () => {
    if (onComplete) {
      onComplete().then(() => {
        setIsLogoUploaderVisible(false);
      });
    } else {
      setIsLogoUploaderVisible(false);
    }
  };

  if (isLogoUploaderVisible) {
    return (
      <Box>
        <Clickable
          onClick={() => {
            setIsLogoUploaderVisible(false);
          }}
        >
          <Box mb={2} color={BLUE_TEXT_COLOR}>
            Keep current {fieldName}
          </Box>
        </Clickable>
        <Uploader
          endpoint={endpoint}
          fieldName={fieldName}
          onComplete={handleUploadComplete}
        />
      </Box>
    );
  }
  return (
    <>
      <LazyImg
        src={logoSrc}
        height={75}
        width={75}
        style={{
          borderRadius: imageFrameType === ImageFrameType.Circle ? "100%" : 8,
        }}
      />
      <Flex
        flexGrow={1}
        flexDirection={isButtonTop ? "column-reverse" : "column"}
        pl={20}
      >
        <Subtext mb={isButtonTop ? 0 : 2}>
          Square images at least {imagesSize}x{imagesSize}px
        </Subtext>
        <GrayButton
          style={{
            borderRadius: 8,
            width: "fit-content",
            background: buttonColor,
            marginBottom: isButtonTop ? 10 : 0,
          }}
          onClick={() => {
            setIsLogoUploaderVisible(true);
          }}
        >
          {buttonLabel}
        </GrayButton>
      </Flex>
    </>
  );
}

export default UploadImageController;
