import { Button, Classes } from "@blueprintjs/core";
import styled from "@emotion/styled";
import { Link } from "react-router-dom";

import { BP_NS, LIGHT_BACKGROUND, MIDTEXT_COLOR } from "@/css/constants";

const buttonStyles = `
background: ${LIGHT_BACKGROUND};
border-radius: 4px;
color: ${MIDTEXT_COLOR};
display: block;
font-weight: 500;
padding: 10px 14px;
text-align: center;
box-shadow: none;

&.transparent {
  background-color: transparent;
}

&:hover {
  box-shadow: 0 0 0 0 rgba(226, 226, 226, 0),
  0 0 0 0 rgba(226, 226, 226, 0), inset 0 0 0 1px #e2e2e2,
  inset 0 0px 1px #e2e2e2, 0 2px 6px rgba(41, 155, 240, 0.18), 0 1px 0 #f0f0f0;
  background-clip: padding-box;
  background: ${LIGHT_BACKGROUND};
}
`;

const LinkButton = styled(Link)`
  ${buttonStyles}
`;

export const ExternalLinkButton = styled("a")`
  ${buttonStyles}
`;

export const StyledButton = styled(Button)`
  ${buttonStyles}

  &.${Classes.BUTTON}:not([class*="${BP_NS}-intent-"]) {
    ${buttonStyles}
  }
`;

export default LinkButton;
