import { KeyboardEvent as ReactKeyboardEvent } from "react";

import { Hit } from "instantsearch.js";
import capitalize from "lodash/capitalize";

import AlgoliaMultiselect from "@/components/pieces/algolia/AlgoliaMultiselect";

interface JobFunctionMultiselectProps {
  // Required props based on usage
  onChange: (selections: Hit[]) => void;
  disabled: boolean;
  selections: Hit[];

  // Optional props based on usage
  large?: boolean;
  autoFocus?: boolean;
  filters?: string;
  onKeyPress?: (event: ReactKeyboardEvent<HTMLElement>) => void;
}

export default function JobFunctionMultiselect(
  props: JobFunctionMultiselectProps,
) {
  const filters = props.selections.map((s) => `NOT objectID:${s.id}`);

  if (props.filters) {
    filters.push(`(${props.filters})`);
  }

  return (
    <AlgoliaMultiselect
      indexName="job_function_index"
      filters={filters.join(" AND ")}
      renderSuggestion={(suggestion) => (
        <div>{suggestion.name.replace(/\w+/g, capitalize)}</div>
      )}
      getSuggestionValue={(suggestion) =>
        suggestion.name.replace(/\w+/g, capitalize)
      }
      placeholder="ie. Software Development"
      {...props}
    />
  );
}
