import { useEffect, useReducer, useState } from "react";

import { useMutation } from "@apollo/client";
import { Colors, Portal } from "@blueprintjs/core";

import { Box, Flex } from "@/components/layout/flexbox";
import SlideContainer, { Directions } from "@/components/layout/SlideContainer";
import SmallCaps from "@/components/pieces/form/SmallCaps";
import ToggleField from "@/components/pieces/form/ToggleField";
import MenuHeader from "@/components/pieces/menu/MenuHeader";
import Toast from "@/components/pieces/Toast";
import {
  LoggedInNavDataQueryDocument,
  SaveNotificationPreferencesDocument,
  WorkspaceNavQueryDocument,
} from "@/graphql";

import NotificationSvg from "./NotificationSvg";

enum PrefActions {
  SetPref = "SET_PREF",
  SetTime = "SET_TIME",
}

function reducer(state, action) {
  switch (action.type) {
    case PrefActions.SetPref:
      return {
        ...state,
        [action.pref]: action.value,
      };
    case PrefActions.SetTime:
      return {
        ...state,
        time: action.value,
      };
    default:
      console.log("Unknown action: " + action.type);
      return state;
  }
}

export default function MenuNotifications({
  user,
  onClose,
  onBackLink,
  isOpen,
}) {
  const prefs = user.notificationPreferences.reduce((obj, pref) => {
    obj[pref.type] = pref;
    return obj;
  }, {});
  const emailOn = (pref) => !!prefs[pref] && prefs[pref].email;
  const [notifState, dispatch] = useReducer(reducer, {
    generalChatEnabled: emailOn("GENERAL_CHAT"),
    directMessagesEnabled: emailOn("DIRECT_MESSAGE"),
    meetingMessagesEnabled: emailOn("MEETING_MESSAGE"),
    reviewMessagesEnabled: emailOn("REVIEW_MESSAGE"),
    announcementsEnabled: emailOn("ANNOUNCEMENTS"),
  });

  const [savedVisible, setSavedVisible] = useState(false);
  const [hasError, setHasError] = useState(false);

  const [mutate] = useMutation(SaveNotificationPreferencesDocument, {
    variables: {
      input: notifState,
    },
    refetchQueries: [
      { query: LoggedInNavDataQueryDocument },
      { query: WorkspaceNavQueryDocument },
    ],
    onError: () => {
      setHasError(true);
    },
    onCompleted: (response) => {
      const { errors } = response.saveNotificationPreferences;
      if (errors.length) {
        setHasError(true);
      } else {
        setSavedVisible(true);
        setTimeout(() => {
          setSavedVisible(false);
        }, 3000);
      }
    },
  });

  function submit() {
    setHasError(false);
    mutate();
  }

  const [ready, setReady] = useState(false);
  useEffect(() => {
    setReady(true);
  }, []);
  useEffect(() => {
    if (ready && isOpen) {
      submit();
    }
  }, [notifState]);

  return (
    <Box width={1}>
      <MenuHeader
        onBackLink={() => {
          setSavedVisible(false);
          onBackLink();
        }}
        onClose={() => {
          setSavedVisible(false);
          onClose();
        }}
        label="Notifications"
      />

      <Box className="text-center" py={3}>
        <Flex justifyContent="center">
          <NotificationSvg />
        </Flex>

        <Box mt={3}>
          <strong>Notification Settings</strong>
        </Box>
      </Box>

      <Box px={24} pb={1} pt={4}>
        <SmallCaps>Real-time Notifications</SmallCaps>
      </Box>
      <Box style={{ backgroundColor: Colors.WHITE }} py={3} px={24}>
        <Flex justifyContent="space-between" alignItems="center" mt={3}>
          <Box>
            <strong>General Chat</strong>
          </Box>
          <ToggleField
            checked={notifState.generalChatEnabled}
            onChange={(enabled) => {
              dispatch({
                type: PrefActions.SetPref,
                pref: "generalChatEnabled",
                value: enabled,
              });
            }}
          />
        </Flex>
        <Box style={{ color: Colors.GRAY3 }} mt={1}>
          Sent real-time via email
        </Box>

        <Flex justifyContent="space-between" alignItems="center" mt={3}>
          <Box>
            <strong>Direct Messages</strong>
          </Box>
          <ToggleField
            checked={notifState.directMessagesEnabled}
            onChange={(enabled) => {
              dispatch({
                type: PrefActions.SetPref,
                pref: "directMessagesEnabled",
                value: enabled,
              });
            }}
          />
        </Flex>
        <Box style={{ color: Colors.GRAY3 }} mt={1}>
          Sent real-time via email
        </Box>

        <Flex justifyContent="space-between" alignItems="center" mt={3}>
          <Box>
            <strong>Meeting Messages</strong>
          </Box>
          <ToggleField
            checked={notifState.meetingMessagesEnabled}
            onChange={(enabled) => {
              dispatch({
                type: PrefActions.SetPref,
                pref: "meetingMessagesEnabled",
                value: enabled,
              });
            }}
          />
        </Flex>
        <Box style={{ color: Colors.GRAY3 }} mt={1}>
          Sent real-time via email
        </Box>

        <Flex justifyContent="space-between" alignItems="center" mt={3}>
          <Box>
            <strong>Review Messages</strong>
          </Box>
          <ToggleField
            checked={notifState.reviewMessagesEnabled}
            onChange={(enabled) => {
              dispatch({
                type: PrefActions.SetPref,
                pref: "reviewMessagesEnabled",
                value: enabled,
              });
            }}
          />
        </Flex>
        <Box style={{ color: Colors.GRAY3 }} mt={1}>
          Sent real-time via email
        </Box>
      </Box>

      <Box px={24} pb={1} pt={4}>
        <SmallCaps>Occassional Emails</SmallCaps>
      </Box>
      <Box style={{ backgroundColor: Colors.WHITE }} py={3} px={24}>
        <Flex justifyContent="space-between" alignItems="center">
          <Box>
            <strong>Announcements</strong>
          </Box>
          <ToggleField
            checked={notifState.announcementsEnabled}
            onChange={(enabled) => {
              dispatch({
                type: PrefActions.SetPref,
                pref: "announcementsEnabled",
                value: enabled,
              });
            }}
          />
        </Flex>
        <Box style={{ color: Colors.GRAY3 }} mt={1}>
          Learn about new features, content, etc.
        </Box>
      </Box>

      {hasError ? (
        <Box my={24} px={24} style={{ color: Colors.ORANGE3 }}>
          An error occurred. We’ll fix it ASAP. For now, try refreshing your
          browser.
        </Box>
      ) : null}

      <Box pb={5} mb={4} />

      <Portal>
        <SlideContainer
          visible={isOpen && savedVisible}
          slideFrom={Directions.DOWN}
          style={{
            position: "fixed",
            top: undefined,
            bottom: 15,
            left: undefined,
            width: 385,
          }}
        >
          <Toast
            message="Saved"
            width={200}
            onClose={() => setSavedVisible(false)}
          />
        </SlideContainer>
      </Portal>
    </Box>
  );
}
