import { useCallback, useEffect, useState } from "react";

import debounce from "lodash/debounce";
import { useMediaQuery } from "react-responsive";

import { Box } from "@/components/layout/flexbox";
import { isXsQuery } from "@/components/layout/Media";
import Checkbox from "@/components/pieces/form/Checkbox";
import MobileSearchFilters from "@/components/pieces/search/GlobalSearch/MobileSearchFilters";

import { ALL_FILTER_OPTION, SearchIndex } from "./search-types";

interface SearchFiltersProps {
  availableIndexes: SearchIndex[];
  filteredIndexes: SearchIndex[];
  onChange: (newIndexes: SearchIndex[]) => void;
}

export default function SearchFilters({
  availableIndexes: availableIndexes,
  filteredIndexes: filteredIndexes,
  onChange,
}: SearchFiltersProps) {
  const isXs = useMediaQuery(isXsQuery);

  const [selectedOptions, setSelectedOptions] = useState([]);

  const debouncedOnChange = useCallback(
    debounce((newOptions) => {
      if (onChange) {
        onChange(newOptions);
      }
    }, 300), // 300ms delay, adjust as needed
    [onChange],
  );

  useEffect(() => {
    return () => {
      debouncedOnChange.cancel();
    };
  }, [debouncedOnChange]);

  useEffect(() => {
    if (filteredIndexes.length === availableIndexes.length) {
      setSelectedOptions([ALL_FILTER_OPTION, ...filteredIndexes]);
    } else {
      setSelectedOptions(filteredIndexes);
    }
  }, [filteredIndexes]);

  if (isXs) {
    return (
      <Box mb={3}>
        <MobileSearchFilters
          options={[ALL_FILTER_OPTION, ...availableIndexes]}
          selectedOptions={selectedOptions}
          onChange={(newOptions) => {
            debouncedOnChange(
              newOptions.filter((i) => i.key !== ALL_FILTER_OPTION.key),
            );
          }}
        />
      </Box>
    );
  }

  return (
    <Box>
      {availableIndexes.map((index) => (
        <Box mb={2} key={index.key}>
          <Checkbox
            checked={filteredIndexes.some((active) => active.key === index.key)}
            label={index.name}
            onChange={(active) => {
              if (active) {
                const isAlreadyPresent = filteredIndexes.some(
                  (item) => item.key === index.key,
                );
                if (!isAlreadyPresent) onChange([...filteredIndexes, index]);
              } else {
                onChange(
                  filteredIndexes.filter((item) => item.key !== index.key),
                );
              }
            }}
            hasBorders={false}
            style={{ fontSize: 16 }}
          />
        </Box>
      ))}
    </Box>
  );
}
