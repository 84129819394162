import { Colors, Icon } from "@blueprintjs/core";
import classnames from "classnames";
import { useHotkeys } from "react-hotkeys-hook";
import {
  Configure,
  Highlight,
  Hits,
  Index,
  useInstantSearch,
  useSearchBox,
} from "react-instantsearch";
import { useNavigate } from "react-router-dom";

import { APP_PROFILE } from "@/components/helpers/configuration/constants";
import { Box, Flex } from "@/components/layout/flexbox";
import Clickable from "@/components/pieces/interaction/Clickable";
import Truncate from "@/components/pieces/Truncate";
import Avatar from "@/components/pieces/users/Avatar";
import { SearchPreferenceQuery } from "@/graphql";

import { IndexComponentProps, ResultComponentProps } from "./search-types";

export default function Customers({
  indexKey,
  hitsPerPage,
  searchPreference,
  selectedObjectId,
  onClose,
  onSelect,
}: IndexComponentProps) {
  const { query } = useSearchBox();

  if (!query && searchPreference?.customer) return null;

  return (
    <Index indexName="user_index" indexId={indexKey}>
      <Configure hitsPerPage={hitsPerPage} filters="is_agent:false" />
      <Hits
        hitComponent={(props) => (
          <CustomerResult
            {...props}
            selected={props.hit.objectID === selectedObjectId}
            onClose={onClose}
            onSelect={onSelect}
          />
        )}
      />

      <SearchedCustomer searchPreference={searchPreference} />
    </Index>
  );
}

function SearchedCustomer({
  searchPreference,
}: {
  searchPreference: SearchPreferenceQuery;
}) {
  const { results } = useInstantSearch();
  const hasResults = results?.hits?.length > 0;

  if (hasResults || !searchPreference?.customer) return null;

  return (
    <Truncate
      px={[2, 3]}
      py={12}
      style={{ backgroundColor: Colors.LIGHT_GRAY3 }}
    >
      <b>
        For {searchPreference.customer.name} ({searchPreference.customer.email})
      </b>
    </Truncate>
  );
}

export function CustomerResult({
  selected = false,
  hit,
  onClose,
  onSelect,
}: ResultComponentProps) {
  const navigate = useNavigate();

  const containerCls = classnames("resultItem", {
    selected,
  });

  const link = `/client/${hit.objectID}`;

  function goToOpps() {
    onSelect(hit);
    navigate(link);
    onClose();
  }

  useHotkeys(
    "enter",
    () => {
      if (selected) {
        goToOpps();
      }
    },
    {},
    [selected, link],
  );

  return (
    <Clickable
      onClick={(e) => {
        if ((e.target as HTMLElement).tagName === "A") {
          onSelect(hit);
          return;
        }
        goToOpps();
      }}
    >
      <Flex alignItems="center" className={containerCls}>
        <Box pl={[2, 3]} py={12}>
          <Avatar
            user={{
              ...hit,
              photo: hit.photo
                ? `${APP_PROFILE.apiUrl}/images/avatar/${hit.email}`
                : null,
            }}
            size={40}
          />
        </Box>
        <Box ml={[2, 3]} py={12} pr={2} flex={1} className="resultItemDetails">
          <Truncate style={{ color: Colors.GRAY3 }}>
            <Highlight hit={hit} attribute="name" />
          </Truncate>
        </Box>
        <Box pl={2} pr={3} className="arrow">
          <Icon icon="arrow-right" />
        </Box>
      </Flex>
    </Clickable>
  );
}
