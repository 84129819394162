import { BRAND_PURPLE } from "@/css/constants";

export default function CoachingSvg({ size = 26, color = BRAND_PURPLE }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill={color}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 17.6348C15.7043 19.3825 14.7974 20.7009 13.1925 21.4896C12.0084 22.0715 10.76 22.1542 9.50209 21.746C8.09666 21.2896 7.06614 20.3835 6.4563 19.0454C5.84988 17.7141 5.85397 16.3611 6.4324 15L8.41272 15.5453C8.06773 16.2027 7.94098 16.8567 8.07201 17.5649C8.37317 19.198 10.0757 20.3103 11.6962 19.9169C12.9992 19.6009 13.8809 18.5897 14.0243 17.2598C14.0355 17.1533 14.0199 17.1036 13.9485 17.0698L16 17.6348Z"
      />
      <path
        fill={color}
        d="M20.8421 1.8088C20.7943 1.98029 20.8002 2.16765 20.7793 2.37313L3.14779 7.32674C3.11119 7.25271 3.08355 7.19 3.05143 7.13032C2.77428 6.60527 2.12958 6.40356 1.6089 6.68006C1.16068 6.91803 1.00156 7.3207 1.00081 7.80495C0.99932 9.91724 1.00007 12.0295 1.00156 14.1403C1.00156 14.271 1.01127 14.404 1.03443 14.5332C1.10988 14.9615 1.36537 15.2463 1.76653 15.3816C2.16171 15.5153 2.53224 15.4397 2.83255 15.1398C2.96477 15.0076 3.05964 14.8376 3.17768 14.6759L20.8002 19.628C20.8002 19.7399 20.798 19.8343 20.801 19.928C20.8241 20.6736 21.5473 21.179 22.248 20.9403C22.7283 20.7764 22.9995 20.3457 22.9995 19.7414C23.0003 13.9152 23.0003 8.08825 22.9988 2.26132C22.9988 2.1616 22.9868 2.06112 22.9726 1.96216C22.8934 1.40991 22.487 1.01556 21.9245 1.00045C21.4539 0.987603 20.9975 1.25202 20.8421 1.8088Z"
      />
    </svg>
  );
}
