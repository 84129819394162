import { Classes } from "@blueprintjs/core";

import { Box } from "@/components/layout/flexbox";
import CoachingHeader from "@/components/pieces/coaching/CoachingHeader";
import {
  LIGHTEST_BACKGROUND,
  SIDE_NAV_WIDTH,
  STANDARD_APP_MAX_WIDTH,
} from "@/css/constants";

export default function DashboardLoader({
  selectedTab = "dashboard",
  includeSidebar = false,
}) {
  return (
    <>
      <Box ml={includeSidebar ? [0, 0, SIDE_NAV_WIDTH] : 0}>
        <CoachingHeader selectedTab={selectedTab} />
        <Box pb={6} bg={LIGHTEST_BACKGROUND}>
          <Box
            className="container"
            maxWidth={STANDARD_APP_MAX_WIDTH}
            m="0 auto"
            px={[3, 24]}
          >
            <Box width={1} mt={4} className={Classes.SKELETON} height={400} />
          </Box>
        </Box>
      </Box>
    </>
  );
}
