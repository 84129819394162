import { useEffect, useState } from "react";

import { Button, Callout, FormGroup, InputGroup } from "@blueprintjs/core";
import styled from "@emotion/styled";
import EmojiPicker, { Emoji, EmojiStyle } from "emoji-picker-react";
import { useNavigate } from "react-router-dom";

import {
  convertMarkdownToHtml,
  isMarkdown,
} from "@/components/helpers/string-utils";
import { Popover } from "@/components/helpers/ui/blueprint-overrides/Popover";
import { Box, Flex } from "@/components/layout/flexbox";
import Tiptap from "@/components/pieces/editor/tiptap";
import StyledDialog from "@/components/pieces/StyledDialog";
import { BP_NS } from "@/css/constants";
import { RoleplayDocument, RoleplayType } from "@/graphql";

import { useSaveCollectionMutation } from "./api";

function CreateCollectionDialog({
  isOpen,
  onClose,
  roleplay,
  onFinished,
}: {
  isOpen: boolean;
  onClose: () => void;
  roleplay?: RoleplayType;
  onFinished?: () => void;
}) {
  const [collectionName, setCollectionName] = useState("");
  const [description, setDescription] = useState("");
  const [overview, setOverview] = useState("");
  const [openEmojiPicker, setOpenEmojiPicker] = useState(false);
  const [emoji, setEmoji] = useState("1f389");
  const [err, setErr] = useState("");
  const navigate = useNavigate();
  const { saveCollection, loading } = useSaveCollectionMutation({
    onCompleted: (collection) => {
      onClose();
      if (collection && !roleplay) {
        navigate(`/roleplays/${collection.slug}`);
      }
      onFinished && onFinished();
    },
    onError: () => {
      setErr(
        "An error occurred while saving the collection. Please try again later.",
      );
    },
    refetchQueries: [
      {
        query: RoleplayDocument,
        variables: {
          slug: roleplay?.slug,
        },
      },
    ],
  });

  const introduction = isMarkdown(roleplay?.introduction)
    ? convertMarkdownToHtml(roleplay?.introduction)
    : roleplay?.introduction;

  useEffect(() => {
    if (roleplay) {
      setCollectionName(roleplay.name);
      setOverview(introduction);
      setEmoji(roleplay.emojiUnicode);
      setDescription(roleplay.description);
    } else {
      setCollectionName("");
      setOverview("");
      setEmoji("1f389");
      setDescription("");
    }
    setErr("");
    return () => {};
  }, [roleplay]);

  const handleSubmit = () => {
    saveCollection({
      id: roleplay?.id,
      name: collectionName,
      introduction: overview,
      emojiUnicode: emoji.toLocaleUpperCase(),
      description,
    });
  };

  return (
    <StyledDialog
      canOutsideClickClose={false}
      isOpen={isOpen}
      onClose={onClose}
      lazy={true}
      title={<Box fontSize={18}>{roleplay ? `Edit` : `Create`} Collection</Box>}
      width="650px"
    >
      <Box p={24}>
        {/* Collection Name Section */}
        <StyledFormGroup label="Collection Name">
          <Flex style={{ gap: 8 }}>
            <Popover
              onClose={() => setOpenEmojiPicker(false)}
              isOpen={openEmojiPicker}
              content={
                <EmojiPicker
                  previewConfig={{
                    showPreview: false,
                  }}
                  open={openEmojiPicker}
                  onEmojiClick={(emoji) => {
                    setEmoji(emoji.unified);
                    setOpenEmojiPicker(false);
                  }}
                  skinTonesDisabled
                  lazyLoadEmojis
                  categories={[]}
                />
              }
            >
              <Button
                style={{
                  height: 40,
                  width: 40,
                  boxShadow: "none",
                  borderRadius: 4,
                  border: "1px solid #d3d3d3",
                }}
                onClick={() => setOpenEmojiPicker(true)}
                outlined
                icon={
                  <Emoji
                    unified={emoji.toLocaleLowerCase()}
                    emojiStyle={EmojiStyle.APPLE}
                    size={16}
                  />
                }
              />
            </Popover>

            <InputGroup
              fill
              style={{
                borderRadius: 4,
                height: 40,
              }}
              value={collectionName}
              onChange={(e) => setCollectionName(e.target.value)}
              placeholder="My Super Cool New Category"
            />
          </Flex>
        </StyledFormGroup>
        <StyledFormGroup label="Short Description" labelInfo="(optional)">
          <InputGroup
            fill
            style={{
              borderRadius: 4,
              height: 40,
            }}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            placeholder="What's this collection about?"
          />
        </StyledFormGroup>

        {/* Overview Section */}
        <StyledFormGroup label="Overview" labelInfo="(optional)">
          <Tiptap
            content={overview}
            onChange={setOverview}
            placeholder="Provide context, link to other training materials, etc."
          />
        </StyledFormGroup>
        {err && (
          <Box mt={"8px"}>
            <Callout intent="danger" icon={null}>
              {err}
            </Callout>
          </Box>
        )}

        <Box mt={24}>
          <Button
            disabled={!collectionName}
            loading={loading}
            intent="primary"
            onClick={handleSubmit}
            text={!roleplay ? "Continue" : "Save"}
            style={{
              height: 50,
              width: 150,
              boxShadow: "none",
              backgroundImage: "none",
              borderRadius: 4,
            }}
          />
        </Box>
      </Box>
    </StyledDialog>
  );
}

export default CreateCollectionDialog;

export const StyledFormGroup = styled(FormGroup)`
  &.${BP_NS}-form-group {
    .${BP_NS}-label {
      font-weight: 600;
    }
    .${BP_NS}-form-group-sub-label {
      margin-bottom: 10px;
    }
    .${BP_NS}-text-muted {
      font-weight: 400;
    }
  }
`;
