import { ReactNode } from "react";

import { MIDTEXT_COLOR } from "@/css/constants";

interface ClickableTextProps {
  text: ReactNode;
  onClick: () => void;
  disabled?: boolean;
  style?: React.CSSProperties;
}

export function ClickableText({
  text,
  onClick,
  disabled = false,
  style,
}: ClickableTextProps) {
  return (
    <span
      onClick={!disabled ? onClick : undefined}
      style={{
        cursor: disabled ? "not-allowed" : "pointer",
        color: disabled ? "gray" : MIDTEXT_COLOR,
        userSelect: "none",
        ...style,
      }}
    >
      {text}
    </span>
  );
}
