import { ReactNode, useEffect, useRef, useState } from "react";

import { useApolloClient } from "@apollo/client";
import { Button, Card } from "@blueprintjs/core";
import * as Sentry from "@sentry/react";
import { useLocation, useNavigate } from "react-router-dom";

import SEOHelmet from "@/components/helpers/seo/SEOHelmet";
import { getHash } from "@/components/helpers/url-utils";
import { isWorkspaceAdmin } from "@/components/helpers/workspace/permissions";
import { Box } from "@/components/layout/flexbox";
import MessageBar from "@/components/nav/MessageBar";
import { LoadingIndicator } from "@/components/pieces/loading/LoadingIndicator";
import Overlay from "@/components/pieces/overlay/Overlay";
import {
  getUser,
  hasValidToken,
  loadTokens,
  LoggedInUserType,
  NETWORK_ERROR,
  UNAUTHENTICATED_ERROR,
} from "@/components/session/JwtTokenManager";
import {
  PADDING_BREAKPOINT_MAP,
  SITE_NAME,
  STANDARD_APP_MAX_WIDTH,
  STANDARD_NAV_HEIGHT,
  STANDARD_SIZE_NAV_BREAKPOINT,
} from "@/css/constants";
import { IsLoggedInQuery } from "@/graphql/cachedVarsQueries";

import AppVersionGuardOverlay from "./AppVersionGuardOverlay";
import AutoReconnectOverlay from "./AutoReconnectOverlay";

interface AuthenticateProps {
  layout?: string;
  children: ReactNode | ((user: LoggedInUserType) => ReactNode);
  showMessageBar?: boolean;
  navHeight?: number;
  sizeNavBreakpoint?: string;
  requireCoach?: boolean;
  requireAssist?: boolean;
  requireWorkspaceAgent?: boolean;
  requireWorkspaceAdmin?: boolean;
  padding?: number | string;
  margin?: string;
  maxWidth?: string | number;
  extraProps?: any;
}

function SessionExpiredOverlay({ isOpen }: { isOpen: boolean }) {
  const navigate = useNavigate();

  const onReload = () => {
    navigate(0);
  };

  return (
    <Overlay
      visible={isOpen}
      canOutsideClickClose={false}
      canEscapeKeyClose={false}
      onClose={onReload}
      backdropClassName="pageDisabled"
    >
      <Card
        className="text-center"
        elevation={4}
        style={{ left: "calc(50vw - 170px)", marginTop: 100 }}
      >
        <Box p={2}>
          <Box>Oops, your session has expired.</Box>
          <Box mt={3}>
            <Button intent="primary" onClick={onReload} large={true}>
              Log in
            </Button>
          </Box>
        </Box>
      </Card>
    </Overlay>
  );
}

export default function Authenticate({
  layout,
  children,
  showMessageBar = true,
  navHeight = STANDARD_NAV_HEIGHT,
  sizeNavBreakpoint = STANDARD_SIZE_NAV_BREAKPOINT,
  requireCoach = false,
  requireAssist = false,
  requireWorkspaceAgent = false,
  requireWorkspaceAdmin = false,
  padding = 0,
  margin = "0 auto",
  maxWidth = STANDARD_APP_MAX_WIDTH,
  extraProps = null,
}: AuthenticateProps) {
  const location = useLocation();
  const navigate = useNavigate();
  const checkingRef = useRef(false);
  const mountedRef = useRef(false);
  const hasRedirectedRef = useRef(false);

  // Note that we need to keep track of the authorized user in both
  // the component state as well as the ref.
  // We persist user in the state to render page loading and top message bar for impersonating session correctly.
  // We also need it in the ref to use in the `authenticate` method below.
  const cachedUserRef = useRef(getUser());

  const [authUser, setAuthUser] = useState(getUser());
  const [authExpired, setAuthExpired] = useState(false);
  const [showReconnectMessage, setShowReconnectMessage] = useState(false);

  const client = useApolloClient();

  function clearLoggedInFlagFromStore() {
    client.writeQuery({
      query: IsLoggedInQuery,
      data: {
        isLoggedIn: false,
      },
    });
  }

  function authenticate(isInitialLoad: boolean) {
    if (checkingRef.current) return;

    checkingRef.current = true;
    loadTokens({
      verify: !isInitialLoad,
      success: ({ user }) => {
        if (mountedRef.current) {
          setShowReconnectMessage(false);
        }
        checkingRef.current = false;
        const conflictingUser =
          cachedUserRef.current && cachedUserRef.current.email !== user.email;

        if (!isInitialLoad && conflictingUser) {
          window.location.href = "/";
        } else {
          if (mountedRef.current) {
            setAuthUser(user);
          }
          cachedUserRef.current = user;
          client.writeQuery({
            query: IsLoggedInQuery,
            data: {
              isLoggedIn: true,
            },
          });
          Sentry.setUser({ email: user.email });
        }
      },
      error: (err) => {
        // if there is any error at the intial page load,
        // just redirect user to login page.
        if (isInitialLoad && !hasRedirectedRef.current) {
          hasRedirectedRef.current = true;
          navigate(
            `/login?next=${encodeURIComponent(
              `${location.pathname}${getHash()}`,
            )}`,
            { replace: true },
          );
          clearLoggedInFlagFromStore();
          return;
        }

        if (err.message === NETWORK_ERROR) {
          // When network error occurs, we show user a message similar to gmail
          // with AutoReconnectOverlay component, which does reconnection attempts with exponential decay.
          if (mountedRef.current) {
            setShowReconnectMessage(true);
          }
        } else {
          // otherwise, make sure we remove AutoReconnectOverlay component
          if (mountedRef.current) {
            setShowReconnectMessage(false);
          }

          // When the token expired, we show user a "session expired" message
          // and clean up the session on the browser.
          if (err.message === UNAUTHENTICATED_ERROR) {
            if (mountedRef.current) {
              setAuthExpired(true);
            }
            clearLoggedInFlagFromStore();
          }
        }

        // For other server errors, we still need to mark checking as done
        checkingRef.current = false;
      },
    });
  }

  // Handle authentication on mount
  useEffect(() => {
    mountedRef.current = true;
    hasRedirectedRef.current = false;

    if (!hasValidToken() || !authUser) {
      authenticate(true);
    } else {
      client.writeQuery({
        query: IsLoggedInQuery,
        data: {
          isLoggedIn: true,
        },
      });
    }

    const focusEventListener = () => {
      if (!checkingRef.current) {
        authenticate(false);
      }
    };

    window.addEventListener("focus", focusEventListener);

    return function cleanUp() {
      mountedRef.current = false;
      window.removeEventListener("focus", focusEventListener);
    };
  }, []);

  // Separate effect for handling role-based redirects
  useEffect(() => {
    if (!authUser || hasRedirectedRef.current) return;

    // Check against role requirements
    let shouldRedirect = false;

    if (
      requireWorkspaceAgent &&
      !authUser.isWorkspaceAgent &&
      !authUser.isStaff &&
      !authUser.isSuperuser
    ) {
      shouldRedirect = true;
    } else if (!authUser.isStaff && !authUser.isSuperuser) {
      if (requireCoach && !authUser.isAgent) {
        shouldRedirect = true;
      } else if (requireAssist && !authUser.isAssist) {
        shouldRedirect = true;
      }
    } else if (
      requireWorkspaceAdmin &&
      authUser.workspaceRole &&
      !isWorkspaceAdmin(authUser.workspaceRole)
    ) {
      shouldRedirect = true;
    }

    if (shouldRedirect && !hasRedirectedRef.current) {
      hasRedirectedRef.current = true;
      navigate("/dashboard", { replace: true });
    }
  }, [
    authUser,
    requireWorkspaceAgent,
    requireCoach,
    requireAssist,
    requireWorkspaceAdmin,
  ]);

  return (
    <>
      <SEOHelmet title={`${SITE_NAME} Dashboard`} canonicalPath="/app" />
      <>
        {authUser ? (
          <div
            style={{
              margin,
              maxWidth,
              padding,
              paddingTop: layout === "compact" ? 0 : navHeight,
            }}
          >
            {showMessageBar && layout === "dashboard" ? (
              <Box ml={PADDING_BREAKPOINT_MAP[sizeNavBreakpoint]}>
                <MessageBar email={authUser.email} />
              </Box>
            ) : null}
            {typeof children === "function" ? children(authUser) : children}

            <SessionExpiredOverlay isOpen={authExpired} />
            <AppVersionGuardOverlay />
            {showReconnectMessage ? (
              <AutoReconnectOverlay onReconnect={() => authenticate(false)} />
            ) : null}
          </div>
        ) : (
          <LoadingIndicator
            type={layout}
            extraProps={extraProps}
            visibleSideNav={true}
          />
        )}
      </>
    </>
  );
}
