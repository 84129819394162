import { useState } from "react";

import { useMutation } from "@apollo/client";
import {
  Button,
  Colors,
  FormGroup,
  InputGroup,
  Intent,
} from "@blueprintjs/core";
import startCase from "lodash/startCase";
import moment from "moment-timezone";

import { Box } from "@/components/layout/flexbox";
import Checkbox from "@/components/pieces/form/Checkbox";
import {
  LoggedInNavDataQueryDocument,
  UpdatePersonalInfoDocument,
  WorkspaceNavQueryDocument,
} from "@/graphql";

import MenuHeader from "./MenuHeader";

function MenuPersonal({ user, onClose, onBackLink, onComplete }) {
  const [name, setName] = useState(user.name);
  const [email, setEmail] = useState(user.email);
  const [phone, setPhone] = useState(user.phoneNumber);
  const [jobTitle, setJobTitle] = useState(user.jobTitle);
  const [linkedinAccount, setLinkedin] = useState(user.linkedinAccount);
  const [updateTimezone, setUpdateTimezone] = useState(false);

  const [nameError, setNameError] = useState(null);
  const [emailError, setEmailError] = useState(null);
  const [phoneError, setPhoneError] = useState(null);
  const [jobTitleError, setJobTileError] = useState(null);
  const [linkedinError, setLinkedinError] = useState(null);

  const browserTimezone = moment.tz.guess();

  const [mutate, { loading }] = useMutation(UpdatePersonalInfoDocument, {
    variables: {
      input: {
        name,
        email,
        linkedinAccount,
        phoneNumber: phone,
        timeZone: updateTimezone ? browserTimezone : user.timeZone,
        jobTitle: jobTitle,
      },
    },
    refetchQueries: [
      { query: LoggedInNavDataQueryDocument },
      { query: WorkspaceNavQueryDocument },
    ],
    onCompleted: (response) => {
      const { errors } = response.updateUser;
      if (errors.length) {
        errors.forEach((err) => {
          if (err.field === "name") {
            setNameError(err.message);
          }
          if (err.field === "email") {
            setEmailError(err.message);
          }
          if (err.field === "phoneNumber") {
            setPhoneError(err.message);
          }
          if (err.field === "jobTitle") {
            setJobTileError(err.message);
          }
          if (err.field === "linkedinAccount") {
            setLinkedinError(err.message);
          }
        });
      } else {
        onComplete();
      }
    },
  });

  function submit() {
    mutate();
  }

  function handleKeyPress(e) {
    if (e.key === "Enter") {
      submit();
    }
  }

  const requiredFieldsPresent = !!name && !!email;

  return (
    <Box width={1}>
      <MenuHeader onBackLink={onBackLink} onClose={onClose} label="Account" />

      <Box style={{ backgroundColor: Colors.WHITE }} mt={4} p={24}>
        <FormGroup label={<b>Full Name</b>}>
          <InputGroup
            large={true}
            value={name || ""}
            name="name"
            intent={nameError ? Intent.DANGER : null}
            onChange={(e) => {
              setName(e.target.value);
              setNameError(null);
            }}
            disabled={loading}
            onKeyDown={handleKeyPress}
            autoFocus={true}
          />
          {nameError ? (
            <Box style={{ color: Colors.RED3 }}>{nameError}</Box>
          ) : null}
        </FormGroup>
        <FormGroup label={<b>Email</b>}>
          <InputGroup
            large={true}
            value={email || ""}
            name="email"
            type="email"
            intent={emailError ? Intent.DANGER : null}
            onChange={(e) => {
              setEmail(e.target.value);
              setEmailError(null);
            }}
            disabled={loading}
            onKeyDown={handleKeyPress}
          />
          {emailError ? (
            <Box style={{ color: Colors.RED3 }}>{emailError}</Box>
          ) : null}
        </FormGroup>
        <FormGroup label={<b>Phone Number</b>}>
          <InputGroup
            large={true}
            value={phone || ""}
            name="phone"
            type="tel"
            intent={phoneError ? Intent.DANGER : null}
            onChange={(e) => {
              setPhone(e.target.value);
              setPhoneError(null);
            }}
            disabled={loading}
            onKeyDown={handleKeyPress}
          />
          {phoneError ? (
            <Box style={{ color: Colors.RED3 }}>{phoneError}</Box>
          ) : null}
        </FormGroup>

        <FormGroup label={<b>Job Title</b>}>
          <InputGroup
            large={true}
            value={jobTitle || ""}
            name="jobTitle"
            intent={jobTitleError ? Intent.DANGER : null}
            onChange={(e) => {
              setJobTitle(e.target.value);
              setJobTileError(null);
            }}
            disabled={loading}
            onKeyDown={handleKeyPress}
          />
          {jobTitleError ? (
            <Box style={{ color: Colors.RED3 }}>{jobTitleError}</Box>
          ) : null}
        </FormGroup>

        <FormGroup label={<b>LinkedIn URL</b>}>
          <InputGroup
            large={true}
            value={linkedinAccount || ""}
            name="linkedin_account"
            intent={linkedinError ? Intent.DANGER : null}
            onChange={(e) => {
              setLinkedin(e.target.value);
              setLinkedinError(null);
            }}
            disabled={loading}
            onKeyDown={handleKeyPress}
            autoFocus={true}
          />
          {linkedinError ? (
            <Box style={{ color: Colors.RED3 }}>{linkedinError}</Box>
          ) : null}
        </FormGroup>
        <FormGroup label={<b>Timezone</b>}>
          {formatTz(user.timeZone)}
          {formatTz(user.timeZone) !== formatTz(browserTimezone) ? (
            <Box mt={3}>
              <Checkbox
                checked={updateTimezone}
                onChange={setUpdateTimezone}
                disabled={loading}
                label={`Update my timezone to ${formatTz(browserTimezone)}.`}
                style={{ whiteSpace: "wrap" }}
              />
            </Box>
          ) : null}
        </FormGroup>

        <Box my={4}>
          <Button
            intent="primary"
            text="Save"
            large={true}
            disabled={
              !requiredFieldsPresent ||
              nameError ||
              emailError ||
              phoneError ||
              linkedinError
            }
            loading={loading}
            onClick={submit}
            fill={true}
          />
        </Box>
      </Box>
    </Box>
  );
}

function formatTz(tz) {
  if (!tz) {
    return "";
  }
  return startCase(tz.replace(/(_\/)/g, " ").toLowerCase());
}

export default MenuPersonal;
