import gql from "graphql-tag";

export const IsLoggedInQuery = gql`
  query IsUserLoggedIn {
    isLoggedIn @client
  }
`;

export const GetCSRFQuery = gql`
  query GetCSRF {
    csrf @client
  }
`;

export const SSRAlgoliaResultsQuery = gql`
  query AlgoliaData {
    algoliaData @client
  }
`;

export const DeviceInfoQuery = gql`
  query DeviceInfo {
    deviceInfo @client {
      screenWidth
    }
  }
`;
