import React, {
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
} from "react";

import { Icon } from "@blueprintjs/core";
import styled from "@emotion/styled";

import { Box, Flex } from "@/components/layout/flexbox";

interface BulletPointLineProps {
  value: string;
  onChange: (index: number, newValue: string) => void;
  onKeyDown: (
    e: React.KeyboardEvent<HTMLTextAreaElement>,
    index: number,
  ) => void;
  onPaste: (
    e: React.ClipboardEvent<HTMLTextAreaElement>,
    index: number,
  ) => void;
  index: number;
}

export interface BulletPointLineRef extends HTMLTextAreaElement {
  focus: () => void;
  setSelectionRange: (start: number, end: number) => void;
}

function BulletPointLine(
  { value, onChange, onKeyDown, onPaste, index }: BulletPointLineProps,
  ref: React.ForwardedRef<BulletPointLineRef>,
) {
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const adjustTextareaHeight = useCallback(
    (textarea: HTMLTextAreaElement | null) => {
      if (textarea) {
        textarea.style.height = "auto";
        textarea.style.height = `${textarea.scrollHeight}px`;
      }
    },
    [],
  );

  useEffect(() => {
    adjustTextareaHeight(textareaRef.current);
  }, [value, adjustTextareaHeight]);

  useImperativeHandle(ref, () => textareaRef.current as BulletPointLineRef, []);

  const handleChevronClick = () => {
    textareaRef.current?.focus();
    textareaRef.current?.setSelectionRange(0, 0);
  };

  return (
    <Flex alignItems="flex-start">
      <Box p={2} onClick={handleChevronClick}>
        <Icon icon="dot" size={16} />
      </Box>
      <StyledTextarea
        ref={textareaRef}
        value={value}
        onChange={(e) => onChange(index, e.target.value)}
        onKeyDown={(e) => onKeyDown(e, index)}
        onPaste={(e) => onPaste(e, index)}
        rows={1}
      />
    </Flex>
  );
}

const StyledTextarea = styled.textarea`
  flex: 1;
  resize: none;
  padding: 8px 0px;
  overflow: hidden;
  background-color: transparent;
  border: none;
  outline: none;

  &:focus {
    outline: none;
  }
`;

export default React.forwardRef(BulletPointLine);
