import { useState } from "react";

import { Button, Classes, Colors, Icon } from "@blueprintjs/core";

import { RichContentRenderer } from "@/components/helpers/ui/RichContentRenderer";
import { Box, Flex } from "@/components/layout/flexbox";
import { Media } from "@/components/layout/Media";
import CloseButton from "@/components/pieces/form/CloseButton";
import CoachRating from "@/components/pieces/talent-agents/CoachRating";
import CoachVideo from "@/components/pieces/talent-agents/CoachVideo";
import EditCoachProfileDialog from "@/components/pieces/talent-agents/EditCoachProfileDialog";
import ProfileDetails from "@/components/pieces/talent-agents/ProfileDetails";
import Avatar from "@/components/pieces/users/Avatar";
import { getUser } from "@/components/session/JwtTokenManager";
import { BP_NS, HEADING_FONT, LIGHTEST_BACKGROUND } from "@/css/constants";

export function CoachProfileHeader({
  profile,
  specialtySlug = null,
  action = null,
  onClose = null,
  skeleton = false,
  containerCss = {},
  isProfile = false,
  onSave = () => Promise.resolve(),
  bgColor = Colors.WHITE,
}) {
  const { user, summary } = profile;
  const currentUser = getUser();
  const isOwner = currentUser && user && user.id === currentUser.id;
  const contentPadding = isProfile ? 0 : 4;
  const [visible, setVisible] = useState(false);

  const shortBio = (
    <Box css={{ position: "relative" }}>
      <Box
        my={3}
        className={skeleton ? `${Classes.SKELETON} ${BP_NS}-no-animate` : ""}
      >
        <RichContentRenderer content={summary} />
      </Box>
      <Box
        className={skeleton ? `${Classes.SKELETON} ${BP_NS}-no-animate` : ""}
      >
        {action}
      </Box>
    </Box>
  );

  const actionBox = (
    <Flex flexDirection="row" alignItems="center">
      {isOwner && (
        <Button
          text="Edit profile"
          onClick={() => {
            setVisible(true);
          }}
        />
      )}
    </Flex>
  );

  return (
    <Box
      pt={4}
      pb={3}
      backgroundColor={bgColor}
      css={
        isProfile
          ? null
          : {
              borderTopRightRadius: 6,
              borderTopLeftRadius: 6,
            }
      }
    >
      <Container mx="auto" css={containerCss} px={contentPadding}>
        <Flex alignItems="start" css={{ flex: 1 }}>
          <Media greaterThan="sm">
            <Avatar user={user} size={150} />
          </Media>
          <Media lessThan="md">
            <Avatar user={user} size={80} />
          </Media>

          <Box pl={3} css={{ flex: 1 }}>
            <Flex
              alignItems="center"
              justifyContent={onClose ? "space-between" : "flex-start"}
              css={{ flex: 1 }}
            >
              <Flex flexDirection="row" alignItems="center" flex={1}>
                <Box
                  mr={1}
                  style={{ fontSize: 36, fontFamily: HEADING_FONT }}
                  lineHeight={"36px"}
                  my={2}
                >
                  <b>{user.name}</b>
                </Box>
              </Flex>
              <Flex flexDirection="row" alignItems="center">
                {onClose ? (
                  <CloseButton
                    onClick={onClose}
                    theme="light"
                    size={50}
                    iconSize={20}
                    showLabel={true}
                  />
                ) : null}
              </Flex>
            </Flex>
            <Flex alignItems="center">
              <CoachRating rating={profile.averageRating} />
              {isProfile ? (
                <Flex alignItems="center">
                  <Box mx={2} opacity={0.5}>
                    ·
                  </Box>
                  <Icon icon="shield" size={18} color="#27AE60" />
                  <Box ml={2} fontSize={13} fontWeight={600}>
                    VERIFIED
                  </Box>
                </Flex>
              ) : null}
            </Flex>
            <Media greaterThan="md">
              <Box>{shortBio}</Box>
              <Box>{actionBox}</Box>
            </Media>
          </Box>
        </Flex>
        <Media lessThan="lg">
          <Box>{shortBio}</Box>
          <Box>{actionBox}</Box>
        </Media>
      </Container>
      {isProfile && (
        <EditCoachProfileDialog
          onSave={onSave}
          visible={profile && visible}
          onClose={setVisible}
          profile={profile}
          specialtySlug={specialtySlug}
        />
      )}
    </Box>
  );
}

export function CoachProfileVideo({
  profile,
  skeleton = false,
  onVideoClick = undefined,
  videoPlaying = undefined,
}) {
  const { introVideo, introVideoThumbnail } = profile;

  if (!(introVideo || skeleton)) {
    return null;
  }

  return (
    <Box
      mb={2}
      className={skeleton ? `${Classes.SKELETON} ${BP_NS}-no-animate` : ""}
      style={{
        borderRadius: 8,
        overflow: "hidden",
      }}
    >
      <CoachVideo
        thumbnail={introVideoThumbnail}
        url={introVideo}
        playing={videoPlaying}
        onClick={onVideoClick}
      />
    </Box>
  );
}

export default function CoachProfile({
  profile,
  specialtySlug = null,
  action = null,
  onVideoClick = undefined,
  videoPlaying = undefined,
  onClose = null,
  skeleton = false,
  containerCss = {},
  isProfile = false,
  onSave = () => Promise.resolve(),
}) {
  const contentPadding = isProfile ? [3, 0] : 4;

  return (
    <>
      <CoachProfileHeader
        profile={profile}
        specialtySlug={specialtySlug}
        isProfile={isProfile}
        onSave={onSave}
        action={action}
        onClose={onClose}
        skeleton={skeleton}
        containerCss={containerCss}
      />
      <Box pt={3} backgroundColor={LIGHTEST_BACKGROUND}>
        <Container mx="auto" px={contentPadding} css={containerCss}>
          <CoachProfileVideo
            profile={profile}
            skeleton={skeleton}
            videoPlaying={videoPlaying}
            onVideoClick={onVideoClick}
          />
          {!skeleton ? (
            <ProfileDetails isProfile={isProfile} profile={profile} />
          ) : null}
        </Container>
      </Box>
    </>
  );
}

export function CoachProfileSkeleton({
  isFullMode = true,
  borderClass = "bbs",
  containerCss = {},
}) {
  return (
    <Container mx="auto" css={containerCss}>
      <Flex className={borderClass} p={24}>
        <Box
          style={{ borderRadius: "100%" }}
          className={Classes.SKELETON}
          width={150}
          height={150}
        />
        <Box pl={3} flex={1}>
          <Box height={40} mb={16} className={Classes.SKELETON} />
          <Box height={94} className={Classes.SKELETON} />
        </Box>
      </Flex>
      {isFullMode && (
        <Box px={24}>
          <Box my={24} height={300} className={Classes.SKELETON} />
          <Box my={24} height={200} className={Classes.SKELETON} />
          <Box my={24} height={100} className={Classes.SKELETON} />
        </Box>
      )}
    </Container>
  );
}

function Container({ css = {}, ...props }) {
  return (
    <Box
      width={[1, 750]}
      css={[
        {
          borderRadius: 6,
          overflow: "hidden",
          display: "table",
        },
        css,
      ]}
      {...props}
    />
  );
}
