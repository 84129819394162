import { Colors, Icon } from "@blueprintjs/core";
import { Link } from "react-router-dom";

import { isCoach } from "@/components/helpers/workspace/permissions";
import { Box, Flex } from "@/components/layout/flexbox";
import { Media } from "@/components/layout/Media";
import BackLink from "@/components/pieces/BackLink";
import SmallCaps from "@/components/pieces/form/SmallCaps";
import Clickable from "@/components/pieces/interaction/Clickable";
import MenuItem from "@/components/pieces/menu/MenuItem";
import { logout } from "@/components/session/JwtTokenManager";
import { CTA_COLOR } from "@/css/constants";

const AVATAR_SIZE = 100;

export default function MenuSettings({
  user,
  completion,
  onBackLink,
  onClose,
  onMenuChange,
  workspaceRole,
  sizeNavBreakpoint,
}) {
  const canEditWorkspace = workspaceRole === "ADMIN";

  return (
    <>
      <Flex justifyContent="center">
        <Box className="text-center">
          <Flex justifyContent="center" pt={4}>
            <Clickable
              onClick={
                completion === "avatar" ? null : () => onMenuChange("avatar")
              }
            >
              <Box
                style={{
                  borderRadius: "100%",
                  border: "3px solid",
                  borderColor:
                    completion === "avatar" ? Colors.LIGHT_GRAY3 : CTA_COLOR,
                  overflow: "hidden",
                }}
                p="2px"
              >
                {user.photo ? (
                  <img
                    style={{
                      borderRadius: "100%",
                    }}
                    width={AVATAR_SIZE}
                    height={AVATAR_SIZE}
                    src={`${user.photo.startsWith("/media") ? API_HOST : ""}${
                      user.photo
                    }`}
                  />
                ) : (
                  <Icon icon="user" size={AVATAR_SIZE} color={Colors.GRAY3} />
                )}
              </Box>
            </Clickable>
          </Flex>

          <Box>
            <h4>
              <b>{user.name}</b>
            </h4>
          </Box>
          {user.isAgent && user.agentProfile && user.agentProfile.coachSlug && (
            <Link to={`/@${user.agentProfile.coachSlug}`}>
              <Box>View My Profile</Box>
            </Link>
          )}
        </Box>
      </Flex>

      <Box mt={3}>
        {canEditWorkspace ? (
          <SmallCaps px={3} py={2}>
            You
          </SmallCaps>
        ) : null}
        <MenuItem
          icon="person"
          label="Account"
          justCompleted={completion === "personal"}
          onClick={() => onMenuChange("personal")}
        />

        <MenuItem
          icon="people"
          label="Assistants"
          justCompleted={completion === "assistants"}
          onClick={() => onMenuChange("assistants")}
        />

        <MenuItem
          icon="notifications"
          label="Notifications"
          justCompleted={completion === "notifications"}
          onClick={() => onMenuChange("notifications")}
        />

        {isCoach() ? null : (
          <MenuItem
            icon="saved"
            label="Resumes"
            justCompleted={completion === "resumes"}
            onClick={() => onMenuChange("resumes")}
          />
        )}

        <MenuItem
          icon="lightbulb"
          label="Feedback"
          justCompleted={completion === "feedback"}
          onClick={() => onMenuChange("feedback")}
        />
      </Box>

      <Media greaterThanOrEqual={sizeNavBreakpoint}>
        <Box width={1} mt={4}>
          <MenuItem
            icon="log-out"
            label="Sign Out"
            onClick={() => {
              onClose();
              logout({});
            }}
          />
        </Box>
      </Media>

      <Box
        className="absolute"
        style={{
          top: 0,
          right: 0,
        }}
      >
        <Clickable onClick={onClose}>
          <Box px={3} py={20}>
            <Icon icon="cross" size={24} color={Colors.DARK_GRAY3} />
          </Box>
        </Clickable>
      </Box>

      {onBackLink && (
        <Media lessThan={sizeNavBreakpoint}>
          <Clickable onClick={onBackLink}>
            <Box
              className="absolute"
              style={{
                top: 0,
                left: 0,
              }}
            >
              <BackLink color={Colors.DARK_GRAY3} />
            </Box>
          </Clickable>
        </Media>
      )}
    </>
  );
}
