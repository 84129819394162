import idx from "idx";

import {
  INDIVIDUAL_OPTION_ID,
  VIEW_ALL_OPTION_ID,
  useSelector,
} from "@/components/helpers/custom-hooks/use-selector";
import {
  WorkspaceQueryDocument,
  WorkspaceQueryQuery,
  WorkspaceType,
} from "@/graphql";

export const INDIVIDUAL_ID = INDIVIDUAL_OPTION_ID;
export const VIEW_ALL_ID = VIEW_ALL_OPTION_ID;

export function useWorkspaces(storageKey: string, allowIndividual?: boolean) {
  return useSelector<WorkspaceQueryQuery>({
    storageKey,
    allowIndividual,
    labelAll: "View All",
    labelIndividual: "Personal",
    queryDocument: WorkspaceQueryDocument,
    getResponseDataFn: (response) => idx(response, (_) => _.workspaces) || [],
  }) as [WorkspaceType[], string[], boolean, (id: string) => void];
}
