import React from "react";

import BitSvg from "@/../../hypetrain/static/img/bit.svg";

import EmbedSvg from "@/components/helpers/ui/EmbedSvg";

export function BitIcon({ height, width }) {
  return <EmbedSvg src={BitSvg} height={height} width={width} lazy={false} />;
}
const MemoizedBitIcon = React.memo(BitIcon);

export default MemoizedBitIcon;
