import { Classes } from "@blueprintjs/core";
import styled from "@emotion/styled";
import nameInitials from "name-initials";

import LazyImg from "@/components/helpers/ui/LazyImg";
import { Box, Flex } from "@/components/layout/flexbox";

export default function AvatarWithPrettyNull({
  user,
  size = 32,
  loading = false,
  defaultText = null,
  height = null,
}) {
  if (loading) {
    return (
      <Box
        width={size}
        height={size}
        style={{
          borderRadius: "100%",
        }}
        className={Classes.SKELETON}
      />
    );
  }

  return (
    <>
      {user?.photo ? (
        <LazyImg
          style={{
            borderRadius: "100%",
          }}
          width={size}
          height={height ? height : size}
          src={user?.photo}
        />
      ) : (
        <EmptyBox
          width={size}
          height={size}
          style={{
            minWidth: size,
            fontSize: Math.max(size / 5, 16),
          }}
        >
          <div>
            {defaultText || nameInitials(user?.name || user?.email || "")}
          </div>
        </EmptyBox>
      )}
    </>
  );
}

const EmptyBox = styled(Flex)`
  padding: 16px;
  border-radius: 100px;
  background-color: rgb(238, 242, 255);
  text-align: center;

  & > div {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    border-radius: 100%;
    background-color: rgb(255, 255, 255);
    color: rgb(17, 20, 24);
    font-weight: bold;
    color: #778397;
    background: rgb(251, 252, 255);
  }
}
`;
