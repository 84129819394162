import React, {
  useContext,
  useEffect,
  useImperativeHandle,
  useRef,
} from "react";

import { Box } from "@/components/layout/flexbox";
import { PageSizeContext } from "@/components/layout/PageSize";

import { HEADER_HEIGHT, MENU_BACKGROUND } from "./MenuHeader";

function MenuPage(
  {
    visible,
    children,
    withHeader = true,
    slideTo = "right",
    backgroundColor = MENU_BACKGROUND,
  },
  ref,
) {
  const { isFullSize = false } = useContext(PageSizeContext) || {};

  const scrollEl = useRef(null);
  useEffect(() => {
    if (visible) {
      scrollEl.current.scrollTop = 0;
    }
  }, [visible]);

  useImperativeHandle(ref, () => ({
    scrollTop: () => {
      scrollEl.current.scrollTop = 0;
    },
  }));

  const hiddenTransform =
    slideTo === "left" ? "translateX(-100%)" : "translateX(100%)";
  return (
    <Box
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        backgroundColor,
        transition: visible
          ? "transform 0.4s"
          : "transform 0.4s, visibility 0s 0.4s",
        transform: visible ? "translateX(0%)" : hiddenTransform,
        visibility: visible ? "visible" : "hidden",
      }}
    >
      <Box
        ref={scrollEl}
        height="100%"
        pt={withHeader ? HEADER_HEIGHT : 0}
        pb={isFullSize ? 4 : 120}
        style={{
          position: "relative",
          overflowY: visible ? "auto" : "hidden",
          overflowX: "hidden",
        }}
      >
        {children}
      </Box>
    </Box>
  );
}

export default React.forwardRef(MenuPage);
