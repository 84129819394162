import { InputGroup } from "@blueprintjs/core";

import { Box, Flex } from "@/components/layout/flexbox";
import { StyledRoundedBox } from "@/components/pages_logged_in/program/components/set-components/StyledRoundedBox";

const indexColors = [
  {
    backgroundColor: "#C61D1D",
    textColor: "#FFFFFF",
  },
  {
    backgroundColor: "#F5E6E8",
    textColor: "#9B0E0E",
  },
  {
    backgroundColor: "#F0F0F0",
    textColor: "#242424",
  },
  {
    backgroundColor: "#E0F0E7",
    textColor: "#006D2E",
  },
  {
    backgroundColor: "#129B4C",
    textColor: "#FFFFFF",
  },
];

export default function Options({
  title = "OPTIONS",
  titleCss = {},
  options,
  editMode = false,
  onChangeOptions,
}: {
  title?: string;
  titleCss?: Record<string, any>;
  options: any[];
  editMode?: boolean;
  onChangeOptions?: (newOptions: any[]) => void;
}) {
  if (!options?.length) return null;
  return (
    <>
      <Box mb={10} fontWeight={600} css={titleCss}>
        {title}
      </Box>
      <Box>
        {options.map((option, index) => {
          const color = indexColors?.[index];
          return (
            <Flex
              key={option?.value}
              alignItems={"center"}
              style={{
                marginRight: 8,
              }}
              mb={10}
            >
              <StyledRoundedBox
                style={{
                  background: color?.backgroundColor || "#F0F0F0",
                  color: color?.textColor || "#242424",
                  width: 24,
                  height: 24,
                }}
              >
                {index + 1}
              </StyledRoundedBox>
              {editMode ? (
                <Box ml={2} width={1}>
                  <InputGroup
                    style={{ fontSize: 16 }}
                    onChange={(e) => {
                      onChangeOptions(
                        options.map((o) => {
                          if (o.value === option.value) {
                            return { ...o, label: e.target.value };
                          }
                          return o;
                        }),
                      );
                    }}
                    value={option?.label}
                    large={true}
                    fill={true}
                  />
                </Box>
              ) : (
                <Box ml={2}>{option?.label}</Box>
              )}
            </Flex>
          );
        })}
      </Box>
    </>
  );
}
