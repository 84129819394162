import { MenuDivider, MenuItem } from "@blueprintjs/core";

import { EvaluationCriteriaDialogs } from "@/components/pages_logged_in/roleplays/details/utils";
import { MoreMenu } from "@/components/pieces/more/MoreMenu";

import { BehaviorCriterionType } from "./types";

export default function EvaluationContextButton({
  isCriterion,
  criterion,
  canSort = false,
  canDelete = false,
  onOpenDialog,
}: {
  isCriterion: boolean;
  criterion: BehaviorCriterionType;
  canSort?: boolean;
  canDelete?: boolean;
  onOpenDialog?: (
    dialog: EvaluationCriteriaDialogs,
    criterion?: BehaviorCriterionType,
  ) => void;
}) {
  return (
    <MoreMenu>
      {isCriterion ? (
        <>
          <MenuItem
            onClick={() => {
              onOpenDialog?.(
                EvaluationCriteriaDialogs.EditCriterion,
                criterion,
              );
            }}
            text="Edit Criteria"
          />
          {canDelete && (
            <>
              <MenuDivider />
              <MenuItem
                onClick={() => {
                  onOpenDialog?.(
                    EvaluationCriteriaDialogs.RemoveCriterion,
                    criterion,
                  );
                }}
                intent="danger"
                text="Remove Criteria"
              />
            </>
          )}
        </>
      ) : (
        <>
          <MenuItem
            onClick={() => {
              onOpenDialog?.(EvaluationCriteriaDialogs.AddCriterion, null);
            }}
            icon="plus"
            text="Add Criteria"
          />
          <MenuItem
            onClick={() => {
              onOpenDialog?.(EvaluationCriteriaDialogs.ImportFromCollection);
            }}
            icon="duplicate"
            text="From Collection"
          />
          {canSort && (
            <>
              <MenuDivider />
              <MenuItem
                onClick={() => {
                  onOpenDialog?.(EvaluationCriteriaDialogs.SortCriteria);
                }}
                icon="arrows-vertical"
                text="Change Sort Order"
              />
            </>
          )}
          {canDelete && (
            <>
              <MenuDivider />
              <MenuItem
                onClick={() => {
                  onOpenDialog?.(
                    EvaluationCriteriaDialogs.ConfirmRemoveAllCriteria,
                  );
                }}
                intent="danger"
                icon="trash"
                text="Remove All"
              />
            </>
          )}
        </>
      )}
    </MoreMenu>
  );
}
