import { keyframes } from "@emotion/react";
import styled from "@emotion/styled";

import { Box } from "@/components/layout/flexbox";

const fadeIn = keyframes`
  0% {opacity:0;}
  100% {opacity:1;}
`;

const FadeInBox = styled(Box)`
  animation: ${fadeIn} 0.5s ease-in forwards;
  opacity: 0;
`;

export default FadeInBox;
