import { useNavigate } from "react-router-dom";

import { Box, Flex } from "@/components/layout/flexbox";
import { ClickableText } from "@/components/pieces/ClickableText";
import Overlay from "@/components/pieces/overlay/Overlay";
import {
  getBuildVersion,
  getConflictVersion,
  getUser,
} from "@/components/session/JwtTokenManager";
import { BLUE_TEXT_COLOR } from "@/css/constants";

export default function AppVersionGuardOverlay() {
  const versionMetaTag = document.querySelector(
    'meta[name="build-version"]',
  ) as HTMLMetaElement;
  const currentVersion = versionMetaTag && versionMetaTag.content;
  const showGuard =
    getUser() && (currentVersion !== getBuildVersion() || getConflictVersion());
  const navigate = useNavigate();

  if (import.meta.env.SSR) {
    return null;
  }

  return (
    <Overlay
      visible={showGuard}
      canOutsideClickClose={false}
      canEscapeKeyClose={false}
      backdropClassName="pageDisabled"
    >
      <Flex alignItems="center" justifyContent="center" width={1}>
        <Flex
          mt={3}
          py={2}
          width={[320, 450]}
          alignItems="center"
          justifyContent="center"
          flexWrap="wrap"
          css={{
            background: "#FFE4A0",
            borderRadius: "6px",
            border: "1x solid gray",
          }}
        >
          <strong>We upgraded the app</strong>
          <Box mx={1}>
            Please{" "}
            <ClickableText
              onClick={() => navigate(0)}
              text={<strong>refresh page</strong>}
              style={{
                color: BLUE_TEXT_COLOR,
              }}
            />{" "}
            to get the latest version
          </Box>
        </Flex>
      </Flex>
    </Overlay>
  );
}
