import { useMemo } from "react";

import Filter from "@/components/pages_logged_in/workspace/pieces/Filter";
import FilterButton from "@/components/pages_logged_in/workspace/pieces/FilterButton";
import SettingSvg from "@/components/pieces/icons/svg/setting";

import { ALL_FILTER_OPTION } from "./search-types";

export default function MobileSearchFilters({
  options,
  selectedOptions,
  onChange,
}) {
  const filterLabel = useMemo(() => {
    if (selectedOptions.length === 0) {
      return "No Results";
    }

    if (selectedOptions.length === 1) {
      return selectedOptions[0].name;
    }

    if (
      selectedOptions.length === options.length ||
      selectedOptions.some((i) => i.key === ALL_FILTER_OPTION.key)
    ) {
      return ALL_FILTER_OPTION.name;
    }
    return `${selectedOptions.length} Selected`;
  }, [selectedOptions, options]);

  const onToggle = (selectedKey) => {
    if (!selectedKey) {
      return;
    }
    const isChecked = selectedOptions.some((i) => i.key === selectedKey);

    if (selectedKey === ALL_FILTER_OPTION.key) {
      onChange(isChecked ? [] : options);
      return;
    }

    if (isChecked) {
      onChange(selectedOptions.filter((i) => i.key !== selectedKey));
    } else {
      const selectedIndice = options.find((i) => i.key === selectedKey);
      onChange([...selectedOptions, selectedIndice]);
    }
  };

  return (
    <Filter
      button={<FilterButton rightIcon={<SettingSvg />} text={filterLabel} />}
      items={options.map((indice) => ({
        id: indice.key,
        text: indice.name,
        hasDividerBelow: [ALL_FILTER_OPTION.key].includes(indice.key),
      }))}
      isSelectedFn={(x) => selectedOptions.some((i) => i.key === x)}
      multiple={true}
      onSelect={onToggle}
      viewMode="checkbox"
    />
  );
}
