import { Icon, IconName, Intent } from "@blueprintjs/core";

import { Tooltip } from "@/components/helpers/ui/blueprint-overrides/Tooltip";
import { AttendingStatus } from "@/graphql";

export const AttendeeAttendingStatus = {
  [AttendingStatus.Attending]: {
    status: AttendingStatus.Attending,
    text: "Attending",
    helpText: "Attending the meeting",
    icon: "tick",
    intent: Intent.SUCCESS,
    style: {},
  },
  [AttendingStatus.NotAttending]: {
    status: AttendingStatus.NotAttending,
    text: "Not Attending",
    helpText: "Not attend the meeting",
    icon: "cross",
    intent: Intent.WARNING,
    style: {},
  },
  [AttendingStatus.MayBe]: {
    status: AttendingStatus.MayBe,
    text: "Maybe",
    helpText: "Maybe attend the meeting",
    icon: "help",
    intent: Intent.NONE,
    style: { color: "#5c7080" },
  },
};

interface AttendingStatusIconProps {
  attendingStatus?: AttendingStatus;
}

function AttendingStatusIcon({ attendingStatus }: AttendingStatusIconProps) {
  if (!attendingStatus) return null;

  const status = AttendeeAttendingStatus[attendingStatus];
  return (
    <Tooltip content={status.helpText} placement="top">
      <Icon
        icon={status?.icon as IconName}
        intent={status?.intent}
        style={{ cursor: "pointer", ...(status?.style || {}) }}
      />
    </Tooltip>
  );
}

export default AttendingStatusIcon;
