import { useState } from "react";

import { Box } from "@/components/layout/flexbox";
import AddExistingScenarioDialog from "@/components/pages_logged_in/roleplays/AddExistingScenarioDialog";
import {
  SCENARIO_SCOPE_ORDER,
  sortScenariosByDifficulty,
} from "@/components/pages_logged_in/roleplays/utils";
import ScenarioCard, {
  CreateScenarioCard,
} from "@/components/pieces/roleplays/ScenarioCard";

const enum AddExistingScenarioModals {
  addExistingScenario = "addExistingScenario",
}

function ScenarioSegment({ scenarios, title, roleplay }) {
  return (
    <Box>
      {title && (
        <Box fontWeight={500} mb={2}>
          {title}
        </Box>
      )}
      {sortScenariosByDifficulty(scenarios).map((scenario, index) => (
        <Box mt={index > 0 ? 24 : 0} key={scenario.id}>
          <ScenarioCard
            scenario={scenario}
            roleplay={roleplay}
            showRoleplay={false}
            showRemoveFromCollection={roleplay.canEdit}
          />
        </Box>
      ))}
    </Box>
  );
}

export default function RoleplayScenarios({
  scenarios,
  roleplay,
  canEdit = false,
}) {
  const [modal, setModal] = useState<AddExistingScenarioModals | null>(null);
  const segmentedScenarios = SCENARIO_SCOPE_ORDER.reduce((acc, item) => {
    const filteredScenarios = scenarios.filter(
      (scenario) => item.scopes.indexOf(scenario.visibilityScope) !== -1,
    );
    if (filteredScenarios.length > 0) {
      acc[item.name] = filteredScenarios;
    }
    return acc;
  }, {});

  const showTitles = Object.keys(segmentedScenarios).length > 1;
  const roleplaySlug = roleplay?.slug;

  const createScenarioComponent = (
    <CreateScenarioCard
      roleplaySlug={roleplaySlug}
      hadScenarios={Boolean(scenarios.length)}
      onAddExist={
        canEdit
          ? () => setModal(AddExistingScenarioModals.addExistingScenario)
          : null
      }
    />
  );

  return (
    <Box>
      {SCENARIO_SCOPE_ORDER.map((item, index) => {
        if (segmentedScenarios[item.name]) {
          return (
            <Box mt={index > 0 ? 24 : 0} key={item.name}>
              <ScenarioSegment
                scenarios={segmentedScenarios[item.name]}
                title={showTitles ? item.name : null}
                roleplay={roleplay}
              />
            </Box>
          );
        }
        return null;
      })}
      <Box mt={24}>{createScenarioComponent}</Box>
      <AddExistingScenarioDialog
        isOpen={modal === AddExistingScenarioModals.addExistingScenario}
        roleplaySlug={roleplaySlug}
        onClose={() => setModal(null)}
        scenarios={scenarios}
      />
    </Box>
  );
}
