import { useState } from "react";

import serviceCompleteSvg from "@/../../hypetrain/static/img/service-complete.svg";
import { useQuery } from "@apollo/client";
import { Button, Callout, Classes, Portal } from "@blueprintjs/core";

import { formatMoney } from "@/components/helpers/string-utils";
import EmbedSvg from "@/components/helpers/ui/EmbedSvg";
import { allowBuyCredits } from "@/components/helpers/workspace/permissions";
import { Box, Flex } from "@/components/layout/flexbox";
import BackDrop from "@/components/pieces/BackDrop";
import BitIcon from "@/components/pieces/BitIcon";
import BitsPaymentForm from "@/components/pieces/buy-bits/BitsPaymentForm";
import usePendingOrder from "@/components/pieces/buy-credits/usePendingOrder";
import DialogHeader from "@/components/pieces/dialog/DialogHeader";
import ProductSelector from "@/components/pieces/products/ProductSelector";
import ScrollableModal from "@/components/pieces/ScrollableModal";
import { getUser } from "@/components/session/JwtTokenManager";
import {
  MIDTEXT_COLOR,
  PORTAL_Z_INDEX,
  SITE_NAME,
  SUBTEXT_COLOR,
} from "@/css/constants";
import { BitPackagesDocument } from "@/graphql";

import { NEW_USER_BIT_BONUS } from "./constants";

function HeaderMessage() {
  return (
    <Box color={MIDTEXT_COLOR} mb={3} pb={3} className="bbs">
      We use a fun, in-app currency called "Bits" here at {SITE_NAME}. Bits can
      be used across all of our AI tools. Get ready to experience a productivity
      boost like never before.
    </Box>
  );
}

function PurchaseBitsForm({
  loading,
  products,
  defaultProductQuantities,
  onSuccess,
}) {
  const canBuyCredits = allowBuyCredits();
  const prodIds = products.map((p) => p.id).join(",");
  const [order, { syncing, setItems, setOrder }] = usePendingOrder({
    customerId: null,
    key: `bit-order-${prodIds}`,
    defaultProductQuantities,
  });
  const selectedProduct =
    order &&
    order.orderItems &&
    !!order.orderItems.length &&
    order.orderItems[0].product;

  if (!order) return <Box height={200} className={Classes.SKELETON} />;

  // If the user is allowed to buy credits, just show them the price in dollars.
  // If they can't, just show them the price in credits.
  const displayedProducts = canBuyCredits
    ? products
    : products.map((p) => ({
        ...p,
        displayedCreditPrice: p.creditPrice,
        price: null,
      }));

  return (
    <>
      <HeaderMessage />
      <Box fontSize={18} fontWeight={600} mb={2}>
        Select number of bits
      </Box>
      <ProductSelector
        loading={loading}
        disabled={syncing}
        products={displayedProducts}
        selectedProduct={selectedProduct}
        setSelectedProduct={(product) => {
          setItems({
            [product.id]: 1,
          });
        }}
        maxPerRow={2}
      />
      <Box mt={2}>
        <BitsPaymentForm
          order={order}
          onOrderConfirmed={onSuccess}
          setOrder={setOrder}
          allowBuyCredits={canBuyCredits}
          disabled={syncing}
        />
      </Box>
    </>
  );
}

function PublicUserView({ products, showSignup }) {
  return (
    <>
      <HeaderMessage />
      <Box mb={3}>
        <Callout intent="primary">
          Use the free, public allowance while it lasts. Once it's gone, buy
          Bits to continue. Sign up and confirm your email to get{" "}
          {NEW_USER_BIT_BONUS} Bits for free.
        </Callout>
      </Box>
      <Box mb={3} className="bas round-corners">
        {products.map((product, i) => (
          <Flex
            key={product.id}
            p={3}
            alignItems="center"
            justifyContent="space-between"
            flexWrap="wrap"
            className={i + 1 < products.length ? "bbs" : ""}
          >
            <Flex alignItems="center" fontWeight={500} color={MIDTEXT_COLOR}>
              <BitIcon height={18} width={18} />
              <Box ml={1}>{product.name}</Box>
            </Flex>
            <Flex alignItems="center">
              {product.price ? (
                <Box
                  ml={product.msrp ? 2 : null}
                  fontWeight={500}
                  color={MIDTEXT_COLOR}
                >
                  {formatMoney(product.price, 0)}
                </Box>
              ) : null}
            </Flex>
          </Flex>
        ))}
      </Box>
      <Box>
        <Button intent="primary" large={true} fill={true} onClick={showSignup}>
          Sign Up
        </Button>
      </Box>
    </>
  );
}

export default function PurchaseBitsModal({
  visible,
  onClose,
  onSuccess,
  showSignup,
}) {
  const [success, setSuccess] = useState(false);
  const user = getUser();

  const { data, loading } = useQuery(BitPackagesDocument);
  const products = data && data.bitPackages;
  const numProds = products && products.length;
  const defaultProductQuantities = numProds
    ? {
        [products[0].id]: 1,
      }
    : null;

  function handleSuccess() {
    onSuccess();
    setSuccess(true);
  }

  if (!defaultProductQuantities) {
    return null;
  }

  return (
    <Portal>
      <BackDrop visible={visible} zIndex={PORTAL_Z_INDEX} opacity={0.6} />
      <ScrollableModal
        visible={visible}
        onClose={onClose}
        verticallyCenter={true}
        canOutsideClickClose={false}
        style={{
          position: "absolute",
          top: 24,
          left: 0,
          width: "100%",
          overflowY: "auto",
          boxSizing: "border-box",
        }}
      >
        <Box
          backgroundColor="#FFF"
          className="round-corners"
          width={550}
          maxWidth="100%"
          margin="0 auto"
        >
          <DialogHeader onClose={onClose}>
            <Flex alignItems="center">
              <BitIcon height={24} width={24} />
              <Box ml={2}>Buy Bits</Box>
            </Flex>
          </DialogHeader>
          <Box p={24}>
            {!user ? (
              <PublicUserView products={products} showSignup={showSignup} />
            ) : success ? (
              <Box textAlign="center">
                <EmbedSvg src={serviceCompleteSvg} width={160} />
                <Box mt={20} fontWeight={600} fontSize={20}>
                  Purchase successful
                </Box>
                <Box color={SUBTEXT_COLOR} mb={20}>
                  You can now use your Bits
                </Box>
                <Button large={true} fill={true} onClick={onClose}>
                  Continue
                </Button>
              </Box>
            ) : visible ? (
              <PurchaseBitsForm
                loading={loading}
                products={products}
                defaultProductQuantities={defaultProductQuantities}
                onSuccess={handleSuccess}
              />
            ) : null}
          </Box>
        </Box>
      </ScrollableModal>
    </Portal>
  );
}
