import { useState } from "react";

import {
  Button,
  Collapse,
  Icon,
  Menu,
  MenuDivider,
  MenuItem,
  Position,
} from "@blueprintjs/core";
import { DragDropContext, Draggable, Droppable } from "@hello-pangea/dnd";
import startCase from "lodash/startCase";
import uniqueId from "lodash/uniqueId";

import { Popover } from "@/components/helpers/ui/blueprint-overrides/Popover";
import { Box, Flex } from "@/components/layout/flexbox";
import {
  ComponentStatus,
  getComponentStatus,
} from "@/components/pages_logged_in/program/components/component-utils";
import AddQuestionDialog, {
  responseTypes,
} from "@/components/pages_logged_in/program/components/set-components/AddQuestionDialog";
import Options from "@/components/pages_logged_in/program/components/set-components/Options";
import { StyledRoundedBox } from "@/components/pages_logged_in/program/components/set-components/StyledRoundedBox";
import {
  StyledStickyBox,
  TabContainerCSS,
} from "@/components/pages_logged_in/program/components/set-components/utils";
import FlexibleButton from "@/components/pieces/button/FlexibleButton";
import { CARD_CLASSES, LIGHT_GRAY, SUBTEXT_COLOR } from "@/css/constants";

export const defaultRatingScaleOption = [
  {
    value: "1",
    label: "Strongly Disagree",
  },
  {
    value: "2",
    label: "Disagree",
  },
  {
    value: "3",
    label: "Neither agree nor disagree",
  },
  {
    value: "4",
    label: "Agree",
  },
  {
    value: "5",
    label: "Strongly Agree",
  },
];

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

function QuestionCard(props: {
  question: any;
  defaultVisible?: boolean;
  onEdit?: (question: any) => void;
  onDelete?: (question: any) => void;
}) {
  const { question, defaultVisible = false, onEdit, onDelete } = props;
  const [cardDetailsVisible, setCardDetailsVisible] = useState(defaultVisible);
  const { prompt, answerType, options } = question;
  const [isOpen, setIsOpen] = useState(false);
  const isRatingScale = answerType === responseTypes[0].value;
  const answerTypeLabel = startCase(
    answerType?.toLowerCase()?.replace("_", " ") || "",
  );

  return (
    <Box flex={1} mb={3} className={CARD_CLASSES}>
      <Flex
        p={3}
        alignItems={"center"}
        width={"100%"}
        css={{ cursor: "pointer" }}
        onClick={() => {
          setCardDetailsVisible(!cardDetailsVisible);
        }}
      >
        <Box>
          <Icon icon="drag-handle-vertical" />
        </Box>
        <Box mx={12} flex={1}>
          <Box fontSize={16} fontWeight={600}>
            {prompt}
          </Box>
          <Box mt={1} color={SUBTEXT_COLOR} fontSize={14}>
            {answerTypeLabel}
          </Box>
        </Box>

        <Popover
          content={
            <Menu>
              <MenuItem
                onClick={(e) => {
                  e.stopPropagation();
                  onEdit(question);
                  setIsOpen(false);
                }}
                text="Edit Question"
              />
              <MenuDivider />
              <MenuItem
                intent="danger"
                onClick={(e) => {
                  e.stopPropagation();
                  onDelete(question);
                  setIsOpen(false);
                }}
                text="Delete Question"
              />
            </Menu>
          }
          canEscapeKeyClose={true}
          position={Position.BOTTOM_RIGHT}
          onClose={() => {
            setIsOpen(false);
          }}
          isOpen={isOpen}
        >
          <Button
            style={{ minHeight: 36 }}
            minimal={true}
            icon="more"
            onClick={(e) => {
              e.stopPropagation();
              setIsOpen(!isOpen);
            }}
          />
        </Popover>
      </Flex>
      <Collapse isOpen={cardDetailsVisible}>
        {cardDetailsVisible && (
          <>
            <Box className="bbs" />
            <Box p={3}>
              {answerType && (
                <>
                  <Box
                    style={{ textTransform: "uppercase" }}
                    fontSize={12}
                    fontWeight={600}
                    mb={3}
                  >
                    Response Type
                  </Box>
                  <Flex mb={3} alignItems="center">
                    <StyledRoundedBox
                      backgroundColor={LIGHT_GRAY}
                      mr={2}
                      style={{ width: 30, height: 30 }}
                    >
                      <Icon icon="more" color="#2B2165" />
                    </StyledRoundedBox>
                    {answerTypeLabel}
                  </Flex>
                </>
              )}
              <Options
                options={isRatingScale ? options : []}
                titleCss={{ fontSize: 12 }}
              />
            </Box>
          </>
        )}
      </Collapse>
    </Box>
  );
}

export const couldEditQuestionResponse = (input): boolean => {
  const componentStatus = getComponentStatus(input);
  return [ComponentStatus.Upcoming, ComponentStatus.InDesign].includes(
    componentStatus,
  );
};

function Customize({ onNext, input, saving = false }) {
  const [selectedQuestion, setSelectedQuestion] = useState(null);
  const questions = input?.formFields || [];

  const onPressNext = (newQuestions, isSave = true, isNext = true) => {
    onNext(
      {
        formFields: newQuestions.map((q, index) => {
          return {
            ...q,
            order: index + 1,
            required: true,
          };
        }),
      },
      isSave,
      isNext,
    );
  };

  function handleDragEnd(result) {
    // dropped outside the list
    if (!result.destination) {
      return;
    }
    onPressNext(
      reorder(questions, result?.source?.index, result?.destination?.index),
      false,
      false,
    );
  }

  const hasQuestions = questions?.length > 0;
  const couldEditResponseType = couldEditQuestionResponse(input);

  return (
    <>
      <Box css={TabContainerCSS}>
        <Box fontSize={24} fontWeight={600} mt={4}>
          Customize to your needs
        </Box>
        <Box color={SUBTEXT_COLOR} mb={4} mt={2}>
          Fine-tune your survey by adding, re-ordering, or removing questions.
        </Box>

        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId="droppable">
            {(provided) => (
              <Box
                width={1}
                className="round-corners"
                flexWrap="wrap"
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {questions.map((question, index) => (
                  <Draggable
                    key={question.id}
                    draggableId={question.id}
                    index={index}
                  >
                    {(provided, snapshot) => (
                      <Flex
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={getItemStyle(
                          snapshot.isDragging,
                          provided.draggableProps.style,
                        )}
                      >
                        <QuestionCard
                          question={question}
                          onEdit={setSelectedQuestion}
                          onDelete={(question) => {
                            onPressNext(
                              questions.filter((q) => q.id !== question.id),
                              false,
                              false,
                            );
                          }}
                        />
                      </Flex>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </Box>
            )}
          </Droppable>
        </DragDropContext>

        <Box mt={3}>
          <FlexibleButton
            rightIcon="plus"
            onClick={() => {
              setSelectedQuestion({ id: uniqueId("new") });
            }}
          >
            Add Question
          </FlexibleButton>
        </Box>

        <AddQuestionDialog
          key={selectedQuestion?.id}
          allowEditResponseType={couldEditResponseType}
          open={!!selectedQuestion}
          onClose={() => {
            setSelectedQuestion(null);
          }}
          onSave={(newQuestion) => {
            const existingQuestion = questions.find(
              (q) => q.id === newQuestion?.id,
            );
            let newQuestions = [];
            if (existingQuestion) {
              newQuestions = questions.map((q) => {
                if (q.id === newQuestion.id) {
                  return newQuestion;
                }
                return q;
              });
            } else {
              newQuestions = [...questions, newQuestion];
            }
            onPressNext(newQuestions, false, false);
            setSelectedQuestion(null);
          }}
          selectedQuestion={selectedQuestion}
        />
      </Box>
      <StyledStickyBox>
        <Box css={TabContainerCSS}>
          <Button
            style={{ minWidth: 160 }}
            large={true}
            intent="primary"
            onClick={() => {
              onPressNext(questions);
            }}
            disabled={saving || !hasQuestions}
          >
            Continue
          </Button>
        </Box>
      </StyledStickyBox>
    </>
  );
}

export default Customize;

export const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  borderRadius: isDragging ? 6 : 0,
  overflow: isDragging ? "hidden" : null,
  // styles we need to apply on draggables
  ...draggableStyle,
});
