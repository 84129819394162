import styled from "@emotion/styled";

import { StyledLinkBtn } from "@/components/pieces/bootstrap";

export const SecondaryLinkBtn = styled(StyledLinkBtn)`
  width: 288px;
  max-width: 100%;
  font-size: 18px;
  font-weight: 500;
  padding: 12px 20px;
  border-radius: 10px;
  border: none;
  box-shadow: none;
  background-color: #f5f5fb;
  color: #242424;
  z-index: 998;
`;
