import { useCallback } from "react";

import { Button, Intent } from "@blueprintjs/core";

import { Box, Flex } from "@/components/layout/flexbox";
import StyledDialog from "@/components/pieces/StyledDialog";

export function ConfirmDialog({
  onConfirm,
  onCancel,
  children,
  intent = Intent.DANGER,
  confirmLabel = "Confirm",
  cancelLabel = "Cancel",
}) {
  const handleConfirm = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      onConfirm();
    },
    [onConfirm],
  );
  const handleCancel = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      onCancel();
    },
    [onCancel],
  );
  return (
    <StyledDialog
      centered={true}
      isOpen={true}
      onClose={handleCancel}
      width="400px"
      title={<Box fontSize={18}>Confirm</Box>}
    >
      <Flex flexDirection="column" style={{ gap: 12 }} p={24}>
        {children}
        <Flex style={{ gap: 12 }}>
          <Button intent={intent} onClick={handleConfirm}>
            {confirmLabel}
          </Button>
          <Button onClick={handleCancel}>{cancelLabel}</Button>
        </Flex>
      </Flex>
    </StyledDialog>
  );
}
