import { useCallback, useMemo, useReducer } from "react";

import {
  Button,
  Colors,
  Drawer,
  FormGroup,
  InputGroup,
  Callout,
} from "@blueprintjs/core";

import { convertToMarkdown } from "@/components/helpers/string-utils";
import { Box, Flex } from "@/components/layout/flexbox";
import Tiptap from "@/components/pieces/editor/tiptap";
import { DARK_BACKGROUND, SUBTEXT_COLOR } from "@/css/constants";
import {
  ScenarioDocument,
  ScenarioType,
  useEditScenarioMutation,
} from "@/graphql";
import { uploadFile } from "@/lib/upload-file";

const reducer = (
  state: {
    context?: string;
    objective?: string;
    name?: string;
    error?: string;
  },
  action: {
    type: string;
    payload: string;
    initialScenario?: ScenarioType;
  },
) => {
  switch (action.type) {
    case "setContext":
      return { ...state, context: action.payload, error: null };
    case "setObjective":
      return { ...state, objective: action.payload, error: null };
    case "setName":
      return { ...state, name: action.payload, error: null };
    case "setError":
      return {
        context: action.initialScenario?.context,
        objective: action.initialScenario?.objective,
        name: action.initialScenario?.name,
        error: action.payload,
      };
  }
};

export function EditScenario({
  state,
  dispatch,
}: {
  state: any;
  dispatch: any;
}) {
  const handleContextChange = useCallback(
    (value: string) => {
      dispatch({ type: "setContext", payload: value });
    },
    [dispatch],
  );

  const handleObjectiveChange = useCallback(
    (value: string) => {
      dispatch({ type: "setObjective", payload: value });
    },
    [dispatch],
  );

  const handleNameChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      dispatch({ type: "setName", payload: event.target.value });
    },
    [dispatch],
  );

  return (
    <Flex
      flexDirection="column"
      p={24}
      pb={12}
      backgroundColor={DARK_BACKGROUND}
    >
      <FormGroup
        label={
          <Box fontSize={16} fontWeight={600}>
            Scenario Name
          </Box>
        }
      >
        <InputGroup value={state.name} onChange={handleNameChange} />
      </FormGroup>
      <FormGroup
        label={
          <Box fontSize={16} fontWeight={600}>
            Scenario Context
          </Box>
        }
        labelInfo={
          <Box fontSize={14} fontWeight={400} color={SUBTEXT_COLOR}>
            What learners see before the conversation begins
          </Box>
        }
      >
        <Flex backgroundColor={Colors.WHITE} className="round-corners">
          <Tiptap
            content={state.context}
            onChange={handleContextChange}
            onFileUpload={uploadFile}
          />
        </Flex>
      </FormGroup>
      <FormGroup
        label={
          <Box fontSize={16} fontWeight={600}>
            Scenario Objective
          </Box>
        }
        labelInfo={
          <Box fontSize={14} fontWeight={400} color={SUBTEXT_COLOR}>
            Define what the learner should accomplish in this conversation and
            any outcomes they should avoid.
          </Box>
        }
      >
        <Flex backgroundColor={Colors.WHITE} className="round-corners">
          <Tiptap
            content={state.objective}
            onChange={handleObjectiveChange}
            onFileUpload={uploadFile}
          />
        </Flex>
      </FormGroup>
    </Flex>
  );
}

export function EditScenarioDrawer({
  open,
  onClose,
  scenario,
}: {
  open: boolean;
  onClose: () => void;
  scenario?: ScenarioType;
}) {
  const [state, dispatch] = useReducer(reducer, {
    context: scenario?.context,
    objective: scenario?.objective,
    name: scenario?.name,
    error: null,
  });

  const [editScenario, { loading }] = useEditScenarioMutation();

  const isDirty = useMemo(() => {
    return (
      state.context !== scenario?.context ||
      state.objective !== scenario?.objective ||
      state.name !== scenario?.name
    );
  }, [state, scenario]);

  const disabled = useMemo(() => {
    return (
      !isDirty ||
      state.name === "" ||
      state.context === "<p></p>" ||
      state.objective === "<p></p>"
    );
  }, [isDirty, state]);

  const handleSave = useCallback(() => {
    editScenario({
      variables: {
        slug: scenario?.slug,
        name: state.name,
        context: convertToMarkdown(state.context),
        objective: convertToMarkdown(state.objective),
      },
      onCompleted: (data) => {
        if (data.editScenario.ok) {
          onClose();
        } else {
          dispatch({
            type: "setError",
            payload: data.editScenario.errors.join(", "),
            initialScenario: scenario,
          });
        }
      },
      onError: (error) => {
        dispatch({
          type: "setError",
          payload: error.message,
          initialScenario: scenario,
        });
      },
      refetchQueries: [
        {
          query: ScenarioDocument,
          variables: { scenarioSlug: scenario?.slug },
        },
      ],
    });
  }, [state, scenario, editScenario, onClose]);

  return (
    <Drawer
      isOpen={open}
      usePortal={true}
      lazy={true}
      onClose={onClose}
      title="Edit Scenario"
      style={{
        maxWidth: "600px",
        width: "100%",
        height: "100%",
      }}
    >
      {state.error && <Callout intent="danger">{state.error}</Callout>}
      <Flex flexDirection="column" overflowY="auto">
        <EditScenario state={state} dispatch={dispatch} />
      </Flex>
      <Flex justifyContent="flex-start" p={24} className="bts">
        <Button
          loading={loading}
          intent="primary"
          large={true}
          onClick={handleSave}
          disabled={disabled}
        >
          Save
        </Button>
      </Flex>
    </Drawer>
  );
}
