import { Button, Classes, Colors } from "@blueprintjs/core";
import styled from "@emotion/styled";

import { breakpoints } from "@/components/layout/Media";
import { BP_NS, LIGHT_BACKGROUND } from "@/css/constants";

const StyledButton = styled(Button)`
  border-radius: 6px;
  &.${Classes.BUTTON}:not([class*='${BP_NS}-intent-']) {
    font-weight: 600;
    padding: 10px 14px;
    color: ${Colors.DARK_GRAY5};
    background-color: ${LIGHT_BACKGROUND};
    border: 1px solid ${LIGHT_BACKGROUND};
    box-shadow: none;
    &:hover {
      box-shadow: none;
      background-color: #e4e9ed;
    }
  }
  :focus {
    outline: none;
    box-shadow: none;
    outline-offset: 0;
  }

  @media screen and (max-width: ${breakpoints.sm}px) {
    &.${Classes.BUTTON}:not([class*='${BP_NS}-intent-']) {
      padding: 6px 10px;
      font-size: 15px;
    }
  }
`;

function GrayButton({ children, ...rest }) {
  return <StyledButton {...rest}>{children}</StyledButton>;
}

export default GrayButton;
