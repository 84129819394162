import { Classes } from "@blueprintjs/core";
import styled from "@emotion/styled";

import { Box, Flex } from "@/components/layout/flexbox";
import ProductList from "@/components/pieces/products/ProductList";
import { LIGHT_BACKGROUND } from "@/css/constants";
import { PendingOrderQuery, ProductType, SpecialtyType } from "@/graphql";

interface ProductSelectorProps {
  products?: ProductType[];
  selectedProduct?: PendingOrderQuery["pendingOrder"]["orderItems"][0]["product"];
  setSelectedProduct?: (product: ProductType | null) => void;
  selectedSpecialty?: SpecialtyType;
  loading?: boolean;
  disabled?: boolean;
  disabledProductIds?: number[];
  className?: string;
  minCredits?: number;
  maxPerRow?: number;
  hideDisabled?: boolean;
}

export default function ProductSelector({
  products,
  selectedProduct,
  setSelectedProduct,
  selectedSpecialty,
  loading = false,
  disabled = false,
  disabledProductIds = [],
  className,
  minCredits,
  maxPerRow = 4,
  hideDisabled = false,
}: ProductSelectorProps) {
  // If we are only looking at products that are for a specialty
  // hide all irrelevant ones
  const eligibleProducts = !products
    ? []
    : !selectedSpecialty
      ? products
      : products.reduce((agg, p) => {
          // if the product doesn't have a specialty,
          // don't include it
          if (!p.specialty) {
            return agg;
          }

          // include it if the product has the specialty requested
          if (parseInt(p.specialty.id) === parseInt(selectedSpecialty.id)) {
            agg.push(p);
          }
          return agg;
        }, []);

  return (
    <StyledFlex className={className} flexWrap="wrap" mx={[0, 0, -12]} mt={3}>
      {loading || !products ? (
        [1, 2, 3].map((i) => (
          <Box
            key={i}
            className={Classes.SKELETON}
            mx={[0, 0, 12]}
            width={[1, 1, 1 / 3]}
            height={120}
            flex={["undefined", "undefined", 1]}
          />
        ))
      ) : eligibleProducts.length ? (
        <ProductList
          disabled={disabled}
          disabledProductIds={disabledProductIds}
          products={products}
          selectedProduct={selectedProduct}
          selectedSpecialty={selectedSpecialty}
          setSelectedProduct={setSelectedProduct}
          eligibleProducts={eligibleProducts}
          minCredits={minCredits}
          maxPerRow={maxPerRow}
          hideDisabled={hideDisabled}
        />
      ) : (
        <Box
          mx={[2, 0]}
          p={[3, 24]}
          className="round-corners"
          backgroundColor={LIGHT_BACKGROUND}
        >
          Your selected coach does not have any session options for this
          coaching type.
        </Box>
      )}
    </StyledFlex>
  );
}

const StyledFlex = styled(Flex)`
  &.slogan-top {
    .product-slogan {
      position: absolute;
      top: 0;
      right: 0;
      margin: 0px;
      padding: 5px 10px;
      font-size: 14px;
      border-radius: 0px 0px 0px 5px;
      box-shadow:
        0 1px 1px rgb(24 32 38 / 10%),
        0 2px 6px rgb(24 32 38 / 5%);
    }
  }
`;
