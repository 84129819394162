import { useLazyQuery, useMutation } from "@apollo/client";

import {
  ArchiveProgramDocument,
  CloneProgramDocument,
  CloseProgramComponentDocument,
  CoachesForComponentDocument,
  CompleteProgramDocument,
  CompleteSetupProgramComponentsDocument,
  CreateProgramDocument,
  CreateProgramUsersDocument,
  IsLastActiveComponentQueryDocument,
  IsLastActiveComponentQueryQuery,
  LaunchProgramDocument,
  ProductsForConfigDocument,
  ProgramComponentForStatisticQueryDocument,
  ProgramComponentForStatisticQueryQuery,
  ProgramComponentParticipantsQueryDocument,
  ProgramComponentParticipantsQueryQuery,
  ProgramComponentResponseQueryDocument,
  ProgramComponentResponseQueryQuery,
  ProgramComponentsQueryDocument,
  ProgramComponentsQueryQuery,
  ProgramQueryDocument,
  ProgramQueryQuery,
  ProgramRoleplayScenarioParticipantsQueryDocument,
  ProgramRoleplayScenarioParticipantsQueryQuery,
  ProgramRoleplayScenariosAnalyticsQueryDocument,
  ProgramRoleplayScenariosAnalyticsQueryQuery,
  ProgramsQueryDocument,
  ProgramsQueryQuery,
  ProgramStatusFilterDocument,
  ProgramStatusFilterQuery,
  ProgramSurveyQueryDocument,
  ProgramSurveyQueryQuery,
  ProgramSurveyQuestionParticipantsQueryDocument,
  ProgramSurveyQuestionParticipantsQueryQuery,
  ProgramSurveyQuestionsAnalyticsQueryDocument,
  ProgramSurveyQuestionsAnalyticsQueryQuery,
  ProgramUsersQueryDocument,
  ProgramUsersQueryQuery,
  PublicProgramQueryDocument,
  PublicProgramQueryQuery,
  RemoveProgramComponentDocument,
  RemoveProgramLeadDocument,
  RemoveProgramParticipantDocument,
  RemoveProgramParticipantMutation,
  SaveProgramComponentDocument,
  SaveProgramSurveyAnswerDocument,
  ScenarioDocument,
  SelectableProgramComponentsQueryDocument,
  SendNudgeSurveyDocument,
  SimpleProgramUsersQueryDocument,
  SpecialtiesForConfigDocument,
  SurveyTemplatesQueryDocument,
  SurveyTemplatesQueryQuery,
  UpdateProgramDocument,
} from "@/graphql";

export class ProgramRefetchQueryBuilder {
  static buildProgramComponentsQuery(programId: string) {
    return {
      query: ProgramComponentsQueryDocument,
      variables: {
        programId,
      },
    };
  }
  static buildProgramQuery(programId: string) {
    return {
      query: ProgramQueryDocument,
      variables: {
        programId,
      },
    };
  }

  static buildComponentForStatisticQuery(programComponentId: string) {
    return {
      query: ProgramComponentForStatisticQueryDocument,
      variables: {
        programComponentId,
      },
    };
  }

  static buildSurveyResponseStatisticsQuery(programSurveyId: string) {
    if (!programSurveyId) {
      return null;
    }
    return {
      query: ProgramSurveyQuestionsAnalyticsQueryDocument,
      variables: {
        programSurveyId,
      },
    };
  }

  static buildComponentParticipantsQuery(
    programComponentId: string,
    page: number,
    size: number,
    orderField: string,
  ) {
    return {
      query: ProgramComponentParticipantsQueryDocument,
      variables: {
        programComponentId,
        page,
        size,
        orderField,
      },
    };
  }
}

export class ScenarioRefetchQueryBuilder {
  static buildScenarioQuery(scenarioSlug: string) {
    return {
      query: ScenarioDocument,
      variables: {
        scenarioSlug,
      },
    };
  }
}

export const useLazyProgramsQuery = ({ onCompleted }) => {
  const [fetch, { loading, data }] = useLazyQuery<ProgramsQueryQuery>(
    ProgramsQueryDocument,
    {
      fetchPolicy: "cache-and-network",
      onCompleted: (data) => {
        if (data?.programs) {
          onCompleted(data);
        }
      },
    },
  );

  return {
    fetch: (page, size, orderField, status, query = null) =>
      fetch({
        variables: {
          page,
          size,
          orderField,
          status,
          query,
        },
      }),
    data,
    loading,
  };
};

export const useUpdateProgramMutation = ({
  onCompleted,
  onError,
  refetchQueries = null,
}) => {
  const [updateProgram, { loading, data }] = useMutation(
    UpdateProgramDocument,
    {
      refetchQueries: refetchQueries,
      onCompleted: (data) => {
        const { ok, msg } = data?.updateProgram || {};
        if (ok) {
          onCompleted(data);
        } else {
          onError(Error(msg || "Something went wrong. Please try again."));
        }
      },
      onError: (err) => {
        onError(err);
      },
    },
  );

  return {
    updateProgram: (name, targetLaunchDate, programId, scheduleLaunchEmail) =>
      updateProgram({
        variables: {
          name,
          targetLaunchDate,
          programId,
          scheduleLaunchEmail,
        },
      }),
    data,
    loading,
  };
};

export const useCreateProgramMutation = ({ onCompleted, onError }) => {
  const [createProgram, { loading, data }] = useMutation(
    CreateProgramDocument,
    {
      onCompleted: (data) => {
        const ok = data?.createProgram?.ok;
        if (ok) {
          onCompleted(data);
        } else {
          onError(Error("Something went wrong. Please try again."));
        }
      },
      onError: (err) => {
        onError(err);
      },
    },
  );

  return {
    createProgram: (name, targetLaunchDate) =>
      createProgram({
        variables: {
          name,
          targetLaunchDate,
        },
      }),
    data,
    loading,
  };
};

export const useLazyProgramUsersQuery = ({ onCompleted, onError }) => {
  const [fetch, { loading, data }] = useLazyQuery<ProgramUsersQueryQuery>(
    ProgramUsersQueryDocument,
    {
      fetchPolicy: "cache-and-network",
      onCompleted: (data) => {
        if (data?.programUsers) {
          onCompleted(data);
        } else {
          onError(Error("Something went wrong. Please try again."));
        }
      },
      onError: (err) => {
        onError(err);
      },
    },
  );

  return {
    fetch: (programId, page, size, orderField, roles, query = null) =>
      fetch({
        variables: {
          programId,
          page,
          size,
          orderField,
          roles,
          query,
        },
      }),
    data,
    loading,
  };
};

export const useSimpleProgramUsersLazyQuery = ({ onCompleted, onError }) => {
  const [fetch, { loading, data }] = useLazyQuery(
    SimpleProgramUsersQueryDocument,
    {
      fetchPolicy: "cache-and-network",
      onCompleted: (data) => {
        if (data?.simpleProgramUsers) {
          onCompleted(data);
        } else {
          onError(Error("Something went wrong. Please try again."));
        }
      },
      onError: (err) => {
        onError(err);
      },
    },
  );

  return {
    fetch: (programId, roles = null) =>
      fetch({
        variables: {
          programId,
          roles,
        },
      }),
    data,
    loading,
  };
};

export const useCreateProgramUsers = ({
  onCompleted,
  onError,
  refetchQueries = null,
}) => {
  const [createProgramUsers, { loading, data }] = useMutation(
    CreateProgramUsersDocument,
    {
      refetchQueries: refetchQueries,
      onCompleted: (data) => {
        const ok = data?.createProgramUsers?.ok;
        const msg = data?.createProgramUsers?.msg;
        if (ok) {
          onCompleted(data);
        } else {
          onError(Error(msg || "Something went wrong. Please try again."));
        }
      },
      onError: (err) => {
        onError(err);
      },
    },
  );

  return {
    createProgramUsers: (
      programId,
      emails = [],
      role = null,
      groupId = null,
      credit = 0,
    ) =>
      createProgramUsers({
        variables: {
          programId,
          emails,
          role,
          groupId,
          credit,
        },
      }),
    data,
    loading,
  };
};

export const useRemoveProgramParticipant = ({
  onCompleted,
  onError,
  refetchQueries = null,
}) => {
  const [removeProgramParticipant, { loading, data }] =
    useMutation<RemoveProgramParticipantMutation>(
      RemoveProgramParticipantDocument,
      {
        refetchQueries: refetchQueries,
        onCompleted: (data) => {
          const { ok, msg } = data?.removeProgramParticipant || {};
          if (ok) {
            onCompleted(data);
          } else {
            onError(
              Error(
                msg ||
                  "Unable to remove program participant. Please try again.",
              ),
            );
          }
        },
        onError: (err) => {
          onError(err);
        },
      },
    );

  return {
    removeProgramParticipant: (programId, programUserId) =>
      removeProgramParticipant({
        variables: {
          programId,
          programUserId,
        },
      }),
    data,
    loading,
  };
};

export const useRemoveProgramLead = ({
  onCompleted,
  onError,
  refetchQueries = null,
}) => {
  const [removeProgramLead, { loading, data }] = useMutation(
    RemoveProgramLeadDocument,
    {
      refetchQueries: refetchQueries,
      onCompleted: (data) => {
        const { msg, ok } = data?.removeProgramLead || {};
        if (ok) {
          onCompleted(data);
        } else {
          onError(
            Error(
              msg || "Unable to remove program participant. Please try again.",
            ),
          );
        }
      },
      onError: (err) => {
        onError(err);
      },
    },
  );

  return {
    removeProgramLead: (programId, programUserId) =>
      removeProgramLead({
        variables: {
          programId,
          programUserId,
        },
      }),
    data,
    loading,
  };
};

export const useLazyProgramQuery = ({ onCompleted, onError }) => {
  const [fetch, { loading, data }] = useLazyQuery<ProgramQueryQuery>(
    ProgramQueryDocument,
    {
      fetchPolicy: "cache-and-network",
      onCompleted: (data) => {
        if (data?.program) {
          onCompleted(data);
        } else {
          onError(Error("Something went wrong. Please try again."));
        }
      },
      onError: (err) => {
        onError(err);
      },
    },
  );

  return {
    fetch: (programId) =>
      fetch({
        variables: {
          programId,
        },
      }),
    data,
    loading,
  };
};

export const useLazyPublicProgramQuery = ({ onCompleted, onError }) => {
  const [fetch, { loading, data }] = useLazyQuery<PublicProgramQueryQuery>(
    PublicProgramQueryDocument,
    {
      fetchPolicy: "cache-and-network",
      onCompleted: (data) => {
        if (data?.publicProgram) {
          onCompleted(data);
        } else {
          onError(Error("Something went wrong. Please try again."));
        }
      },
      onError: (err) => {
        onError(err);
      },
    },
  );

  return {
    fetch: (programId) =>
      fetch({
        variables: {
          programId,
        },
      }),
    data,
    loading,
  };
};

export const useSaveProgramComponentMutation = ({
  onCompleted,
  onError,
  refetchQueries = null,
}) => {
  const [saveProgramComponent, { loading }] = useMutation(
    SaveProgramComponentDocument,
    {
      refetchQueries: refetchQueries,
      onCompleted: (data) => {
        const ok = data?.saveProgramComponent?.ok;
        if (ok) {
          onCompleted(data);
        } else if (data?.saveProgramComponent?.msg) {
          onError(Error(data?.saveProgramComponent?.msg));
        } else {
          onError(Error("Something went wrong. Please try again."));
        }
      },
      onError: (err) => {
        onError(err);
      },
    },
  );

  return {
    saveProgramComponent: (programId, input) => {
      return saveProgramComponent({
        variables: {
          programId,
          input,
        },
      });
    },
    loading,
  };
};

export const useProductsForConfigLazyQuery = ({ onCompleted, onError }) => {
  const [fetch, { data, loading }] = useLazyQuery(ProductsForConfigDocument, {
    fetchPolicy: "cache-and-network",
    onCompleted: (data) => {
      if (data?.productsForConfig) {
        onCompleted(data);
      } else {
        onError(Error("Something went wrong. Please try again."));
      }
    },
    onError: (err) => {
      onError(err);
    },
  });
  return {
    loading,
    data,
    fetch: (specialtyId, categories = []) =>
      fetch({
        variables: {
          specialtyId,
          categories,
        },
      }),
  };
};

export const useCoachesForComponentLazyQuery = ({ onCompleted, onError }) => {
  const [fetch, { data, loading }] = useLazyQuery(CoachesForComponentDocument, {
    fetchPolicy: "cache-and-network",
    onCompleted: (data) => {
      if (data?.coaches) {
        onCompleted(data);
      } else {
        onError(Error("Something went wrong. Please try again."));
      }
    },
    onError: (err) => {
      onError(err);
    },
  });
  return {
    loading,
    data,
    fetch: (programId, specialtyIds = [], productIds = []) =>
      fetch({
        variables: {
          programId,
          specialtyIds,
          productIds,
        },
      }),
  };
};

export const useProgramComponentsLazyQuery = ({ onCompleted, onError }) => {
  const [fetch, { data, loading, refetch }] =
    useLazyQuery<ProgramComponentsQueryQuery>(ProgramComponentsQueryDocument, {
      fetchPolicy: "cache-and-network",
      onCompleted: (data) => {
        if (data?.programComponents) {
          onCompleted(data);
        } else {
          onError(Error("Something went wrong. Please try again."));
        }
      },
      onError: (err) => {
        onError(err);
      },
    });
  return {
    loading,
    data,
    fetch: (programId) =>
      fetch({
        variables: {
          programId,
        },
      }),
    refetch,
  };
};

export const useSelectableProgramComponentsLazyQuery = ({
  onCompleted,
  onError,
}) => {
  const [fetch, { data, loading, refetch }] = useLazyQuery(
    SelectableProgramComponentsQueryDocument,
    {
      fetchPolicy: "no-cache",
      onCompleted: (data) => {
        if (data?.selectableProgramComponents) {
          onCompleted(data?.selectableProgramComponents);
        } else {
          onError(Error("Something went wrong. Please try again."));
        }
      },
      onError: (err) => {
        onError(err);
      },
    },
  );
  return {
    loading,
    data,
    fetch: (programId) =>
      fetch({
        variables: {
          programId,
        },
      }),
    refetch,
  };
};

export const useProgramSurveyLazyQuery = ({ onCompleted, onError }) => {
  const [fetch, { data, loading }] = useLazyQuery<ProgramSurveyQueryQuery>(
    ProgramSurveyQueryDocument,
    {
      fetchPolicy: "cache-and-network",
      onCompleted: (data) => {
        if (data?.programSurvey) {
          onCompleted(data);
        } else {
          onError(Error("Something went wrong. Please try again."));
        }
      },
      onError: (err) => {
        onError(err);
      },
    },
  );
  return {
    loading,
    data,
    fetch: (programSurveyId) =>
      fetch({
        variables: {
          programSurveyId,
        },
      }),
  };
};

export const useSaveProgramSurveyAnswerMutation = ({
  onCompleted,
  onError,
  refetchQueries = null,
}) => {
  const [saveProgramSurveyAnswer, { loading, data }] = useMutation(
    SaveProgramSurveyAnswerDocument,
    {
      refetchQueries: refetchQueries,
      onCompleted: (data) => {
        const ok = data?.saveProgramSurveyAnswer?.ok;
        if (ok) {
          onCompleted(data);
        } else {
          const message = data?.saveProgramSurveyAnswer?.errors?.[0]?.message;
          onError(Error(message || "Something went wrong. Please try again."));
        }
      },
      onError: (err) => {
        onError(err);
      },
      awaitRefetchQueries: true,
    },
  );
  return {
    saveProgramSurveyAnswer: (surveyId, answerData) =>
      saveProgramSurveyAnswer({
        variables: {
          surveyId,
          answerData,
        },
      }),
    data,
    loading,
  };
};

export const useSpecialtiesForConfigLazyQuery = ({ onCompleted, onError }) => {
  const [fetch, { data, loading, refetch }] = useLazyQuery(
    SpecialtiesForConfigDocument,
    {
      fetchPolicy: "cache-and-network",
      onCompleted: (data) => {
        if (data?.specialtiesForConfig) {
          onCompleted(data);
        } else {
          onError(Error("Something went wrong. Please try again."));
        }
      },
      onError: (err) => {
        onError(err);
      },
    },
  );
  return {
    loading,
    data,
    refetch,
    fetch: (categories = []) =>
      fetch({
        variables: {
          categories,
        },
      }),
  };
};

export const useSurveyTemplatesLazyQuery = ({ onCompleted, onError }) => {
  const [fetch, { data, loading }] = useLazyQuery<SurveyTemplatesQueryQuery>(
    SurveyTemplatesQueryDocument,
    {
      fetchPolicy: "cache-and-network",
      onCompleted: (data) => {
        if (data?.surveyTemplates) {
          onCompleted(data);
        } else {
          onError(Error("Something went wrong. Please try again."));
        }
      },
      onError: (err) => {
        onError(err);
      },
    },
  );
  return {
    loading,
    data,
    fetch: (programId) =>
      fetch({
        variables: {
          programId,
        },
      }),
  };
};

export const useRemoveProgramComponentMutation = ({
  programId,
  onCompleted,
  onError,
}) => {
  const [removeProgramComponent, { loading, data }] = useMutation(
    RemoveProgramComponentDocument,
    {
      onCompleted: (data) => {
        const { ok, msg } = data?.removeProgramComponent || {};
        if (ok) {
          onCompleted(data);
        } else {
          onError(Error(msg || "Something went wrong. Please try again."));
        }
      },
      onError: (err) => {
        onError(err);
      },
      refetchQueries: [
        {
          query: ProgramQueryDocument,
          variables: { programId },
        },
        {
          query: ProgramComponentsQueryDocument,
          variables: { programId },
        },
      ],
    },
  );

  return {
    removeProgramComponent: (
      programId,
      programComponentId,
      isReleaseDependentComponents = true,
    ) =>
      removeProgramComponent({
        variables: {
          programId,
          programComponentId,
          isReleaseDependentComponents,
        },
      }),
    data,
    loading,
  };
};

export const useCloseProgramComponentMutation = ({
  programId,
  onCompleted,
  onError,
  refetchQueries = null,
}) => {
  const [closeProgramComponent, { loading, data }] = useMutation(
    CloseProgramComponentDocument,
    {
      onCompleted: (data) => {
        const { ok, msg } = data?.closeProgramComponent || {};
        if (ok) {
          onCompleted(data);
        } else {
          onError(Error(msg || "Something went wrong. Please try again."));
        }
      },
      onError: (err) => {
        onError(err);
      },
      refetchQueries: refetchQueries || [
        ProgramRefetchQueryBuilder.buildProgramQuery(programId),
        ProgramRefetchQueryBuilder.buildProgramComponentsQuery(programId),
      ],
    },
  );

  return {
    closeProgramComponent: (
      programId,
      programComponentId,
      isReleaseDependentComponents = true,
    ) =>
      closeProgramComponent({
        variables: {
          programId,
          programComponentId,
          isReleaseDependentComponents,
        },
      }),
    data,
    loading,
  };
};

export const useProgramComponentForStatisticQuery = ({
  onCompleted,
  onError,
}) => {
  const [fetch, { data, loading }] =
    useLazyQuery<ProgramComponentForStatisticQueryQuery>(
      ProgramComponentForStatisticQueryDocument,
      {
        fetchPolicy: "cache-and-network",
        onCompleted: (data) => {
          if (data?.programComponentForStatistic) {
            onCompleted(data);
          } else {
            onError(Error("Something went wrong. Please try again."));
          }
        },
        onError: (err) => {
          onError(err);
        },
      },
    );
  return {
    loading,
    data,
    fetch: (programComponentId) =>
      fetch({
        variables: {
          programComponentId,
        },
      }),
  };
};

export const useProgramComponentParticipantsQuery = ({
  onCompleted,
  onError,
}) => {
  const [fetch, { data, loading }] =
    useLazyQuery<ProgramComponentParticipantsQueryQuery>(
      ProgramComponentParticipantsQueryDocument,
      {
        fetchPolicy: "cache-and-network",
        onCompleted: (data) => {
          if (data?.programComponentParticipants) {
            onCompleted(data);
          } else {
            onError(Error("Something went wrong. Please try again."));
          }
        },
        onError: (err) => {
          onError(err);
        },
      },
    );
  return {
    loading,
    data,
    fetch: (programComponentId, page, size, orderField = null) =>
      fetch({
        variables: {
          programComponentId,
          page,
          size,
          orderField,
        },
      }),
  };
};

export const useProgramSurveyQuestionsAnalyticsQuery = ({
  onCompleted,
  onError,
}) => {
  const [fetch, { data, loading }] =
    useLazyQuery<ProgramSurveyQuestionsAnalyticsQueryQuery>(
      ProgramSurveyQuestionsAnalyticsQueryDocument,
      {
        fetchPolicy: "cache-and-network",
        onCompleted: (data) => {
          if (data?.programSurveyQuestionsAnalytics) {
            onCompleted(data);
          } else {
            onError(Error("Something went wrong. Please try again."));
          }
        },
        onError: (err) => {
          onError(err);
        },
      },
    );
  return {
    loading,
    data,
    fetch: (programSurveyId) =>
      fetch({
        variables: {
          programSurveyId,
        },
      }),
  };
};

export const useProgramRoleplayScenariosAnalytics = ({
  onCompleted,
  onError,
}) => {
  const [fetch, { data, loading }] =
    useLazyQuery<ProgramRoleplayScenariosAnalyticsQueryQuery>(
      ProgramRoleplayScenariosAnalyticsQueryDocument,
      {
        fetchPolicy: "cache-and-network",
        onCompleted: (data) => {
          if (data?.programRoleplayScenariosAnalytics) {
            onCompleted(data);
          } else {
            onError(Error("Something went wrong. Please try again."));
          }
        },
        onError: (err) => {
          onError(err);
        },
      },
    );
  return {
    loading,
    data,
    fetch: (programComponentId) =>
      fetch({
        variables: {
          programComponentId,
        },
      }),
  };
};

export const useProgramComponentResponseLazyQuery = ({
  onCompleted,
  onError,
}) => {
  const [fetch, { data, loading }] =
    useLazyQuery<ProgramComponentResponseQueryQuery>(
      ProgramComponentResponseQueryDocument,
      {
        fetchPolicy: "cache-and-network",
        onCompleted: (data: ProgramComponentResponseQueryQuery) => {
          if (data?.programComponentResponse) {
            onCompleted(data);
          } else {
            onError(Error("Something went wrong. Please try again."));
          }
        },
        onError: (err) => {
          onError(err);
        },
      },
    );
  return {
    loading,
    data,
    fetch: (responseId) =>
      fetch({
        variables: {
          responseId,
        },
      }),
  };
};

export const useLaunchProgramMutation = ({
  onCompleted,
  onError,
  refetchQueries = null,
}) => {
  const [launchProgram, { loading, data }] = useMutation(
    LaunchProgramDocument,
    {
      refetchQueries: refetchQueries,
      onCompleted: (data) => {
        const ok = data?.launchProgram?.ok;
        if (ok) {
          onCompleted(data);
        } else {
          onError(Error("Something went wrong. Please try again."));
        }
      },
      onError: (err) => {
        onError(err);
      },
    },
  );

  return {
    launchProgram: (programId) =>
      launchProgram({
        variables: {
          programId,
        },
      }),
    data,
    loading,
  };
};

export const useCheckIsLastActiveComponentQuery = ({
  onCompleted,
  onError,
}) => {
  const [fetch, { data, loading }] =
    useLazyQuery<IsLastActiveComponentQueryQuery>(
      IsLastActiveComponentQueryDocument,
      {
        fetchPolicy: "cache-and-network",
        onCompleted: (data) => {
          onCompleted(data?.isLastActiveComponent);
        },
        onError: (err) => {
          onError(err);
        },
      },
    );
  return {
    loading,
    data,
    fetch: (programId, componentId) =>
      fetch({
        variables: {
          programId,
          componentId,
        },
      }),
  };
};

export const useSendNudgeSurveyMutation = ({
  onCompleted,
  onError,
  refetchQueries = null,
}) => {
  const [sendNudgeSurvey, { loading }] = useMutation(SendNudgeSurveyDocument, {
    refetchQueries: refetchQueries,
    onCompleted: (data) => {
      onCompleted(data);
    },
    onError: (err) => {
      onError(err);
    },
  });

  return {
    sendNudgeSurvey: (componentId, participantId) =>
      sendNudgeSurvey({
        variables: {
          participantId,
          componentId,
        },
      }),
    loading,
  };
};

export const useProgramSurveyQuestionParticipantsQuery = ({
  onCompleted,
  onError,
}) => {
  const [fetch, { data, loading }] =
    useLazyQuery<ProgramSurveyQuestionParticipantsQueryQuery>(
      ProgramSurveyQuestionParticipantsQueryDocument,
      {
        fetchPolicy: "no-cache",
        onCompleted: (data) => {
          onCompleted(data);
        },
        onError: (err) => {
          onError(err);
        },
      },
    );
  return {
    loading,
    data,
    fetch: (programSurveyId, formFieldId) =>
      fetch({
        variables: {
          programSurveyId,
          formFieldId,
        },
      }),
  };
};

export const useProgramRoleplayScenarioParticipantsQuery = ({
  onCompleted,
  onError,
}) => {
  const [fetch, { data, loading }] =
    useLazyQuery<ProgramRoleplayScenarioParticipantsQueryQuery>(
      ProgramRoleplayScenarioParticipantsQueryDocument,
      {
        fetchPolicy: "no-cache",
        onCompleted: (data) => {
          onCompleted(data);
        },
        onError: (err) => {
          onError(err);
        },
      },
    );
  return {
    loading,
    data,
    fetch: (programComponentId, scenarioId) =>
      fetch({
        variables: {
          programComponentId,
          scenarioId,
        },
      }),
  };
};

export const useCompleteSetupProgramComponents = ({
  programId,
  onError,
  onCompleted,
  refetchQueries = null,
}) => {
  const [completeSetupProgramComponents, { loading }] = useMutation(
    CompleteSetupProgramComponentsDocument,
    {
      onCompleted: (data) => {
        const ok = data?.completeSetupProgramComponents?.ok;
        if (ok) {
          onCompleted(data);
        } else {
          onError(Error("Something went wrong. Please try again."));
        }
      },
      onError: (err) => {
        onError(err);
      },
      refetchQueries: refetchQueries || [
        ProgramRefetchQueryBuilder.buildProgramQuery(programId),
      ],
    },
  );

  return {
    completeSetupProgramComponents: (programId) =>
      completeSetupProgramComponents({
        variables: {
          programId,
        },
      }),
    loading,
  };
};

export const useArchiveProgram = ({
  onCompleted,
  onError,
  refetchQueries = null,
}) => {
  const [archiveProgram, { loading }] = useMutation(ArchiveProgramDocument, {
    refetchQueries: refetchQueries,
    onCompleted: (data) => {
      const { ok, msg } = data?.archiveProgram || {};
      if (ok) {
        onCompleted();
      } else {
        onError(Error(msg || "Something went wrong. Please try again."));
      }
    },
    onError: (err) => {
      onError(err);
    },
  });

  return {
    archiveProgram: (programId) =>
      archiveProgram({
        variables: {
          programId,
        },
      }),
    loading,
  };
};

export const useProgramStatusLazyQuery = ({
  onComplete = null,
  onError = null,
}) => {
  const [fetch, { data, loading }] = useLazyQuery<ProgramStatusFilterQuery>(
    ProgramStatusFilterDocument,
    {
      fetchPolicy: "cache-and-network",
      onCompleted: (data) => {
        if (data?.programStatusFilter && onComplete) {
          onComplete(data.programStatusFilter);
        }
      },
      onError: (error) => {
        if (onError) {
          onError(error);
        }
      },
    },
  );
  return {
    fetch,
    data,
    loading,
  };
};

export const useCompleteProgram = ({
  onCompleted,
  onError,
  refetchQueries = null,
}) => {
  const [completeProgram, { loading }] = useMutation(CompleteProgramDocument, {
    refetchQueries: refetchQueries,
    onCompleted: (data) => {
      const { ok, msg } = data?.completeProgram || {};
      if (ok) {
        onCompleted();
      } else {
        onError(Error(msg || "Something went wrong. Please try again."));
      }
    },
    onError: (err) => {
      onError(err);
    },
  });

  return {
    completeProgram: (programId) =>
      completeProgram({
        variables: {
          programId,
        },
      }),
    loading,
  };
};

export const useCloneProgram = ({
  onCompleted,
  onError,
  refetchQueries = null,
}) => {
  const [cloneProgram, { loading }] = useMutation(CloneProgramDocument, {
    refetchQueries: refetchQueries,
    onCompleted: (data) => {
      const { ok, msg, program } = data?.cloneProgram || {};
      if (ok) {
        onCompleted(program);
      } else {
        onError(Error(msg || "Something went wrong. Please try again."));
      }
    },
    onError: (err) => {
      onError(err);
    },
  });

  return {
    cloneProgram: (programId: string, name: string, targetLaunchDate: string) =>
      cloneProgram({
        variables: {
          programId,
          name,
          targetLaunchDate,
        },
      }),
    loading,
  };
};
