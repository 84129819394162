import { Classes, Colors } from "@blueprintjs/core";
import { Link } from "react-router-dom";

import { isCoach } from "@/components/helpers/workspace/permissions";
import { Box } from "@/components/layout/flexbox";
import ExecSvg from "@/components/pieces/icons/svg/exec";
import PlacementSvg from "@/components/pieces/icons/svg/placement";
import { BRAND_PURPLE } from "@/css/constants";

export const LOGO_HEIGHT = 70;

const cachedState = {
  logoSrc: null,
};

function WorkspaceMenuLogo({
  workspace,
  iconSize = LOGO_HEIGHT,
  fitSize = false,
  margin = 12,
  isLink = true,
  loading = false,
}) {
  let logoSrc = workspace ? cachedState.logoSrc : null;
  const LogoComponent = IS_EXEC ? ExecSvg : PlacementSvg;
  if (workspace) {
    const { logo } = workspace;
    const newLogoSrc = logo.startsWith("/media") ? `${API_HOST}${logo}` : logo;
    if (logoSrc !== newLogoSrc) {
      cachedState.logoSrc = newLogoSrc;
      logoSrc = newLogoSrc;
    }
  }
  const logo = (
    <Box
      height={iconSize}
      width={fitSize ? iconSize : "auto"}
      m={margin}
      className={loading ? Classes.SKELETON : null}
    >
      {logoSrc ? (
        <img
          src={logoSrc}
          width="100%"
          height="100%"
          style={{
            objectFit: "contain",
            maxWidth: 220,
          }}
        />
      ) : (
        <LogoComponent
          size={iconSize ? iconSize : LOGO_HEIGHT - 10}
          color={workspace ? Colors.LIGHT_GRAY3 : BRAND_PURPLE}
        />
      )}
    </Box>
  );
  return isLink ? (
    <Link to={isCoach() ? "/clients" : "/dashboard"}>{logo}</Link>
  ) : (
    logo
  );
}
export default WorkspaceMenuLogo;
