import styled from "@emotion/styled";

import { Box } from "@/components/layout/flexbox";
import { SUBTEXT_COLOR } from "@/css/constants";
import { RoleplayRank } from "@/graphql";

interface RankIconProps {
  rank: RoleplayRank | "Gray";
  type?: "circle" | "badge";
  size?: number;
}

export function RankText({ rank, children, ...props }) {
  const className = rank ? rank.toLowerCase() : "";
  return (
    <RankTextElem className={className} {...props}>
      {children}
    </RankTextElem>
  );
}

const RankTextElem = styled(Box)`
  font-weight: 600;
  font-size: 14px;
  color: ${SUBTEXT_COLOR};

  &.bronze {
    color: #d66e40;
  }
  &.silver {
    color: #969595;
  }
  &.gold {
    color: #ebac00;
  }
`;

export default function RankIcon({
  rank,
  type = "circle",
  size = 30,
}: RankIconProps) {
  // return the correct icon based on the rank
  // but if they passed in a rank that doesn't exist, return an empty
  // div with the correct size
  if (type === "badge") {
    const BadgeIcon = badgeIcons[rank] || badgeIcons.null;
    return <BadgeIcon size={size} />;
  } else {
    const CircleIcon = circleIcons[rank] || circleIcons.null;
    return <CircleIcon size={size} />;
  }
}

const badgeIcons = {
  null: ({ size }) => <div style={{ width: size, height: size }} />,
  Gold: ({ size }) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size * (152 / 91)}
      fill="none"
      viewBox="0 0 91 155"
    >
      <path
        fill="#C4C4C4"
        d="M15 12h62v27.724a5 5 0 0 1-3.004 4.584l-26 11.323a5 5 0 0 1-3.992 0l-26-11.323A5 5 0 0 1 15 39.724V12Z"
      />
      <path
        fill="#C4C4C4"
        d="M15 12h62v27.724a5 5 0 0 1-3.004 4.584l-26 11.323a5 5 0 0 1-3.992 0l-26-11.323A5 5 0 0 1 15 39.724V12Z"
      />
      <path
        fill="#6466EB"
        d="M15 12h62v27.724a5 5 0 0 1-3.004 4.584l-26 11.323a5 5 0 0 1-3.992 0l-26-11.323A5 5 0 0 1 15 39.724V12Z"
      />
      <path
        fill="#fff"
        d="M31 12h30v38l-13.012 5.639a5 5 0 0 1-3.976 0L31 50V12Z"
      />
      <path
        fill="#2B2165"
        d="M54 12H38v41.033l6.012 2.605a5 5 0 0 0 3.976 0L54 53.034V12Z"
      />
      <circle cx={45.5} cy={106.5} r={45.5} fill="url(#badge-gold-a)" />
      <circle cx={45.5} cy={106.5} r={39.5} fill="url(#badge-gold-b)" />
      <rect width={73} height={12} x={9} fill="url(#badge-gold-c)" rx={6} />
      <path fill="#000" fillOpacity={0.2} d="M15 12h62v5H15z" />
      <path fill="#000" fillOpacity={0.2} d="M15 12h62v5H15z" />
      <rect
        width={23}
        height={4}
        x={52}
        y={2}
        fill="#fff"
        opacity={0.8}
        rx={2}
      />
      <g filter="url(#badge-gold-d)">
        <path
          fill="url(#badge-gold-e)"
          fillRule="evenodd"
          d="M44.923 82.684c.43-.912 1.725-.912 2.154 0l3.39 7.195 7.512 1.094c.963.14 1.36 1.315.681 2.013l-5.513 5.659 1.236 7.977c.151.979-.886 1.705-1.752 1.227L46 104.191l-6.63 3.658c-.867.478-1.904-.248-1.753-1.227l1.236-7.977-5.513-5.659c-.68-.698-.282-1.873.681-2.013l7.512-1.094 3.39-7.195Z"
          clipRule="evenodd"
        />
      </g>
      <g filter="url(#badge-gold-f)">
        <path
          fill="url(#badge-gold-g)"
          fillRule="evenodd"
          d="M24.254 108.473a.824.824 0 0 1 1.492 0l2.346 4.981 5.201.758a.826.826 0 0 1 .472 1.394l-3.817 3.917.855 5.523a.825.825 0 0 1-1.212.85L25 123.363l-4.59 2.533a.825.825 0 0 1-1.213-.85l.855-5.523-3.817-3.917a.826.826 0 0 1 .472-1.394l5.2-.758 2.347-4.981Z"
          clipRule="evenodd"
        />
      </g>
      <g filter="url(#badge-gold-h)">
        <path
          fill="url(#badge-gold-i)"
          fillRule="evenodd"
          d="M66.254 108.473a.824.824 0 0 1 1.492 0l2.346 4.981 5.201.758a.826.826 0 0 1 .472 1.394l-3.817 3.917.856 5.523a.825.825 0 0 1-1.213.85L67 123.363l-4.59 2.533a.825.825 0 0 1-1.213-.85l.855-5.523-3.817-3.917a.826.826 0 0 1 .472-1.394l5.2-.758 2.347-4.981Z"
          clipRule="evenodd"
        />
      </g>
      <path
        stroke="#BD9E1E"
        strokeLinecap="round"
        strokeWidth={4}
        d="M80 91c-6.422-13.062-19.46-22-34.5-22S17.422 77.938 11 91"
        opacity={0.2}
        style={{
          mixBlendMode: "multiply",
        }}
      />
      <defs>
        <linearGradient
          id="badge-gold-a"
          x1={48.5}
          x2={46}
          y1={79}
          y2={138}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F5D114" />
          <stop offset={1} stopColor="#D0990D" />
        </linearGradient>
        <linearGradient
          id="badge-gold-b"
          x1={16.007}
          x2={78.68}
          y1={76.48}
          y2={141.787}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFC712" />
          <stop offset={1} stopColor="#E09D02" />
        </linearGradient>
        <linearGradient
          id="badge-gold-c"
          x1={18.247}
          x2={21.18}
          y1={1.44}
          y2={20.033}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFC712" />
          <stop offset={1} stopColor="#E09D02" />
        </linearGradient>
        <linearGradient
          id="badge-gold-e"
          x1={46}
          x2={46}
          y1={82}
          y2={108}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EBD467" />
          <stop offset={1} stopColor="#F0C914" />
        </linearGradient>
        <linearGradient
          id="badge-gold-g"
          x1={25}
          x2={25}
          y1={108}
          y2={126}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EBD467" />
          <stop offset={1} stopColor="#F0C914" />
        </linearGradient>
        <linearGradient
          id="badge-gold-i"
          x1={67}
          x2={67}
          y1={108}
          y2={126}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EBD467" />
          <stop offset={1} stopColor="#F0C914" />
        </linearGradient>
        <filter
          id="badge-gold-d"
          width={26}
          height={28}
          x={33}
          y={82}
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy={2} />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_33360_57429"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_33360_57429"
            result="shape"
          />
        </filter>
        <filter
          id="badge-gold-f"
          width={18}
          height={20}
          x={16}
          y={108}
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy={2} />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_33360_57429"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_33360_57429"
            result="shape"
          />
        </filter>
        <filter
          id="badge-gold-h"
          width={18}
          height={20}
          x={58}
          y={108}
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy={2} />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_33360_57429"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_33360_57429"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  ),
  Silver: ({ size }) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size * (152 / 91)}
      fill="none"
      viewBox="0 0 91 155"
    >
      <path
        fill="#C4C4C4"
        d="M14.91 11.928h61.63v27.557a4.97 4.97 0 0 1-2.986 4.557L47.71 55.297a4.97 4.97 0 0 1-3.969 0L17.896 44.042a4.97 4.97 0 0 1-2.985-4.557V11.928Z"
      />
      <path
        fill="#6466EB"
        d="M14.91 11.929h61.628v27.557a4.97 4.97 0 0 1-2.985 4.557L47.709 55.298a4.97 4.97 0 0 1-3.969 0L17.896 44.043a4.97 4.97 0 0 1-2.986-4.557V11.93Z"
      />
      <path
        fill="#fff"
        d="M30.814 11.929h29.82V49.7L47.7 55.306a4.97 4.97 0 0 1-3.952 0L30.814 49.7V11.929Z"
      />
      <path
        fill="#2B2165"
        d="M53.676 11.929H37.772v40.787l5.976 2.59a4.97 4.97 0 0 0 3.952 0l5.976-2.59V11.93Z"
      />
      <circle cx={45.227} cy={105.86} r={45.227} fill="url(#badge-silver-a)" />
      <circle cx={45.227} cy={105.863} r={39.263} fill="url(#badge-silver-b)" />
      <rect
        width={72.563}
        height={11.928}
        x={8.946}
        fill="url(#badge-silver-c)"
        rx={5.964}
      />
      <path
        fill="#000"
        fillOpacity={0.2}
        d="M14.911 11.928h61.628v4.97H14.911z"
      />
      <rect
        width={22.862}
        height={3.976}
        x={51.688}
        y={1.988}
        fill="#fff"
        opacity={0.8}
        rx={1.988}
      />
      <g filter="url(#badge-silver-d)">
        <path
          fill="url(#badge-silver-e)"
          fillRule="evenodd"
          d="M27.92 95.999a1.001 1.001 0 0 1 1.812 0l2.851 6.051 6.319.921a1.003 1.003 0 0 1 .572 1.693l-4.637 4.759 1.04 6.71a1.002 1.002 0 0 1-1.474 1.032l-5.577-3.077-5.577 3.077a1.002 1.002 0 0 1-1.473-1.032l1.04-6.71-4.638-4.759a1.003 1.003 0 0 1 .573-1.693l6.318-.921 2.851-6.051Z"
          clipRule="evenodd"
        />
      </g>
      <g filter="url(#badge-silver-f)">
        <path
          fill="url(#badge-silver-g)"
          fillRule="evenodd"
          d="M61.716 95.999a1.001 1.001 0 0 1 1.812 0l2.85 6.051 6.32.921a1.003 1.003 0 0 1 .572 1.693l-4.637 4.759 1.04 6.71a1.002 1.002 0 0 1-1.474 1.032l-5.577-3.077-5.577 3.077a1.002 1.002 0 0 1-1.474-1.032l1.04-6.71-4.637-4.759a1.003 1.003 0 0 1 .573-1.693l6.318-.921 2.851-6.051Z"
          clipRule="evenodd"
        />
      </g>
      <path
        stroke="#282424"
        strokeLinecap="round"
        strokeWidth={3.976}
        d="M79.521 90.453C73.138 77.47 60.178 68.585 45.228 68.585s-27.91 8.885-34.293 21.868"
        opacity={0.1}
      />
      <defs>
        <linearGradient
          id="badge-silver-a"
          x1={45.724}
          x2={45.724}
          y1={151.087}
          y2={60.633}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A1A1A1" />
          <stop offset={1} stopColor="#D5D5D5" />
        </linearGradient>
        <linearGradient
          id="badge-silver-b"
          x1={45.227}
          x2={45.227}
          y1={66.6}
          y2={145.126}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A1A1A1" />
          <stop offset={1} stopColor="#C4C4C4" />
        </linearGradient>
        <linearGradient
          id="badge-silver-c"
          x1={45.626}
          x2={45.626}
          y1={11.928}
          y2={0}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A1A1A1" />
          <stop offset={1} stopColor="#C4C4C4" />
        </linearGradient>
        <linearGradient
          id="badge-silver-e"
          x1={28.826}
          x2={28.826}
          y1={95.424}
          y2={117.292}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D6D6D6" />
          <stop offset={1} stopColor="#C2C2C2" />
        </linearGradient>
        <linearGradient
          id="badge-silver-g"
          x1={62.622}
          x2={62.622}
          y1={95.424}
          y2={117.292}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D6D6D6" />
          <stop offset={1} stopColor="#C2C2C2" />
        </linearGradient>
        <filter
          id="badge-silver-d"
          width={21.868}
          height={23.856}
          x={17.892}
          y={95.424}
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy={1.988} />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_33360_57431"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_33360_57431"
            result="shape"
          />
        </filter>
        <filter
          id="badge-silver-f"
          width={21.868}
          height={23.856}
          x={51.688}
          y={95.424}
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy={1.988} />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_33360_57431"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_33360_57431"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  ),
  Bronze: ({ size }) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size * (152 / 91)}
      fill="none"
      viewBox="0 0 91 155"
    >
      <path
        fill="#C4C4C4"
        d="M15 12h62v27.724a5 5 0 0 1-3.004 4.584l-26 11.323a5 5 0 0 1-3.992 0l-26-11.323A5 5 0 0 1 15 39.724V12Z"
      />
      <path
        fill="#6466EB"
        d="M15 12h62v27.724a5 5 0 0 1-3.004 4.584l-26 11.323a5 5 0 0 1-3.992 0l-26-11.323A5 5 0 0 1 15 39.724V12Z"
      />
      <path
        fill="#fff"
        d="M31 12h30v38l-13.012 5.639a5 5 0 0 1-3.976 0L31 50V12Z"
      />
      <path
        fill="#2B2165"
        d="M54 12H38v41.033l6.012 2.605a5 5 0 0 0 3.976 0L54 53.034V12Z"
      />
      <rect width={73} height={12} x={9} fill="url(#badge-bronze-a)" rx={6} />
      <path fill="#000" fillOpacity={0.2} d="M15 12h62v5H15z" />
      <rect
        width={23}
        height={4}
        x={52}
        y={2}
        fill="#fff"
        opacity={0.8}
        rx={2}
      />
      <circle cx={45.5} cy={106.5} r={45.5} fill="url(#badge-bronze-b)" />
      <circle cx={45.5} cy={106.5} r={39.5} fill="url(#badge-bronze-c)" />
      <g filter="url(#badge-bronze-d)">
        <path
          fill="url(#badge-bronze-e)"
          fillRule="evenodd"
          d="M45.089 96.579a1.007 1.007 0 0 1 1.822 0l2.868 6.088 6.357.925a1.01 1.01 0 0 1 .576 1.704l-4.665 4.788 1.046 6.75a1.008 1.008 0 0 1-1.482 1.038L46 114.777l-5.61 3.095a1.008 1.008 0 0 1-1.483-1.038l1.046-6.75-4.665-4.788a1.01 1.01 0 0 1 .576-1.704l6.357-.925 2.868-6.088Z"
          clipRule="evenodd"
        />
      </g>
      <path
        stroke="#9F3409"
        strokeLinecap="round"
        strokeWidth={4}
        d="M80 91c-6.422-13.062-19.46-22-34.5-22S17.422 77.938 11 91"
        opacity={0.2}
      />
      <defs>
        <linearGradient
          id="badge-bronze-a"
          x1={45.5}
          x2={45.5}
          y1={0}
          y2={12}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D2673B" />
          <stop offset={1} stopColor="#EF905B" />
        </linearGradient>
        <linearGradient
          id="badge-bronze-b"
          x1={46}
          x2={46}
          y1={152}
          y2={61}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D56B3E" />
          <stop offset={1} stopColor="#EF905B" />
        </linearGradient>
        <linearGradient
          id="badge-bronze-c"
          x1={46}
          x2={46}
          y1={72}
          y2={153}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D2673B" />
          <stop offset={1} stopColor="#EF905B" />
        </linearGradient>
        <linearGradient
          id="badge-bronze-e"
          x1={46.121}
          x2={46.121}
          y1={118}
          y2={96}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E09B7E" />
          <stop offset={1} stopColor="#EF905B" />
        </linearGradient>
        <filter
          id="badge-bronze-d"
          width={22}
          height={24}
          x={35}
          y={96}
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy={2} />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_33360_57477"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_33360_57477"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  ),
  Gray: ({ size }) => (
    <svg
      width={size}
      height={size * (88 / 50)}
      viewBox="0 0 50 88"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.3">
        <path
          d="M9.94739 14.4375H40.7299V28.2022C40.7299 29.19 40.1442 30.0838 39.2386 30.4782L26.3298 36.0997C25.6977 36.375 24.9796 36.375 24.3475 36.0997L11.4387 30.4782C10.533 30.0838 9.94739 29.19 9.94739 28.2022V14.4375Z"
          fill="#C4C4C4"
        />
        <path
          d="M9.94739 14.4297H40.7299V28.1944C40.7299 29.1822 40.1442 30.076 39.2386 30.4704L26.3298 36.0919C25.6977 36.3672 24.9796 36.3672 24.3475 36.0919L11.4387 30.4704C10.533 30.076 9.94739 29.1822 9.94739 28.1944V14.4297Z"
          fill="#6466EB"
        />
        <path
          d="M17.8912 14.4297H32.786V33.2964L26.3257 36.0958C25.6959 36.3687 24.9813 36.3687 24.3516 36.0958L17.8912 33.2964V14.4297Z"
          fill="white"
        />
        <path
          d="M29.3106 14.4297H21.3667V34.8024L24.3516 36.0958C24.9813 36.3687 25.6959 36.3687 26.3257 36.0958L29.3106 34.8024V14.4297Z"
          fill="#2B2165"
        />
        <rect
          x="6.96838"
          y="8.47656"
          width="36.2439"
          height="5.9579"
          rx="2.97895"
          fill="url(#paint0_linear_37466_34787)"
        />
        <rect
          x="9.94739"
          y="14.4375"
          width="30.7825"
          height="2.48246"
          fill="black"
          fill-opacity="0.2"
        />
        <rect
          opacity="0.8"
          x="28.3175"
          y="9.46875"
          width="11.4193"
          height="1.98597"
          rx="0.992983"
          fill="white"
        />
        <circle
          cx="25.0904"
          cy="61.356"
          r="22.5904"
          fill="url(#paint1_linear_37466_34787)"
        />
        <circle
          cx="25.0904"
          cy="61.3536"
          r="19.6114"
          fill="url(#paint2_linear_37466_34787)"
        />
        <g filter="url(#filter0_d_37466_34787)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M24.886 56.4279C25.0665 56.0449 25.6107 56.0449 25.7912 56.4279L27.2151 59.4505L30.3711 59.9102C30.7758 59.9691 30.9428 60.4628 30.6572 60.7559L28.3409 63.1332L28.8603 66.4844C28.924 66.8957 28.4883 67.2009 28.1243 67.0001L25.3386 65.4631L22.5529 67.0001C22.1889 67.2009 21.7532 66.8957 21.8169 66.4844L22.3363 63.1332L20.02 60.7559C19.7344 60.4628 19.9015 59.9691 20.3061 59.9102L23.4621 59.4505L24.886 56.4279Z"
            fill="url(#paint3_linear_37466_34787)"
          />
        </g>
        <path
          opacity="0.2"
          d="M42.2193 53.6572C39.0311 47.1721 32.5575 42.7344 25.0904 42.7344C17.6232 42.7344 11.1497 47.1721 7.96143 53.6572"
          stroke="#9F3409"
          stroke-width="1.98597"
          stroke-linecap="round"
        />
      </g>
      {/* <g style='mix-blend-mode:color'>
        <rect y='0.476562' width='50' height='87' fill='#F4F6FC' />
      </g> */}
      <defs>
        <filter
          id="filter0_d_37466_34787"
          x="19.8772"
          y="56.1406"
          width="10.9228"
          height="11.9149"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="0.992983" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_37466_34787"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_37466_34787"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_37466_34787"
          x1="25.0903"
          y1="8.47656"
          x2="25.0903"
          y2="14.4345"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#D2673B" />
          <stop offset="1" stop-color="#EF905B" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_37466_34787"
          x1="25.3386"
          y1="83.9464"
          x2="25.3386"
          y2="38.7656"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#D56B3E" />
          <stop offset="1" stop-color="#EF905B" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_37466_34787"
          x1="25.3387"
          y1="44.2246"
          x2="25.3387"
          y2="84.4405"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#D2673B" />
          <stop offset="1" stop-color="#EF905B" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_37466_34787"
          x1="25.3986"
          y1="67.0634"
          x2="25.3986"
          y2="56.1406"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#E09B7E" />
          <stop offset="1" stop-color="#EF905B" />
        </linearGradient>
      </defs>
    </svg>
  ),
};

const circleIcons = {
  null: ({ size }) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 32 32"
      fill="none"
    >
      <path
        fill="#ccc"
        fillRule="evenodd"
        d="M28.73 17.542a14.011 14.011 0 0 0 .27-2.753 14.174 14.174 0 0 0-.27-2.752 13.914 13.914 0 0 0-.374-1.458 3 3 0 0 0-2.4 3.21 11.178 11.178 0 0 1 0 2 3 3 0 0 0 2.4 3.21c.15-.475.275-.962.373-1.457Zm-1.31 3.714a3 3 0 0 0-3.969.574 10.92 10.92 0 0 1-1.41 1.41 3 3 0 0 0-.574 3.97 14 14 0 0 0 2.496-1.666 14.09 14.09 0 0 0 3.457-4.288Zm-8.21 6.889a3 3 0 0 0-3.21-2.4 11.174 11.174 0 0 1-2 0 3 3 0 0 0-3.21 2.4 13.918 13.918 0 0 0 4.21.644 14.171 14.171 0 0 0 2.753-.27c.495-.1.982-.224 1.457-.374Zm-10.677-.936a3 3 0 0 0-.574-3.968 11.09 11.09 0 0 1-1.41-1.41 3 3 0 0 0-3.969-.575 13.986 13.986 0 0 0 1.665 2.496 14.092 14.092 0 0 0 4.288 3.457ZM1.644 19a3 3 0 0 0 2.4-3.21 11.178 11.178 0 0 1 0-2 3 3 0 0 0-2.4-3.21A13.896 13.896 0 0 0 1 14.789a14.18 14.18 0 0 0 .27 2.753c.1.495.224.982.374 1.457ZM2.58 8.322a3 3 0 0 0 3.968-.574c.427-.512.9-.984 1.41-1.41a3 3 0 0 0 .575-3.969 13.988 13.988 0 0 0-2.496 1.665A14.086 14.086 0 0 0 2.58 8.322Zm8.21-6.889a3 3 0 0 0 3.21 2.4 11.178 11.178 0 0 1 2 0 3 3 0 0 0 3.21-2.4A13.903 13.903 0 0 0 15 .79a14.177 14.177 0 0 0-2.753.27c-.495.1-.982.224-1.457.374Zm10.677.936a3 3 0 0 0 .574 3.969c.512.426.984.898 1.41 1.41a3 3 0 0 0 3.969.574 14.002 14.002 0 0 0-3.457-4.288 14.059 14.059 0 0 0-2.496-1.665Z"
        clipRule="evenodd"
      />
    </svg>
  ),
  Gold: ({ size }) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 32 32"
      fill="none"
    >
      <circle cx={15} cy={15} r={15} fill="url(#circle-gold-a)" />
      <circle cx={15.03} cy={15.03} r={13} fill="url(#circle-gold-b)" />
      <g filter="url(#circle-gold-c)">
        <path
          fill="url(#circle-gold-d)"
          fillRule="evenodd"
          d="M15.199 7.337a.403.403 0 0 1 .729 0l1.147 2.434 2.542.37c.325.048.46.445.23.681l-1.865 1.915.418 2.7a.403.403 0 0 1-.593.415l-2.243-1.238-2.244 1.237a.403.403 0 0 1-.593-.415l.418-2.699-1.865-1.915a.404.404 0 0 1 .23-.68l2.542-.37L15.2 7.336Z"
          clipRule="evenodd"
        />
      </g>
      <g filter="url(#circle-gold-e)">
        <path
          fill="url(#circle-gold-f)"
          fillRule="evenodd"
          d="M8.206 16.064a.279.279 0 0 1 .505 0l.794 1.685 1.76.256a.28.28 0 0 1 .16.472l-1.292 1.325.29 1.869a.28.28 0 0 1-.411.287l-1.553-.857-1.554.857a.279.279 0 0 1-.41-.287l.29-1.869-1.292-1.325a.28.28 0 0 1 .16-.472l1.76-.256.793-1.685Z"
          clipRule="evenodd"
        />
      </g>
      <g filter="url(#circle-gold-g)">
        <path
          fill="url(#circle-gold-h)"
          fillRule="evenodd"
          d="M22.417 16.064a.279.279 0 0 1 .505 0l.793 1.685 1.76.256a.28.28 0 0 1 .16.472l-1.292 1.325.29 1.869a.279.279 0 0 1-.41.287l-1.554-.857-1.553.857a.279.279 0 0 1-.41-.287l.29-1.869-1.292-1.325a.28.28 0 0 1 .16-.472l1.759-.256.794-1.685Z"
          clipRule="evenodd"
        />
      </g>
      <path
        stroke="#BD9E1E"
        strokeLinecap="round"
        strokeWidth={1.353}
        d="M27.067 10.15c-2.172-4.419-6.584-7.443-11.672-7.443-5.09 0-9.5 3.024-11.673 7.444"
        opacity={0.2}
        style={{
          mixBlendMode: "multiply",
        }}
      />
      <defs>
        <linearGradient
          id="circle-gold-a"
          x1={15.989}
          x2={15.165}
          y1={5.934}
          y2={25.385}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F5D114" />
          <stop offset={1} stopColor="#D0990D" />
        </linearGradient>
        <linearGradient
          id="circle-gold-b"
          x1={5.323}
          x2={25.95}
          y1={5.15}
          y2={26.644}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFC712" />
          <stop offset={1} stopColor="#E09D02" />
        </linearGradient>
        <linearGradient
          id="circle-gold-d"
          x1={15.563}
          x2={15.563}
          y1={7.105}
          y2={15.902}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFEC93" />
          <stop offset={1} stopColor="#FFE670" />
        </linearGradient>
        <linearGradient
          id="circle-gold-f"
          x1={8.459}
          x2={8.459}
          y1={15.903}
          y2={21.994}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFEC93" />
          <stop offset={1} stopColor="#FFE670" />
        </linearGradient>
        <linearGradient
          id="circle-gold-h"
          x1={22.669}
          x2={22.669}
          y1={15.903}
          y2={21.994}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFEC93" />
          <stop offset={1} stopColor="#FFE670" />
        </linearGradient>
        <filter
          id="circle-gold-c"
          width={8.797}
          height={9.474}
          x={11.165}
          y={7.105}
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy={0.677} />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_33331_55575"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_33331_55575"
            result="shape"
          />
        </filter>
        <filter
          id="circle-gold-e"
          width={6.09}
          height={6.767}
          x={5.414}
          y={15.903}
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy={0.677} />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_33331_55575"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_33331_55575"
            result="shape"
          />
        </filter>
        <filter
          id="circle-gold-g"
          width={6.09}
          height={6.767}
          x={19.624}
          y={15.903}
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy={0.677} />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_33331_55575"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_33331_55575"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  ),
  Silver: ({ size }) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 32 32"
    >
      <circle cx={15} cy={15.263} r={15} fill="url(#circle-silver-a)" />
      <circle cx={15} cy={15.262} r={13.022} fill="url(#circle-silver-b)" />
      <g filter="url(#circle-silver-c)">
        <path
          fill="url(#circle-silver-d)"
          fillRule="evenodd"
          d="M9.778 10.805a.388.388 0 0 1 .702 0l1.104 2.344 2.448.357a.389.389 0 0 1 .222.656l-1.796 1.844.402 2.599a.388.388 0 0 1-.57.4l-2.161-1.192-2.16 1.192a.388.388 0 0 1-.571-.4l.402-2.6-1.796-1.843a.389.389 0 0 1 .222-.656l2.448-.357 1.104-2.344Z"
          clipRule="evenodd"
        />
      </g>
      <g filter="url(#circle-silver-e)">
        <path
          fill="url(#circle-silver-f)"
          fillRule="evenodd"
          d="M20.752 10.805a.388.388 0 0 1 .702 0l1.104 2.344 2.448.357a.389.389 0 0 1 .222.656l-1.796 1.844.402 2.599a.388.388 0 0 1-.57.4l-2.161-1.192-2.16 1.192a.388.388 0 0 1-.571-.4l.402-2.6-1.796-1.843a.389.389 0 0 1 .222-.656l2.448-.357 1.104-2.344Z"
          clipRule="evenodd"
        />
      </g>
      <path
        stroke="#282424"
        strokeLinecap="round"
        strokeWidth={1.319}
        d="M26.374 10.153C24.257 5.847 19.958 2.9 15 2.9S5.743 5.847 3.626 10.153"
        opacity={0.1}
      />
      <defs>
        <linearGradient
          id="circle-silver-a"
          x1={15.165}
          x2={15.165}
          y1={30.263}
          y2={0.263}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D3D3D3" />
          <stop offset={1} stopColor="#EEE" />
        </linearGradient>
        <linearGradient
          id="circle-silver-b"
          x1={15}
          x2={15}
          y1={2.24}
          y2={28.284}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#C6C6C6" />
          <stop offset={1} stopColor="#EFEFEF" />
        </linearGradient>
        <linearGradient
          id="circle-silver-d"
          x1={10.129}
          x2={10.129}
          y1={10.582}
          y2={19.054}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#fff" />
          <stop offset={1} stopColor="#E9E9E9" />
        </linearGradient>
        <linearGradient
          id="circle-silver-f"
          x1={21.103}
          x2={21.103}
          y1={10.582}
          y2={19.054}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#fff" />
          <stop offset={1} stopColor="#E9E9E9" />
        </linearGradient>
        <filter
          id="circle-silver-c"
          width={8.472}
          height={9.242}
          x={5.893}
          y={10.582}
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy={0.77} />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_33331_55513"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_33331_55513"
            result="shape"
          />
        </filter>
        <filter
          id="circle-silver-e"
          width={8.472}
          height={9.242}
          x={16.867}
          y={10.582}
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy={0.77} />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_33331_55513"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_33331_55513"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  ),
  Bronze: ({ size }) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 32 32"
      fill="none"
    >
      <circle cx={15} cy={15.264} r={15} fill="url(#circle-bronze-a)" />
      <circle cx={15} cy={15.263} r={13.022} fill="url(#circle-bronze-b)" />
      <g filter="url(#circle-bronze-c)">
        <path
          fill="url(#circle-bronze-d)"
          fillRule="evenodd"
          d="M14.864 11.993a.332.332 0 0 1 .601 0l.945 2.008 2.096.305c.269.039.38.367.19.561l-1.538 1.579.345 2.225a.332.332 0 0 1-.489.342l-1.85-1.02-1.85 1.02a.332.332 0 0 1-.488-.342l.345-2.225-1.538-1.579a.333.333 0 0 1 .19-.561L13.918 14l.946-2.008Z"
          clipRule="evenodd"
        />
      </g>
      <path
        stroke="#9F3409"
        strokeLinecap="round"
        strokeWidth={1.319}
        d="M26.374 10.154C24.257 5.848 19.958 2.901 15 2.901S5.743 5.848 3.626 10.154"
        opacity={0.2}
      />
      <defs>
        <linearGradient
          id="circle-bronze-a"
          x1={15.165}
          x2={15.165}
          y1={30.264}
          y2={0.264}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D56B3E" />
          <stop offset={1} stopColor="#EF905B" />
        </linearGradient>
        <linearGradient
          id="circle-bronze-b"
          x1={15.165}
          x2={15.165}
          y1={3.89}
          y2={30.593}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D2673B" />
          <stop offset={1} stopColor="#EF905B" />
        </linearGradient>
        <linearGradient
          id="circle-bronze-d"
          x1={15.204}
          x2={15.204}
          y1={19.055}
          y2={11.803}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E09B7E" />
          <stop offset={1} stopColor="#EF905B" />
        </linearGradient>
        <filter
          id="circle-bronze-c"
          width={7.253}
          height={7.912}
          x={11.538}
          y={11.803}
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy={0.659} />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_33331_55534"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_33331_55534"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  ),
};
