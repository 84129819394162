import { HEADING_FONT, MAINTEXT_COLOR } from "@/css/constants";

export const TITLE_CSS = {
  color: MAINTEXT_COLOR,
  fontSize: 24,
  fontFamily: HEADING_FONT,
  fontWeight: 600,
  minWidth: 130,
  margin: "16px 0",
};
