import { FormGroup } from "@blueprintjs/core";
import styled from "@emotion/styled";

import { BP_NS } from "@/css/constants";

export const StyledFormGroup = styled(FormGroup)`
  &.${BP_NS}-form-group {
    .${BP_NS}-label {
      font-weight: 600;
    }
    .${BP_NS}-form-group-sub-label {
      margin-bottom: 10px;
    }
  }
`;
