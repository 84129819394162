import { useState } from "react";

import {
  BreadcrumbProps,
  Classes,
  Colors,
  Breadcrumbs,
} from "@blueprintjs/core";
import styled from "@emotion/styled";
import { Emoji, EmojiStyle } from "emoji-picker-react";
import { useMediaQuery } from "react-responsive";
import { Link, useParams } from "react-router-dom";

import { useWorkspace } from "@/components/helpers/custom-hooks/use-workspace";
import { isWorkspaceAdmin } from "@/components/helpers/workspace/permissions";
import { Box, Flex } from "@/components/layout/flexbox";
import { isMobileQuery, Media } from "@/components/layout/Media";
import { AddToCollectionDialog } from "@/components/pages_logged_in/roleplays/AddToCollectionDialog";
import CreateCollectionDialog from "@/components/pages_logged_in/roleplays/CreateCollectionDialog";
import { RoleplayPageType } from "@/components/pages_logged_in/roleplays/details/roleplay-types";
import { EditScenarioDrawer } from "@/components/pages_logged_in/roleplays/details/scenario/EditScenario";
import ScenarioEditCharacterDialog from "@/components/pages_logged_in/roleplays/details/scenario/ScenarioEditCharacter/ScenarioEditCharacterDialog";
import { RoleplayModals } from "@/components/pages_logged_in/roleplays/RoleplayDetails";
import {
  buildRoleplayUrl,
  buildScenarioUrl,
  buildSessionUrl,
} from "@/components/pages_logged_in/roleplays/utils";
import { Container } from "@/components/pieces/bootstrap";
import { DateTimeDisplay } from "@/components/pieces/DateTimeDisplay";
import CloneCollectionDialog from "@/components/pieces/roleplays/CloneCollectionDialog";
import CloneScenarioDialog from "@/components/pieces/roleplays/CloneScenarioDialog";
import RoleplayDeleteConfirm from "@/components/pieces/roleplays/RoleplayDeleteConfirm";
import RoleplayLimitBanner from "@/components/pieces/roleplays/RoleplayLimitBanner";
import RoleplayMenu from "@/components/pieces/roleplays/RoleplayMenu";
import RoleplaySessionMenu from "@/components/pieces/roleplays/RoleplaySessionMenu";
import ScenarioDeleteConfirm from "@/components/pieces/roleplays/ScenarioDeleteConfirm";
import ScenarioMenu, {
  ScenarioModals,
} from "@/components/pieces/roleplays/ScenarioMenu";
import { getUser } from "@/components/session/JwtTokenManager";
import { MIDTEXT_COLOR, SUBTEXT_COLOR } from "@/css/constants";
import { RoleplayType, ScenarioType, VisibilityScope } from "@/graphql/index";

import { UpdateVisibilityButton } from "./UpdateVisibilityButton";

function useBreadcrumbs({ page, roleplay, scenario }) {
  const { roleplaySlug } = useParams();

  const roleplayText = roleplay?.name || "Loading";

  const roleplayUrl = roleplay?.slug ? buildRoleplayUrl(roleplay.slug) : "#";

  if (page === RoleplayPageType.RoleplayDetail) {
    return [
      { href: "/roleplays", text: "Roleplay Dashboard" },
      { text: "Collection Overview" },
    ];
  }
  if (page === RoleplayPageType.ScenarioDetail) {
    const breadcrumbs: any = [];
    if (roleplaySlug) {
      breadcrumbs.push({ href: "/roleplays", text: "Roleplay Dashboard" });
      breadcrumbs.push({
        href: roleplayUrl,
        text: roleplayText,
      });
    } else {
      breadcrumbs.push({ href: "/roleplays", text: "Roleplay Dashboard" });
    }
    breadcrumbs.push({ text: scenario?.name || "Loading" });
    return breadcrumbs;
  }

  if (page === RoleplayPageType.SessionDetail) {
    const user = getUser();
    const isScenarioOwner = scenario?.owner?.id === user?.id;
    const couldAccessScenarios =
      isScenarioOwner ||
      (scenario?.visibilityScope === VisibilityScope.User && isScenarioOwner) ||
      (scenario?.visibilityScope === VisibilityScope.Workspace &&
        user?.workspaces) ||
      scenario?.visibilityScope === VisibilityScope.Global;
    const baseBreadcrumbs = [
      {
        href: couldAccessScenarios
          ? buildScenarioUrl(roleplay?.slug, scenario?.slug)
          : null,
        text: scenario?.name || "Loading",
      },
      { text: "Session Feedback" },
    ];

    const preRoutes = !roleplaySlug
      ? [
          {
            text: "Scenario Details",
          },
        ]
      : [
          { href: "/roleplays", text: "Roleplay Dashboard" },
          { href: roleplayUrl, text: roleplayText },
        ];

    return [...preRoutes, ...baseBreadcrumbs];
  }
  if (page === RoleplayPageType.ScenarioCreate) {
    const items = [
      { href: "/roleplays", text: "Roleplay Dashboard" },
      { text: "Create Scenario" },
    ];
    if (roleplay) {
      items.splice(1, 0, {
        href: roleplayUrl,
        text: roleplayText,
      });
    }
    return items;
  }
  if (page === RoleplayPageType.IntakeProcessing) {
    return [
      { href: "/roleplays", text: "Roleplay Dashboard" },
      {
        href: roleplayUrl,
        text: roleplayText,
      },
      { text: "Generating" },
    ];
  }
}

function HeaderTitle({ page, roleplay, scenario, roleplaySession }) {
  if (page === RoleplayPageType.RoleplayDetail) {
    return (
      <Link className="color-inherit" to={buildRoleplayUrl(roleplay?.slug)}>
        {roleplay?.name || "Loading..."}
      </Link>
    );
  }
  if (page === RoleplayPageType.ScenarioDetail) {
    const text = scenario?.name || "Loading...";
    return roleplay ? (
      <Link
        className="color-inherit"
        to={buildScenarioUrl(roleplay?.slug, scenario?.slug)}
      >
        {text}
      </Link>
    ) : (
      <Box>{text}</Box>
    );
  }
  if (page === RoleplayPageType.SessionDetail) {
    return (
      <Link
        className="color-inherit"
        to={buildSessionUrl(
          roleplay?.slug,
          scenario?.slug,
          roleplaySession?.uuid,
        )}
      >
        {scenario?.name || "Loading..."}
      </Link>
    );
  }
  if (page === RoleplayPageType.ScenarioCreate) {
    return "Create Scenario";
  }
  if (page === RoleplayPageType.IntakeProcessing) {
    return "Generating Scenario";
  }
  return null;
}

function Subheader({ page, roleplaySession }) {
  if (page === RoleplayPageType.SessionDetail) {
    if (!roleplaySession) {
      return (
        <Box mt={2} className={Classes.SKELETON} height={22} width={300} />
      );
    }
    return (
      <Box mt={2} color={MIDTEXT_COLOR}>
        Session on{" "}
        <DateTimeDisplay
          time={roleplaySession?.createdAt}
          formatString="MMM Do, YYYY [at] h:mm A"
        />
      </Box>
    );
  }

  return null;
}

const shouldHideMenu = (item: { visibilityScope: VisibilityScope }) => {
  return !item;
};

function RoleplayDetailMenu({ roleplay }: { roleplay?: RoleplayType }) {
  const [currentActiveModal, setCurrentActiveModal] =
    useState<RoleplayModals | null>(null);
  const currentWorkspace = useWorkspace();

  if (
    shouldHideMenu({
      visibilityScope: roleplay?.visibilityScope,
    })
  ) {
    return <Box />;
  }

  const canUpdateVisibilityScope =
    roleplay?.workspace?.id === currentWorkspace?.id &&
    isWorkspaceAdmin() &&
    roleplay?.visibilityScope !== VisibilityScope.Global;

  return (
    <Flex>
      {canUpdateVisibilityScope && roleplay && (
        <>
          <UpdateVisibilityButton
            owner={roleplay.owner}
            visibilityScope={roleplay.visibilityScope}
            id={roleplay.id}
            slug={roleplay.slug}
            name={roleplay.name}
            type="roleplay"
            workspace={roleplay.workspace}
          />
          <Box ml={3} />
        </>
      )}
      <RoleplayMenu
        roleplay={roleplay}
        onDelete={() => setCurrentActiveModal(RoleplayModals.deleteRoleplay)}
        onEdit={() => setCurrentActiveModal(RoleplayModals.editCollection)}
        canEdit={roleplay?.canEdit}
        onClone={() => setCurrentActiveModal(RoleplayModals.cloneCollection)}
      />
      <CreateCollectionDialog
        isOpen={currentActiveModal === RoleplayModals.editCollection}
        roleplay={roleplay}
        onClose={() => setCurrentActiveModal(null)}
        onFinished={() => {
          setCurrentActiveModal(null);
        }}
      />
      <RoleplayDeleteConfirm
        open={currentActiveModal === RoleplayModals.deleteRoleplay}
        onClose={() => setCurrentActiveModal(null)}
        roleplay={roleplay}
        onFinished={() => {
          setCurrentActiveModal(null);
        }}
      />
      <CloneCollectionDialog
        open={currentActiveModal === RoleplayModals.cloneCollection}
        onClose={() => setCurrentActiveModal(null)}
        collection={roleplay}
      />
    </Flex>
  );
}

export function ScenarioDetailMenu({ scenario }: { scenario?: ScenarioType }) {
  const [currentActiveModal, setCurrentActiveModal] =
    useState<ScenarioModals | null>(null);
  const currentWorkspace = useWorkspace();

  if (
    shouldHideMenu({
      visibilityScope: scenario?.visibilityScope,
    })
  ) {
    return <Box />;
  }

  const canUpdateVisibilityScope =
    scenario?.workspace?.id === currentWorkspace?.id &&
    isWorkspaceAdmin() &&
    scenario?.visibilityScope !== VisibilityScope.Global;

  return (
    <Flex>
      {canUpdateVisibilityScope && scenario && (
        <>
          <UpdateVisibilityButton
            owner={scenario.owner}
            visibilityScope={scenario.visibilityScope}
            id={scenario.id}
            slug={scenario.slug}
            name={scenario.name}
            type="scenario"
            workspace={scenario.workspace}
          />
          <Box ml={3} />
        </>
      )}
      <ScenarioMenu
        scenario={scenario}
        onEditCharacter={() => {
          setCurrentActiveModal(ScenarioModals.editCharacter);
        }}
        onDelete={() => {
          setCurrentActiveModal(ScenarioModals.deleteScenario);
        }}
        onCloneScenario={() => {
          setCurrentActiveModal(ScenarioModals.cloneScenario);
        }}
        onAddToCollection={() => {
          setCurrentActiveModal(ScenarioModals.addToCollection);
        }}
        onEditScenario={() => {
          setCurrentActiveModal(ScenarioModals.editScenario);
        }}
      />
      <ScenarioDeleteConfirm
        open={currentActiveModal === ScenarioModals.deleteScenario}
        scenario={scenario}
        onClose={() => setCurrentActiveModal(null)}
      />
      <ScenarioEditCharacterDialog
        open={currentActiveModal === ScenarioModals.editCharacter}
        scenario={scenario}
        onClose={() => setCurrentActiveModal(null)}
      />
      <CloneScenarioDialog
        open={currentActiveModal === ScenarioModals.cloneScenario}
        scenario={scenario}
        onClose={() => setCurrentActiveModal(null)}
      />
      <AddToCollectionDialog
        open={currentActiveModal === ScenarioModals.addToCollection}
        scenario={scenario}
        onClose={() => setCurrentActiveModal(null)}
      />
      <EditScenarioDrawer
        key={`${scenario?.id}-${currentActiveModal}`}
        open={currentActiveModal === ScenarioModals.editScenario}
        scenario={scenario}
        onClose={() => setCurrentActiveModal(null)}
      />
    </Flex>
  );
}

function PageMenu({ page, roleplay, scenario, roleplaySession }) {
  const pageMenus = {
    [RoleplayPageType.RoleplayDetail]: () => (
      <RoleplayDetailMenu roleplay={roleplay} />
    ),
    [RoleplayPageType.ScenarioDetail]: () => (
      <ScenarioDetailMenu scenario={scenario} />
    ),
    [RoleplayPageType.SessionDetail]: () => (
      <RoleplaySessionMenu
        roleplaySession={roleplaySession}
        showAccessButton={true}
      />
    ),
  };

  return pageMenus[page]?.() ?? null;
}
export default function RoleplayHeader({
  page,
  roleplay = null,
  scenario = null,
  roleplaySession = null,
  loading = false,
  px = 0,
}: {
  page: RoleplayPageType;
  roleplay?: any;
  scenario?: any;
  roleplaySession?: any;
  maxWidth?: number;
  loading?: boolean;
  px?: number;
}) {
  const breadcrumbs: BreadcrumbProps[] = useBreadcrumbs({
    page,
    roleplay,
    scenario,
  });

  function breadcrumbRenderer(props: BreadcrumbProps) {
    if (loading) {
      return <Box className={Classes.SKELETON} height={20} width={83} />;
    }
    return (
      <StyledBreadcrumb title={String(props.text)}>
        {props.href ? <Link to={props.href}>{props.text}</Link> : props.text}
      </StyledBreadcrumb>
    );
  }
  const hasIcon = roleplay?.emojiUnicode || loading;
  const isMobile = useMediaQuery(isMobileQuery);
  return (
    <>
      <Box
        className={page !== RoleplayPageType.RoleplayDetail ? "bbs" : ""}
        py={4}
        bg={Colors.WHITE}
        px={px}
      >
        <Container>
          <Box mb={2}>
            <Breadcrumbs
              items={breadcrumbs}
              breadcrumbRenderer={breadcrumbRenderer}
            />
          </Box>
          <Flex
            alignItems="center"
            justifyContent="space-between"
            flexDirection={isMobile ? "column" : "row"}
          >
            <Flex>
              {hasIcon ? (
                <Media greaterThan="xs">
                  {loading || !roleplay ? (
                    <Box className={Classes.SKELETON} height={33} width={26} />
                  ) : (
                    <Emoji
                      unified={roleplay.emojiUnicode.toLowerCase()}
                      emojiStyle={EmojiStyle.APPLE}
                      size={26}
                    />
                  )}
                </Media>
              ) : null}
              <Box ml={hasIcon ? [0, 3] : 0} width="100%">
                <Box
                  fontWeight={600}
                  fontSize={26}
                  lineHeight={1}
                  className={loading ? Classes.SKELETON : ""}
                  width={loading ? 400 : "auto"}
                  height={loading ? 33 : "auto"}
                >
                  <HeaderTitle
                    page={page}
                    roleplay={roleplay}
                    scenario={scenario}
                    roleplaySession={roleplaySession}
                  />
                </Box>
                <Subheader page={page} roleplaySession={roleplaySession} />
              </Box>
            </Flex>
            <Flex alignSelf={"flex-end"} mt={isMobile ? 3 : 0}>
              <PageMenu
                page={page}
                roleplay={roleplay}
                scenario={scenario}
                roleplaySession={roleplaySession}
              />
            </Flex>
          </Flex>
        </Container>
      </Box>
      {!loading && <RoleplayLimitBanner page={page} />}
    </>
  );
}

const StyledBreadcrumb = styled(Box)`
  max-width: 200px;
  color: ${SUBTEXT_COLOR};
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: inline-block;
  font-size: 14px;

  a {
    font-weight: 600;
    color: ${SUBTEXT_COLOR};
  }
`;
