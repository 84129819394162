export default function NotificationSvg() {
  return (
    <svg
      width="178"
      height="145"
      viewBox="0 0 178 145"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M146.602 42.4202C146.602 65.8483 128.128 84.8404 105.338 84.8404C82.5493 84.8404 64.0751 65.8483 64.0751 42.4202C64.0751 18.9922 82.5493 0 105.338 0C128.128 0 146.602 18.9922 146.602 42.4202Z"
        fill="#FFCFBC"
      />
      <path
        d="M108.038 63.6303C108.038 85.1415 90.7723 102.58 69.4741 102.58C48.1759 102.58 30.9102 85.1415 30.9102 63.6303C30.9102 42.1191 48.1759 24.6809 69.4741 24.6809C90.7723 24.6809 108.038 42.1191 108.038 63.6303Z"
        fill="#FFCFBC"
      />
      <path
        d="M175.91 67.4867C175.91 85.5902 159.163 100.266 138.503 100.266C117.844 100.266 101.096 85.5902 101.096 67.4867C101.096 49.3832 117.844 34.7074 138.503 34.7074C159.163 34.7074 175.91 49.3832 175.91 67.4867Z"
        fill="#FFCFBC"
      />
      <path
        d="M60.2188 80.2128C60.2188 93.8436 49.1688 104.894 35.5379 104.894C21.907 104.894 10.8571 93.8436 10.8571 80.2128C10.8571 66.5819 21.907 55.5319 35.5379 55.5319C49.1688 55.5319 60.2188 66.5819 60.2188 80.2128Z"
        fill="#FFCFBC"
      />
      <path
        d="M177.453 97.5665C177.453 110.132 166.057 120.319 152.001 120.319C137.944 120.319 126.549 110.132 126.549 97.5665C126.549 85.0005 137.944 74.8138 152.001 74.8138C166.057 74.8138 177.453 85.0005 177.453 97.5665Z"
        fill="#FFCFBC"
      />
      <path
        d="M155.857 104.894C155.857 121.08 142.563 134.202 126.163 134.202C109.763 134.202 96.4688 121.08 96.4688 104.894C96.4688 88.707 109.763 75.5851 126.163 75.5851C142.563 75.5851 155.857 88.707 155.857 104.894Z"
        fill="#FFCFBC"
      />
      <path
        d="M129.634 100.652C129.634 125.145 108.57 145 82.5858 145C56.602 145 35.5379 125.145 35.5379 100.652C35.5379 76.1586 56.602 56.3032 82.5858 56.3032C108.57 56.3032 129.634 76.1586 129.634 100.652Z"
        fill="#FFCFBC"
      />
      <rect
        y="82.572"
        width="102.464"
        height="8.82139"
        rx="4.41069"
        fill="white"
      />
      <rect
        x="16.2856"
        y="68.322"
        width="101.785"
        height="8.82139"
        rx="4.41069"
        fill="white"
      />
      <rect
        x="116.035"
        y="48.6436"
        width="57.6783"
        height="8.82139"
        rx="4.41069"
        fill="white"
      />
      <rect
        x="116.035"
        y="34.3936"
        width="48.1784"
        height="8.82139"
        rx="4.41069"
        fill="white"
      />
      <ellipse
        cx="52.8094"
        cy="103.455"
        rx="34.6208"
        ry="32.5843"
        fill="#FFC6B0"
      />
      <ellipse
        cx="120.966"
        cy="50.5057"
        rx="34.6208"
        ry="32.5843"
        fill="#FFC6B0"
      />
      <g filter="url(#filter0_d)">
        <rect
          x="45.0707"
          y="30.1404"
          width="97.7528"
          height="97.7528"
          rx="20"
          fill="#4249DA"
        />
        <rect
          x="46.5707"
          y="31.6404"
          width="94.7528"
          height="94.7528"
          rx="18.5"
          stroke="#3E2424"
          strokeWidth="3"
        />
      </g>
      <rect
        x="52.9285"
        y="38.4646"
        width="81.4607"
        height="81.4607"
        rx="10"
        fill="white"
      />
      <circle cx="93.6425" cy="79.1791" r="31.8927" fill="#F07F4D" />
      <circle cx="93.6423" cy="79.1788" r="25.107" fill="white" />
      <rect
        x="94.9995"
        y="80.5361"
        width="2.71427"
        height="18.9999"
        rx="1.35714"
        transform="rotate(-90 94.9995 80.5361)"
        fill="#FAD03E"
      />
      <rect
        x="92.2853"
        y="60.8577"
        width="2.71427"
        height="17.6428"
        rx="1.35714"
        fill="#FAD03E"
      />
      <circle cx="93.6425" cy="79.1792" r="3.39284" fill="black" />
      <circle cx="109.249" cy="65.6079" r="1.35714" fill="#FFC6B0" />
      <circle cx="78.714" cy="65.6079" r="1.35714" fill="#FFC6B0" />
      <circle cx="109.249" cy="94.1076" r="1.35714" fill="#FFC6B0" />
      <circle cx="78.714" cy="94.1076" r="1.35714" fill="#FFC6B0" />
      <circle cx="116.035" cy="79.1792" r="1.35714" fill="#FFC6B0" />
      <circle cx="72.6068" cy="79.1792" r="1.35714" fill="#FFC6B0" />
      <circle cx="93.6424" cy="100.893" r="1.35714" fill="#FFC6B0" />
      <circle cx="93.6424" cy="58.1435" r="1.35714" fill="#FFC6B0" />
      <defs>
        <filter
          id="filter0_d"
          x="40.0707"
          y="30.1404"
          width="107.753"
          height="107.753"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy="5" />
          <feGaussianBlur stdDeviation="2.5" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
}
