import moment from "moment-timezone";

export function removeSignUpParamsFromLocalStorage() {
  localStorage.removeItem("first");
  localStorage.removeItem("refcode");
  localStorage.removeItem("utm_source");
  localStorage.removeItem("utm_medium");
  localStorage.removeItem("utm_campaign");
  localStorage.removeItem("utm_term");
  localStorage.removeItem("utm_content");
  localStorage.removeItem("gclid");
  localStorage.removeItem("ws");
  localStorage.removeItem("coach");
}

export function getSignUpParams(
  email: string,
  name?: string,
  password?: string,
  giftCode?: string,
  asWorkspaceAdmin?: boolean,
): any {
  const params = {
    email,
    name,
    password,
    giftCode,
    returnToken: true,
    timeZone: moment.tz.guess(),
  };

  if (import.meta.env.SSR) {
    return params;
  }

  return {
    ...params,
    firstPage: localStorage.getItem("first"),
    refcode: localStorage.getItem("refcode"),
    utmSource: localStorage.getItem("utm_source"),
    utmMedium: localStorage.getItem("utm_medium"),
    utmCampaign: localStorage.getItem("utm_campaign"),
    utmTerm: localStorage.getItem("utm_term"),
    utmContent: localStorage.getItem("utm_content"),
    gclid: localStorage.getItem("gclid"),
    wsToken: localStorage.getItem("ws"),
    coach: localStorage.getItem("coach"),
    forcePlacementPricing: localStorage.getItem("forcePlacementPricing"),
    asWorkspaceAdmin: asWorkspaceAdmin,
    workspaceSignedToken: localStorage.getItem("workspaceSignedToken"),
  };
}
