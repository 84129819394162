import { useQuery } from "@apollo/client";
import orderBy from "lodash/orderBy";

import { Box } from "@/components/layout/flexbox";
import { RelatedArticleSeriesSSR } from "@/components/pages_flexible/article/SeoRelatedSeries";
import { ArticleSeriesByArticleQueryDocument } from "@/graphql";

export default function RelatedArticlesList({
  articleSlug,
  hasVisualSpace = false,
  primarySeries = null,
  ssrData,
}) {
  const { data } = useQuery(ArticleSeriesByArticleQueryDocument, {
    variables: {
      articleSlug,
    },
  });

  if (!data) {
    return null;
  }

  let articleSeriesList = data.articleSeriesByArticle || [];
  const primarySeriesSlug = primarySeries?.urlSlug;
  const existingPrimarySeries = articleSeriesList.find(
    ({ urlSlug }) => urlSlug === primarySeriesSlug,
  );

  if (primarySeriesSlug && existingPrimarySeries) {
    articleSeriesList = orderBy(
      articleSeriesList,
      ({ urlSlug }) => urlSlug === primarySeriesSlug,
      "desc",
    );
  }
  return (
    <Box mt={hasVisualSpace ? 5 : 0}>
      <RelatedArticleSeriesSSR
        {...ssrData}
        relatedArticleSeries={articleSeriesList}
        excludeArticleSlug={articleSlug}
      />
    </Box>
  );
}
