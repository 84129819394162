import { Classes, Colors } from "@blueprintjs/core";
import nameInitials from "name-initials";

import LazyImg from "@/components/helpers/ui/LazyImg";
import { Box, Flex } from "@/components/layout/flexbox";

export default function Avatar({
  user,
  size = 32,
  backgroundColor = Colors.WHITE,
  color = Colors.BLACK,
  borderColor = "#ccc",
  loading = false,
  defaultText = null,
  height = null,
}) {
  if (loading) {
    return (
      <Box
        width={size}
        height={size}
        style={{
          borderRadius: "100%",
        }}
        className={Classes.SKELETON}
      />
    );
  }

  return (
    <>
      {user?.photo ? (
        <LazyImg
          style={{
            borderRadius: "100%",
          }}
          width={size}
          height={height ? height : size}
          src={user?.photo}
        />
      ) : (
        <Flex
          className="bas text-center"
          width={size}
          height={size}
          alignItems="center"
          justifyContent="center"
          style={{
            borderRadius: "100%",
            borderColor,
            backgroundColor,
            color,
            minWidth: size,
            fontSize: size / 2,
          }}
        >
          {defaultText || nameInitials(user?.name || user?.email || "")}
        </Flex>
      )}
    </>
  );
}
