import defaultCompanyLogo from "@/../../hypetrain/static/img/default-company-logo.svg";
import findIndex from "lodash/findIndex";
import pick from "lodash/pick";

const toWork = (work) => {
  return pick(work, ["id", "startDate", "jobTitles", "endDate"]);
};

const toWorkGroup = (company, work) => ({
  name: company.name,
  logo: company.logoUrl || defaultCompanyLogo,
  id: company.id,
  work: [toWork(work)],
});

const toEmptyCompanyWorkGroup = (work) => ({
  name: work.emptyCompanyLabel,
  logo: defaultCompanyLogo,
  id: null,
  work: [toWork(work)],
});

export function mappingGroupedWorkHistories(workHistories = []) {
  let workGroup = [];
  workHistories.forEach((work) => {
    if (work.company) {
      const index = findIndex(
        workGroup,
        (company) => company.id === work.company.id,
      );
      if (index === -1) {
        workGroup.push(toWorkGroup(work.company, work));
      } else {
        workGroup[index].work.push(toWork(work));
      }
    } else {
      const index = findIndex(
        workGroup,
        (company) => company.name === work.emptyCompanyLabel,
      );
      if (index === -1) {
        workGroup.push(toEmptyCompanyWorkGroup(work));
      } else {
        workGroup[index].work.push(toWork(work));
      }
    }
  });
  workGroup.forEach((workGroup) => {
    workGroup.work.sort((a, b) => {
      if (a.startDate && !b.startDate) {
        return -1;
      } else if (!a.startDate && b.startDate) {
        return 1;
      } else if (a.id > b.id) {
        return 1;
      } else if (a.id < b.id) {
        return -1;
      }
      return 0;
    });
  });
  return workGroup;
}
