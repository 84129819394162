import { useCurrentUser } from "@/components/helpers/custom-hooks/use-current-user";
import { SizeBreakpoint } from "@/components/layout/Media";
import LoggedInNav from "@/components/nav/LoggedInNav";
import WorkspaceNav from "@/components/pages_logged_in/workspace/nav";
import { STANDARD_SIZE_NAV_BREAKPOINT } from "@/css/constants";

interface NavProps {
  sizeNavBreakpoint?: SizeBreakpoint;
  pageLoading?: boolean;
  customerId?: string;
}

export default function Nav({
  sizeNavBreakpoint = STANDARD_SIZE_NAV_BREAKPOINT,
  pageLoading = false,
  customerId = null,
}: NavProps) {
  const user = useCurrentUser();
  const isInWorkspace = !!user?.workspaceRole;
  const nav = isInWorkspace ? (
    <WorkspaceNav
      customerId={customerId}
      sizeNavBreakpoint={sizeNavBreakpoint}
      pageLoading={pageLoading}
    />
  ) : (
    <LoggedInNav
      sizeNavBreakpoint={sizeNavBreakpoint}
      pageLoading={pageLoading}
    />
  );
  return nav;
}
