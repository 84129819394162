import { Icon, Intent } from "@blueprintjs/core";
import { Link } from "react-router-dom";

import { pluralize } from "@/components/helpers/string-utils";
import { isInWorkspace } from "@/components/helpers/workspace/permissions";
import { Box, Flex } from "@/components/layout/flexbox";
import { LiveSVG } from "@/components/pages_logged_in/program/components/ProgramStatusIcons";
import { RoleplayPageType } from "@/components/pages_logged_in/roleplays/details/roleplay-types";
import { getCheckoutUrl } from "@/components/pages_logged_in/subscribe-checkout/get-checkout-url";
import { Container } from "@/components/pieces/bootstrap";
import { useSubscription } from "@/components/pieces/subscription";
import { LIGHT_BLUE, LIGHT_YELLOW } from "@/css/constants";

export default function RoleplayLimitBanner({ page }) {
  const { subscriptionData, loading, error } = useSubscription();

  if (loading) return null;
  if (error) return null;
  if (!subscriptionData) return null;

  const { remainingRoleplaySessions, allowCustomRoleplays } = subscriptionData;
  const upgradeLink = getCheckoutUrl(window.location.href);

  const talkToSales = isInWorkspace() ? (
    <>
      or <Link to="/schedule-demo">talk to sales</Link>
    </>
  ) : null;

  if (page === RoleplayPageType.ScenarioCreate) {
    if (allowCustomRoleplays) {
      return null;
    }
    return (
      <Box py={3} bg={LIGHT_YELLOW}>
        <Container>
          <Flex alignItems="center" style={{ gap: 4 }}>
            <Icon icon="warning-sign" intent={Intent.DANGER} />
            <span>Custom scenarios are only available on paid plans.</span>
            <Link to={upgradeLink}>Upgrade now</Link> {talkToSales}
          </Flex>
        </Container>
      </Box>
    );
  }

  if (remainingRoleplaySessions === null || remainingRoleplaySessions === -1) {
    return null;
  }

  if (remainingRoleplaySessions === 0) {
    return (
      <Box py={12} bg={LIGHT_YELLOW}>
        <Container>
          <Flex alignItems="center" style={{ gap: 4 }}>
            <Icon icon="warning-sign" intent={Intent.DANGER} />
            <span>
              You have no free roleplays remaining.{" "}
              <Link to={upgradeLink}>Upgrade now</Link> {talkToSales}
            </span>
          </Flex>
        </Container>
      </Box>
    );
  }

  return (
    <Box py={12} bg={LIGHT_BLUE} fontSize={14}>
      <Container>
        <Flex alignItems="center" style={{ gap: 4 }}>
          <LiveSVG />
          <span>
            {pluralize(
              remainingRoleplaySessions,
              "free roleplay",
              "free roleplays",
            )}{" "}
            remaining.
          </span>
          <Link to={upgradeLink}>Upgrade now</Link> {talkToSales}
        </Flex>
      </Container>
    </Box>
  );
}
