import { useSearchParams } from "react-router-dom";

export function useStorePromoCode() {
  const [searchParams] = useSearchParams();

  const storePromoCode = () => {
    const promo = searchParams.get("promo");
    if (promo) {
      localStorage.setItem("promoCode", promo);
    }
  };

  return storePromoCode;
}

export function removePromoCodeFromLocalStorage() {
  localStorage.removeItem("promoCode");
}

export function getPromoCodeFromLocalStorage() {
  if (import.meta.env.SSR) return;
  return localStorage.getItem("promoCode");
}
