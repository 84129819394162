import React, {
  HTMLAttributes,
  KeyboardEvent,
  MouseEvent,
  ReactElement,
} from "react";

const KEY_ENTER = "Enter";
const KEY_SPACE = " ";

const a11yClickEvent =
  (clickHandler: (event: KeyboardEvent<HTMLElement>) => void) =>
  (e: KeyboardEvent<HTMLElement>) => {
    if (e.key === KEY_ENTER || e.key === KEY_SPACE) {
      clickHandler(e);
    }
  };

/**
 * Use Clickable to make a div or span respond to clicks. This should be
 *  preferred over setting the `onClick` property directly as this component will
 *  also respond to accessibility events. This component doesn't introduce new
 *  DOM elements, it just takes care of setting up the right properties on the
 *  child div or span.
 *
 * Example:
 *   <Clickable
 *     onClick={this.handleClick}
 *   >
 *      <span
 *        className={someClasses}
 *      >
 *        Something that can't be an Anchor element
 *      </span>
 *    </Clickable>
 */

interface ClickableProps {
  children: ReactElement;
  onClick?: (
    event: MouseEvent<HTMLElement> | KeyboardEvent<HTMLElement>,
  ) => void;
  tabIndex?: number;
  cursor?: string;
  onFocus?: (event: MouseEvent<HTMLElement>) => void;
}

export default function Clickable({
  children,
  onClick,
  tabIndex = 0,
  cursor = "pointer",
  onFocus = (e: MouseEvent<HTMLElement>) => {
    e.preventDefault();
  },
}: ClickableProps) {
  const child = React.Children.only(children);

  if (onClick) {
    return React.cloneElement(
      child as ReactElement<HTMLAttributes<HTMLElement>>,
      {
        onClick,
        tabIndex,
        onKeyDown: a11yClickEvent(
          onClick as (event: KeyboardEvent<HTMLElement>) => void,
        ),
        // Disable focus when clicking (this stops an outline from appearing
        // when clicking). The outline will still appear when focusing on the
        // element by other means (e.g. Tab).
        onMouseDown: onFocus,
        role: "button",
        style: {
          ...(child.props as HTMLAttributes<HTMLElement>).style,
          cursor,
        },
      },
    );
  }

  return child;
}
