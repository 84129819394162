import { useMemo } from "react";

import { useWorkspaceSlug } from "@/components/helpers/custom-hooks/use-workspace-slug";
import { getUser } from "@/components/session/JwtTokenManager";
import { WorkspaceType } from "@/graphql";

export function useWorkspace(): WorkspaceType | undefined {
  const workspaceSlug = useWorkspaceSlug();
  const userWorkspace = getUser()?.workspaces;

  return useMemo(() => {
    const workspace = userWorkspace?.find((w) => w.urlSlug === workspaceSlug);
    if (!workspace) return undefined;

    return {
      ...workspace,
      logo: `${API_HOST}/media/${workspace.logo}`,
      active: true,
    };
  }, [workspaceSlug, userWorkspace]);
}
