import React from "react";

import { Button, Intent, Menu, MenuItem, Position } from "@blueprintjs/core";

import { Popover } from "@/components/helpers/ui/blueprint-overrides/Popover";
import { ScenarioType } from "@/graphql";

export const enum ScenarioModals {
  editCharacter = "editCharacter",
  deleteScenario = "deleteScenario",
  cloneScenario = "cloneScenario",
  addToCollection = "addToCollection",
  editScenario = "editScenario",
}

interface ScenarioMenuProps {
  scenario?: ScenarioType;
  onEditCharacter: () => void;
  onDelete: () => void;
  onCloneScenario: () => void;
  onAddToCollection: () => void;
  onEditScenario: () => void;
}

export default function ScenarioMenu({
  scenario,
  onEditCharacter,
  onDelete,
  onCloneScenario,
  onAddToCollection,
  onEditScenario,
}: ScenarioMenuProps) {
  const baseMenuItems = [
    {
      text: "Clone Scenario",
      onClick: (e: React.MouseEvent) => {
        e.preventDefault();
        onCloneScenario();
      },
    },
  ];

  const editableMenuItems = [
    {
      text: "Edit Character",
      onClick: (e: React.MouseEvent) => {
        e.preventDefault();
        onEditCharacter();
      },
    },
    {
      text: "Edit Scenario",
      onClick: (e: React.MouseEvent) => {
        e.preventDefault();
        onEditScenario();
      },
    },
    ...baseMenuItems,
    {
      text: "Add to Collection",
      onClick: (e: React.MouseEvent) => {
        e.preventDefault();
        onAddToCollection();
      },
    },
    {
      text: "Delete Scenario",
      onClick: (e: React.MouseEvent) => {
        e.preventDefault();
        onDelete();
      },
      intent: Intent.DANGER,
    },
  ];

  const menuItems = (scenario?.canEdit ? editableMenuItems : baseMenuItems).map(
    (item, index) => <MenuItem key={index} {...item} />,
  );

  return (
    <Popover
      canEscapeKeyClose={true}
      position={Position.BOTTOM_RIGHT}
      onClose={() => {}}
      content={<Menu>{menuItems}</Menu>}
    >
      <Button
        style={{ minHeight: 36 }}
        minimal={true}
        icon="more"
        onClick={(e) => {
          e.preventDefault();
        }}
      />
    </Popover>
  );
}
