import { StrictMode } from "react";

import { ApolloProvider } from "@apollo/client/react";
import { CacheProvider, Global } from "@emotion/react";
import * as Sentry from "@sentry/react";
import { hydrateRoot, createRoot } from "react-dom/client";
import { HelmetProvider } from "react-helmet-async";
import {
  createBrowserRouter,
  RouterProvider,
  matchRoutes,
} from "react-router-dom";

import { getHash, saveHash } from "@/components/helpers/url-utils";
import { PageSizeProvider } from "@/components/layout/PageSize";
import { PageLoadingProvider } from "@/components/nav/PageLoading";
import { ResponseContextProvider } from "@/components/ResponseContext";
import { init as initJwtTokenManager } from "@/components/session/JwtTokenManager";
import { cacheOption } from "@/entry/apolloCache";
import { emotionCache } from "@/entry/emotionCache";
import { routes } from "@/entry/routes";
import {
  APP_REDIRECTION_LINK_COOKIE_NAME,
  CSRF_COOKIE_NAME,
} from "@/global/constants";
import globalStyles from "@/global/styles";
import { GetCSRFQuery, IsLoggedInQuery } from "@/graphql/cachedVarsQueries";
import { createClient } from "@/lib/apollo";
import "@/global/tailwind.css";

// Cookie parsing utility
function getCookie(name: string): string | undefined {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop()?.split(";").shift();
  return undefined;
}

// Initialize Sentry
if (SENTRY_DSN) {
  Sentry.init({
    dsn: SENTRY_DSN,
    release: RENDER_GIT_COMMIT || "unknown",
    beforeSend: (event, hint) => {
      if (!IS_PROD) {
        console.error(
          "Sentry:",
          hint.originalException || hint.syntheticException,
        );
        return null;
      }
      return event;
    },
  });
}

// Save the original hash from the URL
saveHash(window.location.hash);

// Restore hash
window.history.replaceState(null, null, getHash());

// Create Apollo client
const client = createClient(cacheOption);

// Initialize client queries
const isLoggedIn = !!(
  getCookie(APP_REDIRECTION_LINK_COOKIE_NAME) ||
  localStorage.getItem(APP_REDIRECTION_LINK_COOKIE_NAME)
);

client.writeQuery({
  query: IsLoggedInQuery,
  data: { isLoggedIn },
});

const csrf = getCookie(CSRF_COOKIE_NAME);
client.writeQuery({
  query: GetCSRFQuery,
  data: { csrf },
});

initJwtTokenManager({ client });

// Mount application
const mount = async () => {
  const container = document.getElementById("root");
  if (!container) {
    console.error("Root element not found");
    return;
  }

  // ============================== START - Avoid Hydration double render with lazy routes ==============================
  // Reference: https://github.com/remix-run/react-router/issues/10918#issuecomment-1761837548, https://reactrouter.com/6.29.0/guides/ssr#lazy-routes
  // Determine if any of the initial routes are lazy
  // TODO: Still break the hydration mismatch of @emotion/react with SSR
  let lazyMatches = matchRoutes(routes, window.location)?.filter(
    (m) => m.route.lazy,
  );

  // Load the lazy matches and update the routes before creating your router
  // so we can hydrate the SSR-rendered content synchronously
  if (lazyMatches && lazyMatches?.length > 0) {
    await Promise.all(
      lazyMatches.map(async (m) => {
        let routeModule = await m.route.lazy();
        Object.assign(m.route, {
          ...routeModule,
          lazy: undefined,
        });
      }),
    );
  }
  // ============================== END - Avoid Hydration double render with lazy routes ==============================

  // Create router with InitializeParams inside the Root component
  const router = createBrowserRouter(routes);

  const app = (
    <StrictMode>
      <ResponseContextProvider req={null} res={null}>
        <ApolloProvider client={client}>
          <HelmetProvider>
            <CacheProvider value={emotionCache}>
              <PageSizeProvider>
                <Global styles={globalStyles} />
                <PageLoadingProvider>
                  <RouterProvider router={router} />
                </PageLoadingProvider>
              </PageSizeProvider>
            </CacheProvider>
          </HelmetProvider>
        </ApolloProvider>
      </ResponseContextProvider>
    </StrictMode>
  );

  // Hydrate the app in production, render in development
  // SSR is disabled in development because ViteJS has FOUC issues with CSS-in-JS
  // Rerference: https://github.com/vitejs/vite/issues/8890#issuecomment-1797929009
  // TODO: comeback to fully hydrate the app in development when we have tailwindcss
  if (IS_PROD) {
    hydrateRoot(container, app, {
      onRecoverableError: (error, errorInfo) => {
        console.error(
          "Caught error",
          error,
          (error as Error).cause,
          errorInfo.componentStack,
        );
      },
    });
  } else {
    const root = createRoot(container);
    root.render(app);
  }
};

mount();
