import { useQuery } from "@apollo/client";
import gql from "graphql-tag";

import ResendEmailConfirmation from "./ResendEmailConfirmation";
import SetPassword from "./SetPassword";

const MESSAGE_BAR_QUERY = gql`
  query MessageBarQuery {
    currentUser {
      id
      onboardingCompletedAt
      isConfirmed
      isPasswordSet
    }
  }
`;

function MessageBar({ email }) {
  const { data } = useQuery(MESSAGE_BAR_QUERY);

  if (data) {
    if (!data.currentUser.isConfirmed) {
      return <ResendEmailConfirmation email={email} />;
    }

    if (!data.currentUser.isPasswordSet) {
      return <SetPassword />;
    }
  }

  return null;
}

export default MessageBar;
