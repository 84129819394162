import { useEffect, useRef } from "react";

import { Button } from "@blueprintjs/core";

import { Box } from "@/components/layout/flexbox";

export default function RatingScaleTemplates({
  ratingScaleOptionTemplates,
  selectedTemplate,
  onSelectTemplate,
}) {
  const templateContainerRef = useRef(null);

  useEffect(() => {
    const handleWheel = (event) => {
      event.preventDefault();

      if (templateContainerRef.current) {
        templateContainerRef.current.scrollBy({
          left: event.deltaY < 0 ? -30 : 30,
        });
      }
    };

    const container = templateContainerRef.current;
    if (container) {
      container.addEventListener("wheel", handleWheel);
    }

    return () => {
      if (container) {
        container.removeEventListener("wheel", handleWheel);
      }
    };
  }, []);

  return (
    <Box
      overflowX={"auto"}
      style={{
        whiteSpace: "nowrap",
        padding: "10px 0",
      }}
      ref={templateContainerRef}
    >
      {ratingScaleOptionTemplates.map((template, index) => (
        <Button
          active={selectedTemplate === index}
          style={{
            fontSize: 12,
            margin: "0 2px",
            fontWeight: 400,
            borderRadius: 100,
            border: "1px solid #E2E2E2",
          }}
          onClick={() => onSelectTemplate(index)}
          key={index}
        >
          {template.name}
        </Button>
      ))}
    </Box>
  );
}
