import { useState } from "react";

import { useMutation, useQuery } from "@apollo/client";
import { Button, Callout, FormGroup } from "@blueprintjs/core";
import Select from "react-select";

import { isInWorkspace } from "@/components/helpers/workspace/permissions";
import { Box, Flex } from "@/components/layout/flexbox";
import StyledDialog from "@/components/pieces/StyledDialog";
import Avatar from "@/components/pieces/users/Avatar";
import { AssignAssistantDocument, WorkspaceMembersDocument } from "@/graphql";

export enum ActionType {
  Add = "Add To Group",
  Remove = "Remove From Group",
}

function Results({ onClose, result, member, assistant }) {
  const success = result?.success;
  const errorMsg = result?.errorMsg;
  const memberName = member?.user?.name || member?.user?.email;
  const assistantName = assistant?.user?.name || assistant?.user?.email;

  return (
    <Box>
      {success ? (
        <Callout intent="success" icon="tick">
          {assistantName} added as an assistant
        </Callout>
      ) : errorMsg ===
        "Assistant and client are already assigned to each other" ? (
        <Callout intent="danger">
          The selected member is already an assistant for {memberName}.
        </Callout>
      ) : (
        <Callout intent="danger">
          We could not process your request at this time. Please contact us.
        </Callout>
      )}
      <Box mt={16}>
        <Button large={true} onClick={() => onClose()} loading={false}>
          Close
        </Button>
      </Box>
    </Box>
  );
}

function AddAssistantDialog({ member, isOpen, onClose, afterModifyAssistant }) {
  const [result, setResult] = useState(null);
  const [assistant, setAssistant] = useState(null);

  const { data: workspaceMembersData, loading: fetchLoading } = useQuery(
    WorkspaceMembersDocument,
    {
      fetchPolicy: "cache-and-network",
      variables: {
        sortField: "name",
        sortDesc: true,
      },
      skip: !isInWorkspace(),
    },
  );

  const [assignAssistant, { loading }] = useMutation(AssignAssistantDocument, {
    onCompleted: (data) => {
      if (
        data &&
        data.assignAssistant &&
        data?.assignAssistant?.ok &&
        data?.assignAssistant?.workspaceAssistantAssignment
      ) {
        setResult({ success: true });
        afterModifyAssistant();
      } else {
        setResult({ success: false, errorMsg: data?.errors[0]?.message });
      }
    },
    onError: (error) => {
      setResult({
        success: false,
        errorMsg: error?.message,
      });
    },
  });

  const assignAssistantToMember = (memberId, assistantId) => {
    assignAssistant({
      variables: {
        clientMemberId: memberId,
        assistantMemberId: assistantId,
      },
    });
  };

  const workspaceMembers = workspaceMembersData
    ? workspaceMembersData.workspaceMembers
    : [];

  const options = workspaceMembers
    .filter((workspaceMember) => workspaceMember.id != member?.id)
    .map((workspaceMember) => ({
      value: workspaceMember,
      label: workspaceMember?.user?.name || workspaceMember?.user?.email,
    }));

  return (
    <StyledDialog
      isOpen={isOpen}
      lazy={true}
      title="Add Assistant"
      width="480px"
      onClose={() => {
        onClose();
      }}
      onOpening={() => {
        setResult(null);
        setAssistant(null);
      }}
    >
      <Box py={3} px={4}>
        {result ? (
          <Results
            result={result}
            onClose={onClose}
            member={member}
            assistant={assistant}
          />
        ) : (
          <Box>
            <Box>
              <FormGroup style={{ margin: 0 }} label={<b>Select User</b>}>
                <Select
                  isLoading={fetchLoading}
                  value={options.find((o) =>
                    assistant ? o.value.id === assistant.id : null,
                  )}
                  onChange={(option) => {
                    setAssistant(option.value);
                  }}
                  isSearchable={true}
                  options={options}
                  formatOptionLabel={(option) => (
                    <Flex alignItems="center">
                      <Avatar size={20} user={option.value.user} />
                      <Box mx={2} fontSize={16} style={{ overflow: "hidden" }}>
                        {option.label}
                      </Box>
                    </Flex>
                  )}
                />
              </FormGroup>
            </Box>
            <Box mt={4}>
              <Button
                intent={"primary"}
                large={true}
                disabled={fetchLoading || !member || !assistant || loading}
                onClick={() => assignAssistantToMember(member.id, assistant.id)}
                loading={fetchLoading}
              >
                Add Assistant
              </Button>
            </Box>
          </Box>
        )}
      </Box>
    </StyledDialog>
  );
}

export default AddAssistantDialog;
