import { useState } from "react";

import { useQuery } from "@apollo/client";
import { Colors, Spinner } from "@blueprintjs/core";

import { useWorkspaceSlug } from "@/components/helpers/custom-hooks/use-workspace-slug";
import { Box, Flex } from "@/components/layout/flexbox";
import Paging from "@/components/pages_logged_in/workspace-admin/Paging";
import { Container } from "@/components/pieces/bootstrap";
import { PaginatedRoleplaysDocument, VisibilityScope } from "@/graphql";

import RoleplayController from "./RoleplayController";
import RoleplayList from "./RoleplayList";
import { RoleplayViewMode } from "./types";

const FETCH_LIMIT = 10;

export default function RoleplaysList({
  isLoadingPage = false,
  onChoose = null,
  showProgress = true,
  includeOwnerData = false,
}) {
  const [currentPage, setCurrentPage] = useState(1);
  const [query, setQuery] = useState(null);
  const [selectedScope, setSelectedScope] = useState(null);
  const [roleplays, setRoleplays] = useState(null);
  const [error, setError] = useState(null);

  const workspaceSlug = useWorkspaceSlug();

  const {
    data,
    loading: loadingData,
    refetch: refetchRoleplays,
  } = useQuery(PaginatedRoleplaysDocument, {
    fetchPolicy: "cache-and-network",
    variables: {
      currentPage: currentPage,
      pageSize: FETCH_LIMIT,
      query: query,
      scope: selectedScope,
      workspaceOnly: false,
      includeOwnerData: includeOwnerData,
    },
    skip: isLoadingPage,
    onCompleted: (roleplayData) => {
      setRoleplays(roleplayData?.paginatedRoleplays?.data || []);
    },
    onError: () => {
      setError("Error fetching roleplays");
    },
  });
  const totalRecords = data?.paginatedRoleplays?.totalRecords || 0;

  const loading = isLoadingPage || loadingData;

  const firstLoading = !roleplays ? loading : false;

  const scopes = [
    { id: null, name: "All" },
    { id: VisibilityScope.User, name: "Personal" },
    { id: VisibilityScope.Workspace, name: "Workspace" },
    { id: VisibilityScope.Global, name: "Core" },
  ].filter((scope) => workspaceSlug || scope.id !== VisibilityScope.Workspace);

  return (
    <Container>
      <Box>
        <RoleplayController
          selectedScopes={[selectedScope]}
          scopes={scopes}
          onChangeScope={(scope) => {
            setSelectedScope(scope);
            setCurrentPage(1);
          }}
          onSearch={(query) => {
            setQuery(query ? query.trim() : null);
            setCurrentPage(1);
          }}
          loading={firstLoading}
          onCreateCollectionFinished={refetchRoleplays}
          allowAdd={true}
        />
        <RoleplayList
          roleplays={roleplays || []}
          loading={firstLoading}
          viewMode={RoleplayViewMode.List}
          visibleTitle={false}
          onChoose={onChoose}
          showActionBar={!!onChoose}
          showProgress={showProgress}
          refetchRoleplays={refetchRoleplays}
        />
        {!loading && roleplays?.length === 0 && (
          <Box my={3} textAlign={"center"}>
            No roleplays found
          </Box>
        )}
        {!loading && error && (
          <Box my={3} textAlign={"center"} color={Colors.RED3}>
            {error}
          </Box>
        )}
        {loading && !firstLoading ? (
          <Flex
            justifyContent="center"
            p={2}
            css={{
              position: "sticky",
              bottom: 100,
              left: 0,
              right: 0,
              opacity: 0.5,
              width: "100%",
              height: "100%",
            }}
          >
            <Spinner intent="primary" />
          </Flex>
        ) : null}
        <Paging
          style={{
            background: Colors.WHITE,
            padding: 10,
            marginTop: 20,
          }}
          currentPage={currentPage}
          pageSize={FETCH_LIMIT}
          setCurrentPage={(index) => {
            setCurrentPage(index);
          }}
          totalRecords={totalRecords}
          isSticky={true}
        />
      </Box>
    </Container>
  );
}
