import {
  ARTICLE_INDEX,
  COACH_INDEX,
  PROGRAM_INDEX,
  ROLEPLAY_INDEX,
  SCENARIO_INDEX,
  USER_INDEX,
} from "@/components/pieces/algolia/indexes";
import { WorkspaceType } from "@/graphql";

import Articles, { ArticleResult } from "./Articles";
import Coaches, { CoachResult } from "./Coaches";
import Customers, { CustomerResult } from "./Customers";
import Programs, { ProgramResult } from "./Program";
import Roleplays, { RoleplayResult } from "./Roleplay";
import Scenarios, { ScenarioResult } from "./Scenario";
import { SearchIndex } from "./search-types";

export function getSearchIndexes(
  workspace: WorkspaceType,
  isCoach: boolean,
): SearchIndex[] {
  let indexes: SearchIndex[] = [];

  if (isCoach) {
    indexes.push({
      key: "CUSTOMER" as const,
      name: "Customers",
      IndexComponent: Customers,
      ResultComponent: CustomerResult,
      indexName: USER_INDEX,
    });
  }

  indexes.push(
    {
      key: "COACH" as const,
      name: "Coaches",
      IndexComponent: Coaches,
      ResultComponent: CoachResult,
      indexName: COACH_INDEX,
    },
    {
      key: "ARTICLE" as const,
      name: "Articles",
      IndexComponent: Articles,
      ResultComponent: ArticleResult,
      indexName: ARTICLE_INDEX,
    },
  );

  if (workspace?.programsEnabled) {
    indexes.push({
      key: "PROGRAM" as const,
      name: "Programs",
      IndexComponent: Programs,
      ResultComponent: ProgramResult,
      indexName: PROGRAM_INDEX,
    });
  }

  if (workspace?.roleplaysEnabled || !workspace) {
    indexes.push({
      key: "ROLEPLAY" as const,
      name: "Collections",
      IndexComponent: Roleplays,
      ResultComponent: RoleplayResult,
      indexName: ROLEPLAY_INDEX,
    });

    indexes.push({
      key: "SCENARIO" as const,
      name: "Scenarios",
      IndexComponent: Scenarios,
      ResultComponent: ScenarioResult,
      indexName: SCENARIO_INDEX,
    });
  }
  return indexes;
}
