import { CSSProperties } from "react";

import { Colors, Icon, Intent } from "@blueprintjs/core";

import { APP_PROFILE } from "@/components/helpers/configuration/constants";
import { Tooltip } from "@/components/helpers/ui/blueprint-overrides/Tooltip";
import { validateEmail } from "@/components/helpers/validation/validate-email";
import { Box, Flex } from "@/components/layout/flexbox";
import AlgoliaMultiselect, {
  AlgoliaMultilselectPropsType,
} from "@/components/pieces/algolia/AlgoliaMultiselect";
import { USER_INDEX } from "@/components/pieces/algolia/indexes";
import Avatar from "@/components/pieces/users/Avatar";
import { TaggableUserType } from "@/graphql";

import type { Hit } from "instantsearch.js";

export enum EmailStatus {
  canBeInvited = "canBeInvited",
  existed = "existed",
  pendingInv = "pendingInv",
  invalid = "invalid",
  existedProgramUser = "existedProgramUser",
  existedButNotInProgram = "existedButNotInProgram",
}

const emailStatusStyle = {
  [EmailStatus.canBeInvited]: {
    color: Colors.GREEN3,
    tooltipText:
      "This person will be invited to join the workspace. This will use an available seat.",
  },
  [EmailStatus.existedButNotInProgram]: {
    color: Colors.GREEN3,
    tooltipText:
      "This existing workspace user will be invited to join the program. This won't use a seat.",
  },
  [EmailStatus.existed]: {
    color: null,
    tooltipText:
      "This person is already a workspace member. No new invitation will be sent.",
  },
  [EmailStatus.pendingInv]: {
    color: null,
    tooltipText:
      "This person already has a pending workspace invitation. No new invitation will be sent.",
  },
  [EmailStatus.invalid]: {
    color: Colors.RED3,
    tooltipText:
      "This email address is invalid. Please correct it to send an invitation.",
  },
  [EmailStatus.existedProgramUser]: {
    color: null,
    tooltipText:
      "This person is already in the program. No new invitation will be sent.",
  },
};

export type EmailMultiselectPropsType = {
  selections?: TaggableUserType[];
  onChange: (value: TaggableUserType[]) => void;
  onInputChange?: (value: string) => void;
  canBeInvitedEmails: string[];
  existedEmails: string[];
  pendingInvEmails: string[];
  invalidEmails?: string[];
  existedProgramUserEmails?: string[];
  programId?: string;
  inputStyle?: CSSProperties;
} & Omit<
  AlgoliaMultilselectPropsType,
  | "indexName"
  | "filters"
  | "renderSuggestion"
  | "getSuggestionValue"
  | "suggestionConverter"
  | "onCreateArtificialObject"
>;

export default function EmailMultiselect(props: EmailMultiselectPropsType) {
  const {
    onInputChange = () => {},
    selections = [],
    canBeInvitedEmails = [],
    existedEmails = [],
    pendingInvEmails = [],
    invalidEmails = [],
    existedProgramUserEmails = [],
    programId,
    onChange,
    placeholder,
    inputStyle,
  } = props;

  const getSuggestionStatus = (suggestion: Hit) =>
    emailStatus(
      suggestion?.email,
      canBeInvitedEmails,
      existedEmails,
      pendingInvEmails,
      invalidEmails,
      existedProgramUserEmails,
      programId,
    );

  const customSuggestion = (value: string, hits: Hit[]) => {
    const isEmail = validateEmail(value);
    if (!isEmail) return undefined;

    const existed = hits.find((hit) => hit?.email === value);
    if (existed) return undefined;

    return {
      id: `custom-${Date.now()}-${value}`,
      objectID: `custom-${Date.now()}-${value}`,
      email: value,
      photo: null,
      __position: 0,
      __queryID: "custom",
    } as Hit;
  };

  return (
    <AlgoliaMultiselect
      onChange={onChange}
      onQueryChange={onInputChange}
      placeholder={placeholder || `name@company.com`}
      indexName={USER_INDEX}
      selections={selections}
      getSuggestionValue={(suggestion) => suggestion.email}
      renderSuggestion={(suggestion, isTag) => (
        <EmailSuggestionItem
          suggestion={suggestion}
          isTag={isTag}
          status={getSuggestionStatus(suggestion)}
        />
      )}
      onCreateArtificialObject={(value) => {
        return {
          id: `custom-${Date.now()}-${value}`,
          objectID: `custom-${Date.now()}-${value}`,
          email: value,
          __position: 0,
          __queryID: "custom",
        };
      }}
      optionFromQuery={customSuggestion}
      optionFromQueryPosition="prepend"
      inputStyle={inputStyle}
    />
  );
}

function emailStatus(
  email: string,
  canBeInvitedEmails: string[],
  existedEmails: string[],
  pendingInvEmails: string[],
  invalidEmails: string[],
  existedProgramUserEmails: string[] = [],
  programId?: string,
) {
  if (programId && existedProgramUserEmails?.includes(email)) {
    return EmailStatus.existedProgramUser;
  }
  if (programId && existedEmails?.includes(email)) {
    return EmailStatus.existedButNotInProgram;
  }

  if (canBeInvitedEmails.includes(email)) {
    return EmailStatus.canBeInvited;
  }
  if (existedEmails.includes(email)) {
    return EmailStatus.existed;
  }
  if (pendingInvEmails.includes(email)) {
    return EmailStatus.pendingInv;
  }
  if (invalidEmails?.includes(email)) {
    return EmailStatus.invalid;
  }
  return null;
}

function EmailSuggestionItem({
  suggestion,
  isTag = false,
  status,
}: {
  suggestion: any;
  isTag?: boolean;
  status: EmailStatus | null;
}) {
  const showWarningIcon = isTag && status === EmailStatus.invalid;

  const style = (isTag && status && emailStatusStyle[status]) || {
    color: undefined,
    tooltipText: undefined,
  };

  const content = (
    <Flex alignItems="center">
      <Box mr="4px">
        {showWarningIcon ? (
          <Icon icon="warning-sign" intent={Intent.DANGER} size={16} />
        ) : (
          <Avatar
            size={16}
            user={{
              name: suggestion?.name,
              id: suggestion?.user_id,
              photo: suggestion?.photo
                ? `${APP_PROFILE.apiUrl}/images/avatar/${suggestion?.email}`
                : null,
              email: suggestion?.email,
            }}
          />
        )}
      </Box>
      <Box color={style.color}>{suggestion?.email}</Box>
    </Flex>
  );

  return isTag && style.tooltipText ? (
    <Tooltip content={<Box width={"300px"}>{style.tooltipText}</Box>}>
      {content}
    </Tooltip>
  ) : (
    <Box>{content}</Box>
  );
}
