import { useState } from "react";

import styled from "@emotion/styled";
import truncate from "lodash/truncate";
import { Link } from "react-router-dom";

import { Box } from "@/components/layout/flexbox";
import {
  AgentProfileWithSlug,
  SpecialtyRenderDataType,
} from "@/components/pieces/coaching/coach-profile/coachingUtils";
import { CARD_CLASSES, SUBTEXT_COLOR } from "@/css/constants";
import { ProductCategory } from "@/graphql";

import Category from "./Category";
import InactiveProducts from "./InactiveProducts";

export interface CoachingProductSpecialtiesProps {
  specialty: SpecialtyRenderDataType;
  isCoachInUserCoaches: boolean;
  asCoach: boolean;
  showLinks: boolean;
  agentProfile: AgentProfileWithSlug;
  noBorder?: boolean;
  showInfo?: boolean;
  backgroundColor?: string;
}

export function Description({ text, color = "#000" }) {
  const [showAll, setShowAll] = useState(false);

  return (
    <>
      <Box
        color={color}
        css={{
          whiteSpace: "pre-line",
        }}
      >
        {showAll ? text : truncate(text, { length: 160, separator: " " })}
      </Box>
      {showAll ? null : (
        <Box mt="2px">
          <a onClick={() => setShowAll(true)}>
            <b>Read More</b>
          </a>
        </Box>
      )}
    </>
  );
}

export default function CoachingProductSpecialties({
  specialty,
  isCoachInUserCoaches,
  asCoach,
  showLinks,
  agentProfile,
  noBorder,
  showInfo = true,
  backgroundColor = null,
}: CoachingProductSpecialtiesProps) {
  const {
    categories = [],
    inactiveProducts = [],
    description,
    name,
  } = specialty || {};

  const allowableCategories = categories
    .sort((item1, item2) => item1.category.localeCompare(item2.category))
    .filter((item) => item.category !== ProductCategory.Credits);

  const specialtyRoute = `/@${agentProfile?.coachSlug}/${specialty?.slug}`;

  return (
    <StyledComponent
      className={!noBorder ? CARD_CLASSES : ""}
      backgroundColor={backgroundColor}
      px={showInfo ? [3, 3, 3, 4] : undefined}
      pb={2}
      my={showInfo ? [36, 36, 36, 36] : undefined}
    >
      {showInfo && (
        <Box pt={3}>
          {showLinks ? (
            <Link to={specialtyRoute}>
              <h4>{name}</h4>
            </Link>
          ) : (
            <h4>{name}</h4>
          )}
          <Box
            css={{
              opacity: 0.8,
            }}
          >
            <Description text={description} />
          </Box>
        </Box>
      )}

      {allowableCategories.map((category) => (
        <Category
          category={category}
          key={category.category}
          asCoach={asCoach}
          showLinks={showLinks}
          isCoachInUserCoaches={isCoachInUserCoaches}
          agentProfile={agentProfile}
          specialty={specialty}
        />
      ))}
      <InactiveProducts inactiveProducts={inactiveProducts} />
    </StyledComponent>
  );
}

const StyledComponent = styled(Box)`
  margin: 0;

  .dot-line {
    border-bottom: dotted 1px ${SUBTEXT_COLOR};
  }
`;
