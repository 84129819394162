export function isUrl(url) {
  try {
    new URL(url);
  } catch (e) {
    console.error(e);
    return false;
  }
  return true;
}

function buildQueryParams(data: any) {
  const ret = data
    ? Object.keys(data).map((key) => {
        if (!data[key]) {
          return null;
        }
        return `${key}=${encodeURIComponent(String(data[key]))}`;
      })
    : [];
  return ret.filter((x) => !!x);
}

export function buildQueryString(data: any) {
  const params = buildQueryParams(data);
  if (params.length) {
    return `?${params.join("&")}`;
  }
  return "";
}

const routeRegex = new RegExp(/:(.*?)(\/|$)/g);

export function buildUrl(
  route: string,
  options: { format?: string; data?: any; hash?: string },
) {
  let result;
  if (!options) {
    return route;
  }
  const constOptions = options;
  try {
    result = route.replace(routeRegex, (p1, p2) => {
      const value = constOptions[p1];
      return value + p2;
    });
  } catch (e) {
    console.error(e);
    result = route;
  }

  if (options && options.format != null && options.format !== "") {
    result += `.${options.format}`;
  }

  result += buildQueryString(options && options.data);

  if (options && options.hash != null && options.hash !== "") {
    result += `#${options.hash.replace(/^#/g, "")}`;
  }

  return result;
}

const placementUrl = new RegExp(
  /^https?:\/\/[a-zA-Z0-9-]+.(placement|exec).com\//,
);

export function isPlacementLink(url) {
  return placementUrl.test(url);
}

export function getPlacementLinkFromUrl(url) {
  return url.replace(placementUrl, "/");
}

export function getMediaLink(url) {
  return url && url.startsWith("http") ? url : `${API_HOST}${url}`;
}

// START hash
// This block below is for dealing with history removing
// hash from the URL when it's created.
let originalHash = "";
export function saveHash(windowHash) {
  originalHash = windowHash;
}
export function getHash() {
  if (typeof window === "undefined") {
    return undefined; // hash is a browser only thing
  }

  const hash = originalHash;

  if (originalHash) {
    originalHash = "";
  }

  return hash || window.location.hash;
}
// END hash

export function getStartPageURL(params: string = "") {
  if (IS_EXEC) {
    return "/start" + params;
  }
  // Redirect to exec.com when domain is other
  return "https://www.exec.com/start" + params;
}
