import { useMemo } from "react";

import { Colors } from "@blueprintjs/core";

import { Box, Flex } from "@/components/layout/flexbox";
import { usePersonalityAttributesQuery } from "@/components/pages_logged_in/roleplays/api";
import Clickable from "@/components/pieces/interaction/Clickable";
import { BRAND_PURPLE, MIDTEXT_COLOR, SUBTEXT_COLOR } from "@/css/constants";
import { PersonalityAttributesQuery } from "@/graphql";

interface PersonalityTraitSwitchProps {
  attribute: PersonalityAttributesQuery["personalityAttributes"][0];
  activeLevelId: string | undefined;
  onChangeLevel: (levelId: string) => void;
}

const isSelected = (activeLevelId, id) => activeLevelId === id;

function PersonalityTraitSwitch({
  attribute,
  activeLevelId,
  onChangeLevel,
}: PersonalityTraitSwitchProps) {
  const levels = attribute?.levels || [];

  const selectedLevel = useMemo(() => {
    return levels.find((level) => level.id === activeLevelId);
  }, [activeLevelId, levels]);

  return (
    <Box
      className="round-corners"
      backgroundColor={Colors.WHITE}
      p={16}
      mb={16}
    >
      <Box fontWeight={600}>{attribute?.name}</Box>
      <Box fontSize={14} color={SUBTEXT_COLOR}>
        {attribute?.description}
      </Box>

      <Flex my={16} p="6px" backgroundColor="#EEF1F8" className="round-corners">
        {levels.map((level) => (
          <Clickable key={level.id} onClick={() => onChangeLevel(level.id)}>
            <Flex
              flex={1}
              alignItems="center"
              justifyContent="center"
              className="round-corners"
              color={
                isSelected(activeLevelId, level.id)
                  ? BRAND_PURPLE
                  : SUBTEXT_COLOR
              }
              backgroundColor={
                isSelected(activeLevelId, level.id)
                  ? Colors.WHITE
                  : "transparent"
              }
              fontWeight={isSelected(activeLevelId, level.id) ? 600 : 400}
              py={10}
            >
              {level.name}
            </Flex>
          </Clickable>
        ))}
      </Flex>

      <Box color={MIDTEXT_COLOR} fontSize={15}>
        {selectedLevel?.description}
      </Box>
    </Box>
  );
}

interface PersonalityTraitSelectorProps {
  personalityTraits: PersonalityAttributesQuery["personalityAttributes"];
  onChangePersonalityTraits: (
    traits: PersonalityAttributesQuery["personalityAttributes"],
  ) => void;
}

function PersonalityTraitSelector({
  personalityTraits,
  onChangePersonalityTraits,
}: PersonalityTraitSelectorProps) {
  const { personalityAttributes = [] } = usePersonalityAttributesQuery({
    onCompleted: () => {},
    onError: (error) => {
      console.log("Error fetching personality attributes:", error);
    },
  });

  return (
    <Box my={16}>
      {personalityAttributes?.map((attribute) => {
        return (
          <PersonalityTraitSwitch
            attribute={attribute}
            key={attribute.id}
            activeLevelId={personalityTraits?.[attribute?.id]}
            onChangeLevel={(levelId) => {
              onChangePersonalityTraits({
                ...personalityTraits,
                [attribute.id]: levelId,
              });
            }}
          />
        );
      })}
    </Box>
  );
}

export default PersonalityTraitSelector;
