import { useEffect, useState } from "react";

import { Callout, Spinner } from "@blueprintjs/core";

import { Box } from "@/components/layout/flexbox";
import CardSelector from "@/components/pieces/buy-credits//CardSelector";
import { AvailableCreditsInner } from "@/components/pieces/coaching/available-credits";

export default function PaymentMethodSelector({
  loading,
  paymentMethods,
  paymentRequest,
  selectedMethodId,
  setSelectedMethodId,
  billingName,
  setBillingName,
  orderCreditTotal,
  user,
  handleSubmit,
  allowBuyCredits = true,
  selectedType,
  onChangePaymentType,
}) {
  const [defaultHandled, setDefaultHandled] = useState(false);

  useEffect(() => {
    if (user && !defaultHandled) {
      if (!allowBuyCredits) {
        onChangePaymentType("CREDITS");
        setSelectedMethodId("CREDITS");
      }
      setDefaultHandled(true);
    }
  }, [user, allowBuyCredits]);

  if (loading) return <Spinner intent="primary" />;

  return (
    <>
      {selectedType === "CARD" ? (
        <CardSelector
          paymentRequest={paymentRequest}
          paymentMethods={paymentMethods}
          selectedMethodId={selectedMethodId}
          setSelectedMethodId={setSelectedMethodId}
          billingName={billingName}
          setBillingName={setBillingName}
          handleSubmit={handleSubmit}
        />
      ) : selectedType === "CREDITS" ? (
        <>
          <Box fontSize={18} fontWeight={600} mb={2}>
            Using Credits to Buy Bits
          </Box>
          <AvailableCreditsInner
            user={user}
            ctaLink={undefined}
            className="bas round-corners"
            py={2}
            px={2}
          />
          {user && user.availableCredits < orderCreditTotal ? (
            <Box mt={3}>
              <Callout intent="warning" title="Not enough credits">
                Your account doesn't have enough credits to complete this order.
              </Callout>
            </Box>
          ) : null}
        </>
      ) : null}
    </>
  );
}
