import { Flex } from "@/components/layout/flexbox";
import Filter from "@/components/pages_logged_in/workspace/pieces/Filter";
import FilterButton from "@/components/pages_logged_in/workspace/pieces/FilterButton";

export default function MobileViewTabs({ tabs, currentTab, onTabChange }) {
  return (
    <Flex py={3} alignItems="center" justifyContent="center">
      <Filter
        button={
          <FilterButton rightIcon="chevron-down" text={currentTab.label} />
        }
        items={tabs.map((link) => ({ id: link.path, text: link.label }))}
        isSelectedFn={(x) => currentTab.path === x}
        onSelect={(x) => x !== currentTab.path && onTabChange(x)}
        viewMode="none"
        fullHeight={true}
      />
    </Flex>
  );
}
