import { Colors, Icon } from "@blueprintjs/core";
import styled from "@emotion/styled";

import { Box, Flex } from "@/components/layout/flexbox";
import Clickable from "@/components/pieces/interaction/Clickable";

interface CloseButtonProps {
  onClick: () => void;
  size?: number;
  iconSize?: number;
  theme?: "dark" | "light";
  showLabel?: boolean;
}

export default function CloseButton({
  onClick,
  size = 40,
  iconSize,
  theme = "dark",
  showLabel = false,
}: CloseButtonProps) {
  return (
    <Clickable onClick={onClick}>
      <Container
        width={showLabel ? null : size}
        height={size}
        alignItems="center"
        justifyContent="center"
        dark={theme === "dark"}
      >
        {showLabel ? <Box mr={1}>Close</Box> : null}
        <Icon icon="cross" className="icon" size={iconSize || size / 2} />
      </Container>
    </Clickable>
  );
}

const Container = styled(Flex)<{ dark: boolean }>`
  color: ${(props) => (props.dark ? Colors.LIGHT_GRAY1 : Colors.GRAY3)};

  .icon {
    color: ${(props) => (props.dark ? Colors.LIGHT_GRAY1 : Colors.GRAY3)};
  }
  &:hover {
    color: ${(props) => (props.dark ? Colors.LIGHT_GRAY1 : Colors.GRAY3)};

    .icon {
      color: ${(props) => (props.dark ? Colors.WHITE : Colors.BLACK)};
    }
  }
`;
