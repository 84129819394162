import React, { useRef, useState } from "react";

import { Button, Icon, OverlayToaster, Position } from "@blueprintjs/core";
import styled from "@emotion/styled";
import { Link } from "react-router-dom";

import { useWorkspaceSlug } from "@/components/helpers/custom-hooks/use-workspace-slug";
import { Popover } from "@/components/helpers/ui/blueprint-overrides/Popover";
import { Tooltip } from "@/components/helpers/ui/blueprint-overrides/Tooltip";
import { Box, Flex } from "@/components/layout/flexbox";
import {
  useInviteUsersToSessionMutation,
  useRevokeAccessFromSessionMutation,
} from "@/components/pages_logged_in/roleplays/api";
import NotificationMessageForm from "@/components/pieces/roleplays/sharing/NotificationMessageForm";
import ShareForm from "@/components/pieces/roleplays/sharing/ShareForm";
import StyledDialog from "@/components/pieces/StyledDialog";
import Avatar from "@/components/pieces/users/Avatar";
import { getUser } from "@/components/session/JwtTokenManager";
import { CTA_COLOR, LIGHT_GRAY, MIDTEXT_COLOR } from "@/css/constants";
import {
  RoleplaySessionDocument,
  RoleplaySessionType,
  TaggableUserType,
  UserType,
} from "@/graphql";

const visibleBgStyle = {
  backgroundColor: "#0719560A",
};

function SharedUserPill({
  user,
  isMe,
  isOwner,
  onRemoveClick,
}: {
  user: UserType | TaggableUserType;
  isMe: boolean;
  isOwner: boolean;
  onRemoveClick?: () => void;
}) {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <Flex alignItems="center" justifyContent="space-between" py={"12px"}>
      <Box css={{ display: "flex", alignItems: "center", gap: 12 }}>
        <Tooltip content={user?.name || user?.email}>
          <Avatar user={user} size={36} />
        </Tooltip>
        <Flex style={{ gap: 8 }} flexDirection={"column"}>
          {user?.name && (
            <Box css={titleCss} lineHeight={"14px"}>
              {user?.name} {isMe && `(You)`}
            </Box>
          )}
          <Box fontSize={12} color={MIDTEXT_COLOR} lineHeight={"12px"}>
            {user?.email}
          </Box>
        </Flex>
      </Box>

      <Flex justifyContent={"center"} alignItems={"center"} style={{ gap: 4 }}>
        <Box css={titleCss}>{isOwner ? "Owner" : "Viewer"}</Box>

        <Popover
          canEscapeKeyClose={true}
          position={Position.BOTTOM_RIGHT}
          onClose={() => {
            setIsOpen(false);
          }}
          isOpen={isOpen}
          content={
            <Box p={3}>
              <Box fontSize={16} fontWeight={500} color={"#000"}>
                Remove Viewer
              </Box>
              <Box fontSize={14} color={MIDTEXT_COLOR}>
                Are you sure to remove this viewer?
              </Box>
              <Flex justifyContent={"flex-end"} mt={3} style={{ gap: 8 }}>
                <Button
                  style={{ height: 32 }}
                  intent="danger"
                  onClick={() => {
                    onRemoveClick();
                    setIsOpen(false);
                  }}
                >
                  Yes
                </Button>
                <Button
                  style={{ height: 32 }}
                  onClick={() => {
                    setIsOpen(false);
                  }}
                >
                  No
                </Button>
              </Flex>
            </Box>
          }
        >
          <Button
            icon="cross"
            minimal={true}
            onClick={(e) => {
              e.stopPropagation();
              setIsOpen(!isOpen);
            }}
            style={{
              height: 12,
              width: 12,
              padding: 0,
              fontSize: 12,
              opacity: isOwner ? 0 : 1,
              cursor: isOwner ? "default" : "pointer",
            }}
            disabled={isOwner}
          />
        </Popover>
      </Flex>
    </Flex>
  );
}

function RoleplaySessionAccessButton({
  session,
}: {
  session: RoleplaySessionType;
}) {
  const [copied, setCopied] = useState(null);
  const user = session?.user;

  const isMe = user?.id === getUser()?.id;

  const isShared =
    session?.programs?.length > 0 || session?.sharedMembers?.length > 0;
  const [open, setOpen] = React.useState(false);
  const [isNotified, setIsNotified] = useState(true);
  const [message, setMessage] = useState("");
  const [selectedEmails, setSelectedEmails] = useState<string[]>([]);
  const workspaceSlug = useWorkspaceSlug();

  const handleClose = () => {
    setOpen(false);
    setSelectedEmails([]);
    setIsNotified(true);
    setMessage("");
  };

  const refetchQueries = [
    {
      query: RoleplaySessionDocument,
      variables: { uuid: session?.uuid },
    },
  ];

  const toasterRef = useRef(null);
  const { inviteUsersToSession, loading } = useInviteUsersToSessionMutation({
    onCompleted: () => {
      toasterRef.current.show({
        message: "Session shared",
        intent: "success",
      });
      handleClose();
    },
    onError: () => {},
    refetchQueries: refetchQueries,
  });

  const { revokeAccessFromSession } = useRevokeAccessFromSessionMutation({
    onCompleted: () => {},
    onError: () => {},
    refetchQueries: refetchQueries,
  });

  const handleInvite = () => {
    const emails = selectedEmails;
    inviteUsersToSession(session?.id, emails, isNotified, message);
  };

  const handleRevoke = (workspaceMemberId: string) => {
    revokeAccessFromSession(session?.id, workspaceMemberId);
  };

  const handleCopyLink = async () => {
    if (copied) {
      return;
    }
    try {
      setCopied(true);
      setTimeout(() => {
        setCopied(false);
      }, 5000);
      await navigator.clipboard.writeText(window.location.href);
    } catch {
      // noop
    }
  };

  const inNotificationState = selectedEmails.length > 0;
  const primaryButtonText = inNotificationState
    ? isNotified
      ? "Send"
      : "Share"
    : "Done";

  return (
    <>
      <OverlayToaster position="top" ref={toasterRef} usePortal={true} />
      <Button
        icon={"eye-open"}
        style={visibleBgStyle}
        onClick={() => {
          setOpen(true);
        }}
      >
        {isShared ? "Shared" : "Only Me"}
      </Button>
      <StyledDialog
        isOpen={open}
        lazy={true}
        title={<Box fontSize={18}>Session Access</Box>}
        width="450px"
        onClose={handleClose}
      >
        <Box css={{ padding: "16px", gap: 32 }}>
          {workspaceSlug && (
            <>
              <Box fontWeight={600} p={1}>
                Enter Emails
              </Box>
              <ShareForm
                onChange={(memberEmails) => {
                  setSelectedEmails(memberEmails);
                }}
              />
            </>
          )}

          {inNotificationState && (
            <NotificationMessageForm
              isNotified={isNotified}
              message={message}
              onIsNotifiedChange={setIsNotified}
              onMessageChange={setMessage}
            />
          )}

          {!inNotificationState && (
            <Box mt={"24px"}>
              <Box fontSize={16} fontWeight={500}>
                People with access
              </Box>
              <SharedUserPill isMe={isMe} isOwner={true} user={user} />
              {session?.sharedMembers?.map((viewer) => (
                <SharedUserPill
                  key={viewer?.id}
                  isMe={viewer?.user?.id === getUser()?.id}
                  isOwner={false}
                  user={viewer.user}
                  onRemoveClick={() => handleRevoke(viewer.id)}
                />
              ))}

              {isShared && (
                <Box mt={"16px"}>
                  <Box fontSize={16} fontWeight={500}>
                    Other Viewers
                  </Box>
                  <Box fontSize={14} color={MIDTEXT_COLOR}>
                    This session is visible to program leads in these programs:
                  </Box>
                  <Box style={{ gap: 16 }} mt={"16px"}>
                    {session?.programs?.map((program) => (
                      <Link
                        to={`/programs/${program.id}`}
                        key={program?.id}
                        style={{ marginBottom: 8, display: "block" }}
                      >
                        <StyledButton>
                          <Box css={titleCss}>{program.name}</Box>
                        </StyledButton>
                      </Link>
                    ))}
                  </Box>
                </Box>
              )}
            </Box>
          )}

          <Flex mt={4} justifyContent="space-between">
            <Button
              icon={
                <Icon
                  icon={copied ? "tick" : "link"}
                  style={{
                    color: copied ? "#0D8050" : "black",
                  }}
                />
              }
              large={true}
              onClick={handleCopyLink}
              style={{
                height: 40,
                background: LIGHT_GRAY,
                boxShadow: "none",
                fontSize: 16,
                color: copied ? "#0D8050" : "black",
              }}
            >
              Copy Link
            </Button>
            <Button
              loading={loading}
              large={true}
              intent="primary"
              onClick={() => {
                if (inNotificationState) {
                  handleInvite();
                } else {
                  handleClose();
                }
              }}
              style={{ height: 40, boxShadow: "none", fontSize: 16 }}
            >
              {primaryButtonText}
            </Button>
          </Flex>
        </Box>
      </StyledDialog>
    </>
  );
}

const titleCss: any = {
  fontSize: "14px",
  fontWeight: 500,
  color: "black",
};

export default RoleplaySessionAccessButton;

const StyledButton = styled(Button)`
  width: 100%;
  display: flex;
  justify-content: flex-start !important;
  height: 38px;
  border: 1px solid #e2e2e2 !important;
  box-shadow: none !important;
  padding: 7px 16px !important;

  &:hover,
  &:focus,
  &:active {
    cursor: pointer;
    border-color: ${CTA_COLOR} !important;
  }
`;
