import { ScenarioDifficulty, VisibilityScope } from "@/graphql";

export function buildRoleplayUrl(roleplaySlug: string) {
  return `/roleplays/${roleplaySlug}`;
}

export function buildScenarioUrl(roleplaySlug: string, scenarioSlug: string) {
  if (!roleplaySlug) {
    return `/scenarios/${scenarioSlug}`;
  }
  return `/roleplays/${roleplaySlug}/${scenarioSlug}`;
}

export function buildSessionUrl(
  roleplaySlug: string,
  scenarioSlug: string,
  sessionUuid: string,
) {
  if (!roleplaySlug) {
    return `/scenarios/${scenarioSlug}/${sessionUuid}`;
  }
  return `/roleplays/${roleplaySlug}/${scenarioSlug}/${sessionUuid}`;
}

export function buildCreateScenarioUrl(roleplaySlug: string = null) {
  if (!roleplaySlug) {
    return "/roleplays/scenarios/create";
  }
  return `/roleplays/${roleplaySlug}/create`;
}

export function buildIntakeUrl(intakeUuid: string) {
  return `/roleplays/intake/${intakeUuid}`;
}

export const CustomScenarioSegmentTitle = "Custom Scenarios";
export const CoreScenarioSegmentTitle = "Core Scenarios";

export const SCENARIO_SCOPE_ORDER = [
  {
    name: CustomScenarioSegmentTitle,
    scopes: [VisibilityScope.Workspace, VisibilityScope.User],
  },
  {
    name: CoreScenarioSegmentTitle,
    scopes: [VisibilityScope.Global],
  },
];

export const SCENARIO_DIFFICULTY_ORDER = {
  [ScenarioDifficulty.Custom]: 0,
  [ScenarioDifficulty.Easy]: 1,
  [ScenarioDifficulty.Medium]: 2,
  [ScenarioDifficulty.Hard]: 3,
  [ScenarioDifficulty.VeryHard]: 4,
};

export function sortScenariosByDifficulty(scenarios = []) {
  return scenarios.slice().sort((a, b) => {
    const indexA = SCENARIO_DIFFICULTY_ORDER?.[a.difficulty];
    const indexB = SCENARIO_DIFFICULTY_ORDER?.[b.difficulty];
    return indexA - indexB;
  });
}
