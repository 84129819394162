import { useContext, useMemo } from "react";

import { ResponseContext } from "@/components/ResponseContext";
import { getWorkspaceSlug } from "@/lib/workspace-utilities";

export function useWorkspaceSlug(): string | null {
  const context = useContext(ResponseContext);

  if (!context) {
    throw new Error(
      "useWorkspaceSlug must be used within a ResponseContextProvider",
    );
  }

  return useMemo(() => {
    const host =
      typeof window === "undefined"
        ? context.req?.headers?.host || ""
        : window.location.host;

    return getWorkspaceSlug(host);
  }, []); // Empty deps since we only need to calculate this once
}
