import { Colors, Icon, Menu, MenuItem, Position } from "@blueprintjs/core";
import styled from "@emotion/styled";
import moment from "moment";
import xss from "xss";

import { unescape } from "@/components/helpers/string-utils";
import { Popover } from "@/components/helpers/ui/blueprint-overrides/Popover";
import { Box, Flex } from "@/components/layout/flexbox";
import { decodeHtmlEntities } from "@/components/pages_logged_in/roleplays/details/scenario/Notes";
import RoundedIconButton from "@/components/pieces/form/RoundedIconButton";
import { CARD_CLASSES, SUBTEXT_COLOR } from "@/css/constants";

import StarRating from "./StarRating";

export default function ClientCommentBlock({
  enableRatingMenu = false,
  rating,
  onPressFeatureRating,
  mt = 0,
}) {
  return (
    <Box p={24} mt={mt} className={CARD_CLASSES} backgroundColor="#FFF">
      <Flex
        alignItems="center"
        justifyContent="space-between"
        flexWrap={["wrap", "nowrap"]}
      >
        <Box flex={1}>
          <Flex alignItems="center" justifyContent="space-between">
            <WrapText fontSize={18} fontWeight={500}>
              {rating.truncatedName}
            </WrapText>
            <Box color={SUBTEXT_COLOR} ml={1}>
              {moment(rating.updatedAt).format("MMM D, YYYY")}
            </Box>
          </Flex>
          <Flex
            justifyContent="space-between"
            alignItems={["flex-start", "flex-start", "center", "center"]}
            flexDirection={["column", "column", "row", "row"]}
          >
            <Box mt={1}>
              <StarRating score={rating.score} />
            </Box>
            {rating.featured && (
              <Flex color={SUBTEXT_COLOR} alignItems="center">
                <Icon icon="pin" size={16} />
                <Box ml={2}>Featured Comment</Box>
              </Flex>
            )}
          </Flex>
        </Box>

        {enableRatingMenu && (
          <Box ml={3}>
            <Popover
              canEscapeKeyClose={true}
              position={Position.BOTTOM_RIGHT}
              content={
                <Menu>
                  {rating.featured ? (
                    <MenuItem
                      onClick={() => onPressFeatureRating(rating.id, false)}
                      text='Stop Featuring"'
                      icon="disable"
                    />
                  ) : (
                    <MenuItem
                      onClick={() => onPressFeatureRating(rating.id, true)}
                      text="Feature Comment"
                      icon="star"
                    />
                  )}
                </Menu>
              }
            >
              <RoundedIconButton
                backgroundColor={Colors.LIGHT_GRAY5}
                icon="more"
                size={44}
                onClick={() => undefined}
              />
            </Popover>
          </Box>
        )}
      </Flex>

      <Box mt={2} css={{ whiteSpace: "pre-line" }}>
        {unescape(xss(decodeHtmlEntities(rating.comment)))}
      </Box>
    </Box>
  );
}

const WrapText = styled(Box)`
  overflow-wrap: anywhere;
`;
