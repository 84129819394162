import { Button, Classes, Icon } from "@blueprintjs/core";
import styled from "@emotion/styled";

import { commafy } from "@/components/helpers/string-utils";
import { Box, Flex } from "@/components/layout/flexbox";
import { PagingSticky } from "@/components/pages_logged_in/workspace-admin/WorkspaceMemberTable";

const NavButton = styled(Button)`
  &.${Classes.BUTTON} {
    padding: 0;
    border-radius: 6px;
    width: 35px;
    height: 40px;
  }
`;

function Paging({
  totalRecords,
  pageSize,
  currentPage,
  setCurrentPage,
  isSticky = false,
  style = {},
}) {
  const maxPageNumber =
    Math.floor(totalRecords / pageSize) +
    (totalRecords % pageSize === 0 ? 0 : 1);
  const allowPrev = currentPage > 1;
  const allowNext = currentPage < maxPageNumber;
  const minRecordIndex =
    (currentPage - 1) * pageSize + (totalRecords > 0 ? 1 : 0);
  const overflowSize = !allowNext ? currentPage * pageSize - totalRecords : 0;
  const maxRecordIndex =
    totalRecords > 0 ? currentPage * pageSize - overflowSize : 0;
  const onPrev = () => {
    setCurrentPage((x) => x - 1);
  };

  const onNext = () => {
    setCurrentPage((x) => x + 1);
  };
  const PagingStyled = isSticky ? PagingSticky : Flex;
  return (
    <PagingStyled style={style} justifyContent="center" alignItems="center">
      <Flex minWidth="180px" justifyContent="space-between" alignItems="center">
        <NavButton
          disabled={!allowPrev}
          onClick={onPrev}
          icon={<Icon icon="chevron-left" />}
        />
        <Box mx={16}>
          {minRecordIndex}-{maxRecordIndex} of {commafy(totalRecords, 0, 0)}
        </Box>
        <NavButton
          disabled={!allowNext}
          onClick={onNext}
          icon={<Icon icon="chevron-right" />}
        />
      </Flex>
    </PagingStyled>
  );
}

export default Paging;
