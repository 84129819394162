import { useCallback, useEffect, useState } from "react";

import { Event, StreamChat } from "stream-chat";

import { useCurrentUser } from "@/components/helpers/custom-hooks/use-current-user";

// TODO(bdj): deprecate the useUnreadMessages hook and use this one instead

export function useUnreadMessageCount({
  onError,
  channelId,
}: {
  onError?: (error: Error) => void;
  channelId?: string;
}) {
  const user = useCurrentUser();
  const [unreadCount, setUnreadCount] = useState(0);
  const onChatEvent = useCallback(
    (event: Event) => {
      if (event.unread_count !== undefined) {
        setUnreadCount(event.unread_count);
      }
    },
    [setUnreadCount],
  );

  useEffect(() => {
    if (!user) {
      return;
    }
    const chatClient = StreamChat.getInstance(STREAM_CHAT_API_KEY);
    if (!chatClient.user || chatClient.user.id != user.id) {
      if (chatClient.user) {
        chatClient.disconnectUser();
      }
      const connection = chatClient.connectUser(
        {
          id: user.id,
          name: user.name,
          image: user.photo,
        },
        user.chatToken,
      );
      connection.then((connection) => {
        if (connection) {
          if (channelId) {
            setUnreadCount(chatClient.channel(channelId).state.unreadCount);
          } else if (connection.me?.total_unread_count) {
            setUnreadCount(connection.me.total_unread_count);
          }
        }
      });
      if (onError) {
        connection.catch(onError);
      }
    } else if (chatClient.user?.id === user?.id) {
      if (channelId) {
        setUnreadCount(chatClient.channel(channelId).state.unreadCount);
      } else if ("total_unread_count" in chatClient.user) {
        setUnreadCount(chatClient.user.total_unread_count as number);
      }
    }
    if (channelId) {
      chatClient.channel(channelId).on(onChatEvent);
    } else {
      chatClient.on(onChatEvent);
    }
    return () => {
      if (channelId) {
        chatClient.channel(channelId).off(onChatEvent);
      } else {
        chatClient.off(onChatEvent);
      }
    };
  }, [user, onChatEvent, onError, channelId]);

  return { unreadCount };
}
