import { useEffect, useState } from "react";

import { Classes, Colors, Icon } from "@blueprintjs/core";
import styled from "@emotion/styled";
import classnames from "classnames";
import { useMediaQuery } from "react-responsive";

import { Box, Flex } from "@/components/layout/flexbox";
import { isMdQuery, isXsToMdQuery } from "@/components/layout/Media";
import { useScenariosLazyQuery } from "@/components/pages_logged_in/roleplays/api";
import { ScenariosPageType } from "@/components/pages_logged_in/roleplays/scenarios/const";
import PaginationHeader from "@/components/pages_logged_in/roleplays/scenarios/PaginationHeader";
import { CustomScenarioSegmentTitle } from "@/components/pages_logged_in/roleplays/utils";
import Clickable from "@/components/pieces/interaction/Clickable";
import ScenarioMiniCard, {
  CreateScenarioCard,
} from "@/components/pieces/roleplays/ScenarioMiniCard";
import { SUBTEXT_COLOR } from "@/css/constants";

const SCENARIO_CARD_HEIGHT = 163;
const OFF_SCEEN_POSITION = 100;

const CREATE_SCENARIO = {
  id: "custom",
  name: CustomScenarioSegmentTitle,
  shortDesc: null,
  rank: null,
};

function CarouselScenarios({
  scenarios,
  loading,
  scenarioCardWidth,
  pageSize,
  itemPerLine,
  page,
  totalPages,
}) {
  const [positions, setPositions] = useState([]);
  const containerHeight = (pageSize / itemPerLine) * SCENARIO_CARD_HEIGHT;

  useEffect(() => {
    const newPositions = Array.from({ length: totalPages }, (_, i) => {
      if (i + 1 < page) return -1 * OFF_SCEEN_POSITION; // Left of viewport
      if (i + 1 > page) return OFF_SCEEN_POSITION; // Right of viewport
      return 0; // In viewport
    });
    setPositions(newPositions);
  }, [page, totalPages]);

  if (scenarios && loading) {
    return (
      <SliderSkeleton
        scenarioCardWidth={scenarioCardWidth}
        pageSize={pageSize}
        itemPerLine={itemPerLine}
      />
    );
  }

  if (scenarios?.length === 0) {
    return (
      <Flex
        flexWrap="wrap"
        minHeight={containerHeight}
        alignItems="center"
        justifyContent="center"
      >
        <Box color={SUBTEXT_COLOR}>No Scenarios</Box>
      </Flex>
    );
  }

  const isActive = (index) => index + 1 === page;
  const isNearby = (index) => !isActive(index) && Math.abs(index + 1 - page);

  return (
    <CarouselContainer minHeight={containerHeight} css={{ overflow: "hidden" }}>
      {positions.map((position, index) => (
        <Box
          key={`position-${index}`}
          className={classnames("carousel-slide", {
            active: isActive(index),
            nearby: isNearby(index),
          })}
          css={{
            transform: `translateX(${position}%)`,
          }}
        >
          <Flex flexWrap="wrap" className="w-full">
            {scenarios
              .slice(index * pageSize, (index + 1) * pageSize)
              .map((scenario, scenarioIndex) => (
                <Box
                  key={scenario.id}
                  width={scenarioCardWidth}
                  mr={(scenarioIndex + 1) % itemPerLine === 0 ? 0 : 16}
                  mt={16}
                >
                  {scenario?.name === CustomScenarioSegmentTitle ? (
                    <CreateScenarioCard variant="primary" />
                  ) : (
                    <ScenarioMiniCard
                      scenario={scenario}
                      showShortDesc={false}
                    />
                  )}
                </Box>
              ))}
          </Flex>
        </Box>
      ))}
    </CarouselContainer>
  );
}

function SliderSkeleton({ scenarioCardWidth, pageSize, itemPerLine }) {
  return (
    <Flex flexWrap="wrap">
      {Array.from({ length: pageSize }, (_, index) => (
        <Box
          key={index}
          width={scenarioCardWidth}
          mr={(index + 1) % itemPerLine == 0 ? 0 : 16}
          mt={16}
          minHeight={147}
          maxHeight={147}
          className={Classes.SKELETON}
        />
      ))}
    </Flex>
  );
}

const useResponsiveMetaData = () => {
  const isSmallScreen = useMediaQuery(isXsToMdQuery);
  const isMediumScreen = useMediaQuery(isMdQuery);

  if (isSmallScreen) {
    return {
      scenarioCardWidth: `calc((100% - 16px) / 2)`,
      pageSize: 4,
      itemPerLine: 2,
    };
  }
  if (isMediumScreen) {
    return {
      scenarioCardWidth: `calc((100% - 32px) / 3)`,
      pageSize: 3,
      itemPerLine: 3,
    };
  }

  return {
    scenarioCardWidth: `calc((100% - 48px) / 4)`,
    pageSize: 4,
    itemPerLine: 4,
  };
};

export default function ScenariosSlider({
  scenarioType = ScenariosPageType.Assigned,
  icon = null,
  title = null,
  orderBy = null,
}) {
  const [isShow, setIsShow] = useState(true);
  const [page, setPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [firstLoad, setFirstLoad] = useState(true);
  const [scenarioList, setScenarioList] = useState(
    scenarioType === ScenariosPageType.Personal ? [CREATE_SCENARIO] : [],
  );
  const { scenarioCardWidth, pageSize, itemPerLine } = useResponsiveMetaData();
  const isPersonal = scenarioType === ScenariosPageType.Personal;

  const mergePaginatedData = (scenarios, pageSize, page) => {
    const hasCreateButton = isPersonal;
    const startIndex = (page - 1) * pageSize + (hasCreateButton ? 1 : 0);
    if (hasCreateButton) {
      const [customElement, ...rest] = scenarioList;
      return [
        customElement,
        ...rest.slice(0, startIndex - 1),
        ...scenarios,
        ...rest.slice(startIndex - 1 + scenarios.length),
      ];
    }
    return [
      ...scenarioList.slice(0, startIndex),
      ...scenarios,
      ...scenarioList.slice(startIndex + scenarios.length),
    ];
  };

  const { loading, queryPersonalScenarios, queryAssignedScenarios } =
    useScenariosLazyQuery({
      onCompleted: (responseData) => {
        const {
          page: currentPage,
          data: scenarios,
          pageSize,
          totalRecords,
        } = responseData;
        if (scenarios) {
          const newScenarioList = mergePaginatedData(
            scenarios,
            pageSize,
            currentPage,
          );

          setScenarioList(newScenarioList);
          setTotalRecords(totalRecords);

          if (firstLoad) {
            setFirstLoad(false);
          }
        }
      },
      onError: () => {},
    });

  const queryScenarios = isPersonal
    ? queryPersonalScenarios
    : queryAssignedScenarios;

  const totalRecordsWithCreateButton = isPersonal
    ? totalRecords + 1
    : totalRecords;
  const totalPageWithCreateButton = Math.ceil(
    totalRecordsWithCreateButton / pageSize,
  );

  useEffect(() => {
    const queryPage = Math.ceil((page + 1) / 2);

    queryScenarios({
      page: queryPage,
      pageSize: pageSize * 2,
      orderBy,
    });
  }, [page, pageSize, orderBy]);

  useEffect(() => {
    if (isPersonal && scenarioList?.length === 0) {
      setScenarioList([CREATE_SCENARIO]);
    }
  }, [isPersonal]);

  useEffect(() => {
    if (scenarioList.length === 0 && page === 1 && !firstLoad) {
      setIsShow(false);
    }
  }, [scenarioList, page, firstLoad]);

  const handleNextPage = () => {
    setPage(page + 1);
  };
  const handlePrevPage = () => {
    setPage(page - 1);
  };

  const numberOfScenarios = scenarioList?.length - (isPersonal ? 1 : 0);
  const sliderLoading =
    loading && numberOfScenarios < pageSize * (page - 1) + 1;

  return (
    <Box
      p={24}
      className="bas round-corners"
      backgroundColor={Colors.WHITE}
      mt={16}
    >
      <PaginationHeader
        page={page}
        totalPages={totalPageWithCreateButton}
        onNext={handleNextPage}
        onPrev={handlePrevPage}
        viewAllUrl={`/roleplays/${isPersonal ? "personal" : "assigned"}`}
        title={
          <Clickable onClick={() => setIsShow(!isShow)}>
            <Flex fontSize={18} fontWeight={600} alignItems="center">
              {icon}
              <Box mx={10}>{title}</Box>
              <Icon icon={isShow ? "chevron-up" : "chevron-down"} />
            </Flex>
          </Clickable>
        }
      />

      {isShow && (
        <CarouselScenarios
          scenarios={scenarioList}
          loading={sliderLoading}
          scenarioCardWidth={scenarioCardWidth}
          pageSize={pageSize}
          itemPerLine={itemPerLine}
          page={page}
          totalPages={totalPageWithCreateButton}
        />
      )}
    </Box>
  );
}

const CarouselContainer = styled(Box)`
  position: relative;

  .carousel-slide {
    position: absolute;
    width: 100%;
    transition: transform 400ms ease-out;
    opacity: 0;
    pointer-events: none;
  }

  .carousel-slide.active {
    opacity: 1;
    pointer-events: auto;
  }

  .carousel-slide.nearby {
    opacity: 1;
  }
`;
