import { Colors } from "@blueprintjs/core";

import { Box } from "@/components/layout/flexbox";
import { getUser, isImpersonating } from "@/components/session/JwtTokenManager";

export default function ImpersonatingMessage() {
  if (!isImpersonating()) {
    return null;
  }

  const user = getUser();

  return (
    <Box
      style={{
        backgroundColor: Colors.GOLD3,
        color: Colors.WHITE,
        borderRadius: 6,
      }}
      ml={3}
      p="7px"
    >
      Impersonating {user.email}
    </Box>
  );
}
