import moment, { Moment } from "moment";

export function findNextEvenWeekTuesday(date?: Moment): string {
  date = date || moment();

  if (date.week() % 2 === 0) {
    // Handle even-numbered weeks
    if (date.day() === 2) {
      // If today is Tuesday, return Today
      // No changes needed
    } else if (date.day() < 2) {
      // If today is Sunday or Monday, return the upcoming Tuesday
      date.day(2);
    } else {
      // If it's Weds through Sat, return the next-next Tuesday
      date.add(2, "week").day(2);
    }
  } else {
    // Handle odd-numbered weeks
    if (date.day() <= 2) {
      // If today is Sunday, Monday or Tuesday, return the next-next Tuesday
      date.add(1, "week").day(2);
    } else {
      // If it's Tues through Sat, return the next Tuesday
      date.add(1, "week").day(2);
    }
  }

  return date.format("MMMM Do, YYYY");
}
