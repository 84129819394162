import React, { ReactNode } from "react";

import {
  Classes,
  Colors,
  Icon,
  IconName,
  Menu,
  MenuDivider,
  MenuItem,
} from "@blueprintjs/core";
import styled from "@emotion/styled";
import classnames from "classnames";

import { Popover } from "@/components/helpers/ui/blueprint-overrides/Popover";
import { Box, Flex } from "@/components/layout/flexbox";
import { BP_NS, BRAND_PURPLE } from "@/css/constants";

function UncheckedIcon() {
  return (
    <Box
      width="22px"
      height="22px"
      style={{ border: `1px solid ${Colors.GRAY5}`, borderRadius: "4px" }}
    />
  );
}
function CheckedIcon({ icon = "tick" }: { icon?: IconName }) {
  return (
    <Flex
      style={{
        backgroundColor: BRAND_PURPLE,
        borderRadius: "4px",
        border: `1px solid ${BRAND_PURPLE}`,
      }}
      alignItems="center"
      justifyContent="center"
      width="22px"
      height="22px"
    >
      <Icon icon={icon} style={{ color: Colors.WHITE }} />
    </Flex>
  );
}
function MenuItemWrap({
  multiple = false,
  selected = false,
  id,
  text,
  onSelect,
  viewMode = "none",
}) {
  let icon = null;
  if (viewMode === "checkbox" || multiple) {
    icon = selected ? <CheckedIcon /> : <UncheckedIcon />;
  }
  if (viewMode === "radio") {
    icon = selected ? (
      <Icon icon="selection" size={20} color={BRAND_PURPLE} />
    ) : (
      <Icon icon="circle" size={20} />
    );
  }
  return (
    <MenuItem
      onClick={() => onSelect(id)}
      className={classnames({ [`${BP_NS}-item-selected`]: selected })}
      text={text}
      icon={icon}
      shouldDismissPopover={!multiple}
    />
  );
}

interface FilterProps {
  items: {
    id: string | number;
    text: ReactNode;
    hasDividerBelow?: boolean;
  }[];
  button: ReactNode;
  isSelectedFn: (item: any) => boolean;
  multiple?: boolean;
  onSelect?: (id: string) => void;
  menuPosition?: "top-left" | "top-right" | "bottom-left" | "bottom-right";
  viewMode?: "none" | "radio" | "checkbox";
  fullHeight?: boolean;
}

function Filter({
  items = [],
  button,
  isSelectedFn,
  multiple = false,
  onSelect,
  menuPosition = "bottom-right",
  viewMode = "none",
  fullHeight = false,
}: FilterProps) {
  const length = items.length;
  const content = (
    <StyledMenu className={fullHeight ? "popup-full-height" : ""}>
      {items.map(({ id, text, hasDividerBelow }, index) => (
        <React.Fragment key={id}>
          <MenuItemWrap
            key={id}
            multiple={multiple}
            id={id}
            text={text}
            selected={isSelectedFn(id)}
            onSelect={onSelect}
            viewMode={viewMode}
          />
          {hasDividerBelow && index < length - 1 && <MenuDivider />}
        </React.Fragment>
      ))}
    </StyledMenu>
  );

  return (
    <StyledPopover
      content={content}
      modifiers={{ arrow: { enabled: false } }}
      position={menuPosition}
    >
      {button}
    </StyledPopover>
  );
}

const StyledPopover = styled(Popover)`
  .${Classes.POPOVER} {
    overflow-x: hidden;
    overflow-y: scroll;
    max-height: 320px;
    margin-top: 5px;
    background-color: ${Colors.WHITE};
  }
  &.popup-full-height {
    .${Classes.POPOVER} {
      max-height: 100%;
      overflow-y: auto;
    }
  }
`;

const StyledMenu = styled(Menu)`
  overflow-x: hidden;
  overflow-y: scroll;
  max-height: 320px;

  &.popup-full-height {
    max-height: 100%;
    overflow-y: auto;
  }

  &.${Classes.MENU} {
    padding: 0;
  }
  &.${Classes.MENU} li {
    padding: 0;
    .${Classes.MENU_ITEM} {
      padding: 12px 16px;
      border-radius: 0;
      font-size: 14px;
    }
  }
  &.${Classes.MENU} .${Classes.FILL} {
    margin-left: 8px;
    font-weight: 400;
  }
  &.${Classes.MENU} .${BP_NS}-item-selected .${Classes.FILL} {
    font-weight: 600;
  }
  &.${Classes.MENU} .${Classes.MENU_DIVIDER} {
    padding: 0;
    margin: 0 0 0 0;
  }
`;

export default Filter;
