import { useEffect, useState } from "react";

import { useMutation, useQuery } from "@apollo/client";
import {
  Icon,
  Menu,
  MenuItem,
  Portal,
  Position,
  Spinner,
} from "@blueprintjs/core";

import { Popover } from "@/components/helpers/ui/blueprint-overrides/Popover";
import { Box, Flex } from "@/components/layout/flexbox";
import SlideContainer, { Directions } from "@/components/layout/SlideContainer";
import { CARD_BRANDS } from "@/components/pieces/buy-credits/constants";
import { StyledButton } from "@/components/pieces/link/LinkButton";
import Toast from "@/components/pieces/Toast";
import { SUBTEXT_COLOR } from "@/css/constants";
import { PaymentMethodsDocument, RemovePaymentMethodDocument } from "@/graphql";

export default function PaymentMethods() {
  const { data } = useQuery(PaymentMethodsDocument);
  const [savedVisible, setSavedVisible] = useState(false);
  const [removePaymentMethod, { loading: removePending }] = useMutation(
    RemovePaymentMethodDocument,
    {
      refetchQueries: [{ query: PaymentMethodsDocument }],
      onCompleted: () => {
        setSavedVisible(true);
      },
    },
  );

  useEffect(() => {
    if (!savedVisible) return;
    const timeout = setTimeout(() => {
      setSavedVisible(false);
    }, 3000);
    return () => clearTimeout(timeout);
  }, [savedVisible]);

  const paymentMethods = data && data.paymentMethods;

  return (
    <Box my={4}>
      <Box px={3} pb={1} className="small-caps" fontWeight={500}>
        Payment Methods
      </Box>
      <Box mt={3} p={3} backgroundColor="#FFF">
        {paymentMethods ? (
          paymentMethods.map((paymentMethod) => {
            return (
              <Flex
                key={paymentMethod.stripeId}
                alignItems="center"
                justifyContent="space-between"
              >
                <div>
                  <b>{CARD_BRANDS[paymentMethod.brand]}</b> ending in{" "}
                  {paymentMethod.last4}
                </div>
                {removePending ? (
                  <Spinner size={24} />
                ) : (
                  <Popover
                    canEscapeKeyClose={true}
                    position={Position.BOTTOM_RIGHT}
                    content={
                      <Menu>
                        <MenuItem
                          onClick={() => {
                            removePaymentMethod({
                              variables: { stripeId: paymentMethod.stripeId },
                            });
                          }}
                          text="Remove"
                          icon="cross"
                        />
                      </Menu>
                    }
                  >
                    <StyledButton onClick={() => null}>
                      <Box minWidth={20}>
                        <Icon icon="more" color={SUBTEXT_COLOR} size={20} />
                      </Box>
                    </StyledButton>
                  </Popover>
                )}
              </Flex>
            );
          })
        ) : (
          <Spinner size={24} />
        )}
        {paymentMethods && !paymentMethods.length ? "No payment methods" : null}
      </Box>
      <Portal>
        <SlideContainer
          visible={savedVisible}
          slideFrom={Directions.DOWN}
          style={{
            position: "fixed",
            top: undefined,
            bottom: 15,
            left: undefined,
            width: 385,
          }}
        >
          <Toast
            message="Saved"
            width={200}
            onClose={() => setSavedVisible(false)}
          />
        </SlideContainer>
      </Portal>
    </Box>
  );
}
