import { Classes } from "@blueprintjs/core";

import { Box, Flex } from "@/components/layout/flexbox";
import { LIGHT_PURPLE } from "@/css/constants";

export default function TodoLoadingIndicator() {
  return (
    <>
      {[1, 2].map((x) => (
        <Box key={x} mt={x > 1 ? 24 : 3}>
          <Flex mb={2} justifyContent="space-between">
            <Box
              className={`${Classes.SKELETON} round-corners`}
              height={30}
              width={[170, 300]}
            />
            <Flex>
              {[1, 2].map((x) => (
                <Box
                  key={x}
                  className={`${Classes.SKELETON} round-corners`}
                  height={30}
                  width={30}
                  ml={2}
                />
              ))}
            </Flex>
          </Flex>
          <Box
            className="round-corners bls"
            style={{
              overflow: "hidden",
              borderLeftWidth: 6,
              borderLeftColor: LIGHT_PURPLE,
            }}
          >
            {[1, 2, 3].map((x) => (
              <Box
                key={x}
                className={`${Classes.SKELETON} ${x > 1 ? "bts" : ""}`}
                height={80}
              />
            ))}
          </Box>
        </Box>
      ))}
    </>
  );
}
