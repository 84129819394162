import React, { ReactNode, useEffect } from "react";

import { useLazyQuery } from "@apollo/client";
import { Alignment, Navbar } from "@blueprintjs/core";

import lazyFetch from "@/components/helpers/graphql/lazy-fetch";
import { Box, Flex } from "@/components/layout/flexbox";
import { Media, SizeBreakpoint } from "@/components/layout/Media";
import HeaderProfile from "@/components/pages_logged_in/workspace/nav/HeaderProfile";
import MessageNav from "@/components/pages_logged_in/workspace/nav/MessageNav";
import SideNav from "@/components/pages_logged_in/workspace/nav/SideNav";
import SearchButton from "@/components/pieces/search/SearchButton";
import {
  NAV_Z_INDEX,
  PADDING_BREAKPOINT_MAP,
  SIDE_NAV_WIDTH,
  STANDARD_NAV_HEIGHT,
  STANDARD_SIZE_NAV_BREAKPOINT,
} from "@/css/constants";
import {
  LoggedInNavDataQueryDocument,
  LoggedInNavDataQueryQuery,
} from "@/graphql";

import ImpersonatingMessage from "./ImpersonatingMessage";
import MainMenuButton from "./MainMenuButton";

let loggedInMenuTimeoutRef;

interface ErrorBoundaryState {
  error: Error | null;
  errorInfo: React.ErrorInfo | null;
}

interface ErrorBoundaryProps {
  children: ReactNode;
}

class ErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  componentDidCatch(error, errorInfo) {
    if (loggedInMenuTimeoutRef) {
      clearTimeout(loggedInMenuTimeoutRef);
    }
    this.setState({
      error: error,
      errorInfo: errorInfo,
    });
  }

  render() {
    if (this.state.errorInfo) {
      window.location.reload();
      return null;
    }
    return this.props.children;
  }
}

interface LoggedInNavProps {
  pageLoading?: boolean;
  showNavLinks?: boolean;
  prerenderNavSection?: any;
  onLoaded?: () => void;
  sizeNavBreakpoint?: SizeBreakpoint;
}

export default function LoggedInNav({
  pageLoading = false,
  showNavLinks = true,
  prerenderNavSection,
  onLoaded,
  sizeNavBreakpoint = STANDARD_SIZE_NAV_BREAKPOINT as SizeBreakpoint,
}: LoggedInNavProps) {
  const [fetch, { data }] = useLazyQuery<LoggedInNavDataQueryQuery>(
    LoggedInNavDataQueryDocument,
    {
      onCompleted: onLoaded,
    },
  );

  useEffect(() => {
    if (!pageLoading) {
      if (loggedInMenuTimeoutRef) {
        clearTimeout(loggedInMenuTimeoutRef);
      }
      loggedInMenuTimeoutRef = lazyFetch(fetch);
      return () => {
        clearTimeout(loggedInMenuTimeoutRef);
      };
    }
  }, [pageLoading]);

  useEffect(() => {
    if (loggedInMenuTimeoutRef) {
      return () => {
        clearTimeout(loggedInMenuTimeoutRef);
      };
    }
  }, []);

  return (
    <ErrorBoundary>
      <Navbar
        fixedToTop={true}
        style={{
          zIndex: NAV_Z_INDEX,
          height: STANDARD_NAV_HEIGHT,
          paddingLeft: 0,
        }}
      >
        <Box pl={PADDING_BREAKPOINT_MAP[sizeNavBreakpoint]} ml={3} pr={2}>
          <Navbar.Group
            align={Alignment.LEFT}
            style={{ height: STANDARD_NAV_HEIGHT }}
          >
            <Flex alignItems="center">
              <Media lessThan={sizeNavBreakpoint}>
                <MainMenuButton pageLoading={pageLoading} />
              </Media>
              {showNavLinks ? <SearchButton disabled={pageLoading} /> : null}
              <Media greaterThan="md">
                <ImpersonatingMessage />
              </Media>
            </Flex>
          </Navbar.Group>
          <Navbar.Group
            align={Alignment.RIGHT}
            style={{ height: STANDARD_NAV_HEIGHT }}
          >
            <Flex alignItems="center" ml={2}>
              <MessageNav />
              <Box>
                <HeaderProfile
                  data={data}
                  pageLoading={pageLoading}
                  prerenderNavSection={prerenderNavSection}
                  sizeNavBreakpoint={sizeNavBreakpoint}
                />
              </Box>
            </Flex>
          </Navbar.Group>
        </Box>
      </Navbar>
      <Media greaterThanOrEqual={sizeNavBreakpoint}>
        <SideNav width={SIDE_NAV_WIDTH} pageLoading={pageLoading} />
      </Media>
    </ErrorBoundary>
  );
}
