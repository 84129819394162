import { useQuery } from "@apollo/client";
import { Divider } from "@blueprintjs/core";

import { Box } from "@/components/layout/flexbox";
import { Container } from "@/components/pieces/bootstrap";
import { FeatureIntroducedDocument } from "@/graphql";

import PracticeHeader from "./PracticeHeader";
import RoleplayIntro from "./RoleplayIntro";
import ForYouScenarios from "./scenarios/ForYouScenarios";

interface RoleplaysHeaderProps {
  pageLoading?: boolean;
}

export default function RoleplaysHeader({
  pageLoading = false,
}: RoleplaysHeaderProps) {
  const { data: dataFeature } = useQuery(FeatureIntroducedDocument, {
    fetchPolicy: "cache-and-network",
    variables: { featureType: "ROLEPLAYS" },
    skip: pageLoading,
  });

  const showIntro = !!(dataFeature && !dataFeature.featureIntroduced);

  return (
    <>
      <PracticeHeader />
      <Container>
        <Box mt={36} mb={36}>
          <RoleplayIntro show={showIntro} />
          <ForYouScenarios pageLoading={pageLoading} />
          <Box my={[30, 30, 30, 36]}>
            <Divider />
          </Box>
        </Box>
      </Container>
    </>
  );
}
