import { Button, Callout, Icon } from "@blueprintjs/core";

import { Box, Flex } from "@/components/layout/flexbox";

const BACKGROUND_PURPLE = "#FAF5FF";
const TEXT_PURPLE = "#581C87";

export default function CriteriaBanner({
  onDismiss,
}: {
  onDismiss: () => void;
}) {
  return (
    <Callout
      style={{
        backgroundColor: BACKGROUND_PURPLE,
        color: TEXT_PURPLE,
      }}
    >
      <Flex justifyContent="space-between" alignItems="center">
        <Flex alignItems="center" style={{ gap: "8px" }}>
          <Icon icon="clean" color={TEXT_PURPLE} />
          <Box>Added automatically - feel free to adjust if needed</Box>
        </Flex>

        <Button
          minimal={true}
          icon={<Icon icon="cross" color={TEXT_PURPLE} />}
          onClick={onDismiss}
        />
      </Flex>
    </Callout>
  );
}
