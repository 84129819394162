import { useEffect, useState } from "react";

import checkEmailSvg from "@/../../hypetrain/static/img/check-email.svg";
import { Button, Icon, Portal } from "@blueprintjs/core";

import EmbedSvg from "@/components/helpers/ui/EmbedSvg";
import { Box, Flex } from "@/components/layout/flexbox";
import { ResendConfirmationButton } from "@/components/nav/MessageBar/ResendEmailConfirmation";
import BackDrop from "@/components/pieces/BackDrop";
import BitIcon from "@/components/pieces/BitIcon";
import DialogHeader from "@/components/pieces/dialog/DialogHeader";
import ScrollableModal from "@/components/pieces/ScrollableModal";
import { PORTAL_Z_INDEX, SUBTEXT_COLOR } from "@/css/constants";

import { NEW_USER_BIT_BONUS } from "./constants";

export default function ConfirmEmailModal({
  visible,
  onClose,
  refetch,
  emailProvider,
}) {
  const [sent, setSent] = useState(false);

  function maybeRefetch() {
    if (visible) refetch();
  }

  useEffect(() => {
    window.addEventListener("focus", maybeRefetch);
    // Specify how to clean up after this effect:
    return () => {
      window.removeEventListener("focus", maybeRefetch);
    };
  }, []);

  return (
    <Portal>
      <BackDrop visible={visible} zIndex={PORTAL_Z_INDEX} opacity={0.6} />
      <ScrollableModal
        visible={visible}
        onClose={onClose}
        verticallyCenter={true}
        canOutsideClickClose={false}
        style={{
          position: "absolute",
          top: 24,
          left: 0,
          width: "100%",
          overflowY: "auto",
          boxSizing: "border-box",
        }}
      >
        <Box
          backgroundColor="#FFF"
          className="round-corners"
          width={550}
          maxWidth="100%"
          margin="0 auto"
        >
          <DialogHeader onClose={onClose}>
            <Flex alignItems="center">
              <BitIcon height={24} width={24} />
              <Box ml={2}>Get Free Bits</Box>
            </Flex>
          </DialogHeader>
          <Box p={24}>
            <Box textAlign="center">
              <EmbedSvg src={checkEmailSvg} width={145} />
              <Box mt={20} fontWeight={600} fontSize={20}>
                Confirm Your Email
              </Box>
              <Box color={SUBTEXT_COLOR} mb={20}>
                Get {NEW_USER_BIT_BONUS} Bits when you confirm your email
              </Box>
              {emailProvider === "gmail" || emailProvider === "outlook" ? (
                <Box mb={3}>
                  <Button
                    intent="primary"
                    large={true}
                    fill={true}
                    onClick={() => {
                      if (emailProvider === "gmail") {
                        window.open("https://www.gmail.com", "_blank").focus();
                      } else {
                        window
                          .open("https://www.outlook.com", "_blank")
                          .focus();
                      }
                    }}
                    text={
                      emailProvider === "gmail" ? "Open Gmail" : "Open Outlook"
                    }
                  />
                </Box>
              ) : null}
              {sent ? (
                <Flex alignItems="center" justifyContent="center" mb={[1, 0]}>
                  <Icon icon="tick-circle" intent="success" />
                  <Box ml={2}>Confirmation Resent</Box>
                </Flex>
              ) : (
                <ResendConfirmationButton
                  large={true}
                  fill={true}
                  cta="Resend Confirmation"
                  onSuccess={() => setSent(true)}
                />
              )}
            </Box>
          </Box>
        </Box>
      </ScrollableModal>
    </Portal>
  );
}
