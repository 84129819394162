export enum EvaluationCriteriaDialogs {
  RemoveCriterion = "remove-criterion",
  EditCriterion = "edit-criterion",
  AddCriterion = "add-criterion",
  SortCriteria = "sort-criteria",
  ImportFromCollection = "import-from-collection",
  ConfirmRemoveAllCriteria = "confirm-remove-all-criteria",
}

export const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

/**
 * Parses text content into an array of bullet points - which not contain the bullet point itself.
 *
 * Handles various input formats:
 * - HTML with paragraph tags (<p>) and line breaks (<br>)
 * - Markdown bullet points (-, *, 1.)
 * - Plain text with newlines
 *
 * @param text - The text content to parse into bullets
 * @returns Array of strings, each representing a bullet point. Returns [''] for empty input.
 */
export const parseTextToBullets = (text: string): string[] => {
  if (!text) {
    return [""]; // at least one empty string for first bullet
  }

  const bulletRegex = /^(- |\* |\d+\. )/;

  const lines = text
    // Remove first bullet point or numbered list marker
    .replace(bulletRegex, "")
    // Handle paragraph tags, preserving first occurrence without newline
    .replaceAll(/<p>(.*?)<\/p>/g, (_, content, offset) =>
      offset === 0 ? content : `\n${content}`,
    )
    // Normalize line breaks
    .replaceAll("<br>", "\n")
    .replaceAll(/\n+/g, "\n")
    // Split into lines and clean up each line
    .split("\n")
    .map((line) => (line ? line.replace(bulletRegex, "") : ""));

  return lines;
};

export const formatBulletsToText = (bullets: string): string => {
  return bullets
    .split("\n")
    .map((bullet) => (bullet ? `- ${bullet}` : ""))
    .join("\n");
};
