import { useEffect, useState } from "react";

import { Colors, Spinner } from "@blueprintjs/core";

import { Box, Flex } from "@/components/layout/flexbox";
import { useGetEvaluationCriteria } from "@/components/pages_logged_in/roleplays/details/api";
import EvaluationCriteriaItem from "@/components/pages_logged_in/roleplays/details/evaluation-criteria/EvaluationCriteriaItem";
import Paging from "@/components/pages_logged_in/workspace-admin/Paging";
import SearchBox from "@/components/pages_logged_in/workspace-admin/SearchBox";
import CardAboveXs from "@/components/pieces/CardAboveXs";

import { BehaviorCriterionType } from "./types";

const PAGE_SIZE = 5;

export default function PickingTemplatesTab({
  currentCriteria,
  templatesSlug,
  pickTemplateLoading,
  onChooseTemplate,
}: {
  currentCriteria: BehaviorCriterionType[];
  templatesSlug: string;
  pickTemplateLoading: boolean;
  onChooseTemplate: (template: BehaviorCriterionType) => void;
}) {
  const [searchValue, setSearchValue] = useState("");
  const [allTemplates, setAllTemplates] = useState([]);
  const [filteredTemplates, setFilteredTemplates] = useState([]);
  const [currentPickingId, setCurrentPickingId] = useState<string | null>(null);
  const [currentPage, setCurrentPage] = useState(1);

  const { fetch, loading } = useGetEvaluationCriteria({
    onCompleted: (data) => {
      setAllTemplates(data?.evaluationCriteria || []);
      setFilteredTemplates(data?.evaluationCriteria || []);
    },
    onError: () => {},
  });

  const onSearch = (value: string) => {
    setSearchValue(value);
  };

  const getTemplatesOnPage = (
    templates: BehaviorCriterionType[],
    page: number,
  ) => {
    const start = (page - 1) * PAGE_SIZE;
    const end = start + PAGE_SIZE;

    return templates.slice(start, end);
  };

  useEffect(() => {
    fetch(null, null, templatesSlug);
  }, [templatesSlug]);

  useEffect(() => {
    if (allTemplates.length === 0) {
      return;
    }

    if (searchValue !== "") {
      const filteredTemplates = allTemplates.filter((template) =>
        template.name.toLowerCase().includes(searchValue.toLowerCase()),
      );
      setFilteredTemplates(filteredTemplates);
    } else {
      setFilteredTemplates(allTemplates);
    }
    setCurrentPage(1);
  }, [searchValue, allTemplates]);

  const isEmpty = !loading && filteredTemplates.length === 0;
  const criteriaNames = currentCriteria.map((c) => c.name);

  const templates = getTemplatesOnPage(filteredTemplates, currentPage);

  const handleChooseTemplate = (template: BehaviorCriterionType) => {
    onChooseTemplate(template);
    setCurrentPickingId(template.id);
  };

  return (
    <CardAboveXs py={4}>
      {loading && (
        <Flex
          justifyContent="center"
          p="16px"
          css={{
            position: "sticky",
            bottom: 100,
            left: 0,
            right: 0,
            opacity: 0.5,
            width: "100%",
            height: "100%",
          }}
        >
          <Spinner intent="primary" />
        </Flex>
      )}

      <Box
        mb={[2, 0]}
        mr={[0, 2]}
        css={{
          borderRadius: 6,
          border: "1px solid #E1E8ED",
          overflow: "hidden",
        }}
      >
        <SearchBox
          currentRefinement={searchValue}
          refine={(value) => {
            onSearch(value);
          }}
          throttledDuration={400}
          searchPlaceholder="Search templates"
          style={{ fontSize: 16 }}
        />
      </Box>

      {!isEmpty ? (
        <>
          <Box>
            {templates?.map((template) => (
              <EvaluationCriteriaItem
                key={template.id}
                criterion={template}
                onOpenDialog={null}
                isTemplate={true}
                pickTemplateLoading={
                  pickTemplateLoading && currentPickingId === template.id
                }
                isPicked={criteriaNames.includes(template.name)}
                onPickTemplate={handleChooseTemplate}
              />
            ))}
          </Box>

          <Paging
            style={{
              background: Colors.WHITE,
              padding: 10,
              marginTop: 16,
            }}
            currentPage={currentPage}
            pageSize={PAGE_SIZE}
            setCurrentPage={(index) => {
              setCurrentPage(index);
            }}
            totalRecords={filteredTemplates.length}
            isSticky={true}
          />
        </>
      ) : (
        <Box my={3} textAlign={"center"}>
          No templates found
        </Box>
      )}
    </CardAboveXs>
  );
}
