import { useState } from "react";

import noCoachVideo from "@/../../hypetrain/static/img/no-coach-video.svg";
import { Icon, Spinner } from "@blueprintjs/core";
import styled from "@emotion/styled";
import VideoPlayer from "react-player/file";
import { useMediaQuery } from "react-responsive";

import EmbedSvg from "@/components/helpers/ui/EmbedSvg";
import { isMobileQuery } from "@/components/layout/Media";
import Clickable from "@/components/pieces/interaction/Clickable";

interface CoachVideoProps {
  url: string;
  playing?: boolean;
  onClick?: () => void;
  borderRadius?: number;
  thumbnail?: string;
}

function CoachVideo({
  url,
  playing,
  onClick,
  borderRadius = 0,
  thumbnail,
}: CoachVideoProps) {
  const [localPlaying, setLocalPlaying] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const isPlaying =
    playing === undefined && onClick === undefined ? localPlaying : playing;
  const isMobile = useMediaQuery(isMobileQuery);

  let config = undefined;

  if (thumbnail) {
    const thumbnailUrl =
      typeof thumbnail === "string" && isMobile
        ? thumbnail.replace("upload/w_750", "upload/w_300")
        : thumbnail;
    config = {
      file: { attributes: { poster: thumbnailUrl } },
    };
  }

  return (
    <VideoContainer borderRadius={borderRadius}>
      {url ? (
        <>
          <VideoPlayer
            url={url.startsWith("http") ? url : `${API_HOST}${url}`}
            playing={isPlaying}
            width="100%"
            height="100%"
            loop={false}
            pip={false}
            config={config}
            onReady={() => setIsLoading(false)}
            onError={() => setIsLoading(false)}
            onBuffer={() => setIsLoading(true)}
            onBufferEnd={() => setIsLoading(false)}
          />
          <Clickable
            onClick={
              onClick === undefined
                ? () => setLocalPlaying(!localPlaying)
                : onClick
            }
          >
            {isPlaying && isLoading ? (
              <TransparentOverlay>
                <OverlayIcon>
                  <Spinner size={72} />
                </OverlayIcon>
              </TransparentOverlay>
            ) : (
              <Overlay playing={isPlaying} borderRadius={borderRadius}>
                <OverlayIcon>
                  <Icon
                    icon={isPlaying ? "pause" : "play"}
                    size={72}
                    color="#FFF"
                  />
                </OverlayIcon>
              </Overlay>
            )}
          </Clickable>
        </>
      ) : (
        <EmbedSvg src={noCoachVideo} width="100%" />
      )}
    </VideoContainer>
  );
}

const VideoContainer = styled("div")<{ borderRadius: number }>`
  position: relative;
  width: 100%;
  height: 100%;
  line-height: 0;
  video {
    border-bottom-right-radius: ${(props) => props.borderRadius}px;
    border-bottom-left-radius: ${(props) => props.borderRadius}px;
    overflow: hidden;
  }
`;

const Overlay = styled("div")<{ playing: boolean; borderRadius: number }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  cursor: pointer;
  opacity: ${(props) => (props.playing ? 0 : 0.5)};
  border-bottom-right-radius: ${(props) => props.borderRadius}px;
  border-bottom-left-radius: ${(props) => props.borderRadius}px;
  overflow: hidden;

  &:hover {
    opacity: 1;
  }
`;

const OverlayIcon = styled("div")`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 72px;
  height: 72px;
`;

const TransparentOverlay = styled("div")`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

export default CoachVideo;
