import { useEffect, useMemo, useRef, useState } from "react";

import { Colors, Icon } from "@blueprintjs/core";
import capitalize from "lodash/capitalize";

import { Box, Flex } from "@/components/layout/flexbox";
import { useRoleplayVoicesQuery } from "@/components/pages_logged_in/roleplays/api";
import Mp3Player from "@/components/pieces/audio/Mp3Player";
import {
  BRAND_PURPLE,
  LIGHT_BRAND_PURPLE,
  SUBTEXT_COLOR,
} from "@/css/constants";

function VoiceOption({ voice, isSelected, onSelect }) {
  return (
    <Flex
      alignItems="center"
      justifyContent="space-between"
      css={{
        "&:hover": {
          bg: "rgba(0,0,0,0.05)",
        },
      }}
      px={12}
      backgroundColor={isSelected ? LIGHT_BRAND_PURPLE : Colors.WHITE}
    >
      <Flex
        flex={1}
        alignItems="center"
        css={{
          borderBottom: `1px solid ${
            isSelected ? LIGHT_BRAND_PURPLE : "#E2E2E2"
          }`,
        }}
      >
        <Flex flex={1}>
          <Box
            width={80}
            color={isSelected ? Colors.BLACK : SUBTEXT_COLOR}
            fontSize={14}
          >
            {capitalize(voice.name)}
          </Box>
          <Box maxWidth={300} flex={1}>
            <Mp3Player
              minimal
              backgroundColor="transparent"
              src={voice.sampleUrl}
              playIcon={<Icon icon="play" size={20} />}
              pauseIcon={<Icon icon="pause" size={20} />}
              showProgressIndicator={false}
            />
          </Box>
        </Flex>
        <Box
          onClick={() => onSelect(voice)}
          fontWeight={700}
          fontSize={14}
          color={isSelected ? Colors.BLACK : BRAND_PURPLE}
          css={{
            cursor: "pointer",
          }}
          py={12}
          pl={12}
        >
          {isSelected ? "Selected" : "Select"}
        </Box>
      </Flex>
    </Flex>
  );
}

function SelectedVoice({ voice }) {
  return (
    <Flex flex={1} alignItems={"center"}>
      <Box width={90}>{capitalize(voice.name)}</Box>
      <Box maxWidth={300} flex={1}>
        <Mp3Player
          minimal
          backgroundColor="transparent"
          src={voice.sampleUrl}
          playIcon={<Icon icon="play" size={20} />}
          pauseIcon={<Icon icon="pause" size={20} />}
          showProgressIndicator={false}
        />
      </Box>
    </Flex>
  );
}

function VoiceSelector({ voiceId, onChangeVoiceId }) {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedVoice, setSelectedVoice] = useState(null);
  const selectorRef = useRef(null);

  const { voicesData } = useRoleplayVoicesQuery({
    onCompleted: () => {},
    onError: () => {},
  });

  const voices = useMemo(() => voicesData || [], [voicesData]);

  useEffect(() => {
    if (voiceId && voices.length) {
      const voice = voices.find((v) => v.voiceId === voiceId);
      setSelectedVoice(voice || null);
    }
  }, [voiceId, voices]);

  const handleSelectVoice = (voice) => {
    setSelectedVoice(voice);
    onChangeVoiceId(voice.voiceId);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (selectorRef.current && !selectorRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <Box
      sx={{
        position: "relative",
        width: "100%",
      }}
      ref={selectorRef}
    >
      <Flex
        className={`bas`}
        onClick={() => setIsOpen(!isOpen)}
        css={{
          borderRadius: isOpen ? "5px 5px 0px 0px" : "5px",
          cursor: "pointer",
        }}
        width={"100%"}
        padding={12}
        alignItems={"center"}
      >
        {selectedVoice ? (
          <SelectedVoice voice={selectedVoice} />
        ) : (
          <Flex flex={1} alignItems="center">
            Select a voice...
          </Flex>
        )}
        <Icon icon={isOpen ? "chevron-up" : "chevron-down"} size={19} />
      </Flex>
      <Box
        className={`bas`}
        css={{
          position: "absolute",
          top: "100%",
          left: 0,
          right: 0,
          overflowY: "auto",
          zIndex: 1000,
          display: isOpen ? "block" : "none",
          borderRadius: "0px 0px 8px 8px",
          borderTop: "0px",
          transition: "all 0.3s ease",
        }}
        maxHeight={225}
        backgroundColor={Colors.WHITE}
        py={0}
      >
        {voices.map((voice) => (
          <VoiceOption
            key={voice.voiceId}
            voice={voice}
            isSelected={selectedVoice?.voiceId === voice.voiceId}
            onSelect={handleSelectVoice}
          />
        ))}
      </Box>
    </Box>
  );
}

export default VoiceSelector;
