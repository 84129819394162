import { Configure, Hits, Index } from "react-instantsearch";

import { useWorkspace } from "@/components/helpers/custom-hooks/use-workspace";
import { ROLEPLAY_INDEX } from "@/components/pieces/algolia/indexes";
import EmojiCircle from "@/components/pieces/EmojiCircle";
import { getUser } from "@/components/session/JwtTokenManager";

import { EntityResult } from "./EntityResult";
import { IndexComponentProps, ResultComponentProps } from "./search-types";

export default function Roleplays({
  indexKey,
  selectedObjectId,
  onClose,
  onSelect,
}: IndexComponentProps) {
  const user = getUser();
  const workspace = useWorkspace();

  return (
    <Index indexName={ROLEPLAY_INDEX} indexId={indexKey}>
      <Configure
        hitsPerPage={7}
        filters={`(_tags:owner_${user?.id} OR _tags:global OR _tags:workspace_${workspace?.id}) AND (_tags:owner_${user?.id} OR _tags:has_scenarios)`}
      />
      <Hits
        hitComponent={(props) => (
          <RoleplayResult
            {...props}
            selected={props.hit.objectID === selectedObjectId}
            onClose={onClose}
            onSelect={onSelect}
          />
        )}
      />
    </Index>
  );
}

export function RoleplayResult({
  selected,
  hit,
  onClose,
  onSelect,
}: ResultComponentProps) {
  const link = `/roleplays/${hit.slug}`;
  return (
    <EntityResult
      selected={selected}
      hit={hit}
      onClose={onClose}
      onSelect={onSelect}
      avatar={<EmojiCircle size={40} unified={hit.emoji_unicode} />}
      link={link}
      titleHighlightKey={"name"}
      descriptionHighlightKey={"description"}
    />
  );
}
