import { useEffect, useState } from "react";

import { Button, Callout, Classes } from "@blueprintjs/core";
import { DragDropContext, Draggable, Droppable } from "@hello-pangea/dnd";

import { Box, Flex } from "@/components/layout/flexbox";
import { getItemStyle } from "@/components/pages_logged_in/program/components/set-components/Customize";
import { DialogFooterSticky } from "@/components/pages_logged_in/roleplays/details/evaluation-criteria/CustomCriterionTab";
import SortCriterionCard from "@/components/pages_logged_in/roleplays/details/evaluation-criteria/SortCriterionCard";
import { BehaviorCriterionType } from "@/components/pages_logged_in/roleplays/details/evaluation-criteria/types";
import { reorder } from "@/components/pages_logged_in/roleplays/details/utils";
import StyledDialog from "@/components/pieces/StyledDialog";

interface SortCriteriaDialogProps {
  open: boolean;
  onClose: () => void;
  onSave: (criteria: BehaviorCriterionType[]) => void;
  criteria: BehaviorCriterionType[];
  loading?: boolean;
  errorMessage?: string;
}

export default function SortCriteriaDialog({
  open,
  onClose,
  onSave,
  criteria,
  loading = false,
  errorMessage = "",
}: SortCriteriaDialogProps) {
  const [sortedCriteria, setSortedCriteria] = useState(criteria);

  useEffect(() => {
    if (open) {
      setSortedCriteria(criteria);
    }
  }, [open, criteria]);

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const items = reorder(
      sortedCriteria,
      result.source.index,
      result.destination.index,
    ) as BehaviorCriterionType[];

    setSortedCriteria(
      items.map((item, index) => ({
        ...item,
        order: index + 1,
      })),
    );
  };

  return (
    <StyledDialog
      isOpen={open}
      onClose={onClose}
      lazy={true}
      title="Sort Criteria"
      width="480px"
    >
      <Box css={{ lineHeight: "24px" }} className={Classes.DIALOG_BODY}>
        {/* To support drag-drop content with many items, we should set max height */}
        <Box py={3} px={24} maxHeight={"70dvh"} overflowY="auto">
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
              {(provided) => (
                <Box
                  width={1}
                  className="round-corners"
                  flexWrap="wrap"
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                >
                  {sortedCriteria.map((criterion, index) => (
                    <Draggable
                      key={criterion.id}
                      draggableId={criterion.id}
                      index={index}
                    >
                      {(provided, snapshot) => (
                        <Flex
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={getItemStyle(
                            snapshot.isDragging,
                            provided.draggableProps.style,
                          )}
                        >
                          <SortCriterionCard criterionName={criterion.name} />
                        </Flex>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </Box>
              )}
            </Droppable>
          </DragDropContext>
        </Box>

        <DialogFooterSticky>
          <Flex flexDirection={"column"} width={1}>
            {errorMessage && (
              <Box mb={2}>
                <Callout icon={null} intent="danger">
                  {errorMessage}
                </Callout>
              </Box>
            )}

            <Box py={3} px={24} m={0}>
              <Button
                onClick={onClose}
                style={{
                  marginRight: "5px",
                }}
              >
                Cancel
              </Button>
              <Button
                intent="primary"
                loading={loading}
                onClick={() => {
                  onSave(sortedCriteria);
                }}
              >
                Save Order
              </Button>
            </Box>
          </Flex>
        </DialogFooterSticky>
      </Box>
    </StyledDialog>
  );
}
