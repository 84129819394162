import flatMap from "lodash/flatMap";

import { Box } from "@/components/layout/flexbox";
import { SITE_NAME, SUBTEXT_COLOR } from "@/css/constants";

export default function InactiveProducts({ inactiveProducts = [] }) {
  if (!inactiveProducts || inactiveProducts.length === 0) {
    return null;
  }
  const parts = flatMap(
    inactiveProducts.map((product, index) => {
      if (index == 0) {
        return product.name;
      }
      const separator = index === inactiveProducts.length - 1 ? " and " : ", ";
      return [separator, product.name];
    }),
  );

  parts.push(
    `${
      inactiveProducts.length === 1 ? " is" : " are"
    } currently disabled on your account.`,
  );

  const text = parts.join("");

  return (
    <Box
      className="bas round-corners"
      p={[3, 3, 3, 3]}
      my={3}
      backgroundColor="#FBFBFF"
      color={SUBTEXT_COLOR}
      css={{ border: "1px solid #e2e2e2" }}
    >
      <Box fontWeight={600}>Inactive Services</Box>
      <Box py={2}>{text}</Box>
      <Box fontStyle="italic">
        Talk to your {SITE_NAME} rep if you are interested in offering these
        services.
      </Box>
    </Box>
  );
}
