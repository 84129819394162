import { useMutation } from "@apollo/client";

import { UpdateScenarioVisibilityScopeDocument } from "@/graphql";

export default function useUpdateScenarioVisibilityScope({
  onCompleted,
  onError,
  refetchQueries,
}: {
  onCompleted?: (data: any) => void;
  onError?: (error: any) => void;
  refetchQueries?: any;
}) {
  const [updateScenarioVisibilityScope, { loading }] = useMutation(
    UpdateScenarioVisibilityScopeDocument,
    {
      refetchQueries: refetchQueries,
      awaitRefetchQueries: true,
      onCompleted: (data) => {
        const { ok, msg, scenario } = data?.updateScenarioVisibilityScope || {};
        if (ok) {
          onCompleted(scenario);
        } else {
          onError(Error(msg || "Something went wrong. Please try again."));
        }
      },
      onError: (err) => {
        onError(err);
      },
    },
  );

  return {
    updateScenarioVisibilityScope: (scenarioId, visibilityScope) =>
      updateScenarioVisibilityScope({
        variables: {
          scenarioId,
          visibilityScope,
        },
      }),
    loading,
  };
}
