import { Button, Colors, Icon, InputGroup } from "@blueprintjs/core";

import { Box, Flex } from "@/components/layout/flexbox";
import { StyledFormGroup } from "@/components/pages_logged_in/program/components/set-components/StyledFormGroup";
import { parseTextToBullets } from "@/components/pages_logged_in/roleplays/details/utils";
import Tiptap from "@/components/pieces/editor/tiptap";
import BulletPointInput from "@/components/pieces/form/BulletPointInput";
import { CARD_CLASSES } from "@/css/constants";

import { BehaviorType } from "./types";

export default function BehaviorCard({
  dragHandleProps,
  behavior,
  onRemoveBehavior,
  onUpdateBehavior,
}: {
  dragHandleProps: any;
  behavior: BehaviorType;
  onRemoveBehavior: (behavior: BehaviorType) => void;
  onUpdateBehavior: (behavior: BehaviorType) => void;
}) {
  const usingSimpleMde = behavior?.description?.includes(":");

  return (
    <Box
      flex={1}
      mb={3}
      className={CARD_CLASSES}
      backgroundColor={Colors.WHITE}
    >
      <Flex p={3} width={"100%"}>
        <Box mr={3} {...dragHandleProps}>
          <Icon
            icon="drag-handle-vertical"
            style={{
              width: "8px",
              height: "18px",
            }}
          />
        </Box>

        <Box
          style={{
            width: "100%",
          }}
        >
          <StyledFormGroup label="Behavior Label">
            <InputGroup
              onChange={(e) => {
                onUpdateBehavior({
                  ...behavior,
                  name: e.target.value,
                });
              }}
              value={behavior?.name}
              large={true}
              fill={true}
              placeholder="Describe what success looks like (e.g., 'Restate customer concerns')"
            />
          </StyledFormGroup>

          <StyledFormGroup
            label="Evaluation Guide"
            subLabel="Help our AI understand how to evaluate this behavior. Be specific and concrete about what success looks like."
          >
            {usingSimpleMde ? (
              <Tiptap
                content={behavior?.description}
                onChange={(value) => {
                  onUpdateBehavior({
                    ...behavior,
                    description: value,
                  });
                }}
                placeholder="Break down how to evaluate this behavior. Include both what to look for and what to avoid."
              />
            ) : (
              <BulletPointInput
                values={parseTextToBullets(behavior?.description || "")}
                onChange={(value) => {
                  onUpdateBehavior({
                    ...behavior,
                    description: value.join("\n"),
                  });
                }}
              />
            )}
          </StyledFormGroup>
        </Box>

        <Box ml={2}>
          <Button
            style={{
              padding: 0,
            }}
            minimal={true}
            icon={<Icon icon="cross" />}
            onClick={() => onRemoveBehavior(behavior)}
          />
        </Box>
      </Flex>
    </Box>
  );
}
