import { useEffect, useState } from "react";

import { useQuery } from "@apollo/client";
import { Button, Classes, Colors } from "@blueprintjs/core";
import { useNavigate, useParams } from "react-router-dom";

import { useWorkspaceSlug } from "@/components/helpers/custom-hooks/use-workspace-slug";
import { Box, Flex } from "@/components/layout/flexbox";
import { AddToCollectionDialog } from "@/components/pages_logged_in/roleplays/AddToCollectionDialog";
import { useScenariosLazyQuery } from "@/components/pages_logged_in/roleplays/api";
import ScenarioEditCharacterDialog from "@/components/pages_logged_in/roleplays/details/scenario/ScenarioEditCharacter/ScenarioEditCharacterDialog";
import { ScenariosPageType } from "@/components/pages_logged_in/roleplays/scenarios/const";
import { buildCreateScenarioUrl } from "@/components/pages_logged_in/roleplays/utils";
import Filter from "@/components/pages_logged_in/workspace/pieces/Filter";
import FilterButton from "@/components/pages_logged_in/workspace/pieces/FilterButton";
import Paging from "@/components/pages_logged_in/workspace-admin/Paging";
import SearchBox from "@/components/pages_logged_in/workspace-admin/SearchBox";
import { Container } from "@/components/pieces/bootstrap";
import { AddButton } from "@/components/pieces/button/AddButton";
import SettingSvg from "@/components/pieces/icons/svg/setting";
import CloneScenarioDialog from "@/components/pieces/roleplays/CloneScenarioDialog";
import ScenarioCard from "@/components/pieces/roleplays/ScenarioCard";
import ScenarioDeleteConfirm from "@/components/pieces/roleplays/ScenarioDeleteConfirm";
import { ScenarioModals } from "@/components/pieces/roleplays/ScenarioMenu";
import { SUBTEXT_COLOR } from "@/css/constants";
import {
  QueryScenariosStatus,
  RoleplayDocument,
  VisibilityScope,
} from "@/graphql";

const PAGE_SIZE = 10;

const StatusFilterOptions = [
  {
    id: 0,
    text: "All",
    value: QueryScenariosStatus.All,
  },
  {
    id: 1,
    text: "Incomplete",
    value: QueryScenariosStatus.Incomplete,
  },
  {
    id: 2,
    text: "Complete",
    value: QueryScenariosStatus.Complete,
  },
];

export const ScopeFilterOptions = [
  {
    id: -1,
    value: null,
    text: "All",
  },
  {
    id: VisibilityScope.User,
    value: VisibilityScope.User,
    text: "Personal",
  },
  {
    id: VisibilityScope.Workspace,
    value: VisibilityScope.Workspace,
    text: "Workspace",
  },
  {
    id: VisibilityScope.Global,
    value: VisibilityScope.Global,
    text: "Core",
  },
];

function ScenariosListHeader({
  title,
  loading = false,
  statusFilter = null,
  onChangeStatusFilter = null,
  scopeFilter = null,
  onChangeScopeFilter = null,
  onSearch = null,
  allowAdd = false,
}) {
  const workspaceSlug = useWorkspaceSlug();
  const navigate = useNavigate();
  if (loading) {
    return (
      <Flex fontWeight={500} mb={24} justifyContent="space-between">
        <Box height={24} width={200} className={Classes.SKELETON} />
        <Box height={24} width={200} className={Classes.SKELETON} />
      </Flex>
    );
  }

  const onChangeStatus = (id) => {
    const option = StatusFilterOptions.find((x) => x.id === id);
    if (onChangeStatusFilter) {
      onChangeStatusFilter(option?.value);
    }
  };

  const isSelectStatusFn = (id) => {
    const option = StatusFilterOptions.find((x) => x.id === id);
    return option.value === statusFilter;
  };

  const currentStatus = StatusFilterOptions.find(
    (x) => x.value === statusFilter,
  );

  const borderStyle = {
    border: "0.5px solid #E2E2E2",
    marginRight: 10,
    padding: "10px 24px",
    minWidth: 120,
  };

  const availableScopeFiltersOptions = workspaceSlug
    ? ScopeFilterOptions
    : ScopeFilterOptions.filter(
        (scope) => scope.id !== VisibilityScope.Workspace,
      );

  const currentScope = availableScopeFiltersOptions.find(
    (x) => x.id === scopeFilter,
  );

  const isSelectScopeFn = (id) => {
    return id === scopeFilter;
  };

  const onChangeScope = (id) => {
    const option = availableScopeFiltersOptions.find((x) => x.id === id);
    if (onChangeScopeFilter) {
      onChangeScopeFilter(option?.id);
    }
  };

  return (
    <Flex fontWeight={500} justifyContent="space-between" mt={4} mb={3}>
      {title && (
        <Box fontSize={24} fontWeight={600}>
          {title}
        </Box>
      )}
      <Flex>
        {statusFilter && (
          <Filter
            menuPosition="bottom-right"
            fullHeight={true}
            button={
              <FilterButton
                style={borderStyle}
                rightIcon={<SettingSvg />}
                text={currentStatus?.text}
              />
            }
            items={StatusFilterOptions}
            isSelectedFn={isSelectStatusFn}
            multiple={false}
            onSelect={onChangeStatus}
            viewMode="radio"
          />
        )}
        {scopeFilter && (
          <Filter
            menuPosition="bottom-right"
            fullHeight={true}
            button={
              <FilterButton
                style={borderStyle}
                rightIcon={<SettingSvg />}
                text={currentScope?.text}
              />
            }
            items={availableScopeFiltersOptions}
            isSelectedFn={isSelectScopeFn}
            multiple={false}
            onSelect={onChangeScope}
            viewMode="radio"
          />
        )}
        {onSearch && (
          <Box ml={1}>
            <SearchBox
              style={{
                borderRadius: 6,
                border: "0.5px solid #E2E2E2",
                overflow: "hidden",
                padding: "22px 0px 22px 36px",
                fontSize: 16,
                minWidth: 265,
              }}
              className="icon-center"
              disabled={loading}
              refine={onSearch}
              throttledDuration={400}
              searchPlaceholder="Search scenarios"
            />
          </Box>
        )}
      </Flex>
      {allowAdd && (
        <Button
          intent="primary"
          rightIcon="plus"
          style={{ fontSize: 14, marginLeft: 10 }}
          onClick={() => {
            navigate(buildCreateScenarioUrl());
          }}
        >
          Create Scenario
        </Button>
      )}
    </Flex>
  );
}

export default function ScenariosList({
  type = ScenariosPageType.Assigned,
  title = "",
  pageLoading = false,
  statusFilter = null,
  scopeFilter = null,
  showShortDesc = false,
  showRank = true,
  showDifficulty = true,
  showRoleplay = true,
  showScope = false,
  showAction = false,
  iconSize = 100,
  allowClickOnCard = true,
  allowAdd = false,
  showPlayButton = true,
  onSelect = null,
  pageSize = PAGE_SIZE,
  selectedScenarioIds = [],
  loadingItem = null,
}) {
  const [page, setPage] = useState(1);
  const [selectedStatus, setSelectedStatus] = useState(statusFilter);
  const [selectedScope, setSelectedScope] = useState(scopeFilter);
  const [selectedScenario, setSelectedScenario] = useState(null);
  const [currentActiveModal, setCurrentActiveModal] =
    useState<ScenarioModals | null>(null);
  const [query, setQuery] = useState("");
  const roleplaySlug = useParams<{
    roleplaySlug?: string;
  }>()?.roleplaySlug;

  const { data: roleplayData } = useQuery(RoleplayDocument, {
    variables: {
      slug: roleplaySlug,
    },
    skip: !roleplaySlug,
  });

  const roleplay = roleplayData?.roleplay;

  const {
    scenariosData,
    loading,
    queryPersonalScenarios,
    queryAssignedScenarios,
    queryAllScenarios,
  } = useScenariosLazyQuery({
    onCompleted: () => {},
    onError: () => {},
  });

  const { data = [], totalRecords = 0 } = scenariosData || {};

  const fetchScenarios =
    type == ScenariosPageType.All
      ? queryAllScenarios
      : type == ScenariosPageType.Assigned
        ? queryAssignedScenarios
        : queryPersonalScenarios;

  const handleFetchScenarios = () => {
    const scope = selectedScope === -1 ? null : selectedScope;
    fetchScenarios({ page, pageSize, status: selectedStatus, scope, query });
  };
  useEffect(() => {
    handleFetchScenarios();
  }, [page, selectedStatus, selectedScope, query]);

  const loadingBody = (
    <Box>
      <Box height={100} className={Classes.SKELETON} mt={0} />
      <Box height={100} className={Classes.SKELETON} mt={24} />
      <Box height={100} className={Classes.SKELETON} mt={24} />
      <Box height={100} className={Classes.SKELETON} mt={24} />
      <Box height={100} className={Classes.SKELETON} mt={24} />
    </Box>
  );

  const emptyBody = (
    <Flex flexWrap="wrap" alignItems="center" justifyContent="center">
      <Box color={SUBTEXT_COLOR}>No Scenarios</Box>
    </Flex>
  );

  return (
    <Container>
      <ScenariosListHeader
        title={title}
        loading={pageLoading}
        allowAdd={allowAdd}
        statusFilter={selectedStatus}
        scopeFilter={selectedScope}
        onChangeStatusFilter={(value) => {
          setSelectedStatus(value);
          setPage(1);
        }}
        onChangeScopeFilter={(value) => {
          setSelectedScope(value);
          setPage(1);
        }}
        onSearch={(value) => {
          setQuery(value);
          setPage(1);
        }}
      />
      {loading || pageLoading ? (
        loadingBody
      ) : (
        <Box mb={24}>
          {data?.length === 0 ? emptyBody : null}
          {data.map((scenario, index) => {
            const isSelected = selectedScenarioIds.includes(scenario.id);
            const isLoading =
              loadingItem?.loading && loadingItem?.id === scenario.id;
            return (
              <Box mt={index > 0 ? 24 : 0} key={scenario.id}>
                <ScenarioCard
                  scenario={scenario}
                  roleplay={roleplay}
                  showShortDesc={showShortDesc}
                  showRank={showRank}
                  showDifficulty={showDifficulty}
                  showRoleplay={showRoleplay}
                  iconSize={iconSize}
                  allowClickOnCard={allowClickOnCard}
                  showPlayButton={showPlayButton}
                  showScope={showScope}
                  showAction={showAction}
                  rightButton={
                    onSelect ? (
                      <AddButton
                        isAdded={isSelected}
                        isLoading={isLoading}
                        onAdd={() => onSelect(scenario)}
                        onRemove={() => onSelect(scenario)}
                      />
                    ) : null
                  }
                  onEditCharacter={() => {
                    setCurrentActiveModal(ScenarioModals.editCharacter);
                    setSelectedScenario(scenario);
                  }}
                  onDelete={() => {
                    setCurrentActiveModal(ScenarioModals.deleteScenario);
                    setSelectedScenario(scenario);
                  }}
                  onCloneScenario={() => {
                    setCurrentActiveModal(ScenarioModals.cloneScenario);
                    setSelectedScenario(scenario);
                  }}
                  onAddToCollection={() => {
                    setCurrentActiveModal(ScenarioModals.addToCollection);
                    setSelectedScenario(scenario);
                  }}
                />
              </Box>
            );
          })}
        </Box>
      )}
      <Paging
        totalRecords={totalRecords}
        pageSize={pageSize}
        currentPage={page}
        setCurrentPage={setPage}
        isSticky={true}
        style={{
          background: Colors.WHITE,
          padding: 10,
          marginTop: 20,
        }}
      />
      <ScenarioDeleteConfirm
        open={currentActiveModal === ScenarioModals.deleteScenario}
        scenario={selectedScenario}
        onClose={() => setCurrentActiveModal(null)}
        onSuccess={() => {
          handleFetchScenarios();
          setCurrentActiveModal(null);
        }}
        shouldRedirectAfterDelete={false}
      />
      <ScenarioEditCharacterDialog
        open={currentActiveModal === ScenarioModals.editCharacter}
        scenario={selectedScenario}
        onClose={() => setCurrentActiveModal(null)}
      />
      <CloneScenarioDialog
        open={currentActiveModal === ScenarioModals.cloneScenario}
        onClose={() => setCurrentActiveModal(null)}
        scenario={selectedScenario}
      />
      <AddToCollectionDialog
        open={currentActiveModal === ScenarioModals.addToCollection}
        onClose={() => setCurrentActiveModal(null)}
        scenario={selectedScenario}
      />
    </Container>
  );
}
