import * as React from "react";

import { InputGroupProps, InputGroup } from "@blueprintjs/core";

interface SlugInputProps {
  value: string;
  onChange: (newValue: string) => void;
  InputGroupProps?: InputGroupProps;
}

const INVALID_SLUG_CHARS = /[^a-z0-9-]/g;
const MULTIPLE_DASHES = /-{2,}/g;

function SlugInput({ value, onChange, InputGroupProps }: SlugInputProps) {
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let sanitizedValue = e.target.value;
    sanitizedValue = sanitizedValue.toLowerCase();
    sanitizedValue = sanitizedValue.replace(" ", "-");
    sanitizedValue = sanitizedValue.replace(INVALID_SLUG_CHARS, "");
    sanitizedValue = sanitizedValue.replace(MULTIPLE_DASHES, "-");
    onChange(sanitizedValue);
  };

  return (
    <InputGroup
      value={value}
      onChange={handleInputChange}
      {...InputGroupProps}
    />
  );
}

export default SlugInput;
