import styled from "@emotion/styled";

import { Box } from "@/components/layout/flexbox";
import { breakpoints } from "@/components/layout/Media";

export default styled(Box)`
  background-color: #fff;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.05);
  padding: 100px 16px;

  @media screen and (max-width: ${breakpoints.md}px) {
    padding: 60px 16px;
  }
`;
