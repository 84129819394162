import { Button } from "@blueprintjs/core";

import { Box } from "@/components/layout/flexbox";
import StyledDialog from "@/components/pieces/StyledDialog";

function SimpleDialog({ onClose, isOpen, children, title, width = "480px" }) {
  return (
    <StyledDialog
      isOpen={isOpen}
      lazy={true}
      title={title}
      width={width}
      onClose={() => {
        onClose();
      }}
    >
      <Box mx={3} mt={3}>
        {children}
        <Box mt={16}>
          <Button
            intent="primary"
            large={true}
            onClick={() => onClose(false)}
            loading={false}
          >
            Close
          </Button>
        </Box>
      </Box>
    </StyledDialog>
  );
}

export default SimpleDialog;
