import { Classes, Icon } from "@blueprintjs/core";

import { useWorkspaceSlug } from "@/components/helpers/custom-hooks/use-workspace-slug";
import { Box } from "@/components/layout/flexbox";
import { ScenariosPageType } from "@/components/pages_logged_in/roleplays/scenarios/const";
import ScenariosSlider from "@/components/pages_logged_in/roleplays/scenarios/ScenariosSlider";
import PracticeSvg from "@/components/pieces/icons/svg/practice";
import { BRAND_PURPLE } from "@/css/constants";
import { QueryScenariosOrderBy } from "@/graphql";

export default function ForYouScenarios({ pageLoading = false }) {
  const workspaceSlug = useWorkspaceSlug();
  if (pageLoading) {
    return (
      <Box>
        <Box height={31} width={100} mb={16} className={Classes.SKELETON} />
        <Box height={250} mb={16} className={Classes.SKELETON} />
        <Box height={250} mb={16} className={Classes.SKELETON} />
      </Box>
    );
  }

  return (
    <Box mt={[30, 30, 30, 36]}>
      <Box fontSize={24} fontWeight={600}>
        For You
      </Box>

      {workspaceSlug && (
        <ScenariosSlider
          icon={<PracticeSvg size={16} color={BRAND_PURPLE} />}
          title="Assigned Scenarios"
          scenarioType={ScenariosPageType.Assigned}
        />
      )}

      <ScenariosSlider
        icon={<Icon size={16} icon={"clean"} color={BRAND_PURPLE} />}
        title="Personal Scenarios"
        scenarioType={ScenariosPageType.Personal}
        orderBy={QueryScenariosOrderBy.CreatedAtDesc}
      />
    </Box>
  );
}
