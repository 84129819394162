import { useLazyQuery } from "@apollo/client";

import { ValidateInviteEmailsDocument } from "@/graphql";

export const useValidateInviteEmailsLazyQuery = ({ onCompleted, onError }) => {
  const [fetch, { data, loading }] = useLazyQuery(
    ValidateInviteEmailsDocument,
    {
      fetchPolicy: "cache-and-network",
      onCompleted: (data) => {
        onCompleted(data?.validateInviteEmails);
      },
      onError: (err) => {
        onError(err);
      },
    },
  );
  return {
    loading,
    data,
    fetch: (emails, programId) =>
      fetch({
        variables: {
          emails,
          programId,
        },
      }),
  };
};
