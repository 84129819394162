import { Colors, Icon } from "@blueprintjs/core";

import hideVirtualKeyboard from "@/components/helpers/ui/hide-virtual-keyboard";
import { Box, Flex } from "@/components/layout/flexbox";
import BackLink from "@/components/pieces/BackLink";
import Clickable from "@/components/pieces/interaction/Clickable";
import { LIGHTEST_BACKGROUND, NAV_Z_INDEX } from "@/css/constants";

export const HEADER_HEIGHT = 50;
export const MENU_BACKGROUND = LIGHTEST_BACKGROUND;

export default function MenuHeader({ label, onClose, onBackLink }) {
  return (
    <Flex
      style={{
        backgroundColor: Colors.WHITE,
        top: 0,
        left: 0,
        right: 0,
        position: "fixed",
        zIndex: NAV_Z_INDEX,
        borderBottom: `2px solid ${MENU_BACKGROUND}`,
        overflow: "hidden",
      }}
      height={HEADER_HEIGHT}
      justifyContent="space-between"
      alignItems="center"
    >
      <Box>
        {onBackLink ? (
          <Clickable
            onClick={() => {
              hideVirtualKeyboard();
              onBackLink();
            }}
          >
            <div>
              <BackLink color={Colors.DARK_GRAY3} />
            </div>
          </Clickable>
        ) : (
          <Box width={50} />
        )}
      </Box>
      <Box>
        <h4>{label}</h4>
      </Box>
      <Box>
        {onClose ? (
          <Clickable
            onClick={() => {
              hideVirtualKeyboard();
              onClose();
            }}
          >
            <Box p={3}>
              <Icon icon="cross" size={24} color={Colors.DARK_GRAY3} />
            </Box>
          </Clickable>
        ) : (
          <Box width={50} />
        )}
      </Box>
    </Flex>
  );
}
