import { Icon, Spinner } from "@blueprintjs/core";
import styled from "@emotion/styled";
import { Node } from "@tiptap/core";
import { ReactNodeViewRenderer, NodeViewWrapper } from "@tiptap/react";
import { filesize } from "filesize";
import mime from "mime";

import { Flex, Box } from "@/components/layout/flexbox";
import {
  CARD_CLASSES,
  CTA_COLOR,
  LIGHT_BLUE,
  MIDTEXT_COLOR,
  RED,
  SUBTEXT_COLOR,
} from "@/css/constants";

export function AttachmentComponent({
  children,
  urlOrError,
  fileType,
  fileSize,
  onRemove,
  loading = false,
  error = false,
}: {
  children: React.ReactNode;
  urlOrError: string;
  fileType: string;
  fileSize: number;
  onRemove?: () => void;
  loading?: boolean;
  error?: boolean;
}) {
  return (
    <Container
      flexDirection="column"
      className={CARD_CLASSES}
      onClick={() => {
        if (urlOrError === "" || error) {
          return;
        }
        window.open(urlOrError, "_blank");
      }}
    >
      <Flex style={{ gap: 16, position: "relative" }} alignItems="center" p={2}>
        <Flex
          backgroundColor={error ? RED : CTA_COLOR}
          p={2}
          className="round-corners"
          alignItems="center"
          justifyContent="center"
          minWidth={"unset"}
        >
          <Icon icon="document" size={32} color="white" />
        </Flex>

        <Flex flexDirection="column" alignItems="flex-start">
          <Box
            contentEditable={false}
            fontSize="12px"
            fontWeight="bold"
            pr={3}
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              width: "100%",
            }}
          >
            {children}
          </Box>
          {loading ? (
            <Spinner size={16} />
          ) : error ? (
            <Flex contentEditable={false} fontSize="12px" color={RED}>
              Error Uploading - {urlOrError}
            </Flex>
          ) : (
            <Flex contentEditable={false} fontSize="12px" color={SUBTEXT_COLOR}>
              {mime.getExtension(fileType)?.toUpperCase()}・{filesize(fileSize)}
            </Flex>
          )}
        </Flex>
        {onRemove && (
          <Flex
            style={{
              position: "absolute",
              right: 0,
              top: 0,
            }}
            p={2}
            onClick={(event) => {
              event.stopPropagation();
              onRemove();
            }}
          >
            <Icon icon="cross" size={16} color={MIDTEXT_COLOR} />
          </Flex>
        )}
      </Flex>
    </Container>
  );
}

function AttachmentNode(props) {
  return (
    <NodeViewWrapper>
      <AttachmentComponent
        urlOrError={props.node.attrs["data-url-or-error"]}
        fileType={props.node.attrs["data-file-type"]}
        fileSize={props.node.attrs["data-file-size"]}
        onRemove={props.deleteNode}
        loading={String(props.node.attrs["data-exec-id"]).startsWith("new")}
        error={String(props.node.attrs["data-exec-id"]).startsWith("error")}
      >
        {props.node.textContent}
      </AttachmentComponent>
    </NodeViewWrapper>
  );
}

const Container = styled(Flex)`
  background-color: white;
  cursor: pointer;
  &:hover {
    background-color: ${LIGHT_BLUE};
  }
  margin-top: 12px;
  margin-bottom: 12px;
`;

export const Attachment = Node.create({
  name: "attachment",
  group: "block",
  atom: true,
  content: "text*",
  selectable: false,

  addAttributes() {
    return {
      "data-file-size": {
        default: 0,
      },
      "data-file-type": {
        default: "",
      },
      "data-exec-id": {
        default: "",
      },
      "data-url-or-error": {
        default: "",
      },
    };
  },

  renderHTML({ HTMLAttributes }) {
    return ["div", HTMLAttributes, 0];
  },

  parseHTML() {
    return [
      {
        tag: "div[data-exec-id]",
      },
    ];
  },

  addNodeView() {
    return ReactNodeViewRenderer(AttachmentNode);
  },
});
