import { useEffect, useMemo, useRef, useState } from "react";

import tick from "@/../../hypetrain/static/img/icons/tick.png";
import { Tag } from "@blueprintjs/core";
import styled from "@emotion/styled";
import startCase from "lodash/startCase";
import toLower from "lodash/toLower";

import LazyImg from "@/components/helpers/ui/LazyImg";
import { RichContentRenderer } from "@/components/helpers/ui/RichContentRenderer";
import { Box, Flex } from "@/components/layout/flexbox";
import CoachingProducts from "@/components/pieces/coaching/coach-profile/CoachingProducts";
import DBFaqItems from "@/components/pieces/faq/DBFaqItems";
import Clickable from "@/components/pieces/interaction/Clickable";
import StandardCredentials from "@/components/pieces/talent-agents/StandardCredentials";
import { WorkHistories } from "@/components/pieces/talent-agents/WorkHistory";
import {
  BRAND_PURPLE,
  HEADING_FONT,
  LIGHTEST_BACKGROUND,
  MIDTEXT_COLOR,
  SUBTEXT_COLOR,
} from "@/css/constants";

import ClientComments from "./ClientComments";
import CoachVideos from "./CoachVideos";
import { TITLE_CSS } from "./constants";

const PREVIEW_ITEM_COUNT = 4;

function ProfileItemsBox({
  items,
  title,
  displayType = "tag",
  previewItemCount = 4,
}) {
  const [showAll, setShowAll] = useState(false);

  const renderItems = useMemo(() => {
    return showAll ? items : items.slice(0, previewItemCount);
  }, [showAll, items]);

  const text = renderItems
    .map((item) => startCase(toLower(item.name ? item.name : item)))
    .join(", ");

  return (
    <Flex pb={3} flexWrap={["wrap", "nowrap"]} flexDirection="column">
      <Box css={TITLE_CSS}>{title}</Box>
      <Flex width={["100%", "auto"]} flexWrap="wrap">
        {displayType === "tag" ? (
          <>
            {renderItems.map((item, index) => (
              <Box mr={2} mb={2} key={`job-functions-${index}`}>
                <Tag large={true} minimal={true}>
                  {startCase(toLower(item.name ? item.name : item))}
                </Tag>
              </Box>
            ))}
            {items.length > previewItemCount && !showAll ? (
              <Box mr={2} mb={2}>
                <Clickable onClick={() => setShowAll(true)}>
                  <MoreButton> + more</MoreButton>
                </Clickable>
              </Box>
            ) : null}
          </>
        ) : (
          <Box className="custom-check-item">
            <Box>
              {text}
              {items.length > previewItemCount && !showAll ? (
                <>
                  <span> and </span>
                  <Clickable onClick={() => setShowAll(true)}>
                    <InlineMoreButton>more</InlineMoreButton>
                  </Clickable>
                </>
              ) : null}
            </Box>
          </Box>
        )}
      </Flex>
    </Flex>
  );
}

export function DetailsBox({ profile, displayType = "tag" }) {
  const {
    matchingIndustries,
    credentials,
    relevantIndustries,
    matchingJobFunctions,
    relevantJobFunctions,
    standardCredentials,
  } = profile;
  const targetIndustries = matchingIndustries || relevantIndustries || [];
  const targetJobFunctions = matchingJobFunctions || relevantJobFunctions || [];

  return (
    <StyledCheckList mt={24}>
      <WorkHistories profile={profile} />
      {credentials ||
      (standardCredentials && standardCredentials.length > 0) ? (
        <Flex pb={3} flexWrap={["wrap", "nowrap"]} flexDirection="column">
          <Box css={TITLE_CSS}>Credentials</Box>
          <Box width={["100%", "auto"]}>
            <ul>
              <StandardCredentials standardCredentials={standardCredentials} />
              {credentials &&
                credentials.map((credential, index) => (
                  <li key={`credential-${index}`}>{credential}</li>
                ))}
            </ul>
          </Box>
        </Flex>
      ) : null}

      {targetIndustries && targetIndustries.length ? (
        <ProfileItemsBox
          items={targetIndustries}
          title="Industry Specialties"
          displayType={displayType}
          previewItemCount={PREVIEW_ITEM_COUNT}
        />
      ) : null}

      {targetJobFunctions && targetJobFunctions.length ? (
        <ProfileItemsBox
          items={targetJobFunctions}
          title="Functional Specialties"
          displayType={displayType}
          previewItemCount={PREVIEW_ITEM_COUNT}
        />
      ) : null}
    </StyledCheckList>
  );
}

const MAX_BIO_HEIGHT = 300;

function BioBox({ about }) {
  const [seeAll, setSeeAll] = useState(false);
  const boxRef = useRef(null);

  useEffect(() => {
    const height = boxRef.current.clientHeight;
    if (height < MAX_BIO_HEIGHT) {
      setSeeAll(true);
    }
  }, []);

  return (
    <StyledCheckList>
      <Box
        ref={boxRef}
        style={{
          overflow: "hidden",
          maxHeight: seeAll ? null : MAX_BIO_HEIGHT,
          position: "relative",
        }}
      >
        <RichContentRenderer content={about} />
        <Box
          style={{
            position: "absolute",
            bottom: 0,
            height: 0,
            width: "100%",
            boxShadow: seeAll
              ? null
              : `0px 0px 30px 30px ${LIGHTEST_BACKGROUND}`,
          }}
        />
      </Box>
      {seeAll ? null : (
        <Clickable onClick={() => setSeeAll(true)}>
          <MoreButton>+ read more</MoreButton>
        </Clickable>
      )}
    </StyledCheckList>
  );
}
export function Testimonials({ quotes = [] }) {
  return (
    <Box>
      {quotes.map((quote, index) => {
        const { photo, promo, client, clientPosition } = quote;
        return (
          <Box
            key={`quote--${index}`}
            className="round-corners bas"
            mt={index > 0 ? 24 : 0}
            p={24}
          >
            <Flex
              flexDirection={"row"}
              alignItems="center"
              className="full-height"
            >
              <Box mr={3} alignSelf="self-start">
                <LazyImg
                  src={photo}
                  width={45}
                  height={45}
                  style={{ borderRadius: 50 }}
                />
              </Box>
              <Flex flexDirection="column" alignItems="flex-start" flex={1}>
                <Box>“{promo}”</Box>
                <Box color={MIDTEXT_COLOR} mt={2}>
                  <b>{client}</b> · {clientPosition}
                </Box>
              </Flex>
            </Flex>
          </Box>
        );
      })}
    </Box>
  );
}

export default function ProfileDetails({
  profile,
  isProfile = false,
  containerCss = {},
}) {
  const {
    about = "",
    faqAnswers = [],
    specialtiesWithCoachProducts = [],
    testimonials = [],
  } = profile || {};
  const showCoachProduct = specialtiesWithCoachProducts.length > 0;

  const quotes = testimonials.map((testimonial) => {
    const {
      name: client,
      jobTitle: clientPosition,
      message: promo,
      avatarUrl: photo,
    } = testimonial;
    return {
      client,
      clientPosition,
      promo,
      photo,
    };
  });
  const textFaqAnswers = faqAnswers.filter((faq) => faq.content);
  const videoFaqAnswers = faqAnswers.filter(
    (faq) => !faq.content && faq.videoUrl,
  );

  return (
    <Box css={containerCss}>
      <Box pb={24}>
        <Box css={TITLE_CSS}>Bio</Box>
        <BioBox about={about} />
      </Box>

      {quotes.length > 0 && (
        <Box pb={24}>
          <Testimonials quotes={quotes} />
        </Box>
      )}

      {videoFaqAnswers.length > 0 && (
        <Box pb={24}>
          <Box css={TITLE_CSS}>In Their Own Words</Box>
          <CoachVideos faqItems={videoFaqAnswers} />
        </Box>
      )}

      <DetailsBox profile={profile} displayType="list" />

      {textFaqAnswers.length > 0 && (
        <>
          <Box css={TITLE_CSS}>FAQ</Box>
          <Box pb={24}>
            <DBFaqItems faqItems={textFaqAnswers} />
          </Box>
        </>
      )}

      {showCoachProduct && (
        <>
          <CoachingProducts
            specialties={specialtiesWithCoachProducts}
            isCoachInUserCoaches={false}
            noBorder={true}
            showSpecialtyInfo={false}
            asCoach={false}
            showLinks={false}
            agentProfile={profile}
          />
        </>
      )}

      {!isProfile ? <ClientComments profile={profile} /> : null}
    </Box>
  );
}

const StyledCheckList = styled(Box)`
  h1,
  h2,
  h3,
  h4 {
    font-family: ${HEADING_FONT};
    font-size: 24px;
    margin: 16px 0;
  }

  .custom-check-item {
    margin-bottom: 16px;
    display: flex;
    align-items: center;

    &:before {
      content: " ";
      width: 32px;
      height: 32px;
      background: #e2eeff;
      border-radius: 50%;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      padding: 16px;
      margin-right: 16px;
      background-image: url(${tick});
      background-size: 14px;
      background-position: center;
      background-repeat: no-repeat;
      align-self: start;
    }
  }

  ul {
    padding-left: 0px;
    margin-bottom: 0;
    margin-top: 4px;
    list-style: none;

    &.bulleted-list {
      list-style: disc;
      padding-left: 16px;
      margin-bottom: 0px;

      li {
        display: list-item;
        margin-bottom: 4px;
        &:before {
          content: none;
        }
      }
    }

    li {
      margin-bottom: 16px;
      display: flex;
      align-items: center;

      &:last-child {
        margin-bottom: 0px;
      }

      &::marker {
        color: ${SUBTEXT_COLOR};
      }

      &:before {
        content: " ";
        width: 32px;
        height: 32px;
        background: #e2eeff;
        border-radius: 50%;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        padding: 16px;
        margin-right: 16px;
        background-image: url(${tick});
        background-size: 14px;
        background-position: center;
        background-repeat: no-repeat;
        align-self: start;
      }
    }
  }
`;

const MoreButton = styled("span")`
  color: ${BRAND_PURPLE};
  font-size: 16px;
  font-weight: 600;
  margin: 0;
`;

const InlineMoreButton = styled("span")`
  color: ${BRAND_PURPLE};
  font-size: 16px;
  font-weight: 600;
`;
