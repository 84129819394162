import CreditSVG from "@/../../hypetrain/static/img/credit.svg";
import { Classes } from "@blueprintjs/core";
import { Link } from "react-router-dom";

import { pluralize } from "@/components/helpers/string-utils";
import EmbedSvg from "@/components/helpers/ui/EmbedSvg";
import { Box, Flex } from "@/components/layout/flexbox";
import { Media } from "@/components/layout/Media";
import {
  AgentProfileWithSlug,
  DisplayedProduct,
} from "@/components/pieces/coaching/coach-profile/coachingUtils";
import { GREEN, RED, SUBTEXT_COLOR } from "@/css/constants";

function StatusLabel({ enabled }) {
  if (typeof enabled !== "boolean") {
    return null;
  }
  const color = enabled ? GREEN : RED;
  const label = enabled ? "ENABLED" : "DISABLED";
  return (
    <Flex
      flexDirection="row"
      alignItems="center"
      justifyContent="flex-end"
      css={{ fontSize: 12, color: color, width: 100 }}
    >
      <Box
        css={{
          width: 14,
          height: 14,
          borderRadius: 7,
          marginRight: 5,
          background: color,
        }}
      />
      <Box css={{ fontWeight: 700 }}>{label}</Box>
    </Flex>
  );
}

const CREDIT_PRICE_SKELETON = (
  <Box className={Classes.SKELETON} width={30} height="1em" ml={2} />
);

interface ProductProps {
  product: DisplayedProduct;
  isCoachInUserCoaches: boolean;
  asCoach: boolean;
  showLinks: boolean;
  agentProfile: AgentProfileWithSlug;
}

export default function Product({
  product,
  isCoachInUserCoaches,
  showLinks,
  asCoach,
  agentProfile,
}: ProductProps) {
  const {
    shortDescription,
    name,
    slug,
    enabled,
    displayedCreditPrice,
    acceptingNewClients,
    enableRfq,
  } = product;
  const productRoute = `/explore/${slug}/${agentProfile?.coachSlug}`;

  const mainContent = (
    <Box
      className={`bas ${Classes.CARD}${
        asCoach ? ` ${Classes.INTERACTIVE}` : ""
      } round-corners`}
      backgroundColor="#FFF"
      p={[3, 3, 3, 3]}
      my={3}
    >
      <Box fontSize={18} fontWeight={600}>
        {!showLinks ? (
          name
        ) : asCoach ? (
          <Flex>
            <Box flexGrow={1}>{name}</Box>
            <StatusLabel enabled={enabled} />
          </Flex>
        ) : (
          <Link to={productRoute}>{name}</Link>
        )}
      </Box>
      {shortDescription && (
        <Box mt={1} css={{ color: "black" }}>
          {shortDescription}
        </Box>
      )}
      <Flex
        mt={3}
        alignItems={["left", "center"]}
        flexDirection={["column", "row"]}
      >
        <Flex alignItems="center">
          <EmbedSvg src={CreditSVG} height={16} width={16} />
          {typeof displayedCreditPrice === "number" ? (
            <Box
              ml={2}
              color={SUBTEXT_COLOR}
              className="dot-line"
              css={{ display: "inline" }}
            >
              {pluralize(displayedCreditPrice, "Credit", "Credits", true)}
            </Box>
          ) : enableRfq ? (
            <Box
              ml={[0, 2]}
              mt={[2, 0]}
              color={SUBTEXT_COLOR}
              css={{ display: "inline" }}
            >
              Pricing upon request
            </Box>
          ) : (
            CREDIT_PRICE_SKELETON
          )}
        </Flex>
        {!isCoachInUserCoaches && !acceptingNewClients && (
          <>
            <Media greaterThan="xs">
              <Box ml={2} css={{ display: "inline" }}>
                ·
              </Box>
            </Media>
            <Box ml={2} color={SUBTEXT_COLOR} css={{ display: "inline" }}>
              Only bookable after intro call
            </Box>
          </>
        )}
      </Flex>
    </Box>
  );

  if (asCoach) {
    return (
      <Link style={{ color: "black" }} to={`/services/${slug}`}>
        {mainContent}
      </Link>
    );
  }
  return mainContent;
}
