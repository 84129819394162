import { useCallback } from "react";

import { Colors, Icon, IconName } from "@blueprintjs/core";
import styled from "@emotion/styled";
import { useNavigate } from "react-router-dom";

import { useUnreadMessageCount } from "@/components/helpers/custom-hooks/use-unread-message-count";
import HeaderButton from "@/components/pieces/button/HeaderButton";
import { CTA_COLOR } from "@/css/constants";

export default function MessageNav() {
  const navigate = useNavigate();
  const onError = useCallback((error: Error) => {
    console.error("Error fetching unread message count", error);
  }, []);
  const { unreadCount } = useUnreadMessageCount({ onError });

  const showPlus = unreadCount >= 10;
  return (
    <HeaderButton onClick={() => navigate("/messages")}>
      <div style={{ position: "relative" }}>
        <Icon icon={"chat" as IconName} size={20} color={Colors.GRAY3} />
        {unreadCount > 0 && <Badge>{showPlus ? "9+" : unreadCount}</Badge>}
      </div>
    </HeaderButton>
  );
}

const Badge = styled.div`
  position: absolute;
  bottom: 4px;
  right: 4px;
  width: 16px;
  height: 16px;
  border-radius: 100%;
  background-color: ${CTA_COLOR};
  color: ${Colors.WHITE};
  font-size: 9px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
