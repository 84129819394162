import styled from "@emotion/styled";
import { Hit } from "instantsearch.js";
import { useInfiniteHits } from "react-instantsearch";

import { breakpoints } from "@/components/layout/Media";
import ArticleCard, {
  ArticleWithReadStatus,
} from "@/components/pieces/learn/ArticleCard";
import { CARD_MARGIN } from "@/components/pieces/learn/constants";
import { SITE_NAME } from "@/css/constants";
import { ArticleReadStatusReadStatus } from "@/graphql";

interface ReadStatus {
  [key: string]: ArticleReadStatusReadStatus;
}

interface ArticleCardGridProps {
  articles: ArticleWithReadStatus[];
  cardsPerRow: number; // Required prop for determining grid layout
}

export default function ArticleCardGrid({
  articles,
  cardsPerRow,
}: ArticleCardGridProps) {
  // Display up to 6 articles (3 rows of 2 on mobile)
  const displayedArticles = articles.slice(0, 6);

  return (
    <GridContainer cardsPerRow={cardsPerRow}>
      {displayedArticles.map((article) => (
        <GridItem key={article.id}>
          <ArticleCard item={article} />
        </GridItem>
      ))}
    </GridContainer>
  );
}

interface AlgoliaArticleCardGridProps {
  readStatusById?: ReadStatus;
  cardsPerRow: number; // Required prop for determining grid layout
}

export function AlgoliaArticleCardGrid({
  readStatusById = {},
  cardsPerRow,
}: AlgoliaArticleCardGridProps) {
  const { results } = useInfiniteHits<Hit>();

  if (!results?.hits || results.hits.length < 1) {
    return null;
  }

  // Transform Algolia hits to ArticleWithReadStatus objects
  const articles = results.hits.map((hit) => toArticle(hit, readStatusById));

  return <ArticleCardGrid articles={articles} cardsPerRow={cardsPerRow} />;
}

function toArticle(
  hit: Hit,
  readStatusById: ReadStatus = {},
): ArticleWithReadStatus {
  const itemId = `${hit.article_id || hit.objectID || 0}`;
  return {
    ...hit,
    timeToRead: hit.time_to_read || 0,
    urlSlug: hit.url_slug || "",
    heroImage: hit.hero_image || "",
    id: itemId,
    title: hit.title || "",
    currentUserReadStatus: readStatusById[itemId],
    createdAt: hit.created_at || new Date().toISOString(),
    externalId: hit.external_id || "",
    isPublished: hit.is_published || false,
    readStatuses: [],
    canonicalDomain: hit.canonical_domain || SITE_NAME,
    visibleOn: hit.visible_on || [],
    isSearchable: hit.is_searchable || false,
  };
}

interface GridContainerProps {
  cardsPerRow: number;
}

const GridContainer = styled.div<GridContainerProps>`
  display: grid;
  grid-template-columns: repeat(${(props) => props.cardsPerRow}, 1fr);
  gap: ${CARD_MARGIN * 2}px;
  margin: 0 0 48px 0;
  width: 100%;

  @media (max-width: ${breakpoints.sm}px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

const GridItem = styled.div`
  min-width: 0; /* Prevents grid items from overflowing */
  height: 100%;
`;
