import { useState } from "react";

import { useMutation } from "@apollo/client";
import { Button, Callout, FormGroup, InputGroup } from "@blueprintjs/core";

import { Box } from "@/components/layout/flexbox";
import { AvailableCreditsDocument, RedeemGiftCodeDocument } from "@/graphql";

export default function RedeemGiftCode({ onCompleted }) {
  const [code, setCode] = useState("");
  const [redeem, { data, loading }] = useMutation(RedeemGiftCodeDocument, {
    variables: { code },
    refetchQueries: [{ query: AvailableCreditsDocument }],
    onCompleted: (response) => {
      if (response && response.redeemGiftCode.ok) {
        setCode("");
      }
      onCompleted(response);
    },
  });
  const hasErrors =
    data && data.redeemGiftCode && !!data.redeemGiftCode.errors.length;

  return (
    <>
      <FormGroup label={<b>Enter Gift Code</b>}>
        <InputGroup
          large={true}
          autoFocus={true}
          value={code}
          placeholder="Gift code"
          onChange={(e) => setCode(e.target.value)}
          disabled={loading}
          style={{ height: 60 }}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              redeem();
            }
          }}
        />
      </FormGroup>
      <FormGroup>
        <Button
          large={true}
          intent="primary"
          disabled={!code.length || loading}
          loading={loading}
          onClick={redeem}
        >
          Redeem
        </Button>
      </FormGroup>
      {hasErrors && (
        <Box mt={3}>
          <Callout intent="warning" title="Oh no!">
            That code didn't work. {data.redeemGiftCode.errors[0].message}
          </Callout>
        </Box>
      )}
    </>
  );
}
