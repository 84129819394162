import { useState, ReactElement } from "react";

import { Classes, Colors, Icon, IconName, Spinner } from "@blueprintjs/core";
import styled from "@emotion/styled";

import { Box, Flex } from "@/components/layout/flexbox";
import FlipIcon from "@/components/pieces/icons/FlipIcon";
import Clickable from "@/components/pieces/interaction/Clickable";
import { BRAND_PURPLE } from "@/css/constants";

interface MenuItemProps {
  icon: IconName | ReactElement;
  label: string;
  onClick: () => void;
  loading?: boolean;
  hoverIcon?: IconName | ReactElement | null;
  justCompleted?: boolean | null;
}
export default function MenuItem({
  icon,
  label,
  onClick,
  loading = false,
  hoverIcon = null,
  justCompleted = null,
}: MenuItemProps) {
  const [hovering, setHovering] = useState(false);
  const clientsMenuIcon = (
    // avoid lagging when swich state
    <FlipIcon
      activeIcon={hoverIcon || icon}
      inactiveIcon={icon}
      isActive={hovering}
    />
  );
  return (
    <Clickable onClick={justCompleted ? null : onClick}>
      <MenuItemBox
        justifyContent="space-between"
        alignItems="center"
        px={3}
        py={24}
        onMouseEnter={() => setHovering(true)}
        onMouseLeave={() => setHovering(false)}
      >
        <Flex alignItems="center">
          <Flex justifyContent="center" width={20}>
            {typeof icon === "string" ? (
              <Icon color="#394B59" icon={icon} />
            ) : hoverIcon ? (
              clientsMenuIcon
            ) : (
              icon
            )}
          </Flex>
          <Box ml={12} mt="1px">
            {label}
          </Box>
        </Flex>
        <Box>
          {justCompleted ? (
            <Box style={{ color: Colors.FOREST3 }}>Saved!</Box>
          ) : loading ? (
            <Box>
              <Spinner size={20} />
            </Box>
          ) : (
            <Box className="arrow">
              <Icon icon="chevron-right" size={20} />
            </Box>
          )}
        </Box>
      </MenuItemBox>
    </Clickable>
  );
}

const MenuItemBox = styled(Flex)`
  background-color: ${Colors.WHITE};
  .arrow {
    .${Classes.ICON} {
      color: ${Colors.GRAY3};
    }
  }

  &:hover {
    position: relative;
    background-color: #f8fafe;
    z-index: 2;
    color: ${BRAND_PURPLE};
    font-weight: 600;
    svg {
      fill: ${BRAND_PURPLE};
    }

    .arrow {
      .${Classes.ICON} {
        color: ${BRAND_PURPLE};
      }
    }
  }
`;
