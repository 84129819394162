import { ReactNode, useEffect, useRef, useState } from "react";

import { Colors, Icon } from "@blueprintjs/core";
import styled from "@emotion/styled";
import AudioPlayer from "react-h5-audio-player";

import { Tooltip } from "@/components/helpers/ui/blueprint-overrides/Tooltip";
import { Box, Flex } from "@/components/layout/flexbox";

interface Mp3PlayerProps {
  src: string;
  onPlay?: () => void;
  playTooltip?: string | null;
  showJumpControls?: boolean;
  onTimeChange?: (event: Event, duration: number) => void;
  onTimeEnd?: () => void;
  currentTime?: number;
  minimal?: boolean;
  backgroundColor?: string;
  playIcon?: ReactNode;
  pauseIcon?: ReactNode;
  textColor?: string;
  mainColor?: string;
  showProgressIndicator?: boolean;
}

export default function Mp3Player({
  src,
  onPlay,
  playTooltip,
  showJumpControls = false,
  onTimeChange,
  onTimeEnd,
  currentTime = 0,
  minimal = false,
  backgroundColor = "#FFF",
  playIcon,
  pauseIcon,
  textColor = Colors.BLACK,
  mainColor = "#4B89E7",
  showProgressIndicator = true,
}: Mp3PlayerProps) {
  const playerRef = useRef<AudioPlayer>(null);
  const [ready, setReady] = useState(false);
  const [hasPlayed, setHasPlayed] = useState(false);

  useEffect(() => {
    const player = playerRef.current && playerRef.current.audio.current;
    if (player) {
      player.addEventListener("loadedmetadata", () => {
        if (playerRef.current) {
          setReady(true);
        }
      });
    }

    return () => {
      player?.removeEventListener("loadedmetadata", () => {});
    };
  }, []);

  useEffect(() => {
    if (ready && playerRef.current?.audio?.current) {
      playerRef.current.audio.current.currentTime = currentTime;
    }
  }, [ready, currentTime]);

  return (
    <>
      <StyledContainer
        ready={ready}
        minimal={minimal}
        backgroundColor={backgroundColor}
        textColor={textColor}
        color={mainColor}
        showProgressIndicator={showProgressIndicator}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <AudioPlayer
          ref={playerRef}
          autoPlay={false}
          autoPlayAfterSrcChange={false}
          src={src}
          onPlay={() => {
            setHasPlayed(true);
            if (onPlay) {
              onPlay();
            }
          }}
          layout="horizontal-reverse"
          showJumpControls={showJumpControls}
          showDownloadProgress={false}
          customAdditionalControls={[]}
          customVolumeControls={[]}
          defaultCurrentTime=""
          defaultDuration=""
          listenInterval={500}
          progressJumpSteps={{ forward: 10000, backward: 10000 }}
          onListen={(event) => {
            const { duration } = (playerRef.current &&
              playerRef.current.audio.current) || { duration: 0 };
            onTimeChange?.(event, duration);
          }}
          onEnded={onTimeEnd}
          customIcons={{
            forward: (
              <Flex
                alignItems="center"
                justifyContent="center"
                width={36}
                height={36}
                css={{
                  backgroundColor: "#0719560A",
                  borderRadius: "50%",
                }}
              >
                <Icon icon="repeat" size={18} />
              </Flex>
            ),
            rewind: (
              <Flex
                alignItems="center"
                justifyContent="center"
                width={36}
                height={36}
                css={{
                  backgroundColor: "#0719560A",
                  borderRadius: "50%",
                }}
              >
                <Icon icon="reset" size={18} />
              </Flex>
            ),
            play: playIcon,
            pause: pauseIcon,
          }}
        />
      </StyledContainer>
      {playTooltip ? (
        <Tooltip
          placement="bottom-start"
          content={playTooltip}
          isOpen={ready && !hasPlayed}
          transitionDuration={200}
          targetProps={{
            style: {
              display: "block",
              width: 42,
            },
          }}
        >
          <Box />
        </Tooltip>
      ) : null}
    </>
  );
}

interface StyledContainerProps {
  ready?: boolean;
  minimal?: boolean;
  backgroundColor?: string;
  textColor?: string;
  color?: string;
  showProgressIndicator?: boolean;
}

const StyledContainer = styled("div")<StyledContainerProps>`
  position: relative;
  width: 100%;
  cursor: default;
  .rhap_container {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    line-height: 1;
    font-family: inherit;
    width: 100%;
    padding: 0;
    background-color: ${(props) => props.backgroundColor || "#FFF"};
  }
  .rhap_container:focus:not(:focus-visible) {
    outline: 0;
  }
  .rhap_container svg {
    vertical-align: initial;
  }

  .rhap_header {
    margin-bottom: 10px;
  }

  .rhap_footer {
    margin-top: 5px;
  }

  .rhap_main {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
  }

  .rhap_horizontal-reverse {
    flex-direction: row-reverse;
  }

  .rhap_horizontal-reverse .rhap_controls-section {
    margin: -4px 6px -4px -4px;
  }

  .rhap_progress-section {
    display: flex;
    flex: 1 1 auto;
    align-items: center;
    position: relative;
  }

  .rhap_progress-container {
    display: flex;
    align-items: center;
    height: 20px;
    flex: 1 0 auto;
    align-self: center;
    margin: ${(props) => (props.minimal ? "0px 4px" : "0")};
    cursor: pointer;
    -webkit-user-select: none;
    transition: margin 50ms ease-in-out;
  }
  .rhap_progress-container:focus:not(:focus-visible) {
    outline: 0;
  }

  .rhap_time {
    color: ${(props) => props.textColor || Colors.BLACK};
    font-size: 12px;
    user-select: none;
    position: margin: ${(props) => (props.minimal ? "static" : "absolute")};
    top: 31px;
    -webkit-user-select: none;
  }

  .rhap_current-time {
    left: 2px;
  }

  .rhap_total-time {
    right: 2px;
  }

  .rhap_progress-bar {
    border-radius: 6px;
    box-sizing: border-box;
    position: relative;
    margin: 0 10px;
    z-index: 0;
    width: 100%;
    height: 5px;
    background-color: #dddddd;
  }

  .rhap_progress-filled {
    border-radius: 6px;
    height: 100%;
    position: absolute;
    z-index: 2;
    background-color: ${(props) => props?.color || "#4B89E7"};
  }

  .rhap_progress-indicator {
    display: ${(props) => (props?.showProgressIndicator ? "block" : "none")};
    box-sizing: border-box;
    position: absolute;
    z-index: 3;
    width: 12px;
    height: 12px;
    margin-left: -6px;
    top: -4px;
    background: ${(props) =>
      props.ready ? props?.color || "#4B89E7" : "#c4d7f5"};
    border-radius: 100%;
  }

  .rhap_controls-section {
    display: flex;
    flex: 0 1 auto;
    justify-content: space-between;
    align-items: center;
  }

  .rhap_additional-controls {
    display: none;
  }

  .rhap_main-controls {
    flex: 0 1 auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .rhap_main-controls-button {
    margin: 0;
    color: ${(props) => (props.ready ? props?.color || "#4B89E7" : "#c4d7f5")};
    font-size: ${(props) => (props.minimal ? "16px" : "50px")};
    width: ${(props) => (props.minimal ? "16px" : "50px")};
    height: ${(props) => (props.minimal ? "16px" : "50px")};
  }

  .rhap_volume-controls {
    display: none;
  }

  .rhap_button-clear {
    background-color: transparent;
    border: none;
    padding: 0;
    overflow: hidden;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .rhap_button-clear:hover {
    opacity: 0.9;
    transition-duration: 0.2s;
  }
  .rhap_button-clear:active {
    opacity: 0.95;
  }
  .rhap_button-clear:focus:not(:focus-visible) {
    outline: 0;
  }
`;
