import {
  Children,
  ReactNode,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import { useMutation } from "@apollo/client";
import { Colors } from "@blueprintjs/core";
import noop from "lodash/noop";
import sortBy from "lodash/sortBy";
import { useHotkeys } from "react-hotkeys-hook";
import { useInstantSearch } from "react-instantsearch";
import { useMediaQuery } from "react-responsive";

import { Box } from "@/components/layout/flexbox";
import { isXsQuery } from "@/components/layout/Media";
import { BOTTOM_SHADOW } from "@/css/constants";
import {
  SaveSearchDocument,
  SearchPreferenceDocument,
  SearchPreferenceQuery,
} from "@/graphql";

import { SearchIndex } from "./search-types";

const HITS_PER_PAGE = 5;

interface SearchResultsProps {
  searchPreference: SearchPreferenceQuery;
  onClose: () => void;
  availableIndexes: SearchIndex[];
  filteredIndexes: SearchIndex[];
  visible: boolean;
  query: string;
}

export default function SearchResults({
  searchPreference,
  onClose,
  availableIndexes: availableIndexes,
  filteredIndexes: filteredIndexes,
  visible,
  query,
}: SearchResultsProps) {
  const containerRef = useRef<HTMLDivElement>(null);
  const [selectedObjectId, setSelectedObjectId] = useState<string | null>(null);
  const { renderState } = useInstantSearch();

  const showRecentSearches =
    !query && searchPreference.recentSearches.length > 0;

  const isXs = useMediaQuery(isXsQuery);

  // Get all results in a flat array with their objectIDs
  // needed for keyboard navigation
  const allResults = useMemo(() => {
    const results: { objectID: string }[] = [];

    if (showRecentSearches) {
      results.push(
        ...searchPreference.recentSearches.map((item) =>
          JSON.parse(item.selectedResult),
        ),
      );
    }

    filteredIndexes.forEach((index) => {
      const hits = renderState[index.key]?.hits?.items ?? [];
      results.push(...hits);
    });

    return results;
  }, [
    showRecentSearches,
    searchPreference.recentSearches,
    renderState,
    filteredIndexes,
  ]);

  const resultCount = allResults.length;

  // Only update selectedObjectId when query changes or visibility changes
  useEffect(() => {
    setSelectedObjectId(allResults.length > 0 ? allResults[0].objectID : null);
  }, [query, visible]);

  useEffect(() => {
    if (!visible) setSelectedObjectId(null);
  }, [visible]);

  useHotkeys(
    "down, tab",
    (e) => {
      if (!visible) return;
      if (allResults.length === 0) return;
      if (!containerRef.current?.contains(document.activeElement)) return;

      setSelectedObjectId((prevId) => {
        const currentIndex = allResults.findIndex((r) => r.objectID === prevId);
        if (currentIndex === -1 || currentIndex === allResults.length - 1)
          return allResults[0].objectID;
        return allResults[currentIndex + 1].objectID;
      });
      e.preventDefault();
    },
    {
      enableOnFormTags: true,
      enableOnContentEditable: true,
      keydown: true,
      keyup: false,
      preventDefault: true,
      enabled: visible,
    },
    [visible, allResults],
  );

  useHotkeys(
    "up, shift+tab",
    (e) => {
      if (!visible) return;
      if (allResults.length === 0) return;
      if (!containerRef.current?.contains(document.activeElement)) return;

      setSelectedObjectId((prevId) => {
        const currentIndex = allResults.findIndex((r) => r.objectID === prevId);
        if (currentIndex === -1 || currentIndex === 0)
          return allResults[allResults.length - 1].objectID;
        return allResults[currentIndex - 1].objectID;
      });
      e.preventDefault();
    },
    {
      enableOnFormTags: true,
      enableOnContentEditable: true,
      keydown: true,
      keyup: false,
      preventDefault: true,
      enabled: visible,
    },
    [visible, allResults],
  );

  const [saveSearch] = useMutation(SaveSearchDocument, {
    refetchQueries: [
      {
        query: SearchPreferenceDocument,
        variables: {
          customerId: searchPreference.customer?.id,
        },
      },
    ],
  });

  return (
    <div ref={containerRef}>
      {showRecentSearches && (
        <Box mb={3} mt={-2}>
          <Box mb={12}>
            <b>Recent</b>
          </Box>
          <ResultsContainer
            noResults={resultCount === 0}
            onClose={onClose}
            className="results"
            showRecentSearches={showRecentSearches}
          >
            {searchPreference.recentSearches.map((item) => {
              const index = availableIndexes.find(
                (i) => i.key === item.selectedResultType,
              );
              if (!index) return null;

              const hit = JSON.parse(item.selectedResult);
              const { ResultComponent } = index;
              const selected = selectedObjectId === hit.objectID;

              return (
                <ResultComponent
                  key={hit.objectID}
                  selected={selected}
                  hit={hit}
                  onClose={onClose}
                  onSelect={noop}
                />
              );
            })}
          </ResultsContainer>
        </Box>
      )}

      <Box
        mb={12}
        style={isXs && query && resultCount === 0 ? { display: "none" } : null}
      >
        <b>
          {resultCount === 0 ? "No Results" : query ? "Results" : "Suggestions"}
        </b>
      </Box>
      <ResultsContainer
        showRecentSearches={showRecentSearches}
        onClose={onClose}
        className={query ? "results" : "suggestions"}
        noResults={resultCount === 0}
      >
        {sortBy(filteredIndexes, (i) => availableIndexes.indexOf(i)).map(
          (index) => {
            const { IndexComponent } = index;
            return (
              <IndexComponent
                key={index.key}
                indexKey={index.key}
                hitsPerPage={HITS_PER_PAGE}
                searchPreference={searchPreference}
                selectedObjectId={selectedObjectId}
                onClose={onClose}
                onSelect={(hit) => {
                  if (query) {
                    saveSearch({
                      variables: {
                        query: query,
                        selectedResultType: index.key,
                        selectedResult: JSON.stringify(hit),
                        customerId: searchPreference?.customer?.id,
                      },
                    });
                  }
                }}
              />
            );
          },
        )}
      </ResultsContainer>
    </div>
  );
}

interface ResultsContainerProps {
  noResults: boolean;
  children: ReactNode;
  onClose: () => void;
  className: string;
  showRecentSearches: boolean;
}

function ResultsContainer({
  noResults,
  children,
  onClose,
  className,
  showRecentSearches,
}: ResultsContainerProps) {
  return (
    <Box
      style={{
        backgroundColor: Colors.WHITE,
        borderRadius: 6,
        border: `1px solid ${Colors.LIGHT_GRAY3}`,
        boxShadow: BOTTOM_SHADOW,
        overflow: "hidden",
        display: noResults ? "none" : undefined,
        overflowY: "scroll",
        maxHeight: showRecentSearches
          ? "calc(100vh - 380px)"
          : "calc(100vh - 140px)",
      }}
      className={className}
      onClick={onClose}
    >
      {Children.map(children, (child, index) => (
        <div key={index}>{child}</div>
      ))}
    </Box>
  );
}
