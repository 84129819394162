import { useEffect, useState } from "react";

import { useMutation } from "@apollo/client";
import {
  Button,
  Callout,
  FormGroup,
  Icon,
  InputGroup,
  Intent,
  Menu,
  MenuItem,
} from "@blueprintjs/core";
import EmojiPicker, { Emoji, EmojiStyle } from "emoji-picker-react";
import { useNavigate } from "react-router-dom";

import { useWorkspace } from "@/components/helpers/custom-hooks/use-workspace";
import { Popover } from "@/components/helpers/ui/blueprint-overrides/Popover";
import { Tooltip } from "@/components/helpers/ui/blueprint-overrides/Tooltip";
import { Box, Flex } from "@/components/layout/flexbox";
import StyledDialog from "@/components/pieces/StyledDialog";
import Avatar from "@/components/pieces/users/Avatar";
import { getUser } from "@/components/session/JwtTokenManager";
import { MIDTEXT_COLOR } from "@/css/constants";
import {
  CloneCollectionDocument,
  RoleplayType,
  VisibilityScope,
} from "@/graphql";

export const validateCollectionInput = ({ name }: { name: string }) => ({
  valid: !!name.trim(),
});

const workspaceIcon = "office";
const workspaceText = "Workspace";
const onlyMeIcon = "eye-open";
const onlyMeText = "Only Me";

const visibleBgStyle = {
  backgroundColor: "#0719560A",
  minWidth: "150px",
};

function CloneCollectionDialog({
  open,
  onClose,
  collection,
}: {
  open: boolean;
  onClose: () => void;
  collection?: RoleplayType;
}) {
  const user = getUser();
  const workspace = useWorkspace();
  const [visibilityScope, setVisibilityScope] = useState<VisibilityScope>(
    VisibilityScope.User,
  );
  const [openEmojiPicker, setOpenEmojiPicker] = useState(false);
  const [emoji, setEmoji] = useState(collection?.emojiUnicode || "1f389");

  const [buttonIcon, buttonText] =
    visibilityScope === VisibilityScope.Workspace
      ? [<Icon icon={workspaceIcon} />, workspaceText]
      : [<Icon icon={onlyMeIcon} />, onlyMeText];

  const isPersonalMode = !workspace;

  const [name, setName] = useState(`${collection?.name} (Copy)`);
  const [errorMessage, setErrorMessage] = useState("123123");
  const navigate = useNavigate();

  useEffect(() => {
    setName(`${collection?.name} (Copy)`);
    setEmoji(collection?.emojiUnicode || "1f389");
  }, [collection]);

  const [clone, { loading }] = useMutation(CloneCollectionDocument, {
    onCompleted: (data) => {
      if (data.cloneCollection.ok) {
        const redirectUrl = `/roleplays/${data.cloneCollection.collection.slug}`;
        navigate(redirectUrl);
        onClose();
      }
      if (data.cloneCollection.errors) {
        setErrorMessage("Couldn't clone collection");
      }
    },
    onError: () => {
      setErrorMessage("Couldn't clone collection");
    },
  });
  const onCloneCollection = async () => {
    setErrorMessage(null);
    clone({
      variables: {
        collectionId: collection.id,
        name,
        visibilityScope,
        emojiUnicode: emoji?.toUpperCase(),
      },
    });
  };

  const validation = validateCollectionInput({ name });

  return (
    <StyledDialog
      isOpen={open}
      lazy={true}
      title={<Box fontSize={18}>Clone Collection</Box>}
      width="480px"
      onClose={() => {
        onClose();
      }}
      onOpening={() => {
        setErrorMessage(null);
      }}
    >
      <Box py={3} px={"24px"} css={{ borderRadius: "0 0 12px 12px" }}>
        {errorMessage && (
          <Box mb={2}>
            <Callout icon={null} intent={Intent.DANGER}>
              {errorMessage}
            </Callout>
          </Box>
        )}
        <Box width={1}>
          <Box fontWeight={600} fontSize={16}>
            Owner
          </Box>
          <Box mt={1}>
            <Box css={{ display: "flex", alignItems: "center", gap: 12 }}>
              <Tooltip content={user?.name || user?.email}>
                <Avatar user={user} size={36} />
              </Tooltip>
              <Flex style={{ gap: 4 }} flexDirection={"column"}>
                {user?.name && (
                  <Box css={titleCss} lineHeight={"14px"}>
                    {user?.name}
                  </Box>
                )}
                <Box fontSize={12} color={MIDTEXT_COLOR} lineHeight={"12px"}>
                  {user?.email}
                </Box>
              </Flex>
            </Box>
          </Box>
          {!isPersonalMode && (
            <Box mt={3}>
              <Box fontWeight={600} fontSize={16}>
                Workspace
              </Box>
              <Box css={titleCss} lineHeight={"14px"} mt={1}>
                {workspace?.name}
              </Box>
            </Box>
          )}
          <Box mt={3}>
            <Box fontWeight={600} fontSize={16}>
              Collection Name
            </Box>
            <Flex style={{ gap: 8 }} mt={1}>
              <Popover
                onClose={() => setOpenEmojiPicker(false)}
                isOpen={openEmojiPicker}
                content={
                  <EmojiPicker
                    previewConfig={{
                      showPreview: false,
                    }}
                    open={openEmojiPicker}
                    onEmojiClick={(emoji) => {
                      setEmoji(emoji.unified);
                      setOpenEmojiPicker(false);
                    }}
                    skinTonesDisabled
                    lazyLoadEmojis
                    categories={[]}
                  />
                }
              >
                <Button
                  style={{
                    height: 40,
                    width: 40,
                    boxShadow: "none",
                    borderRadius: 4,
                    border: "1px solid #d3d3d3",
                  }}
                  onClick={() => setOpenEmojiPicker(true)}
                  outlined
                  icon={
                    <Emoji
                      unified={emoji.toLocaleLowerCase()}
                      emojiStyle={EmojiStyle.APPLE}
                      size={16}
                    />
                  }
                />
              </Popover>

              <InputGroup
                fill
                style={{
                  borderRadius: 4,
                  height: 40,
                }}
                onChange={(e) => {
                  setName(e.target.value);
                }}
                value={name}
                placeholder="My Super Cool New Category"
              />
            </Flex>
          </Box>
          {!isPersonalMode && (
            <Box mt={3}>
              <Box fontWeight={600} fontSize={16}>
                Visibility Scope
              </Box>
              <Box mt={1}>
                <Popover
                  content={
                    <Menu>
                      {visibilityScope === VisibilityScope.User ? (
                        <MenuItem
                          onClick={() =>
                            setVisibilityScope(VisibilityScope.Workspace)
                          }
                          text={workspaceText}
                          icon={workspaceIcon}
                        />
                      ) : (
                        <MenuItem
                          onClick={() =>
                            setVisibilityScope(VisibilityScope.User)
                          }
                          text={onlyMeText}
                          icon={onlyMeIcon}
                        />
                      )}
                    </Menu>
                  }
                  placement="bottom-end"
                >
                  <Button
                    icon={buttonIcon}
                    style={visibleBgStyle}
                    rightIcon="caret-down"
                  >
                    {buttonText}
                  </Button>
                </Popover>
              </Box>
            </Box>
          )}

          <FormGroup style={{ margin: 0, marginTop: 12 }}>
            <Flex mt={3} style={{ gap: 8 }} justifyContent={"flex-end"}>
              <Button onClick={onClose}>Cancel</Button>
              <Button
                loading={loading}
                style={{ minWidth: 160 }}
                intent="primary"
                disabled={!validation.valid}
                onClick={onCloneCollection}
              >
                Clone
              </Button>
            </Flex>
          </FormGroup>
        </Box>
      </Box>
    </StyledDialog>
  );
}

const titleCss: any = {
  fontSize: "14px",
  fontWeight: 500,
  color: "black",
};

export default CloneCollectionDialog;
