import { useState } from "react";

import { useMutation } from "@apollo/client";
import { Button, Callout, Intent } from "@blueprintjs/core";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { Box } from "@/components/layout/flexbox";
import { buildScenarioUrl } from "@/components/pages_logged_in/roleplays/utils";
import StyledDialog from "@/components/pieces/StyledDialog";
import { ArchiveRoleplaySessionDocument, ScenarioDocument } from "@/graphql";

export interface RoleplaySessionDeleteConfirmProps {
  open: boolean;
  onClose: () => void;
  roleplaySession: any;
  refetchQueries?: any;
}

function RoleplaySessionDeleteConfirm({
  open,
  onClose,
  roleplaySession,
  refetchQueries = null,
}: RoleplaySessionDeleteConfirmProps) {
  const navigate = useNavigate();
  const location = useLocation();

  const roleplaySlug = useParams<{
    roleplaySlug?: string;
  }>()?.roleplaySlug;

  const [errorMessage, setErrorMessage] = useState(null);
  const [archive, { loading }] = useMutation(ArchiveRoleplaySessionDocument, {
    refetchQueries,
    update: (cache) => {
      const existingScenarioDocument: {
        scenario: { roleplaySessions: { id: string }[] };
      } | null = cache.readQuery({
        query: ScenarioDocument,
        variables: { scenarioSlug: roleplaySession.scenario.slug },
      });

      if (existingScenarioDocument) {
        cache.writeQuery({
          query: ScenarioDocument,
          variables: { scenarioSlug: roleplaySession.scenario.slug },
          data: {
            scenario: {
              roleplaySessions:
                existingScenarioDocument.scenario.roleplaySessions.filter(
                  (session) => session.id !== roleplaySession.id,
                ),
            },
          },
        });
      }
    },
    onCompleted: (data) => {
      if (
        data.archiveRoleplaySession.ok &&
        location.pathname.includes(roleplaySession.uuid)
      ) {
        navigate(
          buildScenarioUrl(roleplaySlug, roleplaySession?.scenario?.slug),
        );
      }
      if (data.archiveRoleplaySession.errors) {
        setErrorMessage(data.archiveRoleplaySession.errors[0].message);
      }
    },
  });

  return (
    <StyledDialog
      isOpen={open}
      centered={true}
      lazy={true}
      title="Are you sure you want to delete this session?"
      width="480px"
      onClose={() => {
        onClose();
      }}
      onOpening={() => {
        setErrorMessage(null);
      }}
    >
      <Box p={24}>
        {errorMessage && (
          <Box mb={3}>
            <Callout icon={null} intent={Intent.DANGER}>
              {errorMessage}
            </Callout>
          </Box>
        )}
        <Box>
          <Button
            intent={Intent.DANGER}
            large={true}
            disabled={loading}
            onClick={() => {
              archive({ variables: { id: roleplaySession.id } });
            }}
            loading={loading}
          >
            Delete
          </Button>
          <Button
            large={true}
            disabled={loading}
            onClick={() => {
              onClose();
            }}
            style={{ marginLeft: 12 }}
          >
            Nevermind
          </Button>
        </Box>
      </Box>
    </StyledDialog>
  );
}

export default RoleplaySessionDeleteConfirm;
