import { Box } from "@/components/layout/flexbox";
import MemberAssistantBox from "@/components/pages_logged_in/workspace-admin/MemberAssistantBox";
import MenuHeader from "@/components/pieces/menu/MenuHeader";

export default function MenuAssistants({
  member = undefined,
  onClose,
  onBackLink,
}) {
  return (
    <Box width={1} m={1}>
      <MenuHeader
        onBackLink={() => {
          onBackLink();
        }}
        onClose={() => {
          onClose();
        }}
        label="Assistants"
      />
      <Box m={3}>
        <MemberAssistantBox member={member} isMenu />
      </Box>
    </Box>
  );
}
