import get from "lodash/get";

import { Box } from "@/components/layout/flexbox";
import {
  AgentProfileWithSlug,
  categoryTitle,
  SpecialtyRenderDataType,
} from "@/components/pieces/coaching/coach-profile/coachingUtils";
import { SUBTEXT_COLOR } from "@/css/constants";

import Product from "./Product";

type CategoryProps = Readonly<{
  isCoachInUserCoaches: boolean;
  asCoach: boolean;
  showLinks: boolean;
  specialty: SpecialtyRenderDataType;
  agentProfile: AgentProfileWithSlug;
  category: any;
}>;

const productSort = (product1, product2) => {
  if (product1.introProduct != product2.introProduct) {
    return product2.introProduct - product1.introProduct;
  }
  return product1.name.localeCompare(product2.name);
};

export default function Category({
  category,
  agentProfile,
  asCoach,
  showLinks,
  isCoachInUserCoaches,
}: CategoryProps) {
  return (
    <Box mt={3}>
      <Box fontSize={14} color={SUBTEXT_COLOR} fontWeight={600}>
        {get(categoryTitle, `${category.category}`, "")}
      </Box>

      {category.products.sort(productSort).map((product) => (
        <Product
          product={product}
          key={product.id}
          asCoach={asCoach}
          showLinks={showLinks}
          isCoachInUserCoaches={isCoachInUserCoaches}
          agentProfile={agentProfile}
        />
      ))}
    </Box>
  );
}
