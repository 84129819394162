import { useRef, useState } from "react";

import {
  Button,
  Icon,
  Intent,
  Menu,
  MenuItem,
  OverlayToaster,
  Position,
} from "@blueprintjs/core";
import styled from "@emotion/styled";

import { Popover } from "@/components/helpers/ui/blueprint-overrides/Popover";
import LazyImg from "@/components/helpers/ui/LazyImg";
import { isWorkspaceAdmin } from "@/components/helpers/workspace/permissions";
import { Box } from "@/components/layout/flexbox";
import { getUser } from "@/components/session/JwtTokenManager";
import { BP_NS } from "@/css/constants";
import {
  PublicWorkspaceGraphqlType,
  RoleplayType,
  ScenarioType,
  VisibilityScope,
} from "@/graphql";

import UpdateVisibilityScopeDialog from "./UpdateVisibilityScopeDialog";

interface ShareButtonProps {
  onShared?: (scenario: ScenarioType) => void;
  owner: RoleplayType["owner"] | ScenarioType["owner"];
  visibilityScope: VisibilityScope;
  id: string;
  slug: string;
  name: string;
  type: "roleplay" | "scenario";
  workspace: PublicWorkspaceGraphqlType;
}

const coreIcon = "globe";
const coreText = "Core";
const workspaceIcon = "office";
const workspaceText = "Workspace";
const onlyMeIcon = "eye-open";
const onlyMeText = "Only Me";

const visibleBgStyle = {
  backgroundColor: "#0719560A",
  minWidth: "150px",
};

function UserIcon({ user, size = 16 }) {
  return (
    user && (
      <LazyImg
        style={{
          borderRadius: "100%",
        }}
        width={size}
        height={size}
        src={user?.photo}
      />
    )
  );
}

const ReadonlyButton = styled(Button)`
  &:hover {
    cursor: auto;
  }
  background-color: ${visibleBgStyle.backgroundColor};
  &.${BP_NS}-button:not([class*='${BP_NS}-intent-']) {
    background-color: ${visibleBgStyle.backgroundColor};
  }
  &.${BP_NS}-button:not([class*='${BP_NS}-intent-']):hover {
    background-color: ${visibleBgStyle.backgroundColor};
  }
`;

function VisibilityLabel({ text, icon }) {
  return <ReadonlyButton icon={icon}>{text}</ReadonlyButton>;
}

export function UpdateVisibilityButton({
  onShared,
  owner,
  visibilityScope,
  id,
  slug,
  name,
  type,
  workspace,
}: ShareButtonProps) {
  const toasterRef = useRef(null);
  const [selectedScope, setSelectedScope] = useState<VisibilityScope | null>(
    null,
  );
  const onSelect = (scope) => {
    setSelectedScope(scope);
  };

  const isMe = owner?.id === getUser()?.id;
  const myText = isMe ? (
    onlyMeText
  ) : (
    <>
      <Box>Only Owner</Box>
      <Box fontSize={12}>{owner?.name || owner?.email}</Box>
    </>
  );
  const [buttonIcon, buttonText] =
    visibilityScope === VisibilityScope.Workspace
      ? [<Icon icon={workspaceIcon} />, workspaceText]
      : [isMe ? <Icon icon={onlyMeIcon} /> : <UserIcon user={owner} />, myText];

  const menus = (
    <Menu>
      {visibilityScope === VisibilityScope.User ? (
        <MenuItem
          onClick={() => onSelect(VisibilityScope.Workspace)}
          text={workspaceText}
          icon={workspaceIcon}
        />
      ) : (
        <MenuItem
          onClick={() => onSelect(VisibilityScope.User)}
          text={myText}
          icon={isMe ? "eye-open" : <UserIcon user={owner} />}
        />
      )}
    </Menu>
  );
  return (
    <>
      <Popover content={menus} placement="bottom-end">
        <Button icon={buttonIcon} style={visibleBgStyle} rightIcon="caret-down">
          {buttonText}
        </Button>
      </Popover>
      <UpdateVisibilityScopeDialog
        visibilityScope={visibilityScope}
        slug={slug}
        id={id}
        scopeToUpdate={selectedScope}
        open={!!selectedScope}
        onClose={() => {
          setSelectedScope(null);
        }}
        afterUpdatedScope={(updatedScenario) => {
          toasterRef.current.show({
            message: "Visibility Scope Updated",
            intent: Intent.SUCCESS,
          });
          if (onShared) {
            onShared(updatedScenario);
          }
          setSelectedScope(null);
        }}
        name={name}
        type={type}
        workspace={workspace}
      />
      <OverlayToaster position={Position.TOP} ref={toasterRef} />
    </>
  );
}

function VisibilityButton({
  owner,
  visibilityScope,
  onShared = () => {},
  id,
  slug,
  name,
  type,
  workspace,
}: ShareButtonProps) {
  if (visibilityScope === VisibilityScope.Global) {
    return <VisibilityLabel text={coreText} icon={coreIcon} />;
  }

  const isAdmin = isWorkspaceAdmin();
  if (!isAdmin) {
    if (visibilityScope === VisibilityScope.Workspace) {
      return <VisibilityLabel text={workspaceText} icon={workspaceIcon} />;
    }

    if (visibilityScope === VisibilityScope.User) {
      return <VisibilityLabel text={onlyMeText} icon={onlyMeIcon} />;
    }
    return null;
  }

  return (
    <UpdateVisibilityButton
      id={id}
      slug={slug}
      name={name}
      type={type}
      owner={owner}
      visibilityScope={visibilityScope}
      onShared={onShared}
      workspace={workspace}
    />
  );
}

export default VisibilityButton;
