import { detect } from "detect-browser";

import LazyImg from "@/components/helpers/ui/LazyImg";
import { Box, Flex } from "@/components/layout/flexbox";

const browser = detect();

interface EmbedSvgProps {
  imgKey?: string;
  src?: string;
  width?: number | string;
  height?: number | string;
  style?: any;
  alt?: string;
  lazy?: boolean;
}

export default function EmbedSvg({
  imgKey,
  src,
  width,
  height,
  style,
  alt,
  lazy = true,
}: EmbedSvgProps) {
  const commonProps = {
    src,
    width,
    height,
    style,
    alt,
    type: "image/svg+xml",
  };

  const lazyProps = {
    ...commonProps,
    imgKey,
  };

  // We need to do this to get around touch issue on Safari with embed tag
  if (browser && browser.name === "safari") {
    return (
      <Flex
        style={{
          position: "relative",
        }}
        justifyContent="center"
        width={width}
        height={height}
      >
        <embed {...commonProps} />
        <Box
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
          }}
        />
      </Flex>
    );
  }

  if (lazy) return <LazyImg {...lazyProps} />;

  return <img {...commonProps} />;
}
