import groupMeetingIcon from "@/../../hypetrain/static/img/program/camera.svg";
import googleMeetIcon from "@/../../hypetrain/static/img/program/google-meet.svg";
import microsoftTeamsIcon from "@/../../hypetrain/static/img/program/microsoft-team.svg";
import zoomIcon from "@/../../hypetrain/static/img/program/zoom.svg";
import { Colors } from "@blueprintjs/core";
import styled from "@emotion/styled";
import moment from "moment";

import { Box } from "@/components/layout/flexbox";
import {
  ComponentStatus,
  getComponentStatus,
} from "@/components/pages_logged_in/program/components/component-utils";
import { ComponentTypeEnum, ProgramComponentType } from "@/graphql";

export const TabContainerCSS = { maxWidth: 520, margin: "0 auto" };
export const ErrorBoxCSS: Record<string, any> = {
  position: "sticky",
  top: 0,
  background: Colors.WHITE,
  zIndex: 9,
};

export const StyledStickyBox = styled(Box)`
  position: sticky;
  bottom: -36px;
  padding-top: 48px;
  padding-bottom: 36px;
  z-index: 99;
  background: linear-gradient(
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0.2),
    rgba(255, 255, 255, 0.7),
    rgba(255, 255, 255, 0.95),
    rgba(255, 255, 255, 0.99),
    rgba(255, 255, 255, 0.99),
    rgba(255, 255, 255, 0.99),
    rgba(255, 255, 255, 0.99),
    rgba(255, 255, 255, 0.99),
    rgb(255, 255, 255)
  );
`;

export const getMinDate = (dates: moment.Moment[], isRemoveFirst = false) => {
  const filteredDates = dates
    .filter((_: moment.Moment, index: number) =>
      isRemoveFirst ? index !== 0 : true,
    )
    .filter((date: moment.Moment) => date?.isValid());
  if (!filteredDates || filteredDates?.length === 0) return null;

  return filteredDates.reduce(
    (min, date) => (date < min ? date : min),
    filteredDates[0],
  );
};

export const getMaxDate = (dates) => {
  const filteredDates = dates.filter((date) => date?.isValid());
  if (!filteredDates || filteredDates?.length === 0) {
    return null;
  }
  return filteredDates.reduce(
    (max, date) => (date > max ? date : max),
    filteredDates[0],
  );
};

export const isEmptyProgramContext = (contextItems = []) => {
  return contextItems.filter((item) => item.answer).length === 0;
};

export const isCloseEarlyComponent = (component) => {
  if (!component) {
    return false;
  }
  const { closedAt, targetEndDate } = component || {};
  const isCloseEarly =
    closedAt && targetEndDate
      ? moment(closedAt).isBefore(moment(targetEndDate))
      : false;
  return isCloseEarly;
};

export const getCloseDate = (component) => {
  if (!component) {
    return null;
  }
  const isCloseEarly = isCloseEarlyComponent(component);
  const { closedAt, targetEndDate } = component || {};
  return isCloseEarly
    ? closedAt
      ? moment(closedAt)
      : null
    : moment(targetEndDate);
};

export const getDuration = (component: ProgramComponentType) => {
  if (!component) {
    return 0;
  }
  const { targetStartDate, targetEndDate } = component || {};

  return targetStartDate && targetEndDate
    ? moment
        .duration(moment(targetEndDate).diff(moment(targetStartDate)))
        .asMinutes()
    : 0;
};

export const allowEditComponent = (
  component,
  isProgramClosed,
  isProgramLead = true,
) => {
  if (!component) {
    return false;
  }
  const status = getComponentStatus(component);
  return (
    status !== ComponentStatus.Completed && isProgramLead && !isProgramClosed
  );
};

export const hasComponentSubContent = (
  component,
  participantGuidance,
  isProgramLead = true,
) => {
  if (!component) {
    return false;
  }
  const status = getComponentStatus(component);

  if (component.type === ComponentTypeEnum.Announcement) {
    return (
      !!participantGuidance ||
      (isProgramLead && status === ComponentStatus.Upcoming)
    );
  }
  return (
    status === ComponentStatus.Upcoming ||
    (status !== ComponentStatus.Completed && !!participantGuidance) ||
    (status === ComponentStatus.Ongoing && !isProgramLead)
  );
};

export const canViewComponentStatistic = (component, isProgramLead = true) => {
  if (!component) {
    return false;
  }
  const status = getComponentStatus(component);
  return (
    [
      ComponentTypeEnum.Survey,
      ComponentTypeEnum.ActionItem,
      ComponentTypeEnum.AiRoleplay,
      ComponentTypeEnum.Video,
      ComponentTypeEnum.Article,
    ].includes(component.type) &&
    [ComponentStatus.Ongoing, ComponentStatus.Completed].indexOf(status) > -1 &&
    isProgramLead
  );
};

export const ratingScaleOptionTemplates = [
  {
    name: "Agreement",
    options: [
      {
        value: "1",
        label: "Strongly Disagree",
      },
      {
        value: "2",
        label: "Disagree",
      },
      {
        value: "3",
        label: "Neither agree nor disagree",
      },
      {
        value: "4",
        label: "Agree",
      },
      {
        value: "5",
        label: "Strongly Agree",
      },
    ],
  },
  {
    name: "Likelihood",
    options: [
      {
        value: "1",
        label: "Very Unlikely",
      },
      {
        value: "2",
        label: "Unlikely",
      },
      {
        value: "3",
        label: "Neither likely nor unlikely",
      },
      {
        value: "4",
        label: "Likely",
      },
      {
        value: "5",
        label: "Very Likely",
      },
    ],
  },
  {
    name: "Confidence",
    options: [
      {
        value: "1",
        label: "Not at all confident",
      },
      {
        value: "2",
        label: "Slightly confident",
      },
      {
        value: "3",
        label: "Moderately confident",
      },
      {
        value: "4",
        label: "Very confident",
      },
      {
        value: "5",
        label: "Extremely confident",
      },
    ],
  },
  {
    name: "Frequency",
    options: [
      {
        value: "1",
        label: "Never",
      },
      {
        value: "2",
        label: "Rarely",
      },
      {
        value: "3",
        label: "Sometimes",
      },
      {
        value: "4",
        label: "Often",
      },
      {
        value: "5",
        label: "Always",
      },
    ],
  },
  {
    name: "Satisfaction",
    options: [
      {
        value: "1",
        label: "Very dissatisfied",
      },
      {
        value: "2",
        label: "Dissatisfied",
      },
      {
        value: "3",
        label: "Neither satisfied nor dissatisfied",
      },
      {
        value: "4",
        label: "Satisfied",
      },
      {
        value: "5",
        label: "Very Satisfied",
      },
    ],
  },
  {
    name: "Difficulty",
    options: [
      {
        value: "1",
        label: "Very easy",
      },
      {
        value: "2",
        label: "Easy",
      },
      {
        value: "3",
        label: "Neither easy nor difficult",
      },
      {
        value: "4",
        label: "Difficult",
      },
      {
        value: "5",
        label: "Very difficult",
      },
    ],
  },
  {
    name: "Importance",
    options: [
      {
        value: "1",
        label: "Not at all important",
      },
      {
        value: "2",
        label: "Slightly important",
      },
      {
        value: "3",
        label: "Moderately important",
      },
      {
        value: "4",
        label: "Very important",
      },
      {
        value: "5",
        label: "Extremely important",
      },
    ],
  },
];

export enum MeetingPlatforms {
  Zoom = "Zoom",
  GoogleMeet = "Google Meet",
  MicrosoftTeams = "Microsoft Teams",
  Others = "Others",
}

export function detectMeetingPlatform(url: string): MeetingPlatforms {
  try {
    const urlObj = new URL(url.toLowerCase());

    // Zoom patterns
    if (urlObj.hostname.includes("zoom.us")) {
      return MeetingPlatforms.Zoom;
    }

    // Google Meet patterns
    if (urlObj.hostname.includes("meet.google.com")) {
      return MeetingPlatforms.GoogleMeet;
    }

    // Microsoft Teams patterns
    if (
      urlObj.hostname.includes("teams.microsoft.com") ||
      urlObj.hostname.includes("teams.live.com")
    ) {
      return MeetingPlatforms.MicrosoftTeams;
    }

    return MeetingPlatforms.Others;
  } catch (e) {
    console.error(e);
    return MeetingPlatforms.Others;
  }
}

const PLATFORM_CONFIG = {
  [MeetingPlatforms.Zoom]: {
    createLink: "https://app.zoom.us/wc/home",
    icon: zoomIcon,
  },
  [MeetingPlatforms.GoogleMeet]: {
    createLink: "https://meet.google.com/landing",
    icon: googleMeetIcon,
  },
  [MeetingPlatforms.MicrosoftTeams]: {
    createLink: "https://teams.live.com/",
    icon: microsoftTeamsIcon,
  },
  [MeetingPlatforms.Others]: {
    createLink: "",
    icon: groupMeetingIcon,
  },
} as const;

const getPlatformConfig = (platform: MeetingPlatforms) =>
  PLATFORM_CONFIG[platform] || PLATFORM_CONFIG[MeetingPlatforms.Others];

export const getMeetingPlatformCreateHelpLink = (platform: MeetingPlatforms) =>
  getPlatformConfig(platform).createLink;

export const getMeetingPlatformIcon = (platform: MeetingPlatforms) =>
  getPlatformConfig(platform).icon;
