import { Classes, FormGroup, InputGroup } from "@blueprintjs/core";
import { CardElement } from "@stripe/react-stripe-js";

import { Box } from "@/components/layout/flexbox";
import { LIGHTEST_BACKGROUND, MIDTEXT_COLOR } from "@/css/constants";

const CARD_OPTIONS = {
  style: {
    base: {
      fontSize: "18px",
      iconColor: MIDTEXT_COLOR,
      color: MIDTEXT_COLOR,
      fontWeight: 400,
      fontFamily:
        "Inter, -apple-system, Roboto, Open Sans, Segoe UI, sans-serif",
      "::placeholder": {
        color: "rgba(92,112,128,.5)",
      },
    },
    invalid: {
      iconColor: "#D77B48",
      color: "#D77B48",
    },
  },
};

export default function NewPaymentMethodForm({
  billingName,
  setBillingName,
  handleSubmit,
}) {
  return (
    <Box
      className="round-corners"
      backgroundColor={LIGHTEST_BACKGROUND}
      p={[3, 24]}
      maxWidth={500}
    >
      <form action="#" onSubmit={handleSubmit}>
        <Box>
          <FormGroup label="Name on Card" labelFor="cc-name">
            <InputGroup
              autoFocus={true}
              value={billingName}
              name="cc-name"
              placeholder="George Washington"
              onChange={(e) => setBillingName(e.target.value)}
              large={true}
              fill={true}
              required={true}
            />
          </FormGroup>
        </Box>
        <Box>
          <FormGroup label="Card Detail">
            <div
              className={Classes.INPUT}
              style={{ height: 40, padding: "9px 10px" }}
            >
              <CardElement options={CARD_OPTIONS} />
            </div>
          </FormGroup>
        </Box>
        <input type="submit" style={{ display: "none" }} />
      </form>
    </Box>
  );
}
