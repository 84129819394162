import { CSSProperties, ReactNode, useEffect, useId, useRef } from "react";

import {
  useSSRMediaQuery,
  breakpoints,
  isLgQuery,
  isXsQuery,
  isSmQuery,
  isMobileQuery,
  isMdQuery,
  isXsToMdQuery,
  isXsToLgQuery,
  isXsToXlQuery,
  isSmToMlQuery,
  isMlQuery,
  isXlQuery,
} from "@/components/helpers/custom-hooks/use-ssr-media-query";

export type SizeBreakpoint = keyof typeof breakpoints;
export type BreakpointValue = (typeof breakpoints)[SizeBreakpoint];

// Utility functions
const createClassName = (type: string, value: any): string => {
  if (Array.isArray(value)) {
    return `fresnel-${type}-${value[0]}-${value[1]}`;
  }
  return `fresnel-${type}-${value}`;
};

const VALID_KEYS = [
  "at",
  "lessThan",
  "greaterThan",
  "greaterThanOrEqual",
  "between",
];

// Media component props from @artsy/fresnel
export interface MediaBreakpointProps {
  /**
   * Children will only be shown if the viewport matches the specified
   * breakpoint. That is, a viewport width that’s higher than the configured
   * breakpoint value, but lower than the value of the next breakpoint, if any
   * larger breakpoints exist at all.
   *
   * @example
   * ```tsx
   * // With breakpoints defined like these
   * { xs: 0, sm: 768, md: 1024 }
   *
   * // Matches a viewport that has a width between 0 and 768
   * <Media at="xs">ohai</Media>
   *
   * // Matches a viewport that has a width between 768 and 1024
   * <Media at="sm">ohai</Media>
   *
   * // Matches a viewport that has a width over 1024
   * <Media at="md">ohai</Media>
   * ```
   */
  at?: SizeBreakpoint;
  /**
   * Children will only be shown if the viewport is smaller than the specified
   * breakpoint.
   *
   * @example
   * ```tsx
   * // With breakpoints defined like these
   * { xs: 0, sm: 768, md: 1024 }
   *
   * // Matches a viewport that has a width from 0 to 767
   * <Media lessThan="sm">ohai</Media>
   *
   * // Matches a viewport that has a width from 0 to 1023
   * <Media lessThan="md">ohai</Media>
   * ```
   */
  lessThan?: SizeBreakpoint;
  /**
   * Children will only be shown if the viewport is greater than the specified
   * breakpoint.
   *
   * @example
   * ```tsx
   * // With breakpoints defined like these
   * { xs: 0, sm: 768, md: 1024 }
   *
   * // Matches a viewport that has a width from 768 to infinity
   * <Media greaterThan="xs">ohai</Media>
   *
   * // Matches a viewport that has a width from 1024 to infinity
   * <Media greaterThan="sm">ohai</Media>
   * ```
   */
  greaterThan?: SizeBreakpoint;
  /**
   * Children will only be shown if the viewport is greater or equal to the
   * specified breakpoint.
   *
   * @example
   * ```tsx
   * // With breakpoints defined like these
   * { xs: 0, sm: 768, md: 1024 }
   *
   * // Matches a viewport that has a width from 0 to infinity
   * <Media greaterThanOrEqual="xs">ohai</Media>
   *
   * // Matches a viewport that has a width from 768 to infinity
   * <Media greaterThanOrEqual="sm">ohai</Media>
   *
   * // Matches a viewport that has a width from 1024 to infinity
   * <Media greaterThanOrEqual="md">ohai</Media>
   * ```
   */
  greaterThanOrEqual?: SizeBreakpoint;
  /**
   * Children will only be shown if the viewport is between the specified
   * breakpoints. That is, a viewport width that’s higher than or equal to the
   * small breakpoint value, but lower than the value of the large breakpoint.
   *
   * @example
   * ```tsx
   * // With breakpoints defined like these
   * { xs: 0, sm: 768, md: 1024 }
   *
   * // Matches a viewport that has a width from 0 to 767
   * <Media between={["xs", "sm"]}>ohai</Media>
   *
   * // Matches a viewport that has a width from 0 to 1023
   * <Media between={["xs", "md"]}>ohai</Media>
   * ```
   */
  between?: [SizeBreakpoint, SizeBreakpoint];
}

export interface MediaProps extends MediaBreakpointProps {
  children:
    | ReactNode
    | ((className: string, renderChildren: boolean) => ReactNode);
  className?: string;
  style?: CSSProperties;
}

// Validates that only one breakpoint prop is used
const validateProps = (props: MediaBreakpointProps): void => {
  const selectedProps = Object.keys(props).filter((prop) =>
    VALID_KEYS.includes(prop),
  );

  if (selectedProps.length < 1) {
    throw new Error(`1 of ${VALID_KEYS.join(", ")} is required.`);
  } else if (selectedProps.length > 1) {
    throw new Error(
      `Only 1 of ${selectedProps.join(", ")} is allowed at a time.`,
    );
  }
};

// Helper to get the prop key used
const getPropKey = (props: MediaBreakpointProps): string | undefined => {
  return Object.keys(props).find(
    (key) =>
      VALID_KEYS.includes(key) &&
      props[key as keyof MediaBreakpointProps] !== undefined,
  );
};

// Check if a breakpoint prop matches current screen width
const doesBreakpointMatch = (
  props: MediaBreakpointProps,
  screenWidth: number,
): boolean => {
  const key = getPropKey(props);
  if (!key) return false;

  switch (key) {
    case "at": {
      const breakpoint = props.at!;
      const currentBreakpointValue = breakpoints[breakpoint];
      const nextBreakpoint = getNextBreakpoint(breakpoint);
      return (
        screenWidth >= currentBreakpointValue &&
        (!nextBreakpoint || screenWidth < breakpoints[nextBreakpoint])
      );
    }
    case "lessThan": {
      const breakpoint = props.lessThan!;
      return screenWidth < breakpoints[breakpoint];
    }
    case "greaterThan": {
      const breakpoint = props.greaterThan!;
      const nextBreakpoint = getNextBreakpoint(breakpoint);
      return screenWidth >= breakpoints[nextBreakpoint];
    }
    case "greaterThanOrEqual": {
      const breakpoint = props.greaterThanOrEqual!;
      return screenWidth >= breakpoints[breakpoint];
    }
    case "between": {
      const [smallBreakpoint, largeBreakpoint] = props.between!;
      return (
        screenWidth >= breakpoints[smallBreakpoint] &&
        screenWidth < breakpoints[largeBreakpoint]
      );
    }
    default:
      return false;
  }
};

// Get next breakpoint in the sequence
const getNextBreakpoint = (
  current: SizeBreakpoint,
): SizeBreakpoint | undefined => {
  const sortedBreakpoints = Object.keys(breakpoints) as SizeBreakpoint[];
  const currentIndex = sortedBreakpoints.indexOf(current);
  return currentIndex < sortedBreakpoints.length - 1
    ? sortedBreakpoints[currentIndex + 1]
    : undefined;
};

export function Media(props: MediaProps) {
  // Generated by Copilot
  validateProps(props);

  const {
    children,
    className: passedClassName,
    style,
    ...breakpointProps
  } = props;
  const { screenWidth } = useSSRMediaQuery();

  // Create className based on breakpoint prop
  const key = getPropKey(breakpointProps);
  const value = key ? props[key as keyof MediaBreakpointProps] : null;
  const className = value ? createClassName(key!, value) : "";

  // Determine if content should render based on screen width
  const renderChildren = doesBreakpointMatch(breakpointProps, screenWidth);

  // Create unique ID for the component
  const id = useId();
  const uniqueComponentId = ` fresnel-${id}`;
  const finalClassName = className + uniqueComponentId;

  // Handle client-side cleanup for SSR hydration if needed
  const isClient = typeof window !== "undefined";
  const isFirstRender = useRef(true);

  useEffect(() => {
    if (isClient && isFirstRender.current && !renderChildren) {
      const containerEls = document.getElementsByClassName(
        uniqueComponentId.trim(),
      );
      Array.from(containerEls).forEach((el) => (el.innerHTML = ""));
    }
    isFirstRender.current = false;
  }, [uniqueComponentId, renderChildren]);

  if (children instanceof Function) {
    return children(finalClassName, renderChildren);
  }

  return (
    <div
      className={["fresnel-container", finalClassName, passedClassName]
        .filter(Boolean)
        .join(" ")}
      style={{ ...style, display: renderChildren ? "block" : "none" }}
      suppressHydrationWarning
    >
      {renderChildren ? children : null}
    </div>
  );
}

export {
  isLgQuery,
  isXsQuery,
  isSmQuery,
  breakpoints,
  isMobileQuery,
  isMdQuery,
  isXsToMdQuery,
  isXsToLgQuery,
  isXsToXlQuery,
  isSmToMlQuery,
  isMlQuery,
  isXlQuery,
};
