import { useCallback, useRef, useState } from "react";

import { useLazyQuery, useMutation } from "@apollo/client";
import { Button, Card, Icon, InputGroup } from "@blueprintjs/core";
import idx from "idx";
import throttle from "lodash/throttle";
import { useHotkeys } from "react-hotkeys-hook";
import { useSearchParams } from "react-router-dom";

import * as LocalStorageManager from "@/components/helpers/storage/local-storage-utils";
import { Box, Flex } from "@/components/layout/flexbox";
import SlugInput from "@/components/pieces/form/SlugInput";
import { RED, SUBTEXT_COLOR } from "@/css/constants";
import { CreateWorkspaceDocument, ValidSlugDocument } from "@/graphql";

const SLUG_INPUT_WAIT_TIME = 500;

export default function CreateWorkspace({
  redirectToSignup = false,
  onCreateWorkspaceComplete = null,
  onSlugChange = null,
  errorText = null,
  loading = false,
}) {
  const [name, setName] = useState("");
  const [slug, setSlug] = useState("");
  const [validationResult, setValidationResult] = useState(null);

  const [validateSlug] = useLazyQuery(ValidSlugDocument, {
    onCompleted: ({ validSlug }) => {
      setValidationResult(validSlug);
    },
  });

  const checkValidSlugRef = useRef(
    throttle((slug) => {
      if (slug) {
        validateSlug({
          variables: {
            slug,
          },
        });
      }
    }, SLUG_INPUT_WAIT_TIME),
  );

  const [searchParams] = useSearchParams();
  const nextPath = searchParams.get("nextPath");

  const getRefCode = useCallback(() => {
    if (typeof window !== "undefined") {
      return LocalStorageManager.getString("refcode");
    }
    return "";
  }, []);

  const [createWorkspace, { loading: creating }] = useMutation(
    CreateWorkspaceDocument,
    {
      onCompleted: function (data) {
        const { error } = data.createWorkspace;
        if (error) {
          setValidationResult({
            valid: false,
            errorMessage: error.message,
          });
          return;
        }
        const urlSlug = idx(
          data,
          ({ createWorkspace }) => createWorkspace.workspace.urlSlug,
        );
        const workspaceSignedToken = idx(
          data,
          ({ createWorkspace }) => createWorkspace.workspaceSignedToken,
        );
        if (urlSlug && workspaceSignedToken && redirectToSignup) {
          if (typeof window !== "undefined") {
            LocalStorageManager.removeItem("refcode");
            window.location.href = `/to-workspace/${urlSlug}?path=/signup?asWorkspaceAdmin=true&v=new-org&workspaceSignedToken=${workspaceSignedToken}&nextPath=${nextPath}`;
          }
        }
        if (onCreateWorkspaceComplete) {
          onCreateWorkspaceComplete(workspaceSignedToken);
        }
      },
    },
  );

  const handleCreateWorkspace = useCallback(() => {
    createWorkspace({
      variables: {
        name: name,
        slug: slug,
        refCode: getRefCode(),
      },
    });
  }, [name, slug, getRefCode, createWorkspace]);

  useHotkeys("enter", handleCreateWorkspace, {}, [handleCreateWorkspace]);

  return (
    <Card
      style={{ padding: "32px", marginLeft: 0, marginRight: 0 }}
      elevation={2}
    >
      <Box mb={20}>
        <Box fontSize={20} fontWeight={600} mb={2}>
          Organization Name
        </Box>
        <InputGroup
          placeholder="Vandelay Industries"
          large={true}
          value={name}
          autoFocus={true}
          onChange={(e) => {
            setName(e.target.value);
          }}
          style={{ height: 52 }}
          name="organizationName"
        />
      </Box>
      <Box mb={1}>
        <Box fontSize={20} fontWeight={600}>
          Workspace URL
        </Box>
        <Box fontSize={16} color={SUBTEXT_COLOR} mt={1} mb={3}>
          Set your branded workspace
        </Box>
        <Flex alignItems="center" css={{ gap: 10 }}>
          <SlugInput
            InputGroupProps={{
              placeholder: "vandelay",
              style: {
                height: 52,
              },
              large: true,
              name: "slug",
              maxLength: 50,
            }}
            value={slug}
            onChange={(value) => {
              setSlug(value);
              checkValidSlugRef.current(value);
              if (onSlugChange) {
                onSlugChange(value);
              }
            }}
          />
          <Box css={{ fontWeight: 600, fontSize: 18 }}>.exec.com</Box>
        </Flex>
        <Flex my={12} height={25} alignItems="center">
          {slug && validationResult && (
            <>
              {validationResult.valid && (
                <>
                  <Icon
                    icon="tick"
                    size={20}
                    color="#27AE60"
                    style={{ marginRight: 10 }}
                  />
                  <Box color={SUBTEXT_COLOR}>URL available</Box>
                </>
              )}
              {validationResult.errorMessage && (
                <Box color={RED}>{validationResult.errorMessage}</Box>
              )}
            </>
          )}
        </Flex>
        {errorText && (
          <Box my={12} color={RED}>
            {errorText}
          </Box>
        )}
      </Box>
      <Button
        large
        type="submit"
        intent="primary"
        style={{ height: 60 }}
        disabled={
          loading ||
          creating ||
          !(name && slug && validationResult && validationResult.valid)
        }
        onClick={handleCreateWorkspace}
      >
        Continue
      </Button>
    </Card>
  );
}
