import { useState } from "react";

import { Callout } from "@blueprintjs/core";

import { pluralize } from "@/components/helpers/string-utils";
import { Box } from "@/components/layout/flexbox";
import MenuItem from "@/components/pieces/menu/MenuItem";

import RedeemGiftCode from "./RedeemGiftCode";

export default function GiftCode() {
  const [showingForm, setShowingForm] = useState(false);
  const [creditsRedeemed, setCreditsRedeemed] = useState(null);
  function onCompleted(r) {
    if (r && r.redeemGiftCode.ok) {
      setShowingForm(false);
    }
    if (r && r.redeemGiftCode && r.redeemGiftCode.creditsRedeemed) {
      setCreditsRedeemed(r.redeemGiftCode.creditsRedeemed);
    }
  }

  return (
    <Box my={4}>
      <Box px={3} pb={1} className="small-caps" fontWeight={500}>
        Gift Codes
      </Box>
      <Box>
        {showingForm ? (
          <Box p={3}>
            <RedeemGiftCode onCompleted={onCompleted} />
          </Box>
        ) : (
          <MenuItem
            icon="barcode"
            label="Redeem Gift Code"
            onClick={() => setShowingForm(true)}
          />
        )}
        {creditsRedeemed && (
          <Box mt={3} p={2}>
            <Callout intent="success" title="🎉 Success!">
              You redeemed{" "}
              {pluralize(creditsRedeemed, "credit", "credits", true)} which{" "}
              {pluralize(creditsRedeemed, "is", "are", false)} now available on
              your account.
            </Callout>
          </Box>
        )}
      </Box>
    </Box>
  );
}
