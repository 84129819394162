import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";

import { AttachmentComponent } from "@/components/pieces/editor/tiptap/AttachmentNode";

interface RichContentRendererProps {
  content: string;
  className?: string;
}

export function RichContentRenderer({
  content,
  className,
}: RichContentRendererProps) {
  if (!content) return null;

  return (
    <ReactMarkdown
      className={className}
      rehypePlugins={[rehypeRaw]}
      components={{
        div: ({ node, ...props }) => {
          if ("data-exec-id" in props) {
            return (
              <AttachmentComponent
                urlOrError={String(node.properties.dataUrlOrError)}
                fileType={String(node.properties.dataFileType)}
                fileSize={Number(node.properties.dataFileSize)}
                error={String(node.properties.dataExecId).startsWith("error")}
              >
                {props.children}
              </AttachmentComponent>
            );
          }
          return <div {...props} />;
        },
      }}
    >
      {content}
    </ReactMarkdown>
  );
}
