import { useEffect, useRef, useState } from "react";

import { useMutation, useQuery } from "@apollo/client";
import { TextArea } from "@blueprintjs/core";

import { Box, Flex } from "@/components/layout/flexbox";
import IconCircle from "@/components/pieces/IconCircle";
import { getUser } from "@/components/session/JwtTokenManager";
import { CTA_COLOR } from "@/css/constants";
import { SaveScenarioNotesDocument, ScenarioNotesDocument } from "@/graphql";

export function decodeHtmlEntities(str) {
  const parser = new DOMParser();
  const decodedString = parser.parseFromString(str, "text/html").body
    .textContent;
  return decodedString || "";
}

export default function Notes({ scenario }) {
  const noteToSaveRef = useRef(null);
  const timeoutRef = useRef(null);

  const [content, setContent] = useState(null);
  const user = getUser();
  useQuery(ScenarioNotesDocument, {
    variables: { scenarioId: scenario.id },
    onCompleted: (response) => {
      if (!content && response && response.scenarioNotes) {
        const formatedContent = decodeHtmlEntities(
          response.scenarioNotes.content,
        );
        setContent(formatedContent);
      }
    },
  });

  const [saveNotes] = useMutation(SaveScenarioNotesDocument, {
    refetchQueries: [
      {
        query: ScenarioNotesDocument,
        variables: { scenarioId: scenario.id },
      },
    ],
  });

  useEffect(() => {
    if (content === null) {
      return;
    }

    noteToSaveRef.current = content;
    clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(() => {
      if (user) {
        saveNotes({
          variables: {
            scenarioId: scenario.id,
            content: noteToSaveRef.current,
          },
        });
        noteToSaveRef.current = null;
      }
    }, 2000);
  }, [content]);

  return (
    <>
      <Flex fontWeight={600} fontSize={18} alignItems="center" mb={2}>
        <IconCircle
          icon="edit"
          size={30}
          backgroundColor="#E5F2FF"
          color={CTA_COLOR}
        />
        <Box ml={2}>Notepad</Box>
      </Flex>
      <TextArea
        key="notes"
        rows={4}
        fill={true}
        placeholder="Write notes..."
        value={content || ""}
        onChange={(e) => setContent(e.target.value)}
        style={{
          boxShadow: "none",
          resize: "vertical",
          border: "1px solid #E1E8ED",
        }}
      />
    </>
  );
}
