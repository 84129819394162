import Tabs from "@/components/pieces/Tabs";

export default function DesktopViewTabs({ tabs, currentTab, onTabChange }) {
  return (
    <Tabs
      tabs={tabs}
      tab={currentTab}
      onTabChange={onTabChange}
      style={{ boxShadow: null, borderTop: null }}
    />
  );
}
