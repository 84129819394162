export default function LazyImg({
  src,
  imgKey = null,
  alt = "",
  width = null,
  height = null,
  style = null,
  className = "",
}) {
  return (
    <img
      key={imgKey || src}
      src={src}
      loading="lazy"
      className={className}
      style={style}
      alt={alt}
      width={width}
      height={height}
    />
  );
}
