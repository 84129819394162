import { Button, Colors } from "@blueprintjs/core";

import { Box, Flex } from "@/components/layout/flexbox";
import EvaluationContextButton from "@/components/pages_logged_in/roleplays/details/evaluation-criteria/EvaluationContextButton";
import { EvaluationCriteriaDialogs } from "@/components/pages_logged_in/roleplays/details/utils";
import IconCircle from "@/components/pieces/IconCircle";

import { BehaviorCriterionType } from "./types";

export default function EvaluationCriteriaItem({
  criterion,
  onOpenDialog,
  canEdit = false,
  canDelete = false,
  isTemplate = false,
  pickTemplateLoading = false,
  isPicked = false,
  onPickTemplate,
}: {
  criterion: BehaviorCriterionType;
  onOpenDialog: (
    dialog: EvaluationCriteriaDialogs,
    criterion?: BehaviorCriterionType,
  ) => void;
  canEdit?: boolean;
  canDelete?: boolean;
  isTemplate?: boolean;
  pickTemplateLoading?: boolean;
  isPicked?: boolean;
  onPickTemplate?: (template: BehaviorCriterionType) => void;
}) {
  return (
    <Box
      className="bas round-corners"
      mt={16}
      backgroundColor="#FFF"
      p={24}
      css={{ overflow: "hidden" }}
    >
      <Flex alignItems="center" justifyContent="space-between" mb={2}>
        <Box
          fontWeight={600}
          fontSize={18}
          flex={1}
          style={{
            wordWrap: "break-word",
            overflowWrap: "break-word",
            minWidth: 0,
          }}
        >
          {criterion.name}
        </Box>

        {canEdit && (
          <EvaluationContextButton
            isCriterion
            criterion={criterion}
            onOpenDialog={onOpenDialog}
            canDelete={canDelete}
          />
        )}
        {isTemplate && (
          <Button
            text={isPicked ? "Added" : "Add"}
            rightIcon={isPicked ? "tick" : "plus"}
            style={{
              color: isPicked ? Colors.WHITE : "#394B59",
              backgroundColor: isPicked ? Colors.GREEN4 : Colors.LIGHT_GRAY5,
              borderRadius: "4px",
            }}
            disabled={isPicked}
            loading={pickTemplateLoading}
            onClick={() => onPickTemplate?.(criterion)}
          />
        )}
      </Flex>
      <Flex mt={3} flexDirection="row" justifyContent="space-between">
        <Flex css={{ gap: 16 }} flexDirection="column">
          {criterion?.items?.map((item) => (
            <Flex key={item.id}>
              <IconCircle
                icon="tick"
                backgroundColor="#D3F2E5"
                color="#0D8050"
                size={24}
                iconSize={14}
              />
              <Box ml={12} css={{ flex: 1 }}>
                {item.name}
              </Box>
            </Flex>
          ))}
        </Flex>
      </Flex>
    </Box>
  );
}
