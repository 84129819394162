import { ReactNode, useEffect } from "react";

import {
  Outlet,
  RouteObject,
  useLocation,
  useNavigationType,
} from "react-router-dom";

import { useStorePromoCode } from "@/components/helpers/custom-hooks/use-store-promo-code";
import { useStoreSignUpParams } from "@/components/helpers/custom-hooks/use-store-signup-params";
import NotFound from "@/components/pages_public/404";
import GlobalErrorPage from "@/components/pages_public/500";
import pageRoutes from "@/server/pages/routes";

/**
 * Extended RouteObject with optional name property for easier identification
 * Generated by Copilot
 */
type NameRouteObject = RouteObject & {
  name?: string;
  title?: string;
  children?: NameRouteObject[];
};

// Initialize browser history and store parameters
function InitializeParams() {
  const { storeParams } = useStoreSignUpParams();
  const storePromoCode = useStorePromoCode();

  useEffect(() => {
    storeParams();
    storePromoCode();
  }, [storeParams, storePromoCode]);

  return null;
}

function ScrollTop({ children }: { children: ReactNode }) {
  const location = useLocation();
  const navigationType = useNavigationType();

  useEffect(() => {
    if (navigationType === "PUSH") {
      window.scrollTo(0, 0);
    }
  }, [location, navigationType]);

  return children;
}

function RootWrapper() {
  return (
    <ScrollTop>
      <InitializeParams />
      <Outlet />
    </ScrollTop>
  );
}

export const routes: NameRouteObject[] = [
  {
    path: "/",
    element: <RootWrapper />,
    errorElement: <GlobalErrorPage />,
    children: pageRoutes as unknown as NameRouteObject[],
  },
  // Add a catch-all route to prevent 404s on client-side routes
  { path: "*", element: <NotFound /> },
];
