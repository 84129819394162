import { algoliasearch, SearchClient } from "algoliasearch";

import { getUser } from "@/components/session/JwtTokenManager";
import generateApiKey from "@/server/algolia/generateApiKey";

let searchClient: SearchClient | null = null;

const publicApiKey = import.meta.env.SSR
  ? generateApiKey([])
  : window.ALGOLIA_PUBLIC_KEY || ALGOLIA_SECURED_KEY;

export function getSearchClient(): SearchClient {
  if (!searchClient) {
    searchClient = createAlgoliaClient();
  }
  return searchClient;
}

export function createAlgoliaClient(): SearchClient {
  const user = getUser();
  const apiKey = user ? user.algoliaApiKey : publicApiKey;
  return algoliasearch(ALGOLIA_APPLICATION_ID, apiKey);
}
