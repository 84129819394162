import { useState } from "react";

import {
  Button,
  Callout,
  FormGroup,
  Intent,
  TextArea,
} from "@blueprintjs/core";
import Select from "react-select";

import { Box, Flex } from "@/components/layout/flexbox";
import Options from "@/components/pages_logged_in/program/components/set-components/Options";
import RatingScaleTemplates from "@/components/pages_logged_in/program/components/set-components/RatingScaleTemplates";
import { ratingScaleOptionTemplates } from "@/components/pages_logged_in/program/components/set-components/utils";
import StyledDialog from "@/components/pieces/StyledDialog";

export const responseTypes = [
  { value: "RATING_SCALE", name: "Rating Scale" },
  { value: "SINGLE_LINE", name: "Single Line" },
  { value: "MULTI_LINE", name: "Multi Line" },
];

function AddQuestionDialog({
  open,
  onClose,
  onSave,
  selectedQuestion,
  allowEditResponseType = false,
}) {
  const [question, setQuestion] = useState(selectedQuestion);
  const [errorMessage, setErrorMessage] = useState(null);
  const [selectedTemplate, setSelectedTemplate] = useState(0);

  const handleOnClosing = () => {
    setQuestion(null);
    setErrorMessage(null);
  };

  const handleOnClose = () => {
    onClose();
  };

  const handleTemplateChange = (selectedIndex) => {
    setSelectedTemplate(selectedIndex);
    setQuestion({
      ...question,
      options: ratingScaleOptionTemplates[selectedIndex].options,
    });
  };

  const selectedType = question?.answerType;
  const isRatingScale = selectedType === responseTypes[0].value;
  const isEditMode = !!selectedQuestion;

  const handleOnSave = () => {
    if (isRatingScale) {
      const hasEmptyOption = question?.options?.some(
        (option) => !option?.label?.trim(),
      );
      if (hasEmptyOption) {
        setErrorMessage("Please fill in all rating scale options");
        return;
      }
    }

    onSave(question);
  };

  const options = responseTypes.map((type) => ({
    value: type.value,
    label: type.name,
  }));

  return (
    <StyledDialog
      isOpen={open}
      lazy={true}
      title={
        <Box fontSize={18} fontWeight={600}>
          {isEditMode ? "Edit Question" : "Add Question"}
        </Box>
      }
      onClosing={handleOnClosing}
      onClose={handleOnClose}
      width="480px"
    >
      <Box css={{ height: "100%" }} p={3}>
        {errorMessage && (
          <Box mb={3}>
            <Callout icon={null} intent={Intent.DANGER}>
              {errorMessage}
            </Callout>
          </Box>
        )}
        <FormGroup
          subLabel="This is the question that will be displayed to users"
          label={<b>Question</b>}
        >
          <TextArea
            autoResize={true}
            onChange={(e) =>
              setQuestion({ ...question, prompt: e.target.value })
            }
            value={question?.prompt}
            large={true}
            fill={true}
            placeholder="Enter question..."
            style={{ fontSize: 16 }}
          />
        </FormGroup>

        <FormGroup label={<b>Response Type</b>}>
          <Select
            isDisabled={isEditMode && !allowEditResponseType}
            placeholder="Select an option"
            value={options.find((type) => type.value === selectedType)}
            onChange={(selected) => {
              setQuestion({
                ...question,
                answerType: selected.value,
                options:
                  selected.value === responseTypes[0].value
                    ? ratingScaleOptionTemplates[selectedTemplate].options
                    : undefined,
              });
            }}
            isSearchable={true}
            options={options}
          />
        </FormGroup>
        {isRatingScale && (
          <Box>
            <RatingScaleTemplates
              ratingScaleOptionTemplates={ratingScaleOptionTemplates}
              selectedTemplate={selectedTemplate}
              onSelectTemplate={handleTemplateChange}
            />
            <FormGroup>
              <Options
                title="Rating Scale"
                titleCss={{ fontSize: 16 }}
                options={question?.options}
                onChangeOptions={(newOptions) => {
                  setQuestion({ ...question, options: newOptions });
                }}
                editMode
              />
            </FormGroup>
          </Box>
        )}

        <FormGroup style={{ margin: 0 }}>
          <Flex justifyContent="space-between">
            <Button
              large={true}
              intent="primary"
              disabled={!question?.prompt?.trim() || !question?.answerType}
              onClick={handleOnSave}
              style={{ minWidth: 130 }}
            >
              {"Save"}
            </Button>
          </Flex>
        </FormGroup>
      </Box>
    </StyledDialog>
  );
}

export default AddQuestionDialog;
